import templateUrl from './input-filter.component.html';

class InputFilter {
    constructor($window, $element, $timeout) {
        this.$window = $window;
        this.$element = $element;
        this.$timeout = $timeout;
        this.filterVisible = false;
    }

    $onInit() {
        const self = this;
        this.isActive = this.inputFilterIsActive || false;

        angular.element(this.$window).on('mouseup.k2InputFilter', (e) => {
            if (!this.filterVisible) return;

            const blacklist = this.$element.find('.k2-filter-menu, .k2-filter-toggle');
            if (!blacklist.is(e.target) && blacklist.has(e.target).length === 0) {
                self.$timeout(() => self.filterVisible = false);
            }
        });
    }

    toggleFilter() {
        this.filterVisible = !this.filterVisible;
    }

    clear() {
        this.isActive = false;
        this.inputFilterModel = '';

        this.$timeout(() => this.inputFilterAction(), 0);

        const addressInput = this.$element.find('input#addressInput');
        addressInput.focus();
    }

    filter() {
        this.isActive = true;

        this.filterVisible = false;
        this.inputFilterAction();
    }
}

InputFilter.$inject = ['$window', '$element', '$timeout'];
export const inputFilterComponent = {
    templateUrl: templateUrl,
    controller: InputFilter,
    bindings: {
        inputFilterModel: '=',
        inputFilterAction: '&',
        inputFilterPlaceholder: '@?',
        inputFilterTitle: '@?',
        inputFilterIsActive: '='
    }
};
