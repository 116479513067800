DeleteMessageModalCtrl.$inject = [
    '$uibModalInstance', 'message', 'MessageService', 'NotificationService'
];

export function DeleteMessageModalCtrl($uibModalInstance, message, messageService, NotificationService) {
    const ctrl = this;
    ctrl.message = message;
    ctrl.closeModal = function () {
        $uibModalInstance.dismiss(ctrl.message);
    };

    ctrl.deleteMessage = function () {
        messageService.delete(ctrl.message).then(function (data) {
            NotificationService.success("Message has been deleted");
            $uibModalInstance.close(ctrl.message);
        }, function (res) {
            NotificationService.error({
                messages: [res.data.message]
            });
        });
    };
}
