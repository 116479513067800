TriageCreateEditMessageModalCtrl.$inject = ['$uibModalInstance', '_', 'rules', 'message', 'template'];

export function TriageCreateEditMessageModalCtrl($uibModalInstance, _, rules, message, template) {
    var vm = this;

    vm.rules = rules;
    vm.editing = !!message;
    vm.save = save;
    vm.cancel = cancel;
    vm.setForm = function (form) { vm.form = form; };
    vm.newMessage = angular.merge({ ruleId: 0 }, template);
    activate();

    function activate() {
        vm.message = message ? copy(message) : vm.newMessage;
    }

    function save() {
        if (vm.form.$invalid) return;
        $uibModalInstance.close(angular.merge(message || {}, vm.message));
    }

    function cancel() {
        $uibModalInstance.dismiss('cancel');
    }

    function copy(message) {
        var copy = _.omit(message, 'attachments');
        copy.patient = _.clone(message.patient);
        return copy;
    }
}
