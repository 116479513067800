(() => {
    'use strict';

    angular.module('kno2.services').service('CsvService', csvService);

    csvService.$inject = ['$http', 'SaveService'];

    function csvService($http, saveService) {

        return {
            getCsvFile: getCsvFile
        };

        function getCsvFile(url, filename, params, callback) {

            // Use an arraybuffer
            $http.get(url, {
                    params: params,
                    headers: { Accept: 'text/csv' },
                    responseType: 'arraybuffer'
                })
                .then((response) => {
                    saveService.saveFile(response.data, response.status, response.headers, filename);
                })
                .catch((response) => {
                    alert('Unable to download the report file. Status: ' + response.status);
                })
                .finally(() => {
                    if (callback) {
                        callback();
                    }
                })
        }
    }

})();
