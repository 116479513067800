import templateUrl from './query.preview.modal.component.html';

angular
    .module('kno2.records')
    .component('k2QueryPreviewModal',
    {
        controller: queryPreviewModalController,
        templateUrl,
        bindings: {
            modalInstance: '<',
            resolve: '<'
        }
    });

queryPreviewModalController.$inject = ['QueryService', 'NotificationModalService'];

function queryPreviewModalController(QueryService, NotificationModalService) {
    var ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.close = close;

    function $onInit() {
        var query = ctrl.resolve.query;

        QueryService.cacheDocument(query).then(function (cacheId) {
            ctrl.cacheId = cacheId;
        })
        .catch(function () {
            NotificationModalService.error('There was an error retrieving the document.');
        });
    }

    function close() {
        ctrl.modalInstance.dismiss();
    }
}
