angular.module('kno2.settings')
    .controller('AddUserModalCtrl',
    ['$timeout', '$uibModalInstance', 'usersFactory', 'NotificationService', 'ValidationService',
        'SessionService', 'DocumentSourceService',
        function ($timeout,
            $uibModalInstance,
            usersFactory,
            NotificationService,
            ValidationService,
            SessionService,
            DocumentSourceService) {

            const ctrl = this;

            ctrl.title = "Create User";
            ctrl.user = {};
            ctrl.selected = {};
            ctrl.organizationId = SessionService.getProfile().organizationId;
            ctrl.validateUsername = validateUsername;
            ctrl.saveChanges = saveChanges;
            ctrl.closeModal = closeModal;
            ctrl.setForm = setForm;
            ctrl.addAll = addAll;

            ctrl.$onInit = onInit;

            function onInit() {
                usersFactory.getJobFunctions().then(function (res) {
                    ctrl.user.jobFunctions = res.data;
                });

                DocumentSourceService.getOrgDocumentSources().then(function (data) {
                    ctrl.documentSources = data;
                });
            }

            function validateUsername(username, callback) {
                ValidationService.validateUsername(username, callback);
            }

            function addAll() {
                if (!ctrl.selected.documentSources)
                    ctrl.selected.documentSources = [];
                _.each(ctrl.documentSources,
                    function (documentSource) {
                        ctrl.selected.documentSources.push(documentSource);
                    });
            }

            function saveChanges() {
                if (ctrl.form && ctrl.form.$valid) {
                    usersFactory.create(ctrl.user)
                        .then(function (res) {
                            // success
                            NotificationService.success("New user has been added successfully.");
                            $uibModalInstance.close(ctrl.user);
                            return res.data.id;
                        },
                        function (res) {
                            NotificationService.serverError(res.data.modelState,
                                "There was an error creating this user.");
                        })
                        .then(function (userId) {
                            var documentSources = _(ctrl.selected.documentSources).map(function (d) {
                                return {
                                    documentSourceId: d.id,
                                    organizationId: ctrl.organizationId
                                };
                            });
                            DocumentSourceService.setDocumentSourcesForUser(userId, documentSources);
                        });
                }
            }

            function closeModal() {
                $uibModalInstance.dismiss('cancel');
            }

            function setForm(form) {
                ctrl.form = form;
            }
        }
    ]);