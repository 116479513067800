(function () {
    'use strict';

    angular.module('kno2.factories')
        .factory('ApiInterceptorFactory', apiInterceptorFactory)

    function apiInterceptorFactory() {
        return {
            'request': (config) => {
                if (_.startsWith(config.url, '/api') || _.includes(config.url, '/odata')) {
                    config.url = `${ENVIRONMENT.baseUrl}${config.url}`
                }
                return config;
            }
        };
    }
})();
