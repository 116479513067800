(function (undefined) {
    'use strict';

    angular
        .module('kno2.filters')
        .filter('empty', emptyFilter);

    emptyFilter.$inject = [];

    function emptyFilter() {
        return function (collection, bool) {
            if (bool === undefined) bool = true;
            return (!!collection && collection.length === 0) === bool;
        };
    }

})();