import templateUrl from './query.directory.component.html';

angular
    .module('kno2.records')
    .component('k2QueryDirectory', {
        controller: QueryDirectoryController,
        templateUrl,
        require: {
            wizard: '^^k2QueryWizard'
        }
    });

QueryDirectoryController.$inject = ['$state', 'QueryService', 'CommonData', 'NotificationService'];

function QueryDirectoryController($state, QueryService, CommonData, NotificationService) {
    var ctrl = this;

    ctrl.$onInit = $onInit;

    function $onInit() {
        ctrl.form = ctrl.wizard.form;
        ctrl.organization = ctrl.wizard.query.organization;
        ctrl.wizard.submit = search;
        ctrl.states = CommonData.states.asArray();
        ctrl.gatherErrors = gatherErrors;
        ctrl.requester = ctrl.wizard.query.requester;
        ctrl.organization.isHealthReferralRegionSearch = false;
    }

    function gatherErrors() {
        let errorList = [];

        if(!ctrl.requester.purpose)
            errorList.push("Purpose of Use is required.");
        if(ctrl.organization.isHealthReferralRegionSearch && !ctrl.organization.zipcode)
            errorList.push("Organization Zip Code is required if performing a Health Referral Region search.");

        return errorList;
    }

    function search() {
        if (ctrl.form.$invalid) {
            NotificationService.errors(gatherErrors(), "There was an error querying Organizations. Please include the following information.");
            return;
        }
        ctrl.searching = true;

        QueryService.queryOrganizations(ctrl.organization)
            .then(function (organizations) {
                ctrl.wizard.organizations = organizations;
                $state.go('records.query.wizard.organizations');
            })
            .catch(function () {
                NotificationService.error('There was an error querying organizations. Please try again.');
            })
            .then(function () {
                ctrl.searching = false;
            });
    }
}
