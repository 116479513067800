chartDirective.$inject = ['$timeout'];
export function chartDirective($timeout) {
    return {
        template: '<div></div>',
        replace: true,
        restrict: 'E',
        link: function (scope, element, attrs) {
            scope.$watch('chartData', function (data) {
                var options = {
                    xaxis: {
                        tickLength: 0,
                        ticks: scope.chartDataTicks
                    },
                    yaxis: {
                        min: 0,
                        tickDecimals: 0
                    },
                    grid: {
                        labelMargin: 10,
                        borderColor: 'transparent',
                        hoverable: true,
                        clickable: false
                    },
                    legend: {
                        noColumns: 3,
                        container: $(".dashboard-chart-legend")
                    }
                };

                $timeout(function () {
                    $.plot(element, data, options);
                    element.show();
                });

                // Tooltips rendering
                element.bind("plothover", function (event, pos, item) {
                    $("#tooltip").remove();
                    if (item) {
                        var tooltip = item.series.data[item.dataIndex][2];
                        var container = $('#chartContainer');
                        var topAdjustments = 60;
                        var leftAdjustments = item.pageX + 50 > $('#content').width() ? -140 : 25;
                        $('<div id="tooltip" class="dashboard-chart-tooltip">' + tooltip + '</div>')
                            .css({
                                position: 'absolute',
                                top: item.pageY - container.offset().top + container.position().top - topAdjustments,
                                left: item.pageX - container.offset().left + leftAdjustments,
                            }).appendTo('#chartContainer').fadeIn(200);
                    }
                });
            });
        }
    };
}
