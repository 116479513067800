'use strict';

export class Auth0Service {
    constructor($state, angularAuth0, $timeout, $q, LocalStorageFactory) {
        this.$state = $state;
        this.angularAuth0 = angularAuth0;
        this.$timeout = $timeout;
        this.$q = $q;
        this.localStorageFactory = LocalStorageFactory;

        this.connections = {
            'azuread': 'kno2-azure-ad',
            'pcc': 'PointClickCare'
        };
    }

    getIdToken() {
        return this.idToken;
    }

    getAccessToken() {
        return this.accessToken;
    }

    login(loginType) {
        const obj = {};
        if (this.connections[loginType]) {
            obj.connection = this.connections[loginType];
        }
        this.angularAuth0.authorize(obj);
    }

    handleAuthentication() {
        return this.$q((resolve, reject) => {
            this.angularAuth0.parseHash((err, authResult) => {
                if (authResult && authResult.accessToken && authResult.idToken) {
                    this.localLogin(authResult);
                    resolve();
                } else if (err) {
                    reject(err);
                }
            });
        });
    }

    localLogin(authResult) {
        this.localStorageFactory.set('auth', { isLoggedIn: true, expiresAt: (authResult.expiresIn * 1000) + new Date().getTime() });
        this.expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();
        this.accessToken = authResult.accessToken;
        this.idToken = authResult.idToken;
    }

    callbackRenewTokens() {
        window.location.hash = '';
        return this.renewTokens();
    }

    scheduleRenewTokens() {
        const currentTime = (new Date()).getTime();
        const auth = this.localStorageFactory.get('auth') || {};
        const scheduledTime = auth.expiresAt
            ? auth.expiresAt - currentTime - 60000
            : 1000 * 60 * 15;

        if (this.renewTokensTimeoutFn) this.$timeout.cancel(this.renewTokensTimeoutFn);
        this.renewTokensTimeoutFn = this.$timeout(() => this.renewTokens(), scheduledTime);
    }

    renewTokens() {
        return this.$q((resolve, reject) => {
            this.angularAuth0.checkSession({}, (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    this.scheduleRenewTokens();
                    this.localLogin(result);
                    resolve();
                }
            });
        });
    }

    logout() {
        this.localStorageFactory.remove('auth');
        this.localStorageFactory.remove('features');
        this.localStorageFactory.remove('redirectUrl');
        this.accessToken = '';
        this.idToken = '';
        this.expiresAt = 0;

        this.angularAuth0.logout();
    }

    isAuthenticated() {
        const auth = this.localStorageFactory.get('auth') || {};
        return auth.isLoggedIn && new Date().getTime() < auth.expiresAt;
    }
}

Auth0Service.$inject = ['$state', 'angularAuth0', '$timeout', '$q', 'LocalStorageFactory'];
