angular.module('kno2.release')
    .controller('IntakeReviewSendMessageModalCtrl', IntakeReviewSendMessageModalCtrl)

IntakeReviewSendMessageModalCtrl.$inject = ['$scope', '$timeout', '$location', '$q', '$sce', 'data', 'ReleaseService', 'NotificationService', 'NotificationModalService', 'AddressesCacheFactory', 'InteroperabilityService'];
function IntakeReviewSendMessageModalCtrl($scope, $timeout, $location, $q, $sce, data, ReleaseService, NotificationService, NotificationModalService, AddressesCacheFactory, InteroperabilityService) {

    $scope.toggleAttachments2PdfEnabled = toggleAttachments2PdfEnabled;
    $scope.closeModal = closeModal;
    $scope.sendMessage = sendMessage;
    activate();

    function activate() {
        $scope.messageStrategy = data.messageStrategy;
        $scope.allowComments = $scope.messageStrategy.getName() == 'new';
        $scope.message = data.message || {};
        $scope.message.attachments2PDF = data.message.attachments2PDF || false;
        $scope.message.attachments2CDA = data.message.attachments2CDA || false;

        $scope.attachments = data.attachments || [];
        $scope.patient = data.patient || {};

        $scope.attachments2PDFEnabled = false;
        $scope.toggleAttachments2PdfEnabled();

        InteroperabilityService.get().then(function (response) {
            $scope.interoperabilitySettings = response.data;
            $scope.message.includeCoverPage = !response.data.suppressDefaultFaxCoverSheet;
            $scope.message.includeMessageBody = !response.data.suppressFaxMetadataPages;
            $scope.message.includeAttachmentDescriptions = !response.data.suppressFaxMetadataPages;
        });

        $scope.$parent.myScrollOptions = {
            'attachments-scroll-wrapper': {
                momentum: false,
                interactiveScrollbars: true,
                scrollbars: true
            },
            'message-body-scroll-wrapper': {
                momentum: false,
                interactiveScrollbars: true,
                scrollbars: true
            }
        };

        $scope.patient.fullName = $scope.patient.firstName;
        if ($.trim($scope.patient.middleName) !== '') {
            $scope.patient.fullName += ' ' + $scope.patient.middleName;
        }
        $scope.patient.fullName += ' ' + $scope.patient.lastName;
        $scope.messageStrategy.initForModal($scope);
    }

    function toggleAttachments2PdfEnabled() {
        if (_.some($scope.attachments)) {
            $scope.attachments2PDFEnabled = true;
        }
    }

    function closeModal() {
        $scope.$close('canceled');
    }

    $scope.hasFaxDestination = function () {
        let hasFax = false;
        //_.forEach($scope.message.toAddresses,
        //    function(address) {
        //        if (address.text.indexOf('@') === -1)
        //            hasFax = true;
        //    });
        return hasFax;
    }

    function sendMessage() {
        $scope.sendInProgress = true;

        var comments = angular.element.trim($scope.message.comments);
        if (!_.isEmpty(comments) && $scope.allowComments) {
            $scope.message.body += '\n\nComments:\n';
            $scope.message.body += comments;
        }

        var message = {
            id: $scope.message.id,
            attachments: $scope.attachments,
            body: $scope.message.body,
            fromAddress: $scope.message.fromAddress,
            patient: $scope.patient,
            patientName: $scope.patient.fullName,
            origin: $scope.message.origin || 'Kno2',
            // Checking to see if there is a subject already set (ie. FW:... or RE:...)
            subject: ($scope.message.subject) ? $scope.message.subject : $scope.message.releaseType,
            toAddress: _.map($scope.message.toAddresses, 'text').join(';'),
            reasonForDisclosure: $scope.message.reasonForDisclosure,
            attachments2PDF: $scope.message.conversion === 'Pdf' || $scope.message.conversion === 'Cda',
            attachments2CDA: $scope.message.conversion === 'Cda',
            originalObjectId: $scope.message.originalMessageId,
            classification: $scope.message.classification,
            properties: {}
        };
        message.properties.includeCoverPage = $scope.message.includeCoverPage;
        message.properties.includeMessageBody = $scope.message.includeMessageBody;
        message.properties.includeAttachmentDescriptions = $scope.message.includeAttachmentDescriptions;

        var servicePromise;
        switch ($scope.messageStrategy.getName()) {
            case 'forward':
                servicePromise = ReleaseService.forwardMessage(data.draftId, message);
                break;
            case 'reply':
                servicePromise = ReleaseService.replyMessage(data.draftId, message);
                break;
            default:
                servicePromise = ReleaseService.sendMessage(data.draftId, message);
                break;
        }

        var errors = [];
        $scope.messageStrategy.validateMessageModel($scope, errors);

        if (!_.isEmpty(errors)) {
            NotificationService.error({ messageTitle: 'Please fix the following errors before sending.', messages: errors });
            $scope.sendInProgress = false;
            $scope.$close('error');
        } else {
            servicePromise.then(function () {
                NotificationService.success('The message was sent successfully.');
                AddressesCacheFactory.add(_.map($scope.message.toAddresses, 'text'));
                $scope.$close('success');
            }).catch(function (response) {
                var msg = 'There was an error sending your message. Please contact support.';
                if (response.data.message)
                    msg = response.data.message;
                NotificationModalService.error(msg);
            }).finally(function () {
                $scope.sendInProgress = false;
            });
        }
    }
}
