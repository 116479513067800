TriageBatchCtrl.$inject = ['$scope', 'TriageServiceFactory'];

export function TriageBatchCtrl($scope, TriageServiceFactory) {
    var vm = this,
        triageService = TriageServiceFactory();

    vm.toggleCollapsed = toggleCollapsed;
    vm.select = triageService.select;
    vm.isSelected = triageService.isSelected;
    vm.moveAttachment = triageService.moveAttachment;
    vm.movePage = triageService.movePage;
    vm.selectedPages = triageService.selectedPages;
    vm.isPageSelected = triageService.isPageSelected;
    vm.toggleSelectedPage = triageService.toggleSelectedPage;
    vm.collections = triageService.allPageCollections;
    vm.wireUp = wireUp;

    function toggleCollapsed(attachment) {
        attachment.collapsed = !attachment.collapsed;
    }

    function wireUp(item, source, dest) {

        if (source === dest) return;

        var from = item.$$attachment,
            attachments = triageService.allAttachments(),
            to = _.find(attachments, function (a) {
                return a.pages === dest;
            });

        item.$$attachment = to;
        triageService.cleanUp();
    }
}
