(function () {
    'use strict';

    angular.module('kno2.services')
        .service('AttachmentService', AttachmentService);

    AttachmentService.$inject = ['$http', '$q', 'Upload', '_'];
    function AttachmentService($http, $q, Upload, _) {

        return {
            getAttachment,
            getAttachmentPdfContent,
            uploadAttachment,
            deleteAttachment,
            saveAttachmentDetails,
            transformAttachment,
            transformAttachments,
            convertAttachment,
            convertAttachments,
            restoreAttachment,
            getExtensions,
            requestPreview,
            extractPatient,
            getExtractedPatient
        };

        function requestPreview(messageId, attachmentId) {
            return $http.put(`/api/messages/${messageId}/attachments/${attachmentId}/generatePreview`, { throbber: false });
        }

        function getAttachment(messageId, attachmentId) {
            return $http.get(`/api/messages/${messageId}/attachments/${attachmentId}`, { throbber: false });
        }

        function getAttachmentPdfContent(messageId, attachmentId) {
            return $http.get(`/api/messages/${messageId}/attachments/${attachmentId}`, {
                headers: {
                    Accept: 'application/pdf'
                },
                responseType: 'blob'
            }).then(x => x.data);
        }

        function uploadAttachment(messageId, file, data) {

            data.convert = true;
            data.usePriorityQueue = true;

            return Upload.upload({
                url: `/api/messages/${messageId}/attachments`,
                data: {
                    file: file,
                    attachmentMeta: Upload.json(data)
                },
                throbber: false
            });
        }

        function deleteAttachment(messageId, attachmentId) {
            return $http.delete(`/api/messages/${messageId}/attachments/${attachmentId}`);
        }

        function saveAttachmentDetails(messageId, attachments) {
            return $http.put(`/api/messages/${messageId}/attachments`, attachments);
        }

        function transformAttachment(messageId, attachmentId, transforms, now) {
            const params = (now == null) ? null : { now };
            return $http.put(`/api/messages/${messageId}/attachments/${attachmentId}/transforms`, transforms, { params });
        }

        function transformAttachments(messageId, attachments, now) {
            var promises = _.reduce(attachments, function (result, a) {
                if (a.$$transforms && Object.keys(a.$$transforms).length)
                    result.push(transformAttachment(messageId, a.id, a.$$transforms, now).then(function () {
                        delete a.$$transforms.rotations;
                        delete a.$$transforms.deletions;
                    }));
                return result;
            }, []);

            return $q.all(promises);
        }

        function convertAttachment(messageId, attachmentId, type) {
            const params = { type: type };
            return $http.put(`/api/messages/${messageId}/attachments/${attachmentId}/convert`, null, { params });
        }

        function convertAttachments(messageId, attachments, type) {
            const promises = _.map(attachments, function (a) {
                return convertAttachment(messageId, a.id, type);
            });

            return $q.all(promises);
        }

        function restoreAttachment(messageId, attachmentId) {
            return $http.put(`/api/messages/${messageId}/attachments/${attachmentId}/restore`);
        }

        function getExtensions() {
            return $http.get('/api/attachments/extensions', { throbber: false }).then(function (response) {
                return response.data;
            });
        }

        function extractPatient(messageId, attachmentId) {
            return $http.put(`/api/messages/${messageId}/attachments/${attachmentId}/patient/extract`)
                .then(response => response.data);

        }

        function getExtractedPatient(messageId, attachmentId) {
            return $http.get(`/api/messages/${messageId}/attachments/${attachmentId}/patient`)
                .then(response => response.data);
        }
    }

})();