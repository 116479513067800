angular.module('kno2.filters')
    .filter('reservedWord', function() {

        var obfuscateWord = function(word) {
            return _.reduce(word.split(''), function(word) {
                return word += "* ";
            }, "");
        };

        return function(value, censor) {
            var trimmed = $.trim(value);
            var word = (censor === false) ? trimmed : obfuscateWord(trimmed);
            return word;
        };
    });
