PatientLookupModalCtrl.$inject = ['_', '$uibModalInstance', '$timeout', 'IntegrationsService', 'patient', 'patientLookupTemplate', 'visitEnabled', 'supportsGenderOnSearch', 'NotificationService'];

var tempCachedPatients = {};

export function PatientLookupModalCtrl(_, $uibModalInstance, $timeout, IntegrationsService, patient, patientLookupTemplate, visitEnabled, supportsGenderOnSearch, NotificationService) {
    var self = this;

    self.patientLookupTemplate = patientLookupTemplate;
    self.patient = mapPatient(patient);
    self.visitEnabled = visitEnabled;
    self.search = search;
    self.selectPatient = selectPatient;
    self.showVisits = showVisits;
    self.hideVisits = hideVisits;
    self.selectVisit = selectVisit;
    self.hideBack = !!patient.patientResourceId;
    self.confirm = confirm;
    self.dismiss = dismiss;
    self.capabilities = null;
    self.showInactivePatientsControl = false;
    self.clearSearch = clearSearch;
    self.supportsGenderOnSearch = supportsGenderOnSearch;

    activate();

    function activate() {
        IntegrationsService.getCapabilities()
            .then(function (data) {
                self.capabilities = data.capabilities;
                self.showInactivePatientsControl = _.some(self.capabilities, x => _.some(x.properties, y => y.name === 'includeInactive'));
            });
        if (patient.integrationMeta && patient.integrationMeta.patientId) {
            var p = tempCachedPatients[patient.integrationMeta.patientId];
            if (!p) {
                p = {
                    patientResourceId: patient.integrationMeta.patientId
                };

                self.patientsLoading = true;
                IntegrationsService.getPatient(patient, patient.integrationMeta.patientId).then(function (current) {
                    tempCachedPatients[patient.integrationMeta.patientId] = self.selectedPatient = current;
                    self.patientsLoading = false;
                });
            }

            showVisits(p);
        }
    }

    function search() {
        $timeout(function () {
            if (!self.visitsVisible && self.patientLookupForm.$valid) {
                self.patientsLoading = true;
                IntegrationsService.getPatients(self.patient).then(function (patients) {
                    self.patients = patients;

                    if (patients.length === 1)
                        selectPatient(patients[0]);

                    self.patientsLoading = false;
                }, err => {
                    self.patientsLoading = false;
                    if (err.data && err.data.message) {
                        NotificationService.error(`Patient Search - ${err.data.message}`);
                    }
                });
            }
        });
    }

    function selectPatient(patient) {
        tempCachedPatients[patient.patientResourceId] = patient;
        if (self.selectedPatient === patient)
            patient = null;
        self.selectedPatient = patient;
    }

    function showVisits(patient) {
        tempCachedPatients[patient.patientResourceId] = patient;
        self.selectedPatient = patient;
        self.visitsLoading = true;
        self.visitsVisible = true;
        IntegrationsService.getVisits(patient.patientResourceId).then(function (visits) {
            self.visits = visits;
            self.visitsLoading = false;
        });
    }

    function hideVisits() {
        self.visits = null;
        self.selectedVisit = null;
        self.visitsVisible = false;
    }

    function selectVisit(visit) {
        if (self.selectedVisit === visit)
            visit = null;
        self.selectedVisit = visit;
    }

    function confirm() {
        $uibModalInstance.close({
            patient: self.selectedPatient,
            visit: self.selectedVisit
        });
    }

    function dismiss() {
        $uibModalInstance.dismiss('cancel');
    }

    function clearSearch() {
        self.patient = mapPatient({});
        self.patients = null;
    }

    function mapPatient(p) {
        if (p) {
            let name = undefined;
            if (self.patientLookupTemplate === 'App/src/intake/message/intake.message.hcs-patient-lookup-modal.html') {
                if (p.firstName || p.lastName) {
                    name = `${p.lastName || ''}, ${p.firstName || ''}`;
                }
            }
            const obj = {
                patientId: p.patientId,
                firstName: p.firstName,
                lastName: p.lastName,
                birthdate: p.birthDate,
                name: name,
                includeInactive: p.includeInactive,
                medicalRecordNumber: p.medicalRecordNumber
            }
            if (p.birthDate) {
                obj.birthdate = moment(p.birthDate).format('YYYY-MM-DD');
            }
            return obj;
        }
        return {};
    }
}
