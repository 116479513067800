IdentityTermsAndConditionsModal.$inject = ['$scope', '$uibModalInstance', 'EulasService', 'NotificationService', 'SessionService', '$timeout', '$window', '$state'];

export function IdentityTermsAndConditionsModal($scope, $uibModalInstance, eulasService, notificationService, sessionService, $timeout, $window, $state) {

    $scope.title = "License Agreement";
    $scope.userProfile = sessionService.getProfile();
    $scope.acceptedTermsAndConditions = false;

    $scope.setForm = function (form) {
        $scope.form = form;
    };

    $scope.printTermsAndConditions = function () {
        if ($scope.form.$valid) {
            var url = `${$state.href('termsOfUse')}?print=true`;
            $window.open(url, "_blank");
        }
    };

    $scope.acceptTermsAndConditions = function () {
        eulasService.accept($scope.eula)
            .then(function () {
                sessionService.refreshProfile();
                $uibModalInstance.close();
            })
            .catch(function () {
                notificationService.error("An error occurred when accepting License Agreement.");
            });
    };

    eulasService.getActive().then(function (response) {
        $scope.eula = response.data;
    });

    eulasService.getViewActive().then(function (response) {
        $scope.eulaHtml = response.data;
    });
}
