import editUserModalTemplate from './settings.users.edit-user-modal.html';
import orgsModalTemplate from './settings.users.orgs-modal.html';
import disableUserModalTemplate from './settings.users.disable-user-modal.html';
import resendInviteModaltemplate from './settings.users.resend-invitation-modal.html';
import userActivityModalTemplate from './settings.users.user-activity-modal.html';
import subscriptionLicensedModalTemplate from '../subscription/settings.subscription.licensed-modal.html';
import subscriptionUpgradeModalTemplate from '../../settings/subscription/settings.subscription.upgrade-modal.html';
import resetPasswordModalTemplate from '../../account/profile/account.profile.reset-password-modal.html';

angular.module('kno2.settings')
    .controller('UsersCtrl', ['$scope', '$uibModal', '$timeout', '$window', 'usersFactory', 'SessionService', 'NotificationService', 'PagingFactory', 'SubscriptionsFactory', 'CommonData',
        function ($scope, $uibModal, $timeout, $window, usersFactory, sessionService, NotificationService, PagingFactory, SubscriptionsFactory, commonData) {

            $scope.currentUser = sessionService.getProfile();

            $scope.showDisabled = function (disabled) {
                $scope.showDeletedUsers = disabled;
                getUsersData($scope.currentPage, $scope.pagedItemRange.value);
            };

            $scope.editUser = function (userId) {
                var modalInstance = $uibModal.open({
                    templateUrl: editUserModalTemplate,
                    controller: 'EditUserModalCtrl',
                    controllerAs: '$ctrl',
                    resolve: {
                        userId: function () {
                            return userId;
                        }
                    }
                });
                modalInstance.result.then(function (x) {
                    getUsersData($scope.currentPage, $scope.pagedItemRange.value);
                }, angular.noop);
            };

            $scope.userOrgs = function (userId) {
                var modalInstance = $uibModal.open({
                    templateUrl: orgsModalTemplate,
                    controller: 'AdminOrgUsersModalCtrl',
                    windowClass: 'modal-800',
                    resolve: { data: function () { return userId; } }
                });

                modalInstance.result.then(function () {
                    getUsersData($scope.currentPage, $scope.pagedItemRange.value);
                }, angular.noop);
            };

            $scope.addUser = function () {
                checkSubscriptionUpgrade({ mode: 'addUser' });
            };

            var addUser = function () {
                var modalInstance = $uibModal.open({
                    templateUrl: editUserModalTemplate,
                    controller: 'AddUserModalCtrl',
                    controllerAs: '$ctrl'
                });

                modalInstance.result.then(function (x) {
                    getUsersData($scope.currentPage, $scope.pagedItemRange.value);
                }, angular.noop);
            };

            var executeAction = function (operation) {
                switch (operation.mode) {
                    case 'addUser':
                        addUser();
                        break;
                    case 'enableUser':
                        usersFactory.enable(operation.user, 1).then(function () {
                            // success
                            NotificationService.success("User has been enabled.");
                            getUsersData($scope.currentPage, $scope.pagedItemRange.value);
                        }, function (res) {
                            // failure
                            NotificationService.serverError(res.data.modelState, "There was an error enabling the user.");
                        });
                        break;
                }
            };

            var getFilteredPlans = function (plan) {
                var filteredPlans = _.reject(plan.plans, function (p) {
                    if (((p.allowedUsers > plan.allowedUsers) && (p.monthlyCost > 0)) ||
                        (p.id == -1 && plan.isFreePlan) ||
                        (p.id > 0 && p.monthlyCost == -1) ||
                        (p.id == plan.planId)) {
                        return false;
                    } else {
                        return true;
                    }
                });
                plan.plans = filteredPlans;
                return plan;
            };

            var resfreshSubscription = function () {
                SubscriptionsFactory.get()
                    .then(function (res) {
                        $scope.plan = getFilteredPlans(res.data);
                    });
            };

            var isOrgLicensed = function () {
                return $scope.currentUser.resellerType == commonData.resellerTypes.findByValue("Licensing").key;
            }

            var checkSubscriptionUpgrade = function (operation) {
                SubscriptionsFactory.get()
                    .then(function (res) {
                        $scope.plan = getFilteredPlans(res.data);


                        // If Subscription limit is reached and handles billing, then confirm subscription upgrade.
                        if ($scope.plan.isUsersLimitReached) {

                            if (isOrgLicensed()) {
                                $uibModal.open({
                                    templateUrl: subscriptionLicensedModalTemplate,
                                    controller: 'SubscriptionLicensedModalCtrl',
                                    windowClass: 'subscription-licensed'
                                }).result.then(() => { }, () => { });
                            } else {
                                var modalInstance = $uibModal.open({
                                    templateUrl: subscriptionUpgradeModalTemplate,
                                    controller: 'SubscriptionUpgradeModalCtrl',
                                    windowClass: 'subscription-plans',
                                    resolve: {
                                        plan: function () {
                                            return $scope.plan;
                                        }
                                    }
                                });

                                modalInstance.result.then(function (planId) {
                                    // Update Subscription
                                    SubscriptionsFactory.update(null, planId).then(function () {
                                        // success
                                        NotificationService.success("Subscription plan has been updated.");
                                        resfreshSubscription();

                                        executeAction(operation);
                                    },
                                        function (res) {
                                            // failure
                                            NotificationService.serverError(res.data.modelState, "There was an error upgrading your subscription plan.");
                                        });
                                }, () => { });
                            }
                        } else {
                            executeAction(operation);
                        }
                    });
            };

            $scope.editUserDisabledClass = function (user) {
                if ($scope.currentUser.isNetworkAdmin) {
                    var userOrg = _.find(user.organizations, function (u) {
                        return u.id === $scope.currentUser.organizationId;
                    });
                    return userOrg && user.isEnabled ? '' : 'disabled';
                } else {
                    return user.isEnabled ? '' : 'disabled';
                }
            };

            $scope.canEnableUser = function (user) {
                if ($scope.currentUser.isNetworkAdmin) {
                    var userOrg = _.find(user.organizations, function (u) {
                        return u.id === $scope.currentUser.organizationId;
                    });
                    return userOrg;
                } else {
                    return true;
                }
            };

            $scope.showPage = function (page, currentPage) {
                return PagingFactory.getPageRange(page, currentPage, $scope.pagedItemRange.value, $scope.pagedItemCount).visible[page - 1];
            };

            $scope.isFirstPageRange = function (page, currentPage) {
                return PagingFactory.getPageRange(page, currentPage, $scope.pagedItemRange.value, $scope.pagedItemCount).isFirst;
            };

            $scope.isLastPageRange = function (page, currentPage) {
                return PagingFactory.getPageRange(page, currentPage, $scope.pagedItemRange.value, $scope.pagedItemCount).isLast;
            };

            $scope.userEnable = function (user, enable) {
                if (!enable) {
                    var modal = $uibModal.open({
                        templateUrl: disableUserModalTemplate,
                        controller: 'DisableUserModalCtrl',
                        resolve: {
                            user: function () {
                                return user;
                            }
                        }
                    });

                    modal.result.then(function (disabledUser) {
                        if (disabledUser.isIdProofed && !$scope.currentUser.isIdProofed) {
                            // Reload to force Admin to ID Proof
                            $window.location.reload();
                        }
                        getUsersData($scope.currentPage, $scope.pagedItemRange.value);
                    }, angular.noop);
                } else {
                    checkSubscriptionUpgrade({ mode: 'enableUser', user: user });
                }
            };

            $scope.changePassword = function (user) {
                var modal = $uibModal.open({
                    templateUrl: resetPasswordModalTemplate,
                    controller: 'ResetPasswordModalCtrl',
                    resolve: {
                        user: function () {
                            return user;
                        }
                    }
                });

                modal.result.then(function () {
                    getUsersData($scope.currentPage, $scope.pagedItemRange.value);
                }, angular.noop);
            };

            $scope.resendInvitation = function (user) {
                var modal = $uibModal.open({
                    templateUrl: resendInviteModaltemplate,
                    controller: 'ResendInvitationModalCtrl',
                    resolve: {
                        user: function () {
                            return user;
                        }
                    }
                });
                modal.result.then(function () {
                    getUsersData($scope.currentPage, $scope.pagedItemRange.value);
                }, angular.noop);
            };

            $scope.showActivity = function (user) {
                $uibModal.open({
                    templateUrl: userActivityModalTemplate,
                    windowClass: 'audit-message-modal',
                    controller: 'UserActivityModalCtrl',
                    resolve: {
                        data: function () {
                            return {
                                id: user.id,
                                title: user.userName,
                                userName: user.userName,
                                eventTypes: [{
                                    label: 'Login Success',
                                    key: 'LoginSuccess'
                                }, {
                                    label: 'Login Fail',
                                    key: 'LoginFail'
                                }]

                            };
                        }
                    }
                }).result.then(() => { }, () => { });
            };

            $scope.updatePagedItemCount = function () {
                $scope.pagedItemCount = angular.element('#pagedItemSelect').val();
                getUsersData(1, $scope.pagedItemRange.value);
            };

            $scope.sort = function (column) {
                $scope.setSort(column);
                getUsersData(1, $scope.pagedItemRange.value);
            };

            $scope.setSort = function (column) {
                var _sort = _.find($scope.sortBy, {
                    column: column
                });
                if ($scope.sortBy[0].column == column) {
                    _sort.isSortDesc = !_sort.isSortDesc;
                };
                // Sorted column is always at index 0.
                var _sorts = new Array();
                _sorts.push(_sort);
                angular.forEach($scope.sortBy, function (value) {
                    if (_.isUndefined(_.find(_sorts, {
                        column: value.column
                    }))) {
                        _sorts.push(value);
                    }
                });
                $scope.sortBy = _sorts;
            };

            $scope.sortedBy = function (column) {
                return ($scope.sortBy[0].column == column);
            };

            $scope.sortIconClass = function (column) {
                var _sort = _.find($scope.sortBy, {
                    column: column
                });
                var selectedSortClass = (_sort.column == $scope.sortBy[0].column) ? String.fromCharCode(32) + 'sortby-selected' : '';
                return (_sort.isSortDesc ? 'fa fa-chevron-down' : 'fa fa-chevron-up') + selectedSortClass;
            };

            $scope.isCurrentUser = function (userId) {
                return userId.toUpperCase() == $scope.currentUser.userId.toUpperCase();
            };

            var searchTimeout;
            $scope.searchChanged = function (searchTerm) {
                if (searchTerm == undefined) return;
                if (searchTimeout) $timeout.cancel(searchTimeout);

                searchTimeout = $timeout(function () {
                    getUsersData(1, $scope.pagedItemRange.value);
                }, 500);
            };

            $scope.$watch('search.criteria', $scope.searchChanged);

            var resultHandler = function (res) {
                $scope.users = res.items;

                $scope.pagedItemCount = res.totalCount;
                $scope.maxPage = Math.ceil(res.totalCount / $scope.pagedItemRange.value);
                $scope.pages = _.range(1, $scope.maxPage + 1);

                if ($scope.currentPage > $scope.maxPage && res.totalCount) {
                    $scope.gotoPage($scope.maxPage);
                }
            };

            var getUsersData = function (page, pageSize) {
                $scope.currentPage = page;

                var searchTerm = $scope.search != undefined ? $scope.search.criteria : undefined;

                usersFactory.list($scope.sortBy, page, pageSize, $scope.showDeletedUsers, searchTerm, true)
                    .then(resultHandler);

                resfreshSubscription();
            };

            var init = function () {
                $scope.showDeletedUsers = false;
                $scope.sortBy = [{
                    column: 'userName',
                    isSortDesc: false
                }, {
                    column: 'name',
                    isSortDesc: false
                }, {
                    column: 'role',
                    isSortDesc: false
                }, {
                    column: 'isEnabled',
                    isSortDesc: false
                }];
                $scope.currentPage = 1;
                $scope.pagedItemRanges = [{
                    value: '10'
                }, {
                    value: '25'
                }, {
                    value: '50'
                }, {
                    value: '100'
                }];
                $scope.pagedItemRange = $scope.pagedItemRanges[0];

                getUsersData($scope.currentPage, $scope.pagedItemRange.value);
            };

            $scope.gotoPage = function (page) {
                if (page < 1) page = 1;
                else if (page > $scope.maxPage) page = $scope.maxPage;

                $scope.currentPage = page;
                getUsersData($scope.currentPage, $scope.pagedItemRange.value);
            };

            init();
        }
    ])




