angular.module('kno2.release')
    .directive("docStatus", function () {
        var PROCESSED_CLASS = "intake-doc-status-success";
        var URGENT_CLASS = "intake-doc-status-important";
        var DEFAULT_CLASS = "intake-doc-status-default";

        return {
            restrict: 'A',
            scope: {
                document: '=document'
            },
            link: function (scope, element) {
                var urgent = scope.document.isUrgent;
                var processed = scope.document.isProcessed;
                var css = urgent ? URGENT_CLASS : DEFAULT_CLASS;
                var text = urgent ? "Urgent" : "Default";
                if (processed) {
                    css = PROCESSED_CLASS;
                    text = "Processed";
                }
                element.addClass(css);
                element.text(text);
            }
        };
    })
    /*
     * Checks every $digest for height changes of the input tags and adjust the search button height accordingly.
     */
    .directive('inputTagResize', ['$window', function ($window) {
        return {
            link: function (scope, elem, attrs) {
                var resizedInputTag = function () {
                    var btnGroup = angular.element('.recipients-search-button');
                    if (btnGroup) {
                        if (btnGroup.is(':hidden'))
                            btnGroup.css('height', 'auto');
                        else
                            btnGroup.css('height', angular.element('.tags').height() + 4);
                    }
                };
                var win = angular.element($window);
                win.bind("resize", resizedInputTag);
                scope.$watch(resizedInputTag);
            }
        }
    }])
   .directive('validateDocumentDate', ['$timeout', 'ValidateFactory', function ($timeout, ValidateFactory) {
       return {
           require: 'ngModel',
           link: function (scope, element, attrs, ctrl) {
               element.bind('blur', function () {
                   $timeout(function () {
                       var message = stringFormat("Document Date must be equal or less than today.");
                       var val = element.val();
                       var momentDate = moment(val, attrs.uibDatepickerPopup.toUpperCase());
                       var valid = momentDate.isValid() && momentDate.toDate() <= moment().toDate();
                       ValidateFactory.setValidity(element, attrs, ctrl, "validdocumentdate", valid, message);
                   });
               });
           }
       };
   }])
;
