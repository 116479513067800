angular.module('kno2.records')
    .service('PartnersService', [
        '$http', '$q', function($http, $q) {

        this.getPartnerDto = function (partnerId) {
            var promise = $http.get('/api/partners/' + partnerId)
                .then(function (response) {
                    return response.data;
                });
            return promise;
        };
}]);