import angular from 'angular';

import { CommonModule } from '../common';
import { intakeRoutes } from './intake.routes';
import { DownloadStatusService } from './message/intake.message.download-status.service'
import { IntakeService } from './intake.service';
import { IntakeCtrl } from './intake.ctrl';
import { AttachmentPreviewModalCtrl } from './intake.attachment-preview-modal.ctrl';
import { AuditMessageModalCtrl } from './intake.audit-message-modal.ctrl';
import { TriageUploadModalCtrl } from './intake.triage-upload-modal.ctrl';

import { IntakeMessageCtrl } from './message/intake.message.ctrl';
import { ExportMessageModalCtrl } from './message/intake.message.export-message-modal.ctrl';
import { ForwardMessageModalCtrl } from './message/intake.message.forward-message-modal.ctrl';
import { ReviewerLookupModalCtrl } from './message/intake.message.reviewer-lookup-modal.ctrl';
import { PatientLookupModalCtrl } from './message/intake.message.patient-lookup-modal.ctrl';
import { OrderLookupModalCtrl } from './message/intake.message.order-lookup-modal.ctrl';

import { IntakeRulesCtrl } from './intake.rules.ctrl';
import { docStatusDirective, k2DropdownDirective } from './intake.directives';
import { deleteMultipleMessagesModalComponent } from './multipledelete/intake.delete-multiple-messages-modal.component';
import { processMultipleMessagesModalComponent } from './multipleprocess/intake.process-multiple-messages-modal.component';

export const IntakeModule = angular
    .module('kno2.intake', [CommonModule])
    .config(intakeRoutes)
    .factory('DownloadStatusService', DownloadStatusService)
    .service('IntakeService', IntakeService)
    .controller('IntakeCtrl', IntakeCtrl)
    .controller('AuditMessageModalCtrl', AuditMessageModalCtrl)
    .controller('TriageUploadModalCtrl', TriageUploadModalCtrl)
    .controller('AttachmentPreviewModalCtrl', AttachmentPreviewModalCtrl)
    .controller('IntakeMessageCtrl', IntakeMessageCtrl)
    .controller('ExportMessageModalCtrl', ExportMessageModalCtrl)
    .controller('ForwardMessageModalCtrl', ForwardMessageModalCtrl)
    .controller('ReviewerLookupModalCtrl', ReviewerLookupModalCtrl)
    .controller('PatientLookupModalCtrl', PatientLookupModalCtrl)
    .controller('OrderLookupModalCtrl', OrderLookupModalCtrl)
    .controller('IntakeRulesCtrl', IntakeRulesCtrl)
    .component('k2ProcessMultipleMessagesModalComponent', processMultipleMessagesModalComponent)
    .component('k2DeleteMultipleMessagesModalComponent', deleteMultipleMessagesModalComponent)
    .directive('docStatus', docStatusDirective)
    .directive('k2Dropdown', k2DropdownDirective)
    .name;
