angular.module('kno2.factories')
    .factory("CommonData", ['_', (_) => {
        return {
            supportTicketAreas: supportTicketAreas(),
            supportTicketSeverities: supportTicketSeverities(),
            sourceTypes: sourceTypes(),
            states: states(),
            confidentialityFlags: confidentialityFlags(),
            idProofingValidation: idProofingValidation(),
            idProofingStatuses: idProofingStatuses(),
            orgActivationStatuses: orgActivationStatuses(),
            colorCodes: colorCodes(),
            dateDisclosures: dateDisclosures(),
            routingRuleStepTypes: routingRuleStepTypes(),
            routingRuleSecurityTypes: routingRuleSecurityTypes(),
            routingRuleConvertToFormat: routingRuleConvertToFormat(),
            routingRuleLocationCode: routingRuleLocationCode(),
            resellerTypes: resellerTypes(),
            disclosurePurposes: disclosurePurposes(),
            additionalRequestTypes: additionalRequestTypes()
        };
        
        function supportTicketAreas() {
            let data = {
                'addOn': 'Add On',
                'directAddressAssignment': 'Address Configuration',
                'apis': 'APIs',
                'documentTypeSetup': 'Document Type Setup',
                'documentViewing': 'Document Viewing',
                'faxNumberConfiguration': 'Fax Number Configuration',
                'intake': 'Intake',
                'process': 'Intake Process',
                'intakeDownload': 'Intake Download',
                'intakeRules': 'Intake Rules',
                'kno2Desktop': `${ENVIRONMENT.brand} Desktop`,
                'kno2Fax': 'Fax',
                'myProfile': 'My Profile',
                'notifications': 'Notifications',
                'other': 'Other',
                'portingFaxNumber': 'Porting Fax Number',
                'release': 'Release',
                'releaseTypeSetup': 'Releases Setup',
                'requestConnectionInvite': 'Request Connection/Invite',
                'userAssignment': 'User Assignment'
            };

            if (ENVIRONMENT.brand === 'Consensus') {
                const temp = data;
                temp['kno2Send'] = 'Kno2Send';
                Object.keys(temp).sort().forEach((key) => data[key] = temp[key]);
            }

            return {
                hash: data,
                asArray: function() {
                    return _.map(this.hash, function (v, k) {
                        return { 'id': k, 'name': v };
                    });
                }
            }
        }

        function supportTicketSeverities() {
            var data = {
                '1': `1 - ${ENVIRONMENT.brand} is not operational`,
                '2': '2 - Feature not working as expected',
                '3': '3 - Enhancement request',
                '4': `4 - Question about ${ENVIRONMENT.brand}`
            };

            return {
                hash: data,
                asArray: function() {
                    return _.map(this.hash, function(v, k) {
                        return { 'id': k, 'name': v };
                    });
                }
            }
        }

        function sourceTypes() {
            var data = {
                'dm': 'DirectMessage'
            };

            return {
                hash: data,
                asArray: function () {
                    return _.map(this.hash, function (v, k) {
                        return { 'id': k, 'name': v };
                    });
                }
            };
        }

        function states() {
            var data = {
                'AL': 'Alabama',
                'AK': 'Alaska',
                'AS': 'American Samoa',
                'AZ': 'Arizona',
                'AR': 'Arkansas',
                'AA': 'Armed Forces Americas',
                'AE': 'Armed Forces Europe',
                'AP': 'Armed Forces Pacific',
                'CA': 'California',
                'CO': 'Colorado',
                'CT': 'Connecticut',
                'DE': 'Delaware',
                'DC': 'District Of Columbia',
                'FM': 'Federated States Of Micronesia',
                'FL': 'Florida',
                'GA': 'Georgia',
                'GU': 'Guam',
                'HI': 'Hawaii',
                'ID': 'Idaho',
                'IL': 'Illinois',
                'IN': 'Indiana',
                'IA': 'Iowa',
                'KS': 'Kansas',
                'KY': 'Kentucky',
                'LA': 'Louisiana',
                'ME': 'Maine',
                'MH': 'Marshall Islands',
                'MD': 'Maryland',
                'MA': 'Massachusetts',
                'MI': 'Michigan',
                'MN': 'Minnesota',
                'MS': 'Mississippi',
                'MO': 'Missouri',
                'MT': 'Montana',
                'NE': 'Nebraska',
                'NV': 'Nevada',
                'NH': 'New Hampshire',
                'NJ': 'New Jersey',
                'NM': 'New Mexico',
                'NY': 'New York',
                'NC': 'North Carolina',
                'ND': 'North Dakota',
                'MP': 'Northern Mariana Islands',
                'OH': 'Ohio',
                'OK': 'Oklahoma',
                'OR': 'Oregon',
                'PW': 'Palau',
                'PA': 'Pennsylvania',
                'PR': 'Puerto Rico',
                'RI': 'Rhode Island',
                'SC': 'South Carolina',
                'SD': 'South Dakota',
                'TN': 'Tennessee',
                'TX': 'Texas',
                'UT': 'Utah',
                'VT': 'Vermont',
                'VI': 'Virgin Islands',
                'VA': 'Virginia',
                'WA': 'Washington',
                'WV': 'West Virginia',
                'WI': 'Wisconsin',
                'WY': 'Wyoming'
            };

            return {
                hash: data,
                asArray: function () {
                    return _.map(this.hash, function (v, k) {
                        return { 'abbr': k, 'name': v };
                    });
                }
            };
        }

        function confidentialityFlags() {
            var data = {
                'Normal': 'Normal',
                'Restricted': 'Restricted',
                'VeryRestricted': 'Very Restricted'
            };

            return {
                hash: data,
                asArray: function () {
                    return _.map(this.hash, function (v, k) {
                        return { 'id': k, 'name': v };
                    });
                },
                getById: function (id) {
                    return this.hash[id];
                }
            };
        }

        function idProofingValidation() {
            var data = {
                "/InitialRequest/Identity/Name/FirstName": "First Name is invalid",
                "/InitialRequest/Identity/Name/LastName": "Last Name is invalid",
                "/InitialRequest/Identity/DateOfBirth": "Date of Birth is invalid",
                "/InitialRequest/Identity/SSN": "Social Security Number is invalid",
                "/InitialRequest/Identity/Address[@addressType='Current']/*/AddressLine[1]": "Current Address is invalid",
                "/InitialRequest/Identity/Address[@addressType='Current']/*/City": "Current City is invalid",
                "/InitialRequest/Identity/Address[@addressType='Current']/*/State": "Current State is invalid",
                "/InitialRequest/Identity/Address[@addressType='Current']/*/ZIP": "Current Zip Code is invalid",
                "/InitialRequest/Identity/Address[@addressType='Former']/*/AddressLine[1]": "Former Address is invalid",
                "/InitialRequest/Identity/Address[@addressType='Former']/*/City": "Former City is invalid",
                "/InitialRequest/Identity/Address[@addressType='Former']/*/State": "Former State is invalid",
                "/InitialRequest/Identity/Address[@addressType='Former']/*/ZIP": "Former Zip Code is invalid"
            };

            return {
                hash: data,
                asArray: function () {
                    return _.map(this.hash, function (v, k) {
                        return { 'key': k, 'value': v };
                    });
                }
            };
        }

        function idProofingStatuses() {
            var data = {
                "Passed": true,
                "Failed": false
            };

            return {
                hash: data,
                asArray: function () {
                    return _.map(this.hash, function (v, k) {
                        return { 'description': k, 'value': v };
                    });
                }
            };
        }

        function orgActivationStatuses() {
            var data = {
                "Activated": true,
                "Not Activated": false
            };

            return {
                hash: data,
                asArray: function () {
                    return _.map(this.hash, function (v, k) {
                        return { 'description': k, 'value': v };
                    });
                }
            };
        }

        function colorCodes() {
            var data = {
                "White": "#ffffff",
                "Light Grey": "#e6e9ed",
                "Dark Grey": "#3f4449"
            };

            return {
                hash: data,
                asArray: function () {
                    return _.map(this.hash, function (v, k) {
                        return { 'name': k, 'hexCode': v };
                    });
                }
            };
        }

        function dateDisclosures() {
            var data = {
                '0': 'All Dates',
                '1': '2 Years from Date Last Seen',
                '2': '3 Years from Date Last Seen',
                '3': '5 Years from Date Last Seen',
                '4': 'Date Range',
                '5': 'Specific Date(s)'
            };

            return {
                hash: data,
                asArray: function () {
                    return _.map(this.hash, function (v, k) {
                        return { 'description': k, 'value': v };
                    });
                }
            };
        }

        function routingRuleStepTypes() {
            const data = {
                '0': 'SendAsHL7Step',
                '1': 'SendToKeyhieStep',
                '2': 'SendToNCHIE',
                '3': 'SendITI41',
                '4': 'SendITI41WithPatientRegistry',
                '5': 'SendITI41WithAdtA08PatientRegistry',
                '6': 'SendHl7ToWebService'
            };

            return {
                hash: data,
                asArray: function () {
                    return _.map(this.hash, function (v, k) {
                        return { 'key': k, 'value': v };
                    });
                },
                findByValue: function (value) {
                    return _.find(this.asArray(), { value: value });
                }
            };
        }

        function routingRuleSecurityTypes() {
            const data = {
                0: 'Certificate',
                1: 'SAML'
            }

            return {
                hash: data,
                asArray: function () {
                    return _.map(this.hash, function (v, k) {
                        return { 'key': k, 'value': v };
                    });
                },
                findByValue: function (value) {
                    return _.find(this.asArray(), { value: value });
                }
            };

        }

        function routingRuleConvertToFormat() {
            var data = {
                '0': 'CDA',
                '1': 'MDM',
                '2': 'ORU'
            };

            return {
                hash: data,
                asArray: function () {
                    return _.map(this.hash, function (v, k) {
                        return { 'key': k, 'value': v };
                    });
                },
                findByValue: function (value) {
                    return _.find(this.asArray(), { value: value });
                }
            };
        }

        function routingRuleLocationCode() {
            var data = {
                '0': 'rrh',
                '1': 'smh'
            };

            return {
                hash: data,
                asArray: function () {
                    return _.map(this.hash, function (v, k) {
                        return { 'key': k, 'value': v };
                    });
                },
                findByValue: function (value) {
                    return _.find(this.asArray(), { value: value });
                }
            };
        }

        function resellerTypes() {
            var data = {
                '0': 'Affiliate',
                '1': 'Licensing'
            };

            return {
                hash: data,
                asArray: function () {
                    return _.map(this.hash, function (v, k) {
                        return { 'key': k, 'value': v };
                    });
                },
                findByValue: function (value) {
                    return _.find(this.asArray(), { value: value });
                },
                findByKey: function (key) {
                    return _.find(this.asArray(), { key: key });
                },
            };
        }

        function disclosurePurposes() {
            return [
                'Medical Request',
                'Business Office Request',
                'Child Welfare Request',
                'Correctional Facility Request',
                'Disability Request',
                'Federal Reporting Requirement',
                'Government Agency',
                'Insurance Claim Request',
                'Insurance Underwriting Request',
                'Labor and Industries Request',
                'Law Enforcement Request',
                'Legal Request',
                'Patient Request',
                'Public Health Reporting',
                'QIO/RAC Request',
                'Quality Assurance Review',
                'Research Study Request',
                'School Request',
                'State Reporting Requirement',
                'Treatment Payment or Operations',
                'Workers Comp Request'
            ];
        }

        function additionalRequestTypes() {
            return [
                'Alcohol',
                'Drug',
                'Genetic Testing',
                'HIV',
                'Mental Health',
                'STDs'
            ];
        }
    }]);
