import templateUrl from './sidebar.component.html';

export class SideBarController {
    constructor($rootScope, SessionService, DirectoryService, $state) {
        this.$rootScope = $rootScope;
        this.sessionService = SessionService;
        this.directoryService = DirectoryService;
        this.$state = $state;
    }

    $onInit() {
        const ctrl = this;
        this.setupProfile();

        this.$rootScope.$on('routeChange',
            function (event, params) {
                if (params.action)
                    ctrl.$rootScope.beforeChange = params.action;
            });

        this.$rootScope.$on('routeChangeClear',
            function () {
                ctrl.$rootScope.beforeChange = null;
            });
    }

    searchDirectory() {
        if (this.profile.orgIsActivated)
            this.directoryService.searchDirectory();
    }

    setupProfile() {
        this.profile = this.sessionService.getProfile();
        this.profile.orgIsActivated ? this.profile.requestLink = "/records" : this.profile.requestLink = "#";
    }

    goToIntake() {
        if (this.profile.orgIsActivated)
            this.go('intake');
    }

    goToRelease() {
        if (this.profile.orgIsActivated)
            this.go('release');
    }

    go(to) {
        if (this.$rootScope.beforeChange) {
            this.$rootScope.beforeChange().then(function () {
                this.$state.go(to, { isLeftNav: true });
            }, function () {
            }).catch(angular.noop);
        } else
            this.$state.go(to, { isLeftNav: true });
    }


}

SideBarController.$inject = ['$rootScope', 'SessionService', 'DirectoryService', '$state'];
export const sideBarComponent = {
    templateUrl: templateUrl,
    controller: SideBarController
};
