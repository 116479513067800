export function QueryMetricsFactory() {
    const queryMetric = { }

    queryMetric.create = (item, type) => {
        const initialValue = 0;
        const obj = {
            friendlyName: _.startCase(item.method),
            elementId: `${type}-${item.method}-chart`,
            match: initialValue,
            results: initialValue,
            success: initialValue,
            noMatch: initialValue,
            noResults: initialValue,
            notFound: initialValue,
            error: initialValue,
            authFail: initialValue,
            totalSuccessful: initialValue,
            totalUnsuccessful: initialValue,
            totalError: initialValue,
            ...item
        }

        obj.totalSuccessful = obj.match + obj.results + obj.success;
        obj.totalUnsuccessful = obj.noMatch + obj.noResults + obj.notFound;
        obj.totalError = obj.error + obj.authFail;

        return obj
    }

    return queryMetric;
}
