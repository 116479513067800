import templateUrl from './query.grid.component.html';

angular
    .module('kno2.records')
    .component('k2QueryGrid', {
        controller: QueryGridController,
        templateUrl
    });

QueryGridController.$inject = ['$state', 'QueryService', 'NotificationService', '_'];

function QueryGridController($state, QueryService, NotificationService, _) {
    var ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.removeHistoryEntry = removeHistoryEntry;
    ctrl.resumeQuery = resumeQuery;
    ctrl.pageChanged = getHistory;

    function $onInit() {
        ctrl.history = { histories: [], currentPage: 1, pageSize: 10 }

        getHistory();
    }

    function getHistory() {
        QueryService.getHistory(ctrl.history.currentPage, ctrl.history.pageSize).then(
            function (result) {
                ctrl.history.histories = result.items;
                ctrl.history.count = result.totalCount;
            },
            function (error) {
                NotificationService.error(error);
            });
    }

    function resumeQuery(query) {
        if (!isEmpty(query.organizationQuery)) {
            if (query.selectedOrganizations && query.selectedOrganizations.length) {
                if (!isEmpty(query.patientQuery)) {
                    return $state.go('.wizard.patient', { id: query.id, submit: true });
                }
                return $state.go('.wizard.patient', { id: query.id });
            }
            return $state.go('.wizard', { id: query.id, submit: true });
        }
        return $state.go('.wizard', { id: query.id });
    }

    function removeHistoryEntry(id) {
        QueryService.removeHistoryEntry(id)
            .then(() => {
                NotificationService.success('Successfully deleted previous search');
                getHistory();
            })
            .catch(() => NotificationService.errorToaster('Error deleting previous search'));
    }

    function isEmpty(obj) {
        return _.every(_.values(_.omit(obj, 'id')), x => !x);
    }
}
