(function() {
    'use strict';

    angular
        .module('kno2.directives')
        .directive('autofocus', autofocus);

    autofocus.$inject = ['$timeout'];
    var timeout;

    function autofocus($timeout) {
        return {
            restrict: 'A',
            link: function (scope, element) {
                if (timeout) $timeout.cancel(timeout);
                timeout = $timeout(function () {
                    element.focus().select();
                    timeout = null;
                }, 200);
            }
        };
    }
})();
