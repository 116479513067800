SendModalCtrl.$inject = ['$scope', '$uibModalInstance', 'SessionService'];
export function SendModalCtrl($scope, $uibModalInstance, SessionService) {

    $scope.onFreePlan = $scope.hasUIResourceAction('releaseSendButton', 'planUpgradePrompt');

    $scope.dismissSendModal = function () {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.oneAtATime = true;
    $scope.kno2Send = function () {
        return $scope.onFreePlan ? "Kno2Send - Upgrade" : "Kno2Send";
    }
    $scope.kno2Fax = function () {
        return $scope.onFreePlan ? "Fax - Upgrade" : "Fax";
    }
    $scope.smartReleases = function () {
        return $scope.onFreePlan ? "Smart releases - Upgrade" : "Smart releases";
    }

    $scope.status = {
        isFirstOpen: true,
        isFirstDisabled: false
    };

    var init = function () {
        $scope.userProfile = SessionService.getProfile();
    };

    init();
}
