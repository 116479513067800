import newFeaturesAndUpdatesModalTemplate from '../new-features/account.dashboard.new-features-modal.html';

DisplayGetStartedModalCtrl.$inject = ['$scope', '$http', '$uibModalInstance', 'SessionService', 'DashboardService', '$uibModal'];
export function DisplayGetStartedModalCtrl($scope, $http, $uibModalInstance, SessionService, DashboardService, $uibModal) {

    $scope.dashboardService = DashboardService;

    $scope.onFreePlan = $scope.hasUIResourceAction('releaseSendButton', 'planUpgradePrompt');

    $scope.getChecklistValues = {
        installKno2Client: false
    };

    $scope.displayNewFeaturesAndUpdates = function () {
        $uibModalInstance.close();
        var modal = $uibModal.open({
            templateUrl: newFeaturesAndUpdatesModalTemplate,
            controller: 'NewFeaturesAndUpdatesModalCtrl'
        });
        modal.result.then(function(success){

        }, function(error){

        });
    }

    $scope.dismissGetStartedModal = function () {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.getStartedSetupComplete = function () {
        $uibModalInstance.close();
    };

    $scope.updateCheckbox = function () {
        var params = $scope.checklistValues;
        var orgId = $scope.userProfile.organizationId;
        DashboardService.putChecklistValue(orgId, params)
            .then(function (response) {
                $scope.finished();
            });
    };

    $scope.finished = function () {
        $scope.allChecked = false;
        $scope.dashboardService.allChecked = false;
        _.each($scope.checklistValues, function (value, key) {
            if ($scope.onFreePlan == true && key == 'setupReleaseTypes') {
                // do nothing
            } else if (value == false) {
                $scope.allChecked = true; // button disabled
                $scope.dashboardService.allChecked = true;
            }
        });
    }

    $scope.getChecklistValues = function (orgId) {
        DashboardService.getChecklistValues(orgId)
            .then(function (response) {
                $scope.checklistValues = response;
                $scope.finished();
            });
    };

    var init = function () {
        $scope.userProfile = SessionService.getProfile();
        $scope.getChecklistValues($scope.userProfile.organizationId);
    };

    init();
}
