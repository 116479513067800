import angularFilter from 'angular-filter';

import { CoreModule } from '../config';
import { BrandService } from './services/brand.service';
import { ConfigurationService } from './services/configuration.service';
import { UnsupportedBrowserService } from './services/unsupported-browser.service';
import { DeleteMessageModalCtrl } from './delete-message/delete-message-modal.ctrl';
import { QueryService } from './services/query.service';
import { SessionTimeoutCtrl } from './session-timeout/session-timeout.ctrl';
import { SessionTimeoutModalCtrl } from './session-timeout/session-timeout.modal.ctrl';
import { UserOrganizationCtrl } from './controllers/user-organization.ctrl';
import { UserInvitationModalCtrl } from './user-invitation/account.dashboard.user-invitation-modal.ctrl';
import { patientAttachmentsComponent } from './components/patient-attachments/patient-attachments.component';
import { toSentenceFilter } from './filters/to-sentence.filter';
import { documentDateFormatFilter } from './filters/document-date-format.filter';
import { statusAbbrevFilter } from './filters/status-abbrev.filter';
import { titlecaseFilter } from './filters/titlecase.filter';
import { PrintService } from './services/print.service';
import { viewerComponent } from './components/viewer.component';
import { viewerPanelComponent } from './components/viewer-panel/viewer-panel.component';
import { cdaPreviewFrameComponent } from './components/cda-preview-frame.component';
import { pdfViewerFrameComponent } from './components/pdf-viewer-frame.component';
import { jumpPagerComponent } from './components/jump-pager/jump-pager.component';
import { dateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { inputFilterComponent } from './components/input-filter/input-filter.component';
import { headerComponent } from './components/header/header.component';
import { footerComponent } from './components/footer/footer.component';
import { sideBarComponent } from './components/sidebar/sidebar.component';
import { notificationListComponent } from './components/notification-list/notification-list.component';
import { messageRowRepeater } from './message-row-repeater/message-row-repeater.component';
import {
    uibDatepickerPopupDirective,
    openOnFocusDirective,
    datepickerPopupReadonlyDirective,
    datePickerInputDirective,
    datePickerAutoFormatDirective,
    datePickerConfig
} from './directives/datepicker.directive';

import { datepickerLocalDateDirective } from './directives/datepickerlocal.directive';
import { paginationComponent } from './components/pagination/pagination.component';
import { QueryMetricsFactory } from './factories/querymetrics.factory';
import { StateRoutingService } from './services/state-routing.service';
import { HomeLayoutCtrl } from '../layouts/home.layout.ctrl';
import { ErrorLayoutCtrl } from '../layouts/error.layout.ctrl';
import { LegalInformationCtrl } from './legal-information/legal-information.ctrl';
import { PrivacyPolicyCtrl } from './privacy/privacy-policy.ctrl';
import { TermsOfUseCtrl } from './terms-of-use/terms-of-use.ctrl';

angular
    .module(CoreModule)
    .service('BrandService', BrandService)
    .service('ConfigurationService', ConfigurationService)
    .factory('QueryService', QueryService)
    .factory('QueryMetricsFactory', [QueryMetricsFactory])
    .controller('DeleteMessageModalCtrl', DeleteMessageModalCtrl)
    .controller('SessionTimeoutCtrl', SessionTimeoutCtrl)
    .controller('SessionTimeoutModalCtrl', SessionTimeoutModalCtrl)
    .controller('UserOrganizationCtrl', UserOrganizationCtrl)
    .controller('UserInvitationModalCtrl', UserInvitationModalCtrl)
    .controller('HomeLayoutCtrl', HomeLayoutCtrl)
    .controller('ErrorLayoutCtrl', ErrorLayoutCtrl)
    .controller('LegalInformationCtrl', LegalInformationCtrl)
    .controller('PrivacyPolicyCtrl', PrivacyPolicyCtrl)
    .controller('TermsOfUseCtrl', TermsOfUseCtrl)
    .component('k2PatientAttachments', patientAttachmentsComponent)
    .component('messageRowRepeater', messageRowRepeater)
    .filter('toSentence', toSentenceFilter)
    .filter('documentDateFormat', documentDateFormatFilter)
    .filter('statusAbbrev', statusAbbrevFilter)

angular.module('kno2.controllers', []); // controllers shared across modules
angular.module('kno2.services', ['angularSpinner', 'angular-data.DSCacheFactory', 'kno2.libs', 'ngSanitize'])
    .service('UnsupportedBrowserService', UnsupportedBrowserService)
    .service('StateRoutingService', StateRoutingService)

angular
    .module('kno2.directives', [
        'ui.bootstrap',
        'ng-iscroll',
        'ngTagsInput',
        'ui.mask',
        'ngclipboard',
        'kno2.libs',
        angularFilter
    ])
    .run(datePickerConfig)
    .directive('uibDatepickerPopup', uibDatepickerPopupDirective)
    .directive('openOnFocus', openOnFocusDirective)
    .directive('datepickerPopupReadonly', datepickerPopupReadonlyDirective)
    .directive("datePickerInput", datePickerInputDirective)
    .directive("datePickerAutoFormat", datePickerAutoFormatDirective)
    .directive('datepickerLocalDate', datepickerLocalDateDirective);

angular
    .module('kno2.components', ['kno2.directives'])
    .component('k2JumpPager', jumpPagerComponent)
    .component('k2Pagination', paginationComponent)
    .component('k2DatePicker', dateRangePickerComponent)
    .component('k2InputFilter', inputFilterComponent)
    .component('k2Header', headerComponent)
    .component('k2Footer', footerComponent)
    .component('k2Sidebar', sideBarComponent)
    .component('notificationList', notificationListComponent)

angular.module('kno2.filters', ['kno2.filters'])
    .filter('titlecase', titlecaseFilter)

angular.module('kno2.factories', []);
angular.module('kno2.shared.settings', ['localytics.directives']);

angular.module('kno2.libs', [])
    .factory('moment', ['$window', $window => $window.moment])
    .factory('_', ['$window', $window => $window._]);

export const CommonModule = angular
    .module('kno2.client.common', [
        CoreModule,
        'kno2.controllers',
        'kno2.services',
        'kno2.directives',
        'kno2.components',
        'kno2.filters',
        'kno2.shared.settings',
        'kno2.libs'
    ])
    .service('PrintService', PrintService)
    .component('k2Viewer', viewerComponent)
    .component('k2ViewerPanel', viewerPanelComponent)
    .component('k2CdaPreviewFrame', cdaPreviewFrameComponent)
    .component('k2PdfViewerFrame', pdfViewerFrameComponent)
    .name;
