import templateUrl from './integrations-upsert.modal.component.html';
import { find, includes, get, filter } from 'lodash';

export const integrationsUpsertModalComponent = {
    templateUrl: templateUrl,
    controller: IntegrationsUpsertModalController,
    bindings: {
        resolve: '<',
        modalInstance: '<'
    }
};

IntegrationsUpsertModalController.$inject = ['IntegrationsService', 'NotificationModalService', 'NotificationService', 'CodeGrantsService', 'DocumentSourcesService', 'FeatureService'];

export function IntegrationsUpsertModalController(IntegrationsService, NotificationModalService, NotificationService, CodeGrantsService, DocumentSourcesService, FeatureService) {
    var ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.integrationSelected = integrationSelected;
    ctrl.saveChanges = saveChanges;
    ctrl.dismissModal = dismissModal;
    ctrl.displayCheckConnection = displayCheckConnection;
    ctrl.checkConnection = checkConnection;
    ctrl.getInputType = getInputType;
    ctrl.getCodeGrants = getCodeGrants;
    ctrl.toggleCapability = toggleCapability;
    ctrl.toggleCodeGrantCapability = toggleCodeGrantCapability;
    ctrl.displayPropertyFormElement = displayPropertyFormElement;
    ctrl.hasAdvancedOptions = hasAdvancedOptions;
    ctrl.isAdvancedOption = isAdvancedOption;
    ctrl.isRequiredInput = isRequiredInput;

    function $onInit() {
        ctrl.availableTypes = ctrl.resolve.availableTypes;
        ctrl.types = ctrl.resolve.types;
        ctrl.integration = ctrl.resolve.integration || {};
        ctrl.readonlyProperties = [];

        if (ctrl.integration && ctrl.integration.capabilities && ctrl.integration.capabilities.facilityLookup) {
            getFacilities();
        }

        integrationSelected();
    }

    function integrationSelected() {
        ctrl.selectedIntegration = find(ctrl.types, x => x.type === ctrl.integration.type);

        if (ctrl.integration.id) {
            ctrl.selectedIntegration.properties.forEach(p => {
                const hasValue = ctrl.integration.values[p.name] !== undefined;
                if (hasValue) ctrl.readonlyProperties.push(p);
            });
        } else if (ctrl.selectedIntegration) {
            ctrl.integration.capabilities = ctrl.selectedIntegration.capabilities.reduce((result, x) => {
                const defaultEnabledCapabilities = get(ctrl, 'selectedIntegration.defaultEnabledCapabilities', []);
                if (defaultEnabledCapabilities.length) {
                    result[x] = includes(defaultEnabledCapabilities, x);
                } else {
                    result[x] = true;
                }
                return result;
            }, {});
        }

        if (ctrl.selectedIntegration) {
            ctrl.integration.values = ctrl.integration.values || {};
            ctrl.selectedIntegration.properties.forEach((p) => {
                if (ctrl.integration.values[p.name] === undefined && p.defaultValue !== null)
                    ctrl.integration.values[p.name] = p.defaultValue;
                if (p.dataSource && p.dataSource.name)
                    getDataSource(p);
            });

            getCodeGrants(ctrl.integration.type);
        }


    }

    function saveChanges() {
        ctrl.savingIntegration = true;
        IntegrationsService.upsert(ctrl.integration)
            .then(res => {
                ctrl.modalInstance.close();
                if (res.message) NotificationService.notice(res.message);
            }, err => {
                if (err.data.message)
                    NotificationModalService.error(err.data.message);
                else
                    NotificationModalService.serverError(err.data.modelState, 'There was an error saving the integration.');
            })
            .then(() => ctrl.savingIntegration = false);
    }

    function dismissModal() {
        ctrl.modalInstance.dismiss();
    }

    function getCodeGrants(integrationType) {
        return CodeGrantsService.getCodeGrants(integrationType)
            .then((res) => ctrl.codeGrants = res)
            .catch((err) => console.log(err));
    }

    function displayCheckConnection() {
        return ctrl.selectedIntegration && ctrl.selectedIntegration.allowCheckForConnectivity;
    }

    function checkConnection() {
        ctrl.checkingConnection = true;
        ctrl.connected = null;

        const testById = ctrl.integration.id && !ctrl.setProperties;
        const payload = testById ? ctrl.integration.id : ctrl.integration;
        const method = testById ? 'testConnectionById' : 'testConnection';
        IntegrationsService[method](payload)
            .then(connected => ctrl.connected = connected.isSuccess)
            .catch(err => {
                ctrl.connected = false;
                NotificationService.serverError(err.data.modelState, err.data.exceptionMessage);
            })
            .finally(function () {
                ctrl.checkingConnection = false;
            });
    }

    function getFacilities() {
        IntegrationsService.getFacilities()
            .then(data => {
                ctrl.facilities = _.forEach(data, x => {
                    x.id = x.id.toString();
                });
            }).catch(err => {
                NotificationModalService.serverError(err.data.modelState, 'There was an error retrieving facilities.');
            });
    }

    function getDataSource(property) {
        switch (property.dataSource.name) {
            case 'DocumentSource':
                DocumentSourcesService.getDocumentSources('asc', 1, 100)
                    .then(data => {
                        property.values = data.documentSources.map(x => {
                            return { 'name': x.address, 'value': x.address };
                        });
                    });
                break;

            case 'Integration':
                IntegrationsService.list()
                    .then(data => {
                        property.values = data.map(x => {
                            return { 'name': x.name, 'value': x.type }
                        })
                    });
                break;

            case 'DocusignEnvelopeCustomField':
                IntegrationsService.getPatientKeys()
                    .then(data => {
                        property.values = data;
                    });
                break;
        }
    }

    function getInputType(property) {
        if (property.dataSource && property.dataSource.type === 'StringList') {
            return 'StringList';
        }

        if (property.values) {
            return null;
        }

        switch (property.type) {
            case 'Boolean':
                return 'checkbox';
            case 'String':
                if (property.isSensitive)
                    return 'password';
                else
                    return 'text';
            default:
                return 'text';
        }
    }

    function toggleCapability(c) {
        if (ctrl.selectedIntegration.allowCapabilitiesToBeEdited && c !== 'haveCodeGrantLogin')
            ctrl.integration.capabilities[c] = !ctrl.integration.capabilities[c];
    }

    function toggleCodeGrantCapability(p) {
        if (ctrl.integration.values[p.name] === 'OAuth2AuthorizationCode')
            ctrl.integration.capabilities['haveCodeGrantLogin'] = true;
        else
            delete ctrl.integration.capabilities['haveCodeGrantLogin'];
    }

    function displayPropertyFormElement(p) {
        return !p.isList
            && (!p.displayIfHasFeature || FeatureService.isEnabled(p.displayIfHasFeature))
            && (!p.displayIfHasValue || ctrl.integration.values[p.name])
            && (!p.displayWhen || p.displayWhen.value.indexOf(ctrl.integration.values[p.displayWhen.property]) > -1)
            && !p.hideFromUi;
    }

    function isAdvancedOption(p) {
        return p.advancedOptions || false;
    }

    function hasAdvancedOptions() {
        if (ctrl.selectedIntegration) {
            const property = find(ctrl.selectedIntegration.properties, { 'advancedOptions': true });
            return property != null;
        }
        return false;
    }

    function isRequiredInput(value) {
        if (value.requiredWhenBooleanProperty) {
            return ctrl.integration.values[value.requiredWhenBooleanProperty];
        }
        return true;
    }
}
