import _ from 'lodash';
import templateUrl from './ip-addresses.component.html'

export const serviceAccountipAddressesComponent = {
    controller: ServiceAccountIpAddressesController,
    templateUrl: templateUrl,
    bindings: {
        limit: '<',
        addresses: '<',
        currentAddress: '<',
        onSave: '&'
    }
};

ServiceAccountIpAddressesController.$inject = ['$timeout', 'SessionService'];

export function ServiceAccountIpAddressesController($timeout, SessionService) {
    var ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.showAddMyAddress = showAddMyAddress;
    ctrl.add = add;
    ctrl.save = save;

    function $onInit() {
        ctrl.currentAddress = ctrl.currentAddress || SessionService.getProfile().ipAddress;
        if (!ctrl.currentAddress)
            SessionService.getClientIp().then(function(response) {
                    ctrl.currentAddress = response.data;
                });
        ctrl.addresses = ctrl.addresses || [];
        ctrl.limit = ctrl.limit || 6;
        ctrl.ipAddressRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    }

    function showAddMyAddress() {
        return ctrl.currentAddress && ctrl.addresses.indexOf(ctrl.currentAddress) === -1;
    }

    function add(address) {
        ctrl.addresses.push(address);
    }

    function save() {
        ctrl.addressesForm.$setSubmitted();

        // remove blanks and duplicates
        ctrl.addresses = _.chain(ctrl.addresses)
            .filter(function (a) {
                // undefined means invalid, don't remove
                return a === undefined || !!_.trim(a);
            })
            .uniq()
            .value();

        // allow validattion to recheck with timeout
        $timeout(function () {
            if (ctrl.addressesForm.$invalid) return;

            ctrl.onSave({ $addresses: ctrl.addresses });
            ctrl.addressesForm.$setPristine();
        });
    }
}
