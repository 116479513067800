import sessionTimeoutWarningDialogTemplate from './session-timeout.warning-dialog.html';

SessionTimeoutCtrl.$inject = ['$scope', 'Idle', '$uibModal', '$location', '$rootScope', 'Auth0Service'];

export function SessionTimeoutCtrl($scope, Idle, $uibModal, $location, $rootScope, Auth0Service) {

    var modal;

    function closeModal() {
        if (modal) {
            modal.close();
            modal = null;
        }
    }

    $scope.$on('IdleStart', function () {
        modal = $uibModal.open({
            controller: 'SessionTimeoutModalCtrl as vm',
            templateUrl: sessionTimeoutWarningDialogTemplate,
            windowClass: 'modal-danger',
            backdrop: 'static' //This fixes an issue where the timeout stops completely
        });
    });

    $scope.$on('IdleEnd', function () {
        closeModal();
    });

    $scope.$on('IdleTimeout', function () {
        if ($location.host().indexOf('.local') === -1) { // Don't logout in dev.
            $rootScope.logoff();
        }
    });
}
