angular.module('kno2.services')
    .service('DownloadProfilesService',
        ['$http',
            function ($http) {
                var getDownloadProfiles = function () {
                    var promise = $http.get('/api/downloadprofiles')
                        .then(function (result) {
                            return result.data;
                        });
                    return promise;
                };

                var getProfile = function(id) {
                    var promise = $http.get('/api/downloadprofiles/' + id)
                        .then(function (result) {
                            return result.data;
                        });
                    return promise;
                }

                var saveProfile = function (profile) {
                    var promise = $http.post('/api/downloadprofiles', profile)
                        .then(function (result) {
                            return result.data;
                        });
                    return promise;
                };

                var deleteProfile = function (id) {
                    var promise = $http.delete('/api/downloadprofiles/' + id)
                        .then(function (result) {
                            return result.data;
                        });
                    return promise;
                }

                return {
                    getDownloadProfiles: getDownloadProfiles,
                    getProfile: getProfile,
                    saveProfile: saveProfile,
                    deleteProfile: deleteProfile
                };
            }]);