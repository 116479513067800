(function (undefined) {
    'use strict';

    angular
        .module('kno2.directives')
        .directive('clickToEdit', clickToEdit)
        .run(run);

    clickToEdit.$inject = ['$document'];

    function clickToEdit($document) {
        // Usage:
        //     <clickToEdit></clickToEdit>
        var directive = {
            link: link,
            restrict: 'EA',
            require: 'ngModel',
            templateUrl: 'click-to-edit',
            transclude: true,
            scope: {
                model: '=ngModel'
            }
        };
        return directive;

        function link(scope, element, attrs, ngModelCtrl) {

            var document = angular.element($document),
                originalValue;

            element.addClass('click-to-edit');

            document.on('click.click-to-edit', function () {
                setEditing(false);
            });

            element
                .click(function (e) {
                    e.stopPropagation();

                    if (e.target.tagName === 'INPUT') return;

                    document.trigger('click.click-to-edit');
                    setEditing(true);
                    element.find('input').select();

                    originalValue = ngModelCtrl.$viewValue;
                })
                .keypress(function (e) {
                    if (e.keyCode === 9 || e.which === 13) // (tab or enter)
                        setEditing(false);
                });

            function setEditing(editing) {
                scope.$apply(function () {

                    if (editing === false && isFalseyOrWhiteSpace(ngModelCtrl.$viewValue)) {
                        ngModelCtrl.$setViewValue(originalValue);
                    }

                    scope.editing = editing;
                });
            }

            function isFalseyOrWhiteSpace(value) {
                return !value || value.trim() === '';
            }
        }
    }

    run.$inject = ['$templateCache'];
    function run($templateCache) {
        $templateCache.put('click-to-edit',
            '<span ng-hide="editing" class="display" title="Click to edit...">{{model}}</span>' +
            '<span ng-hide="editing" class="include" ng-transclude></span>' +
            '<span ng-show="editing" class="spacer">&nbsp;</span>' +
            '<input ng-show="editing" ignore-special-characters type="text" class="form-control edit" ng-model="model">' +
            '');
    }

})();