SupportDownloadsController.$inject = ['$state', '$location', '$timeout', '$window', 'DownloadsService'];

export function SupportDownloadsController($state, $location, $timeout, $window, DownloadsService) {
    var ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.toggle = toggle;
    ctrl.baseUrl = ENVIRONMENT.baseUrl;

    function $onInit() {
        ctrl.downloads = {
            'bundle':
            {
                name: `${ENVIRONMENT.brand} Bundle Client`,
                version: '1.2.3.1',
                link: `${ENVIRONMENT.baseUrl}/api/downloads/tag/Kno2client/download?brand=${ENVIRONMENT.brand}`
            },
            'kno2send':
            {
                name: 'Kno2send Client',
                version: '2.1.0',
                link: `${ENVIRONMENT.baseUrl}/api/downloads/tag/Kno2send/download`
            },
            'kno2mac':
            {
                name: `${ENVIRONMENT.brand} Client For Mac`,
                version: '1.1',
                link: `${ENVIRONMENT.baseUrl}/api/downloads/tag/Kno2MacClient/download?brand=${ENVIRONMENT.brand}`
            }
        };
        getManagedDownloads();
        startDownload();
    }

    function toggle(state) {
        if ($state.is(state))
            $state.go('support.default');
        else
            $state.go(state);
    }

    function getManagedDownloads() {
        return DownloadsService.getAll().then(function (data) {
            ctrl.managedDownloads = data;
        });
    };

    function startDownload() {
        var search = $location.search();
        if (search.d && ctrl.downloads[search.d]) {
            if (search.d === 'bundle' && window.navigator.userAgent.indexOf("Mac") !== -1) search.d = "kno2mac";
            $timeout(function () {
                $window.location.href = ctrl.downloads[search.d].link;
            }, 500);
        }
    }
}
