ForwardMessageModalCtrl.$inject = [
    '$scope', '$filter', '$window', 'data', 'NotificationModalService', 'MessageService', 'ReleaseService', '$location',
    '$q'
];

export function ForwardMessageModalCtrl(
    $scope, $filter, $window, data, NotificationModalService, MessageService, ReleaseService, $location,
    $q
) {
    var buildBundleOptions = function () {
        $scope.bundleOptions["includeMessageBody"] = $scope.exportOptions.includeMessageBody;
        $scope.bundleOptions["includeAttachments"] = $scope.exportOptions.includeAttachments;
        $scope.forwardattachments = [];
        if ($scope.exportOptions.includeAttachments) {
            _.each($scope.attachments, function (item) {
                _.each($scope.draft.attachments, function (draftitem) {
                    if (item.isSelected && draftitem.key == item.key) {
                        $scope.forwardattachments.push(draftitem);
                    }
                });

            });
        }
    };

    $scope.saveMessage = function () {

        $scope.saveInProgress = true;

        $q.resolve()
            .then(function () {
                var selected = _(data.attachments).filter('isSelected').map('id').value(),
                    shouldWait = !$scope.exportOptions.includeAttachments || !_.some(selected);
                if (shouldWait) return MessageService.waitForTransforms(data.message.id);
            })
            .then(function () {
                return MessageService.saveProcessedMessage(data.message.id, $scope.processed.checked, data.message.subject, $scope.bundleOptions.exportMode);
            })
            .then(function () {
                return MessageService.createDraft(data.message.id);
            })
            .then(function (result) {
                $scope.draft = result;
                buildBundleOptions();
                $scope.draft.originalObjectId = data.message.id;
                $scope.draft.toAddresses = _.map($scope.emr.addresses, function (address) {
                    return { text: address };
                });

                if ($scope.draft.toAddresses.length === 0) {
                    NotificationModalService.error("Please choose a direct address.");
                    return $q.reject('some error occured');
                }

                $scope.draft.fromAddress = data.interopSettings.fromDirectAddress;
                if ($scope.forwardattachments.length > 0) {
                    $scope.draft.attachments2Cda = data.interopSettings.forwardOption == 'cda' ? true : false;
                    $scope.draft.attachments2Pdf = data.interopSettings.forwardOption == 'pdf' ? true : false;
                } else {
                    $scope.draft.attachments2Cda = false;
                    $scope.draft.attachments2Pdf = false;
                }
                return ReleaseService.saveDraft($scope.draft.id, $scope.draft, $scope.forwardattachments, data.message.patient);
            })
            .then(function () {
                return ReleaseService.forwardMessage($scope.draft.id, $scope.draft);
            })
            .then(function () {
                NotificationModalService.success("The message has been forwarded to your EMR");

                $location.path('/intake');
                $scope.closeModal();
            })
            .finally(function () {
                $scope.saveInProgress = false;
            });
    };

    $scope.closeModal = function () {
        $scope.$close('canceled');
    };

    (function () {
        $scope.exportOptions = {
            includeMessageBody: data.attachments.length == 0,
            includeAttachments: true
        };
        $scope.bundleOptions = data.bundleOptions;
        $scope.attachments = data.attachments;
        $scope.title = (data.bundleOptions.exportMode == 'print') ? 'Print' : (data.bundleOptions.exportMode == 'forward') ? 'Forward to EMR' : 'Download';
        $scope.message = data.message;
        $scope.toDirectAddresses = data.interopSettings.directAddress.split(';');
        $scope.emr = { addresses: [] };
        $scope.processed = {
            id: 'markAsProcessed',
            name: 'Mark as Processed',
            checked: false
        };
    })();
}
