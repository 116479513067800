angular.module('kno2.directives')
    .directive('limitTags', function () {
    return {
        require: 'ngModel',
        link: function (scope, elem, attrs, ngModel) {
            var maxTags = parseInt(attrs.maxTags, 10);
            ngModel.$parsers.unshift(function (value) {
                if (value && value.length > maxTags) {
                    value.splice(value.length - 1, 1);
                }
                return value;
            });
        }
    };
});