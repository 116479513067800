import templateUrl from './date-range-picker.component.html';
import * as daterangepicker from 'daterangepicker';

class DateRangePicker {
    constructor() {
    }

    $onInit() {
        const self = this;

        this.isActive = this.dateFilterIsActive || false;
        this.defaultRange = {
            startDate: moment().utc().subtract(1, 'year').startOf('day').toDate(),
            endDate: moment()
        };
        this.dateRange = { ...this.defaultRange };
        this.rangeForPicker = {
            'Today': [moment(), moment().endOf('day')],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
            'Last 30 Days': [moment().subtract(29, 'days'), moment().endOf('day')],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        };

        this.updateDatePicker = (startDate, endDate) => {
            self.dateRange = { startDate, endDate };
            self.refreshData(self.dateRange);
        };

        $("#daterange").daterangepicker({
            startDate: moment().utc().subtract(1, 'year').startOf('day').toDate(),
            endDate: moment().utc().endOf('day'),
            ranges: this.rangeForPicker,
            locale: { cancelLabel: 'Reset' },
            alwaysShowCalendars: this.dateShowCalendars || false
        }, this.updateDatePicker);

        $('#daterange').on('cancel.daterangepicker', function (ev, picker) {
            $('#daterange').data('daterangepicker').setStartDate(self.defaultRange.startDate);
            $('#daterange').data('daterangepicker').setEndDate(self.defaultRange.endDate);
            
            self.refreshData(self.defaultRange, false);
        });
    }

    refreshData({ startDate, endDate }, isActive = true) {
        if (isActive) this.dateChange({ startDate, endDate });
        else this.dateClear();

        this.isActive = isActive;
    }

    clearFilter() {
        this.refreshData(this.defaultRange, false);
    }
}

DateRangePicker.$inject = [];
export const dateRangePickerComponent = {
    templateUrl: templateUrl,
    controller: DateRangePicker,
    bindings: {
        dateChange: '&',
        dateClear: '&',
        dateShowCalendars: '=',
        dateFilterIsActive: '='
    }
};
