'use strict';
export class StateRoutingService {
    constructor($state, $window, $timeout, SupportedFileExtensionsFactory, FeatureService, SessionService) {
        this.$state = $state;
        this.$window = $window;
        this.$timeout = $timeout;
        this.supportedFileExtensionsFactory = SupportedFileExtensionsFactory;
        this.featureService = FeatureService;
        this.sessionService = SessionService;
    }

    getState() {
        let state = '';
        if (this.sessionService.userInRole(['SysAdmin', 'SysAdminReporting'])) {
            state = 'dashboardMessaging';
            return state;
        }
        else if (this.sessionService.userInRole('Administrator'))
            state = 'accountDashboard';
        else if (this.sessionService.userInRole('User'))
            state = 'intake';

        this.supportedFileExtensionsFactory.load();
        this.featureService.load();

        return state;
    }

    goTo(targetState, requiresReload = false) {
        switch (targetState) {
            case 'accountDashboard':
            case 'intake':
                this.$state.go(targetState).then(() => {
                    if (requiresReload) this.$timeout(() => this.$window.location.reload(), 0);
                });
                break;
            case 'dashboardMessaging':
                this.$window.location = '/admin/dashboard/messaging';
                break;
            case 'login':
            default:
                this.$state.go('login');
        }
    }
}

StateRoutingService.$inject = ['$state', '$window', '$timeout', 'SupportedFileExtensionsFactory', 'FeatureService', 'SessionService'];
