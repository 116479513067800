angular.module('kno2.settings')
    .controller('SettingsLicensedPlanCtrl', ['$scope', '$uibModal', '$timeout', 'SessionService', 'SubscriptionsFactory', 'NotificationService', 'SpinnerService', '$rootScope',
        function ($scope, $uibModal, $timeout, SessionService, SubscriptionsFactory, NotificationService, SpinnerService, $rootScope) {

            $scope.userProfile = SessionService.getProfile();

            var refreshSubscription = function () {
                SubscriptionsFactory.get()
                    .then(function (res) {
                        $scope.plan = res.data;
                        if ($scope.plan.planId == null) {
                            $scope.plan.planId = -1;
                        }
                        $scope.plan.upgradePlanId = $scope.plan.planId;
                        $scope.plan.originalPlanId = $scope.plan.planId;
                    });
            };

            (function () {
                refreshSubscription();
            }());
        }
    ]);
