import deleteMessageModalTemplate from '../../common/delete-message/delete-message-modal.html';
import intakeAuditMessageModalTemplate from '../../intake/intake.audit-message-modal.html';

angular.module('kno2.release')
    .controller('ReleaseMessageViewCtrl', ['$scope', '$stateParams', '$location', 'IntakeService', 'SessionService', 'MessageFactory',
        'message', 'SubscriptionService', '$uibModal', 'ConversationServices', 'NotificationService', '$timeout',
    function ($scope, $stateParams, $location, IntakeService, SessionService, MessageFactory, message, SubscriptionService, $uibModal, 
        ConversationServices, NotificationService, $timeout) {

            var messageId = $stateParams.id;

            $scope.viewerOptions = {
                rotate: 'hidden',
                remove: 'hidden',
                print: 'hidden',
                download: 'hidden',
                zoom: 'auto',
                // Old options
                supportPageReordering: false,
                supportPageRotation: false,
                supportPageRemoval: false
            };

            $scope.saveAttachmentDetails = function () { };

            $scope.cancelAttachmentDetails = function () { };

            var initPageControls = function () {

                // Header title controls
                $scope.message = {};
                $scope.message.id = messageId;
                $scope.message.subject = $scope.doc.subject || '';
                $scope.message.fromAddress = $scope.doc.fromAddress || '';
                $scope.message.toAddress = $scope.doc.toAddress || '';
                $scope.message.systemReceivedDate = $scope.doc.messageDate || '';
                $scope.message.body = $scope.doc.body || '';
                $scope.message.isDraft = $scope.doc.isDraft;

                if ($scope.doc.attachments2Cda) {
                    $scope.message.conversion = 'Cda';
                } else if ($scope.doc.attachments2Pdf) {
                    $scope.message.conversion = 'Pdf';
                }

                // Patient Details data
                // Get this data from a service.
                // When saving this data, it will get saved to the server via the Bulgaria team.
                $scope.patient = $scope.doc.patient || {};

                // Attachments data
                $scope.attachments = $scope.doc.attachments || [];

                // No deleting of attachments on this page
                $scope.hideDeleteAttachments = true;

                MessageFactory.init($scope, 'release');   // Used to set proper page state on viewer release page (read only).
            };

            $scope.forwardClick = function (message, event) {
                event.stopPropagation();
                if (!SubscriptionService.checkAndShowPrompt('replyAndForwardButton'))
                    $location.path('/release/forward/' + message.id).search({ "returnUrl": "/release" });
            };

            $scope.deleteClick = function (message, event) {
                event.stopPropagation();
                const modal = $uibModal.open({
                    templateUrl: deleteMessageModalTemplate,
                    controller: 'DeleteMessageModalCtrl',
                    controllerAs: '$ctrl',
                    resolve: {
                        message: () => message
                    }
                });

                modal.result.then(() => {
                    $location.path('/release');
                }, () => { });
            };

            $scope.choose360xReferralClick = function (status, doc, message) {
                $scope.$applyAsync(function() {
                    ConversationServices.choose360xReferral(status, doc.conversation.conversationId, message.id)
                        .then(function() {
                            NotificationService.success(`The message has been successfully ${status}.`);
                            $scope.is360xReferralSelected = true;
                        })
                        .catch((err) => NotificationService.error(`Unable to change status to ${status}.`));
                });
            }

            function display360xTrackedNotifications() {
                if($scope.doc.conversation.conversationId != null && $scope.doc.conversation.type == '360x') {
                    $scope.notifications.push({
                        level: "info",
                        message: 'This message is part of a tracked referral'
                    },
                    {
                        level: "info",
                        message: `This referral has been ${$scope.doc.conversation.conversationStatus}`
                    });
                }
            }

            $scope.showActivity = function (message, event) {
                event.stopPropagation();
                $uibModal.open({
                    windowClass: 'audit-message-modal',
                    templateUrl: intakeAuditMessageModalTemplate,
                    controller: 'AuditMessageModalCtrl',
                    controllerAs: '$ctrl',
                    resolve: {
                        message: () => message
                    }
                }).result.then(() => { }, () => { });
            };

            // Set up the controller initialization, dependencies are in order.
            // NOTE: messageId and documentId are the same. They should be in Base64.
            (function () {

                // The URL must contain the messageId/documentId.
                // Redirect back to intake page if there is no current document to process.
                if (!messageId) {
                    IntakeService.redirectToIntakeView();
                    return;
                }

                $scope.doc = message;
                $scope.profile = SessionService.getProfile();
                $scope.location = $location;
                $scope.notifications = [];
                $scope.is360xReferralSelected = false;

                $scope.dateOptions = {
                    maxDate: new Date()
                };

                // Header dropdown for include options
                $scope.k2Dropdown = {};
                $scope.k2Dropdown.displayText = "Include:";
                $scope.k2Dropdown.options = [{
                    text: "Message Body",
                    checked: false
                }, {
                    text: "Selected Attachments",
                    checked: true
                }];

                initPageControls();
                if($scope.doc.conversation) {
                    display360xTrackedNotifications();
                }
            }());
        }
    ]);
