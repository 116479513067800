import templateUrl from './identityproviders.upsert.modal.component.html';
import { find, includes, get } from 'lodash';

class IdentityProvidersUpsertModalController {
    constructor(IdentityProvidersService, NotificationModalService) {
        this.identityProvidersService = IdentityProvidersService;
        this.notificationModalService = NotificationModalService;
    }

    $onInit() {
        this.availableTypes = this.resolve.availableTypes;
        this.identityProvider = this.resolve.identityProvider;

        if (this.availableTypes.length === 1) {
            this.identityProvider.type = this.availableTypes[0].type;
        }
        this.identityProviderSelected();
    }

    identityProviderSelected() {
        this.selectedIdentityProvider = find(this.availableTypes, x => x.type === this.identityProvider.type);
    }

    saveChanges() {
        this.saving = true;
        this.identityProvidersService.upsert(this.identityProvider)
            .then(res => this.close({ $value: this.identityProvider}))
            .catch(err => this.notificationModalService.error(err.data.message));
    }
}

IdentityProvidersUpsertModalController.$inject = ['IdentityProvidersService', 'NotificationModalService'];

export const identityProvidersUpsertModalComponent = {
    templateUrl: templateUrl,
    controller: IdentityProvidersUpsertModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
}
