import uiRouter from '@uirouter/angularjs';

import { Auth0Service } from './auth0.service';
import { auth0CallbackComponent } from './auth0-callback.component';
import { auth0ErrorComponent } from './error/auth0-error.component';
import { auth0LogoutComponent } from './logout/auth0-logout.component';
import { auth0ResponseConstants } from './auth0-response.constants';

export const Auth0Module = angular
    .module('kno2.auth0', [uiRouter, 'auth0.auth0', 'ngIdle'])
    .service('Auth0Service', Auth0Service)
    .component('auth0Callback', auth0CallbackComponent)
    .component('auth0Error', auth0ErrorComponent)
    .component('auth0Logout', auth0LogoutComponent)
    .constant('auth0ResponseConstants', auth0ResponseConstants)
    .config(['$stateProvider', '$locationProvider', 'angularAuth0Provider',
        ($stateProvider, $locationProvider, angularAuth0Provider) => {
            $locationProvider.hashPrefix('');
            $locationProvider.html5Mode(true);

            $stateProvider
                .state('callback', {
                    url: '/callback',
                    component: 'auth0Callback'
                })
                .state('authError', {
                    url: '/auth-error',
                    component: 'auth0Error',
                    params: {
                        error: null
                    }
                })

            angularAuth0Provider.init({
                clientID: ENVIRONMENT.auth0.clientId,
                domain: ENVIRONMENT.auth0.domain,
                redirectUri: ENVIRONMENT.auth0.redirectUri,
                audience: ENVIRONMENT.auth0.audience,
                responseType: 'token id_token',
                scope: 'openid'
            });
        }
    ])
    .run(['Auth0Service', 'LocalStorageFactory', '$window', '$state', 'auth0ResponseConstants', '$interval',
        async (Auth0Service, LocalStorageFactory, $window, $state, auth0ResponseConstants, $interval) => {
            const redirectUrl = $window.location.pathname + $window.location.search;
            if (hasValidRedirectUrl(redirectUrl))
                LocalStorageFactory.set('redirectUrl', redirectUrl);
                
            const fifteenMinutes = 15*60*1000;
            $interval(() => {
                Auth0Service.renewTokens(Auth0Service, auth0ResponseConstants, $state);
            }, fifteenMinutes);

            Auth0Service.renewTokens(Auth0Service, auth0ResponseConstants, $state);
        }
    ]).name;

function renewTokens(Auth0Service, auth0ResponseConstants, $state) {
    Auth0Service.renewTokens()
        .catch((err) => {
            if (err.code !== auth0ResponseConstants.LoginRequired)
                return $state.go('authError', { error: err });

            Auth0Service.handleAuthentication();
        });
}

function hasValidRedirectUrl(redirectUrl) {
    const blacklistRedirectUrls = [
        '/unauthorized',
        '/access-denied',
        '/auth-error',
        '/'
    ];

    const blacklistedStartsWith = [
        '/callback',
        '/account/login',
        '/account/register',
        '/account/logout',
        '/?email='
    ];

    if (_.includes(blacklistRedirectUrls, redirectUrl))
        return false;

    for (let i = 0; i <= blacklistedStartsWith.length; i++) {
        if (_.startsWith(redirectUrl, blacklistedStartsWith[i]))
            return false;
    }

    return true;
}
