PhoneNumberModalCtrl.$inject = ['$rootScope', '$uibModalInstance', '$timeout', 'SessionService', 'NotificationService', 'profile', 'usersFactory'];
export function PhoneNumberModalCtrl($rootScope, $uibModalInstance, $timeout, SessionService, NotificationService, profile, usersFactory) {
    const ctrl = this;
    ctrl.$onInit = onInit;
    ctrl.userProfile = profile;
    ctrl.userId = ctrl.userProfile.userId;

    function onInit() {
        usersFactory.user(ctrl.userId)
            .then(function (response) {
                ctrl.user = response.data;
                ctrl.user.originalPhone = ctrl.user.phoneNumber;
                ctrl.phoneNumber = ctrl.user.phoneNumber;
            },
                function (error) {
                    NotificationService.serverError(error.data.modelState,
                        "There was an error getting your information.");
                });
    }

    ctrl.submitPhoneNumber = function () {
        if (ctrl.user.originalPhone === ctrl.phoneNumber)
            $uibModalInstance.close(ctrl.user);
        else {
            ctrl.user.phoneNumber = ctrl.phoneNumber;
            usersFactory.save(ctrl.user).then(function (response) {
                NotificationService.success("Phone number has been updated.");
                $uibModalInstance.close(ctrl.user);
            });
        }
    }

    ctrl.remindMeLater = function () {
        $uibModalInstance.dismiss({ dismiss: true });
    }

    ctrl.close = function () {
        $rootScope.logoff();
    }
}