(function () {
    'use strict';

    angular
        .module('kno2.settings')
        .controller('DirectAddressesFaxVerifyModalCtrl', DirectAddressesFaxVerifyModalCtrl);

    DirectAddressesFaxVerifyModalCtrl.$inject = ['$uibModalInstance', 'DocumentSourceFaxService', 'NotificationService', 'documentSourceId', 'faxNumber'];

    function DirectAddressesFaxVerifyModalCtrl($uibModalInstance, DocumentSourceFaxService, NotificationService, documentSourceId, faxNumber) {
        const ctrl = this;

        ctrl.faxNumber = faxNumber;
        ctrl.sendCode = sendCode;
        ctrl.verify = verify;
        ctrl.cancel = cancel;

        function sendCode() {
            DocumentSourceFaxService.sendCode(documentSourceId, faxNumber.id).then(function (response) {
                NotificationService.success('The code was sent.');
            },
            function () {
                NotificationService.error("An error occurred when trying to send the code, please try again.");
            });
        }

        function verify() {
            DocumentSourceFaxService.verify(documentSourceId, faxNumber.id, faxNumber.code).then(function (response) {
                NotificationService.success('The fax number was successfully verified.');
                $uibModalInstance.close();
            },
            function (response) {
                if (response.status === 400)
                    NotificationService.error(response.data.message);
                else
                    NotificationService.error("There was an error verifying the fax number, please try again.");
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }

})();