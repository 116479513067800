NotificationsCtrl.$inject = ['$scope', '$stateParams', 'AlertsService', 'EventService', 'IntakeService', 'IAuditService', 'LocalStorageFactory', '_']
export function NotificationsCtrl($scope, $stateParams, AlertsService, EventService, IntakeService, AuditService, storage, _) {

    // 0 = All, 1 = Important, 2 = Warning, 3 = Information
    $scope.alertsFilterSelection = 0;
    $scope.alertSelection = 0;
    $scope.pageSize = 10;
    $scope.auditEnabled = 0; // Hidden for MVP.

    // all, messageDelivered, messageExported, messageForwarded, messageRead,
    // messageReplied, messageDeleted, messageUnknown, attachmentRead
    $scope.eventsFilterSelection = 'all';
    $scope.failedEventsFilterSelection = "NewFailed";
    $scope.eventSelection = 0;

    $scope.auditFilter = (function () {
        var filter; // = storage.get("notificationsAuditFilter");
        if (!filter) {
            const now = moment().utc();
            const start = moment(now).subtract(14, 'days');
            filter = {
                eventType: "All",
                startDate: start.toJSON(),
                endDate: now.toJSON(),
                page: 1
            };
            storage.set("notificationsAuditFilter", filter);
        }
        return filter;
    })();

    $scope.changeTab = function (tab) {
        setLastTab(tab);
    };

    const getLastTab = function () {
        let tab = $stateParams.selectedTab
            ? $stateParams.selectedTab
            : storage.get("notificationsTab");
        if (tab)
            tab = parseInt(tab);
        const selectedTab = ((tab === 1) || (tab === 2) || (tab === 3 && $scope.auditEnabled) || tab === 4) ? tab : 1;

        setLastTab(tab);
        return selectedTab;
    };

    var setLastTab = function (tab) {
        return storage.set("notificationsTab", tab);
    };

    var getLastMessageType = function () {
        return storage.get("notificationsMessageType") || "all";
    };
    var setLastMessageType = function (type) {
        return storage.set("notificationsMessageType", type);
    };

    var getLastFailedMessageType = function () {
        return storage.get("failedMessageType") || "NewFailed";
    };
    var setLastFailedMessageType = function (type) {
        return storage.set("failedMessageType", type);
    };

    var getLastAlertType = function () {
        return storage.get("notificationsAlertType") || 0;
    };
    var setLastAlertType = function (type) {
        return storage.set("notificationsAlertType", type);
    };

    $scope.selectedTab = getLastTab();

    $scope.alertsFilterClick = function (priority) {
        $scope.alertsFilterSelection = priority;
        $scope.alertSelection = 0;
        setLastAlertType(priority);
    };

    $scope.eventsFilterClick = function (filter) {
        $scope.eventsFilterSelection = filter;
        $scope.eventSelection = 0;
        if (filter === "all") {
            getRecentEvents();
        } else {
            getRecentEventsByType(filter);
        }
        setLastMessageType(filter);
    };

    $scope.failedMessagesFilterClick = function (filter) {
        $scope.failedEventsFilterSelection = filter;
        getFailedMessages().then(function() {
            setLastFailedMessageType(filter);
            switch (filter) {
            case 'Acknowledged':
                $scope.failedMessagesGroup = $scope.acknowledgedFailures;
                break;
            case 'NewFailed':
                $scope.failedMessagesGroup = $scope.newFailures;
                break;
            case 'AllFailed':
            default:
                $scope.failedMessagesGroup = $scope.allFailures;
                break;
            }
        });
    };

    $scope.getAlertsFilterClass = function (priority) {
        return priority === $scope.alertsFilterSelection ? "alerts-menu-active" : "";
    };

    $scope.getEventsFilterClass = function (filter, failed) {
        if (failed) {
            return filter === $scope.failedEventsFilterSelection ? 'alerts-menu-active' : '';
        }
        return filter === $scope.eventsFilterSelection ? 'alerts-menu-active' : '';
    };

    $scope.alertVisible = function (alert) {
        return ($scope.alertsFilterSelection === 0) || (alert.priority === $scope.alertsFilterSelection);
    };

    $scope.alertClass = function (alert) {
        var cssClass = (($scope.alertsFilterSelection === 0) || $scope.alertsFilterSelection === alert.priority) ? '' : 'alert-hidden';
        switch (alert.priority) {
            case 1:
                cssClass += ' alert-priority-important';
                break;
            case 2:
                cssClass += ' alert-priority-warning';
                break;
            case 3:
                cssClass += ' alert-priority-information';
                break;
        }
        if (alert.id === $scope.alertSelection) {
            cssClass += ' active';
        }
        return cssClass;
    };

    $scope.alertClick = function (alert) {
        $scope.alertSelection = alert.id;
    };

    $scope.eventRowClick = function (event) {
        if (event.messageType === 2) {
            EventService.redirectToReleaseView(event);
        } else {
            IntakeService.redirectToMessageView(event);
        }
    };

    var getRecentEvents = function () {
        const promise = EventService.getMessageEventsReport();
        promise.then(function (data) {
            $scope.msgEventGroup = data;
        });
    };

    var getRecentEventsByType = function (eventType) {
        const promise = EventService.getMessageEventsReport({
            eventType: eventType,
            isAcknowledged: $scope.isAcknowledged
        });
        promise.then(function (data) {
            $scope.msgEventGroup = data;
        });
    };

    function getFailedMessages() {
        return EventService.getMessageEventsReport({
            eventType: "Failed",
            filter: "AllFailed"
        })
            .then(function (data) {
                $scope.allFailures = _.flatten(_.map(data, 'events'));
                angular.forEach($scope.allFailures,
                    function(failure) {
                        failure.localeDate = new Date(failure.date).toLocaleDateString();
                    });

                $scope.acknowledgedFailures = $scope.allFailures.filter(function (failure) {
                    return failure.isAcknowledged;
                });

                $scope.newFailures = $scope.allFailures.filter(function (failure) {
                    return !failure.isAcknowledged;
                });
            });
    }

    var getAuditEventTypes = function () {
        AuditService.getEntryTypes().then(function (types) {
            $scope.eventTypes = types;
        });
    };

    var sliceAuditResults = function () {
        const pageStart = ($scope.auditFilter.page - 1) * $scope.pageSize;
        var pageEnd = $scope.auditFilter.page * $scope.pageSize;
        if ($scope.allEntries.length < pageEnd)
            pageEnd = $scope.allEntries.length;

        $scope.auditEntries = $scope.allEntries.slice(pageStart, pageEnd);
    };

    var getAuditEntries = function () {
        AuditService.getEntriesByType($scope.auditFilter.eventType,
            $scope.auditFilter.startDate,
            $scope.auditFilter.endDate).then(function (data) {
                $scope.allEntries = data;
                sliceAuditResults();
            });
    };

    $scope.updateAudits = function () {
        sliceAuditResults();
    };

    $scope.auditFilterClick = function (eventType) {
        if (!eventType.value)
            $scope.auditFilter.eventType = eventType;
        else {
            $scope.auditFilter.eventType = eventType.value;
        }
        getAuditEntries();
    };

    $scope.getAuditfilterClass = function (eventType) {
        return eventType.value == $scope.auditFilter.eventType ? 'alerts-menu-active' : '';
    };

    $scope.isFailedSelected = function () {
        return $scope.eventsFilterSelection === "Failed";
    };

    $scope.isFailedMessage = function (event) {
        return event.failedMessageId !== null && event.failedMessageId !== undefined;
    };

    $scope.acknowledge = function (event) {
        EventService.acknowledgeFailedMessage(event.failedMessageId).then(function (res) {
            $scope.failedMessagesFilterClick($scope.failedEventsFilterSelection);
        });
    };

    $scope.acknowledgeAll = function () {
        EventService.acknowledgeAllFailedMessages().then(function (res) {
            $scope.failedMessagesFilterClick($scope.failedEventsFilterSelection);
        });
    }

    $scope.getPopoverValues = function (event) {
        if (event.events && event.events.length) return;
        EventService.getMyRecentEvents(null, null, event.id).then(function (res) {
            event.events = _.get(res, "data.items[0].events");
        });
    };

    (function () {
        // Init the alerts, using local storage to persist the last selection
        $scope.alertsFilterSelection = getLastAlertType();
        $scope.failedEventsFilterSelection = getLastFailedMessageType();
        $scope.failedMessagesFilterClick($scope.failedEventsFilterSelection);

        $scope.eventsFilterSelection = getLastMessageType();
        if ($scope.eventsFilterSelection === "all") getRecentEvents();
        else getRecentEventsByType($scope.eventsFilterSelection);

        if ($scope.auditEnabled) {
            getAuditEventTypes();
            getAuditEntries();
        }
    }());
}
