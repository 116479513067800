'use strict';

export class IntercomService {
    constructor($transitions, $window, SessionService) {
        this.$window = $window;
        this.sessionService = SessionService;
    }

    boot() {
        const profile = this.sessionService.getProfile();
        this.$window.Intercom('boot', {
          app_id: profile.intercomAppId,
          user_id: profile.userId
        });
    }
}

IntercomService.$inject = ['$transitions', '$window', 'SessionService'];
