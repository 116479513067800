import templateUrl from './message-actions.component.html';

angular
    .module('kno2.components')
    .component('messageActions', {
        transclude: true,
        templateUrl,
        bindings: {
            align: '@'
        }
    });
