InviteService.$inject = ['$http'];
export function InviteService($http) {

    this.getInvitations = function (pageNumber, pageCount) {
        var promise = $http.get('/api/invitations/my/?pageNumber=' + pageNumber + '&pageCount=' + pageCount)
            .then(function (response) {
                return response.data;
            });
        return promise;
    };
}