(function () {
    'use strict';

    angular
        .module('kno2.directives')
        .directive('growTo', growTo);

    growTo.$inject = ['$timeout'];

    /**
     * A directive that resizes an element to the top of a given element.
     */
    function growTo($timeout) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                if (!attrs.growTo) return;

                $timeout(function () {
                    var newHeight = angular.element(attrs.growTo).offset().top - element.offset().top;
                    var offset = +attrs.growToOffset || 10;
                    element.height(newHeight - offset);
                    element.css('overflow-y', 'scroll');
                }, 0, false);
            }
        }
    }

})();
