import settingsRulesDeleteModalTemplate from './settings.rules.delete-modal.html';

(function () {
    'use strict';

    angular
        .module('kno2.settings')
        .controller('SettingsRulesCtrl', SettingsRulesCtrl)

    SettingsRulesCtrl.$inject = [
        '$scope', '$timeout', '$uibModal', '$location', 'MessageService', 'RulesService', 'DocumentSourceService',
        'SureScriptsService', 'UsersService', 'NotificationService', 'IntakeService', 'PagingFactory', 'SubscriptionService'
    ];

    function SettingsRulesCtrl($scope, $timeout, $uibModal, $location, MessageService, RulesService, DocumentSourceService,
            SureScriptsService, UsersService, NotificationService, IntakeService, PagingFactory, SubscriptionService) {

        const defaultName = 'New Untitled Rule';
        let rulesRequest = [];

        $scope.rowClick = rowClick;
        $scope.showPage = showPage;
        $scope.isFirstPageRange = isFirstPageRange;
        $scope.isLastPageRange = isLastPageRange;
        $scope.initializeRules = initializeRules;
        $scope.edit = edit;
        $scope.copy = copy;
        $scope.cancel = cancel;
        $scope.delete = deleteRule;
        $scope.saveRule = saveRule;
        $scope.validateRuleName = validateRuleName;
        $scope.gotoPage = gotoPage;
        $scope.onFromAddressChange = onFromAddressChange;
        $scope.onFromAddressBlur = onFromAddressBlur;
        $scope.onToAddressChange = onToAddressChange;
        $scope.startsWith = startsWith;
        $scope.hideTriage = hideTriage;
        $scope.ruleTypeChanged = ruleTypeChanged;
        $scope.addAll = addAll;

        activate();

        function activate() {

            DocumentSourceService.getOrgDocumentSources()
            .then(function (data) {
                $scope.toAddresses = data;
                $scope.toAddress = $scope.editingRule.toAddress;
            });

            getAllOrgRules();
            getOrgUsers();
            getAllDocumentSources();

            $scope.userName = "";
            $scope.currentPage = 1;
            $scope.maxPage = 10;
            $scope.sortItems = MessageService.getDocumentSortItems();

            $scope.pagedItemRanges = [
                { value: '10' },
                { value: '25' },
                { value: '50' },
                { value: '100' }
            ];
            $scope.pagedItemRange = $scope.pagedItemRanges[0];

            updateSortDetails("receiveDate"); // this is the default sort
            resetEditingRule();
            triggerEditInPlace();
        }

        function applyRule(rule) {
            rulesRequest = [];
            rulesRequest.push({
                fromAddress: rule.fromAddress,
                toAddress: rule.toAddress

            });
            getMessagesFromService(1, $scope.pagedItemRange.value);
        }

        // intake document row click event handler
        function rowClick(document) {
            IntakeService.redirectToMessageView(document);
        }

        function showPage(page, currentPage) {
            return PagingFactory.getPageRange(page, currentPage, $scope.pagedItemRange.value, $scope.totalDocumentCount).visible[page - 1];
        }

        function isFirstPageRange(page, currentPage) {
            return PagingFactory.getPageRange(page, currentPage, $scope.pagedItemRange.value, $scope.totalDocumentCount).isFirst;
        }

        function isLastPageRange(page, currentPage) {
            return PagingFactory.getPageRange(page, currentPage, $scope.pagedItemRange.value, $scope.totalDocumentCount).isLast;
        }

        //Shared functions for this controller
        function updateSortDetails(sortByField) {
            $scope.sortInfo = _.find($scope.sortItems.data, function (x) { return x.id == sortByField; });
            $scope.isSortDesc = false; // used for orderBy filtering
            $scope.sortOrder = $scope.sortInfo.order;
        }

        // skipApply would be true only when editing a rules. Otherwise it will be null or undefined.
        function resetEditingRule(skipApply) {

            var defaultRuleType = $location.search().triage && !hideTriage() ? 'Triage' : 'Intake';

            $scope.editingRule = {
                name: defaultName,
                ruleType: defaultRuleType
            };

            setActiveRule(0);

            // When resetEditingRule executed from edit skipApply is set to 1 to prevent unnecessary server call.  
            if (!skipApply) { applyRule($scope.editingRule); }
            NotificationService.hideErrors();
            triggerEditInPlace();
        }

        function setActiveRule(ruleId) {
            // Highlight the rule in My Saved Rules
            $("div.saved-rule-container div.saved-rule").removeClass("selected");
            $("div.saved-rule-container div.saved-rule i").removeClass("fa fa-pencil").addClass("fa fa-caret-down");

            if (ruleId) {
                $("#rule-id-" + ruleId + " i").removeClass("fa fa-caret-down").addClass("fa fa-pencil");
                $("#rule-id-" + ruleId).addClass("selected");
            }
        }

        function triggerEditInPlace() {
            $timeout(function () {
                $('span.edit-in-place').click();
            }, 100);
        }

        function editOrCopyRule(rule, isCopy) {
            resetEditingRule(true);

            const ruleId = rule.id;
            $scope.editingRule = rule;

            if ($scope.editingRule.ruleAssignments) {
                // Reset assignedUsers
                var assignedUsers = [];

                _.each($scope.editingRule.ruleAssignments, function (x) {
                    const u = _.find($scope.ruleAssignments, function (y) { return y.userName === x; });
                    assignedUsers.push(u);
                });

                $scope.editingRule.ruleAssignments = assignedUsers;
            }

            if (isCopy) {
                // Reset ID and update name for Copy
                $scope.editingRule.id = 0;
                $scope.editingRule.name = $scope.editingRule.name + ' (Copy)';
            }
            else {
                setActiveRule(ruleId);
            }
            applyRule($scope.editingRule);
        }

        function initializeRules() {
            resetEditingRule();
        }

        /* ### Edit/Copy/Rename/Delete/Save ### */
        function edit(ruleId) {
            RulesService.getRule(ruleId)
                .then(function (data) {
                    editOrCopyRule(data, false);
                });

            // Set focus on ToAddress for Edit
            $('[name=ToAddress]').focus();
        }

        function copy(rule) {
            const ruleId = rule.id;
            RulesService.getRule(ruleId)
                .then(function (data) {
                    editOrCopyRule(data, true);
                });

            // Set focus on ToAddress for Edit
            $('[name=ToAddress]').focus();
        }

        function cancel() {
            // Reset the rules UI back to a new rule state, 
            // we are no longer editing a specific rule.
            resetEditingRule();
        }

        function deleteRule(ruleId) {
            const modalInstance = $uibModal.open({
                templateUrl: settingsRulesDeleteModalTemplate,
                controller: 'RuleDeleteCtrl',
                controllerAs: '$ctrl',
                resolve: { items: function () { return ruleId; } }
            });

            modalInstance.result.then(function (result) {
                if (result.status === 200) {
                    getAllOrgRules();
                    //showMessage('success', 'Rule deleted.');
                    NotificationService.success("Rule deleted");

                    // Reset form if current editing rule was deleted
                    if ($scope.editingRule.id === ruleId) {
                        getAllOrgRules();
                        resetEditingRule();
                    }
                } else {
                    //showMessage('danger', 'An error occurred when deleting this Rule.');
                    NotificationService.error("An error occurred when deleting this Rule.");
                }
            }, function () {
                // do something with dissResult
            });
        }

        function saveRule() {
            // Extract "Assign To" userNames
            const assignments = [];

            _.each($scope.editingRule.ruleAssignments || [],
                function(x) { assignments.push(x.userName); });

            const rule = {
                ruleAssignments: assignments,
                documentType: null,
                fromAddress: $scope.editingRule.fromAddress,
                toAddress: $scope.editingRule.toAddress,
                name: $scope.editingRule.name,
                ruleType: $scope.editingRule.ruleType
            };

            if ($scope.editingRule.id) {
                rule.id = $scope.editingRule.id;
            }

            const errorMessage = validateSave(rule, assignments);

            if (errorMessage) {
                return NotificationService.error(errorMessage);
            } else {
                if (Ladda) {
                    var l = Ladda.create(document.querySelector('#SaveRule'));
                    l.start();
                }

                const ruleFunc = rule.id ? RulesService.editRule : RulesService.createRule;
                ruleFunc(rule)
                    .then(function () {
                        NotificationService.success("Rule saved.");
                        getAllOrgRules();
                        resetEditingRule();
                        if (Ladda && l) { l.stop(); }
                    }, function(result) {
                        if (result.data.modelState) {
                            const messages = [];
                            messages.push(result.data.message);
                            for (const key in result.data.modelState) {
                                if (result.data.modelState.hasOwnProperty(key)) {
                                    Array.prototype.push.apply(messages, result.data.modelState[key]);
                                }
                            }
                            NotificationService.error({ messages: messages, messageTitle: "Invalid Rule" });
                        } else {
                            NotificationService.error(result.message);
                        }
                        if (Ladda && l) { l.stop(); }
                    });
            }

        }

        function validateSave(rule, assignments) {

            if (!rule.name) {
                $('[tabindex=0]').addClass('ng-invalid');
                return 'Please enter a valid name.';
            }

            if (ruleNameExists($scope.editingRule.name, $scope.allRules)) {
                return 'A rule with this name already exists.';
            }

            if (ruleCriteriaExists(rule, $scope.allRules)) {
                return 'A rule already exists with matching criteria.';
            }

            // Require either toAddress OR fromAddress
            if (rule.ruleType === 'Intake' && !rule.toAddress && !rule.fromAddress) {
                return 'At least one "To" address or one "From" address is required.';
            }

            // Require assignTo
            if (!assignments.length) {
                return 'At least one user must be assigned to this Rule.';
            }

            return false;
        }

        // Validate the EditInPlace directive. As users type, we check
        // the name of the rule they are attempting to use for duplicates.
        function validateRuleName(x) {
            if (ruleNameExists($scope.editingRule.name, $scope.allRules)) {
                return { isValid: false, message: 'A rule with this name already exists.' };
            }
            else {
                return { isValid: true, message: 'This rule name is unique.' };
            }
        }

        var ruleNameExists = function (nameToCheck, rules) {
            return _.find(rules, function (x) {
                return (x.name === nameToCheck) && (x.id !== $scope.editingRule.id);
            });
        }

        // Check if a rule exists with the criteria that ruleDto is trying to save
        function ruleCriteriaExists(ruleDto, rules) {
            // According to the rules, we do not care about the ruleAssignments being duplicated.
            // We care about:
            //  fromAddress: "someemail@dd.com"
            //  toAddress: "someemail@dd.com"
            const possibleRules = _.filter(rules, function (x) {
                return ruleDto.fromAddress === x.fromAddress
                    && ruleDto.toAddress === x.toAddress
                    && x.id !== $scope.editingRule.id;
            });

            return possibleRules && possibleRules.length > 0;
        }

        /* ### Paging/Sorting ### */
        function gotoPage(page) {
            if (page < 1) page = 1;
            else if (page > $scope.maxPage) page = $scope.maxPage;

            $scope.currentPage = page;
            $scope.loadingPage = true;
            getMessagesFromService($scope.currentPage, $scope.pagedItemRange.value)
                .then(() => $scope.loadingPage = false, () => $scope.loadingPage = false);
        }

        function getMessagesFromService(page, pageSize) {
            $scope.currentPage = page;
            // Add previewing rule

            // When no from/to address and a new rules, show the unassigned.
            const isFromAddress = rulesRequest.length === 1 && rulesRequest[0].fromAddress;
            const isToAddress = rulesRequest.length === 1 && rulesRequest[0].toAddress;
            const isAll = $scope.editingRule.id === 0 && !isFromAddress && !isToAddress;
            const statuses = [
                'Received',
                'None',
                'Suspended',
                'Processed',
                'Forwarded',
                'Replied',
                'Deleted',
                'Removed',
                'Triaged'
            ];
            const params = {
                isSortDesc: $scope.isSortDesc ? 'desc' : 'asc',
                pageNumber: page,
                pageSize: pageSize,
                rules: rulesRequest,
                statuses: statuses,
                isAll: isAll
            };
            return MessageService.getMessages(params)
                .then(function (res) {
                    const data = res.data;
                    $scope.messages = data.items;
                    $scope.totalDocumentCount = data.totalCount;
                    $scope.maxPage = Math.ceil(data.totalCount / $scope.pagedItemRange.value);
                    $scope.pages = _.range(1, $scope.maxPage + 1);

                    // Update the range to be displayed in the title row
                    $scope.rangeStart = ($scope.currentPage * $scope.pagedItemRange.value) - ($scope.pagedItemRange.value - 1);
                    $scope.rangeEnd = $scope.currentPage * $scope.pagedItemRange.value;
                    if ($scope.rangeEnd > $scope.totalDocumentCount)
                        $scope.rangeEnd = $scope.totalDocumentCount;
                    if ($scope.rangeStart > $scope.rangeEnd)
                        $scope.rangeStart = $scope.rangeEnd;
                });
        }

        function addAll() {
            if ($scope.editingRule) {
                if (!$scope.editingRule.ruleAssignments)
                    $scope.editingRule.ruleAssignments = [];
                _.each($scope.ruleAssignments,
                    function (assignment) {
                        $scope.editingRule.ruleAssignments.push(assignment);
                    });
            }
        }

        function getOrgUsers() {
            UsersService.getOrgUsers(false)
                .then(function (data) {
                    $scope.ruleAssignments = data;
                });
        }

        function getAllOrgRules() {
            RulesService.getAllRules()
                .then(function (data) {
                    $scope.allRules = data;
                });
        }

        function getAllDocumentSources(fromAddress) {
            SureScriptsService.getAddresses(fromAddress)
                .then(function (data) {
                    $scope.fromAddresses = data;
                });
        }

        function onFromAddressChange() {
            getAllDocumentSources($scope.editingRule.fromAddressValue);
        }

        function onFromAddressBlur() {
            applyRule($scope.editingRule);
        }

        function onToAddressChange() {
            applyRule($scope.editingRule);
        }

        function startsWith(item, startWith) {
            return item.substr(0, startWith.length).toLowerCase() === startWith.toLowerCase();
        }

        function hideTriage() {
            return SubscriptionService.hasHideAction('triageRules');
        }

        function ruleTypeChanged() {

            const r = $scope.editingRule;

            switch(r.ruleType) {
                case 'Triage':
                    r.fromAddress = 'Triage';
                    r.toAddress = r.name + ' Intake';
                    break;

                case 'Intake':
                    r.fromAddress = null;
                    r.toAddress = null;
            }

            applyRule(r);
        }
    }
})();
