IntakeService.$inject = ['$location', 'SessionStorageFactory', 'EventService'];

export function IntakeService($location, sessionStorageFactory) {

    const CURRENT_PAGE_KEY = 'intake.current_page';
    const START_DATE_KEY = 'intake.start_date';
    const END_DATE_KEY = 'intake.end_date';
    const ADDRESS_FILTER_KEY = 'intake.address_filter';

    this.messageDocument = undefined;

    this.redirectToMessageView = function (document) {
        this.messageDocument = document;
        $location.path('/intake/' + document.id);
    };

    this.redirectToIntakeView = function () {
        $location.path('/intake');
    };

    this.getCurrentPage = function () {
        var val = sessionStorageFactory.get(CURRENT_PAGE_KEY);
        return val || 1;
    };

    this.setCurrentPage = function (currentPage) {
        sessionStorageFactory.set(CURRENT_PAGE_KEY, currentPage);
    };

    this.setAddressFilter = (filter) => {
        sessionStorageFactory.set(ADDRESS_FILTER_KEY, filter);
    };

    this.getAddressFilter = () => {
        return sessionStorageFactory.get(ADDRESS_FILTER_KEY);
    };

    this.setDateRange = (startDate, endDate) => {
        sessionStorageFactory.set(START_DATE_KEY, startDate.toJSON());
        sessionStorageFactory.set(END_DATE_KEY, endDate.toJSON());
    };

    this.getDateRange = () => ({
        startDate: sessionStorageFactory.get(START_DATE_KEY),
        endDate: sessionStorageFactory.get(END_DATE_KEY)
    });

    this.clearCurrentPage = function () {
        sessionStorageFactory.remove(CURRENT_PAGE_KEY);
    };

    this.clearFilters = () => {
        sessionStorageFactory.removeMany(START_DATE_KEY, END_DATE_KEY, ADDRESS_FILTER_KEY);
    }

    // When raising a rule changed event, pass in the view.
    // view: all, unassigned, null
    this.raiseRuleChanged = function (view, friendlyName, rules, statuses, sourceTypes, isAssignedToSelf, persistsCurrentPage) {
        if (this.onRuleChanged) {
            this.onRuleChanged(view, friendlyName, rules, statuses, sourceTypes, isAssignedToSelf, persistsCurrentPage);
        }
    };

    this.raiseStatusChanged = function (statuses) {
        if (this.onStatusChanged) {
            this.onStatusChanged(statuses);
        }
    };

    this.raiseProcessedTypeChanged = function (processedTypes, statuses, sourceTypes) {
        if (this.onProcessedTypeChanged) {
            this.onProcessedTypeChanged(processedTypes, statuses, sourceTypes);
        }
    };
}
