CdaDocumentTypesService.$inject = ['$http'];

export function CdaDocumentTypesService($http) {
    return {
        get: get,
        upsert: upsert,
        getTemplates: getTemplates
    };

    function get() {
        return $http.get('/api/cdadocumenttypes')
            .then(response => response.data);
    }

    function upsert(resource) {
        return $http.put('/api/cdadocumenttypes', resource)
            .then(response => response.data);
    }

    function getTemplates() {
        return $http.get('/api/cdadocumenttypes/template')
            .then(response => response.data);
    }
}
