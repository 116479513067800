angular.module('kno2.settings')
    .controller('DisableUserModalCtrl', ['$scope', '$uibModalInstance', 'user', 'usersFactory', 'NotificationService',
        function ($scope, $uibModalInstance, user, usersFactory, NotificationService) {

            $scope.user = user;
            $scope.setEnable = function () {
                usersFactory.enable($scope.user, 0).then(function () {
                    // success
                    NotificationService.success("User has been disabled.");
                    $uibModalInstance.close($scope.user);
                }, function (res) {
                    // failure
                    NotificationService.serverError(res.data.modelState, "There was an error disabling the user.");
                });
            };

            $scope.cancelModal = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }
    ]);
