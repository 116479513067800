import templateUrl from './service-account-wizard.component.html';

export const serviceAccountWizardComponent = {
    templateUrl: templateUrl,
    controller: ServiceAccountWizardController,
    bindings: {
        type: '<',
        onInit: '&'
    }
};

ServiceAccountWizardController.$inject = [];

export function ServiceAccountWizardController() {
    var ctrl = this;
    ctrl.$onInit = $onInit;
    ctrl.step0 = step0;
    ctrl.step1 = step1;
    ctrl.step2 = step2;
    ctrl.step3 = step3;

    function $onInit() {

        ctrl.alertLevels = {
            info: 'panel-info',
            success: 'panel-success',
            warning: 'panel-warning'
        };

        ctrl.messages = {
            noCredentials: 'No client credentials are set for this ' + ctrl.type,
            saveYourSecret: 'Save your secret — this is the <u>last time</u> you will have access to it',
            setIpAddresses: 'Please set at least one IP address to enable API access',
            configurationComplete: 'Congratulations your configuration is complete!'
        };

        ctrl.show = false;
        ctrl.complete = false;
        ctrl.alertLevel = ctrl.alertLevels.info;
        ctrl.heading = ctrl.messages.noCredentials;
        ctrl.supplemental = ''; // ;'Here is a <u>supplemental</u> message',
        ctrl.punchList = [
            {
                name: 'SetCredentials',
                complete: false,
                listItem: 'Generate your credentials below',
                listSummary: 'To enable client service account API access, create a client credential key and secret. This is the equivalent of a username/password and should be treated with the same care.'
            },
            {
                name: 'SaveCredentials',
                complete: false,
                listItem: 'Store the result in a secure location',
                listSummary: 'After your client credential key and secret have been generated, save those details immediately, as return visits to this page will no longer display the secret key.'
            },
            {
                name: 'SetIpAddresses',
                complete: false,
                listItem: 'Assign whitelist IP addresses that can access your API',
                listSummary: 'After generating and saving your client credentials, add a set of whitelist IP addresses, enabling control over what networks can access your service account API endpoints.'
            }
        ];

        ctrl.onInit({ $wizard: ctrl });
    }

    function step0() {
        _.each(ctrl.punchList, function (x) { x.complete = false; });
        ctrl.heading = ctrl.messages.noCredentials;
        ctrl.alertLevel = ctrl.alertLevels.info;
        if (!ctrl.complete) {
            ctrl.show = true;
        }
    }

    function step1() {
        ctrl.punchList[0].complete = true;
        ctrl.heading = ctrl.messages.saveYourSecret;
        if (!ctrl.complete) {
            ctrl.show = true;
        }
    }

    function step2() {
        ctrl.punchList[0].complete = true;
        ctrl.punchList[1].complete = true;
        ctrl.heading = ctrl.messages.setIpAddresses;
        if (!ctrl.complete) {
            ctrl.show = true;
        }
    }

    function step3() {
        ctrl.alertLevel = ctrl.alertLevels.success;
        ctrl.punchList[0].complete = true;
        ctrl.punchList[1].complete = true;
        ctrl.punchList[2].complete = true;
        ctrl.heading = ctrl.messages.configurationComplete;
        if (!ctrl.complete) {
            ctrl.show = true;
        }
    }
}