(function () {
    'use strict';

    angular
        .module('kno2.services')
        .service('SubscriptionService', SubscriptionService);

    SubscriptionService.$inject = ['SessionService', 'PromptUpgradeModal'];

    function SubscriptionService(SessionService, PromptUpgradeModal) {

        var service = {
            hasPromptAction: hasPromptAction,
            hasShowAction: hasShowAction,
            hasHideAction: hasHideAction,
            hasDisableAction: hasDisableAction,
            hasCancelAction: hasCancelAction,
            showPrompt: showPrompt,
            checkAndShowPrompt: checkAndShowPrompt
        };

        return service;

        function hasPromptAction(resource) {
            return SessionService.hasUIResourceAction(resource, 'planUpgradePrompt');
        }

        function hasShowAction(resource) {
            return SessionService.hasUIResourceAction(resource, 'show');
        }

        function hasHideAction(resource) {
            return SessionService.hasUIResourceAction(resource, 'hide');
        }

        function hasDisableAction(resource) {
            return SessionService.hasUIResourceAction(resource, 'disable');
        }

        function hasCancelAction(resource) {
            return SessionService.hasUIResourceAction(resource, 'cancel');
        }

        function showPrompt(resource) {
            return PromptUpgradeModal(resource);
        }

        function checkAndShowPrompt(resource) {
            var hasAction = hasPromptAction(resource);
            if (hasAction)
                showPrompt(resource);

            return hasAction;
        }
    }
})();