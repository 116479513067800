angular.module('kno2.services')
    .service('WestFaxService', ['$http', 'CacheService', function ($http) {
        this.postAccount = function(resource) {
            return $http.put('/api/westfax/account', resource);
        }

        this.deleteWestFaxAccount = function(organizationId) {
            return $http.delete('/api/westfax/account/' + organizationId);
        }

        this.addWestFaxNumber = function(organizationId, faxNumber) {
            return $http.post('/api/westfax/account/' + organizationId + '/' + faxNumber);
        }

        this.deleteWestFaxNumber = function(organizationId, documentSourceId) {
            return $http.delete('/api/westfax/account/' + organizationId + '/' + documentSourceId);
        }

        this.searchWestFaxNumbers = function(areaCode) {
            return $http.get('/api/westfax/numbers/' + areaCode);
        }
    }]);