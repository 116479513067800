import triageEditMessageModalTemplate from './triage.edit-message-modal.html';

TriageCtrl.$inject = ['$state', '$rootScope', '$q', '$stateParams', '$location', '$scope', '$window', '$uibModal', '$confirm', '_', 'TriageHttpService', 'TriageServiceFactory', 'SessionService', 'RulesService', 'NotificationService', 'MessageService', 'MessageHubService'];

export function TriageCtrl($state, $rootScope, $q, $stateParams, $location, $scope, $window, $uibModal, $confirm, _, TriageHttpService, TriageServiceFactory, SessionService, RulesService, NotificationService, MessageService, MessageHubService) {
    var vm = this,
        triageService = TriageServiceFactory({
            beforeCreateMessage: createMessage,
            getRuleName: getRuleName
        });

    vm.fromParameters = $state.transition.params('from');
    vm.backTitle = vm.fromParameters.messageId ? 'Return to Message' : 'Return to Intake';
    vm.messageTemplate = { subject: '' };
    vm.patient = {};
    vm.reset = reset;
    vm.reload = reload;
    vm.submit = submit;
    vm.submitDisabled = submitDisabled;
    vm.createMessage = triageService.createMessage;
    vm.editMessage = editMessage;
    vm.deleteMessage = deleteMessage;
    vm.createAttachment = triageService.createAttachment;
    vm.messages = triageService.messages;
    vm.trash = triageService.trash;
    vm.getSelected = triageService.getSelected;
    vm.selectedPages = triageService.selectedPages;
    vm.allProcessed = allProcessed;
    vm.moveSelectedPagesToAttachment = function () {
        setDirty();
        triageService.moveSelectedPagesToAttachment.apply(this, arguments);
    };
    vm.moveSelectedPagesToCollection = function () {
        setDirty();
        triageService.moveSelectedPagesToCollection.apply(this, arguments);
    };

    vm.getRuleName = getRuleName;
    vm.getTemplate = getTemplate;
    vm.viewerOptions = {
        paging: 'hidden',
        thumbnails: 'hidden',
        rotate: 'enabled',
        remove: 'hidden',
        print: 'hidden',
        download: 'hidden',
        zoom: 'page-height',
        // Old options
        height: -1,
        showPaging: false,
        showViewerStyleControl: false,
        showThumbnails: false,
        centerDocument: true,
        toolbarPosition: 'bottom',
        zoomToFitWidth: true,
        hideWindowBorder: true,
        supportPageRotation: true
    };
    vm.urlFn = (x) => `${ENVIRONMENT.baseUrl}/api/triage/${x.messageId}/pages/${x.id}`;
    clearDirty();

    activate();

    function goBack() {
        if (vm.fromParameters.messageId) {
            $state.go('intakeMessage', { messageId: vm.fromParameters.messageId });
        } else {
            $state.go('intake');
        }
    }

    vm.back = function () {
        if (vm.dirty) {
            confirmNavigation().then(function() {
                clearRouteChangeAlert();
                goBack();
            }).catch(angular.noop);
        } else
            goBack();
    }


    vm.attachments = function () {
        var messageAttachments = _.chain(triageService.messages).map('attachments').flattenDeep().value();
        var attachments = [].concat(triageService.trash, triageService.unprocessed, messageAttachments);
        return attachments;
    };

    function setDirty() {
        vm.dirty = true;
        setRouteChangeAlert();
    }

    function clearDirty() {
        vm.dirty = false;
        clearRouteChangeAlert();
    }

    function setRouteChangeAlert() {
        if(!$rootScope.beforeChange) {
            $rootScope.$broadcast('routeChange',
            {
                action: function () {
                    var deferred = $q.defer();
                    confirmNavigation().then(function () {
                        clearDirty();
                        clearRouteChangeAlert();
                        deferred.resolve();
                    }, function() {
                        deferred.reject();
                    }).catch(angular.noop);
                    return deferred.promise;
                }
            });
        }
    }

    function clearRouteChangeAlert() {
        $rootScope.$broadcast('routeChangeClear');
    }

    function allProcessed() {
        if (vm.isLoading) return false;
        let unprocessedCount = 0;
        _.each(vm.unprocessed,
            function (attachment) {
                unprocessedCount += attachment.pages.length;
            });
        return unprocessedCount === 0;
    }

    function activate() {
        vm.isLoading = true;

        TriageHttpService.get($stateParams.id).then(function (batch) { // success
            vm.batchId = batch.id;
            vm.unprocessed = triageService.addUnprocessed(batch.unprocessed);
            vm.isLoading = false;
        }, function (response) { // error
            // todo: prompt to reload/reprocess?
            vm.isError = true;
            vm.isLoading = false;
        });

        RulesService.getAllTriageRules().then(function (data) {
            vm.rules = data || [];
            vm.rules.splice(0, 0, { id: 0, name: 'My Intake' });
        });

        MessageService.getMessage($stateParams.id)
            .then(function (message) {
                if (message) {
                    vm.messageTemplate.subject = message.subject;

                    if (message.patient) {
                        vm.patient = message.patient;
                    }
                }
            });

        $scope.$on('$destroy', function () {
            triageService.destroy();
            angular.element($window).off('beforeunload.triage');
        });

        MessageHubService.openMessage($stateParams.id);

        var stopListening = $scope.$on('$locationChangeStart', function (event) {
            if (vm.dirty) {
                event.preventDefault();
                var newPath = $location.path();
                confirmNavigation()
                    .then(function () {
                        clearRouteChangeAlert();
                        MessageHubService.closeMessage($stateParams.id);
                        stopListening();
                        $location.path(newPath);
                    })
                    .catch(angular.noop);
            }
            else {
                MessageHubService.closeMessage($stateParams.id);
            }
        });

        angular.element($window).on('beforeunload.triage', function (event) {
            if (vm.dirty) {
                var message = 'There is unsaved work, are you sure you\'d like to navigate away?';
                event.returnValue = message;
                return message;
            }
        });
    }

    function confirmNavigation() {
        return $confirm.open({
            bodyText: 'There is unsaved work, are you sure you\'d like to navigate away?',
        })
            .result.then(function (ok) {
                if (!ok) throw 'Cancelled';
            });
    }

    function reset() {
        $confirm.open({
            bodyText: 'Are you sure you want to reload? This action cannot be undone.',
            okText: 'Reset'
        })
            .result.then(function (ok) {
                if (ok) {
                    reload();
                }
            })
            .catch(angular.noop);
    }

    function reload() {
        clearRouteChangeAlert();
        $state.reload();
    }

    function submit() {

        vm.submitting = true;

        var messages = vm.messages.slice();
        _.each(messages, function (m) {

            // remove empty mesages
            if (!m.attachments.length)
                vm.messages.remove(m);

            // remove faux rule
            if (!m.ruleId) m.ruleId = null;

            // prep attachments for upload
            prepAttachments(m.attachments);
        });

        TriageHttpService.submit({
            id: vm.batchId,
            messages: vm.messages,
            unprocessed: prepAttachments(vm.unprocessed)
        })
            .then(function () {
                NotificationService.success('Batch has been submitted.');
                clearDirty();
                $location.path('/intake');
            })
            .catch(function () {
                NotificationService.error({ messages: ['An error occurred while attempting to submit the batch.'] });
            })
            .finally(function () {
                vm.submitting = false;
            });
    }

    function prepAttachments(collection) {
        var copy = collection.slice();
        _.each(copy, function (a) {
            // remove empty attachments 
            if (!a.pages.length) collection.remove(a);

            // pass up rotation
            _.each(a.pages, function (p) {
                if (p.$$transforms && p.$$transforms.rotations && p.$$transforms.rotations[0])
                    p.rotation = p.$$transforms.rotations[0];
            });
        });
        return collection;
    }

    function submitDisabled() {

        var pages = _.chain(vm.messages)
            .map('attachments').flattenDeep()
            .map('pages').flattenDeep()
            .value();

        var deletedPages = _.chain(vm.trash)
            .map('pages').flattenDeep()
            .value();

        return !pages.length && !deletedPages.length;
    }

    function createMessage() {
        return $uibModal.open({
            templateUrl: triageEditMessageModalTemplate,
            controller: 'TriageCreateEditMessageModalCtrl as vm',
            windowClass: 'modal-draggable modal-500',
            resolve: {
                message: function () {
                    return null;
                },
                template: function () {
                    return getTemplate();
                },
                rules: function () {
                    return vm.rules;
                }
            }
        })
            .result.then(function (message) {
                setDirty();
                return message;
            }, angular.noop);
    }

    function editMessage(message) {
        return $uibModal.open({
            templateUrl: triageEditMessageModalTemplate,
            controller: 'TriageCreateEditMessageModalCtrl as vm',
            windowClass: 'modal-draggable modal-500',
            resolve: {
                message: function () {
                    return message;
                },
                template: function () {
                    return null;
                },
                rules: function () {
                    return vm.rules;
                }
            }
        })
            .result.then(() => { }, angular.noop);
    }

    function deleteMessage(message) {
        var text = 'Are you sure you want to delete ' + message.patient.firstName + ' ' + message.patient.lastName + (message.rule ? ', ' + message.rule + '?' : '?');
        $confirm.open({
            bodyText: text,
            okText: 'Delete'
        })
            .result.then(function (ok) {
                if (ok) {
                    triageService.deleteMessage(message);
                }
            });
    }

    function getRuleName(ruleId) {

        var rule = _.find(vm.rules, function (r) {
            return r.id === ruleId;
        });

        return rule && rule.name || 'My Intake';
    }

    function getTemplate() {
        if (!triageService.messages.length)
            return angular.merge({}, vm.messageTemplate, { patient: vm.patient });
        else
            return vm.messageTemplate;
    }
}
