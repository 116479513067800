import templateUrl from './viewer-panel.component.html';

export class ViewerPanelController { }

ViewerPanelController.$inject = [];

export const viewerPanelComponent = {
    templateUrl,
    controller: ViewerPanelController,
    bindings: {
        message: '<',
        attachment: '<',
        viewerOptions: '<',
        change: '&'
    }
}
