import templateUrl from './client-credentials-delete.modal.component.html';

export const clientCredentialsDeleteModalComponent = {
    templateUrl: templateUrl,
    controller: ClientCredentialsDeleteModalController,
    bindings: {
        resolve: '<',
        modalInstance: '<'
    }
}

ClientCredentialsDeleteModalController.$inject = ['ServiceAccountServiceFactory', 'NotificationModalService'];

export function ClientCredentialsDeleteModalController(ServiceAccountServiceFactory, NotificationModalService) {
    var ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.delete = deleteServiceAccount;
    ctrl.cancel = cancel;

    function $onInit() {
        ctrl.group = ctrl.resolve.group;
        ctrl.type = ctrl.resolve.type;
    }

    function deleteServiceAccount() {
        return ServiceAccountServiceFactory.ofType(ctrl.type).deleteServiceAccount(ctrl.group.id)
            .then(function () {
                ctrl.modalInstance.close();
            })
            .catch(function () {
                NotificationModalService.error("An error occurred when deleting api settings");
            });
    }

    function cancel() {
        ctrl.modalInstance.dismiss();
    }
}