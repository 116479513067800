angular.module('kno2.factories').factory('focus', [
    '$rootScope', '$timeout', (function($rootScope, $timeout) {
        return function(name) {
            return $timeout(function() {
                return $rootScope.$broadcast('focusOn', name);
            });
        };
    })
]);

angular.module('kno2.directives').directive('focusOn', function() {
    return {
        restrict: 'A',
        scope: true,
        link: function(scope, elem, attr) {
            var destroyEvent = scope.$on('focusOn', function(e, name) {
                if (name === attr.focusOn) {
                    return elem[0].select();
                }
            });
            scope.$on('$destroy', destroyEvent);
        }
    };
});
