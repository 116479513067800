import templateUrl from './auth0-error.component.html';

class Auth0ErrorController {
    constructor($timeout, $stateParams, Auth0Service, auth0ResponseConstants) {
        this.$timeout = $timeout;
        this.$stateParams = $stateParams;
        this.auth0Service = Auth0Service;
        this.auth0ResponseConstants = auth0ResponseConstants;
    }

    $onInit() {
        this.error = this.$stateParams.error;
        this.seconds = 6;
        this.hasAccessDeniedError = this.error.code === this.auth0ResponseConstants.AccessDenied

        if (this.hasAccessDeniedError)
            this.countdown();
    }

    $onDestroy() {
        this.auth0Service.logout();
    }

    getFriendlyError() {
        return this.error.code
            .split('_')
            .map(x => x.charAt(0).toUpperCase() + x.slice(1))
            .join(' ');
    }

    countdown() {
        this.seconds--;
        if (this.seconds <= 0)
            return this.auth0Service.logout();
        this.$timeout(() => this.countdown(), 1000);
    }
}

Auth0ErrorController.$inject = ['$timeout', '$stateParams', 'Auth0Service', 'auth0ResponseConstants'];

export const auth0ErrorComponent = {
    templateUrl: templateUrl,
    controller: Auth0ErrorController
};
