(function() {
    'use strict';

    angular.module('kno2.factories')
    .factory('LoadingInterceptorFactory', loadingInterceptorFactory)

    loadingInterceptorFactory.$inject = ['$q', '$rootScope', '$location', '_'];
    
    function loadingInterceptorFactory($q, $rootScope, $location, _) {
        var stack = [];
        return {
            'request': function (config) {

                var silent = config.throbber === false || config.url.indexOf("s=true") != -1;

                if ((stack.length == 0) && (!silent))
                    $rootScope.$broadcast('loading-started');

                stack.push(config);
                return config || $q.when(config);
            },

            'requestError': function(rejection) {
                $rootScope.$broadcast('loading-complete');

                stack = [];
                return $q.reject(rejection);
            },

            'response': function(response) {
                stack = _.filter(stack, function(config) {
                    return response.config.url != config.url && config.throbber !== false;
                });

                if (stack.length < 1)
                    $rootScope.$broadcast('loading-complete');
                return response || $q.when(response);
            },

            'responseError': function (rejection) {
                stack = [];

                $rootScope.$broadcast('loading-complete');
                return $q.reject(rejection);
            }
        };
    }

})();