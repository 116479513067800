angular.module('kno2.settings')
    .controller('UserActivityModalCtrl', ['$scope', '$uibModalInstance', '$timeout', 'data', 'IAuditService',
        function ($scope, $uibModalInstance, $timeout, data, AuditService) {

            $scope.startDate = moment().utc().subtract(14, 'days').toJSON();
            $scope.endDate = moment().endOf('day').utc().toJSON();

            $scope.title = data.title;
            $scope.pageSize = 10;
            $scope.currentPage = 1;
            $scope.auditEntries = [];

            $scope.dateOptions = {
                maxDate: new Date()
            };

            // Accept an evenType string for the auditFilter
            $scope.resetWithFilter = function (filterEventType) {
                $scope.auditFilter = {
                    startDate: $scope.startDate,
                    endDate: $scope.endDate,
                    eventType: filterEventType
                };
            }

            // Initialize with the "All" eventType for the auditFilter
            $scope.resetWithFilter("All");

            var loadAuditEntriesInternal = function () {
                var startDate = moment($scope.startDate);
                var endDate = moment($scope.endDate);
                var queryStartDate = new Date(startDate.year(), startDate.month(), startDate.date(), 0, 0, 0).toJSON();
                var queryEndDate = new Date(endDate.year(), endDate.month(), endDate.date(), 23, 59, 59).toJSON();
                var promise = AuditService.getEntriesByUser(data.userName, $scope.auditFilter.eventType, queryStartDate, queryEndDate);

                promise.then(function (res) {
                    $scope.auditEntries = res;
                    spliceResults();
                });
            };

            var spliceResults = function () {
                var pageStart = ($scope.currentPage - 1) * $scope.pageSize;
                var pageEnd = $scope.currentPage * $scope.pageSize;

                // check to see if pageEnd is greater than total count
                if ($scope.auditEntries.length < pageEnd) {
                    pageEnd = $scope.auditEntries.length;
                }

                $scope.dataPage = $scope.auditEntries.slice(pageStart, pageEnd);
            };

            $scope.$watch('currentPage', function () {
                spliceResults();
            });

            $scope.selectedClass = function (selectedBy) {
                return $scope.auditFilter.eventType == selectedBy ? 'alerts-menu-active' : '';
            };

            $scope.setSelectedType = function (selectedType) {
                $scope.auditFilter.eventType = selectedType;
            };

            $scope.closeModal = function () {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.setPage = function (page) {
                $scope.currentPage = page;
            };

            $scope.$watch('auditFilter',
                function (val) {
                    $scope.startDate = moment(val.startDate).toJSON();
                    $scope.endDate = moment(val.endDate).toJSON();
                    loadAuditEntriesInternal();
                    spliceResults();
                },
                function (newVal, oldVal) {
                    return newVal.eventType == oldVal.eventType &&
                        newVal.startDate == oldVal.startDate &&
                        newVal.endDate == oldVal.endDate;
                });
        }
    ]);
