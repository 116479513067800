angular.module('kno2.settings')
    .controller('EditUserModalCtrl',
    [
        '$q', '$uibModalInstance', '$timeout', 'userId', 'usersFactory', 'NotificationService', 'SessionService',
        'ValidationService', 'DocumentSourceService', '_',
        function ($q,
            $uibModalInstance,
            $timeout,
            userId,
            usersFactory,
            NotificationService,
            sessionService,
            ValidationService,
            DocumentSourceService,
            _) {
            const ctrl = this;
            ctrl.title = "Update User";
            ctrl.currentUser = sessionService.getProfile();
            ctrl.userId = userId;
            ctrl.isCurrentUser = userId.toUpperCase() === sessionService.getProfile().userId.toUpperCase();
            ctrl.validateUsername = validateUsername;
            ctrl.saveChanges = saveChanges;
            ctrl.setForm = setForm;
            ctrl.closeModal = closeModal;
            ctrl.selected = {};
            ctrl.addAll = addAll;

            ctrl.$onInit = activate;

            function activate() {
                usersFactory.userfororg(ctrl.userId).then(function (res) {
                    // success
                    ctrl.user = res.data;

                },
                    function (status) {
                        // error
                    });

                const promises = [];

                promises.push(DocumentSourceService.getOrgDocumentSources()
                    .then(function (data) {
                        ctrl.documentSources = data;
                    })
                    .then(function () {
                        return DocumentSourceService.getDocumentSourcesByUser(userId).then(function (data) {
                            ctrl.selected.documentSources = filterBy(ctrl.documentSources, data, 'id');
                        });
                    }));

                $q.all(promises).then(function () {
                });
            }

            function addAll() {
                if (!ctrl.selected.documentSources)
                    ctrl.selected.documentSources = [];
                _.each(ctrl.documentSources,
                    function(documentSource) {
                        ctrl.selected.documentSources.push(documentSource);
                    });
            }

            function filterBy(collection1, collection2, prop1, prop2) {
                if (prop2 === undefined) prop2 = prop1;
                var props = _.map(collection2, prop2);
                return _.filter(collection1,
                    function (value) {
                        return _.includes(props, value[prop1]);
                    });
            }

            function setForm(form) {
                ctrl.form = form;
            }

            function closeModal() {
                $uibModalInstance.dismiss('cancel');
            }

            function validateUsername(username, callback) {
                ValidationService.validateUsername(username, callback);
            }

            function saveChanges() {
                if (ctrl.form && ctrl.form.$valid) {

                    const documentSources = _(ctrl.selected.documentSources).map(function (d) {
                        return {
                            documentSourceId: d.id,
                            organizationId: ctrl.currentUser.organizationId
                        };
                    });

                    const promises = [
                        usersFactory.save(ctrl.user),
                        DocumentSourceService.setDocumentSourcesForUser(userId, documentSources)
                    ];

                    $q.all(promises).then(function () {
                        NotificationService.success("User has been saved successfully.");
                        sessionService.refreshProfile();
                        $uibModalInstance.close(ctrl.user);
                    },
                        function (res) {
                            NotificationService.serverError(res.data.modelState,
                                "There was an error saving this user.");
                        });
                }
            }
        }
    ]);