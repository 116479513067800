(function () {
    'use strict';

    angular
        .module('kno2.directives')
        .directive('autoSubmit', autoSubmit);

    autoSubmit.$inject = ['$timeout'];

    function autoSubmit($timeout) {
        return {
            restrict: 'A',
            replace: true,
            require: ['^form'],
            link: function (scope, element, attr, ctrl) {
                if (element.context.tagName !== 'FORM' || !ctrl) return;
                $timeout(function () {
                    element.triggerHandler('submit');
                });
            }
        };
    }

})();