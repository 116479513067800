import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { downgradeModule, downgradeComponent } from '@angular/upgrade/static';
import { ExampleComponent } from './example/example.component';

@NgModule({
    imports: [
        BrowserModule,
        CommonModule
    ],
    declarations: [
        ExampleComponent
    ],
    entryComponents: [
        ExampleComponent
    ]
})
export class SharedModule {
    constructor() { }
    ngDoBootstrap() {
    }
}

const ng2BootstrapFn = (extraProviders) => platformBrowserDynamic(extraProviders).bootstrapModule(SharedModule);
export const downgradedSharedModule = downgradeModule(ng2BootstrapFn);
