angular.module('kno2.filters')
    .filter('endsWith', ['SupportedFileExtensionsFactory', function (SupportedFileExtensionsFactory) {
        return function (n) {
            // Input: SomefileName.docx
            // Return: docx
            if (!n || n === 'undefined') return "";
            n = n.substring(n.lastIndexOf('.') + 1);
            var ext = n.toLocaleLowerCase();
            return _.indexOf(SupportedFileExtensionsFactory.getExtensions(), ext) == -1 ? 'doc' : ext;
        };
    }])

    .filter('isSupportedExtension', ['SupportedFileExtensionsFactory', function (SupportedFileExtensionsFactory) {
        return function (n) {
            if (!n || n === 'undefined') return false;
            n = n.substring(n.lastIndexOf('.') + 1);
            var ext = n.toLocaleLowerCase();
            return _.indexOf(SupportedFileExtensionsFactory.getExtensions(), ext) != -1;
        };
    }])

    // Format blank Patient Name with N/A (if null) otherwise return Patient Name from json object.
    .filter('formatPatientName', function () {
        return function (value) {
            var patientName = $.trim(value);
            return (patientName !== '') ? patientName : 'N/A';
        };
    })

    .filter('formatSemicolons', ['$sce', function ($sce) {
        return function (value) {
            if (value) {
                var html = [];
                if (value.substring(value.length - 1) == ";") {
                    value = value.substring(0, value.length - 1);
                }
                var parts = value.split(";");
                _.each(parts, function (part, idx, list) {
                    html.push('<span class="hover-address" title="');
                    html.push(part);
                    html.push('">');
                    html.push(part.substring(0, part.indexOf("@")));
                    html.push('</span>');
                    if (idx < list.length - 1) {
                        html.push(',');
                    }


                });

                html.push('<span class="address-more">');
                html.push('[ ');
                html.push('<a href="javascript:;" tabindex="-1" popover="john@j.com <br> bill@bill.com <br> dan@dan.com <br> jill@jill.com" popover-trigger="mouseenter" popover-popup-delay="400" popover-placement="bottom" class="ng-scope">');
                html.push('<i class="fa fa-plus-circle box-info"></i>');
                html.push('</a>');
                html.push(' 3');
                html.push(' more ]');
                html.push('</span>');

                return $sce.trustAsHtml(html.join(""));
            }
            return value;
        };
    }])

    // Returns a PascalCaseString to be Pascal Case String.
    .filter('pascalCaseFormatter', function () {
        return function (text) {
            return _.isEmpty(text) ? "" : text.replace(/([A-Z])/g, " $1").trim();
        };
    })

    // Return the proper icon format based on the number of attachments.
    .filter('formatAttachmentIcon', ['SupportedFileExtensionsFactory', function (SupportedFileExtensionsFactory) {
        return function (attachments) {
            var ext = 'doc',
                count = (attachments) ? attachments.length : 0;
            switch (count) {
                case 0:
                    ext = 'doc-none';
                    break;
                case 1:
                    var fileName = attachments[0].fileName;
                    var index = fileName.lastIndexOf('.');
                    if (index >= 0) {
                        var _ext = fileName.substr(index + 1).toLocaleLowerCase();
                        ext = _.indexOf(SupportedFileExtensionsFactory.getExtensions(), _ext) == -1 ? 'doc' : _ext;
                    }
                    break;
                default:
                    ext = 'doc-many';
                    break;
            }
            return ext;
        };
    }])

    .filter('formatAlertDate', function () {
        return function (date) {
            var dateMoment = moment.utc(date).local();
            if (dateMoment.isSame(moment(), 'day')) {
                return dateMoment.calendar();
            }
            return dateMoment.format("MMMM D, YYYY");
        };
    })

    .filter('formatDateWithTime', function () {
        return function (date) {
            if (!date)
                return '';
            var dateMoment = moment.utc(date).local();
            if (dateMoment.isSame(moment(), 'day')) {
                return dateMoment.calendar();
            }
            return dateMoment.format("LLLL");
        };
    })

    .filter('utcDate', ['$filter', function ($filter) {
        return function (date, format) {
            if (date) {
                var momentDate = moment.utc(date).local();
                if (momentDate.isValid()) {
                    return $filter('date')(momentDate.toDate(), format);
                }
            }
            return undefined;
        };
    }])

    // Return a substring of provided input truncated at a word boundary
    .filter('truncate', function () {
        return function (str, length, suffix) {

            if (typeof (str) == 'undefined') return "";   // Prevent errors when ng-show="false" with a truncate.
            if (str.length <= length)
                return str;
            suffix = suffix || '...';

            var words = str.split(' ');
            var outWords = [];
            // start out with the length of the provided suffix
            var total = suffix.length;
            var i = 0;
            while (total < length) {
                var word = words[i];
                total += word.length + 1;// add one for the space char
                outWords.push(word);
                i++;
            }
            var ret = outWords.join(' ') + suffix;
            return ret;
        };
    })

    // Return a substring of provided input truncated at a specific length boundary
    .filter('truncateText', function () {
        return function (text, length, end) {
            if (isNaN(length)) length = 10;
            if (end === undefined) end = "...";
            if (text === undefined) text = '';
            if (text.length <= length) {
                return text;
            }
            else {
                return String(text).substring(0, length - end.length) + end;
            }
        };
    })

    .filter('filterIntakeProcessedTypes', function () {
        return function (array) {
            return _.sortBy(_.filter(array, function (x) {
                return x == "Printed" || x == "StructuredExport" || x == "PDFExport" || x == "NativeExport";
            }));
        };
    })
    .filter('decamelize', function () {
        return function (text) {
            return text.replace(/([A-Z]{1,}[a-z])/g, ' $1')
                .replace(/(EMR)/g, '$1 ')
                .replace(/^./, function (str) {
                    return str.toUpperCase();
                });
        };
    })

    .filter('documentSourceStatus', function () {
        return function (str) {
            if (typeof (str) == 'undefined') return "";
            var value = "";
            switch (str) {
                case 0:
                    value = 'Provisioning';
                    break;
                case 1:
                    value = 'Activated';
                    break;
                case 2:
                    value = 'Pending Update';
                    break;
                case 3:
                    value = 'Pending Deletion';
                    break;
                case 4:
                    value = 'Deleted';
                    break;
                case 5:
                    value = 'Failed';
                    break;
            }
            return value;
        };
    })

    .filter('addressFormatting', function () {
        return function (address) {
            if (!address) return address;
            var reg = new RegExp(/^\d+$/);
            if (!reg.test(address)) return address;
            var formatted = "";
            if (address.length === 10) {
                formatted = address.replace(/^(\d{3})(\d{3})(\d{4}).*/, '$1-$2-$3');
            }
            else if (address.length === 11) {
                formatted = address.replace(/^(\d{1})(\d{3})(\d{3})(\d{4}).*/, '$1-$2-$3-$4');
            }
            else if (address.length === 12) {
                formatted = address.replace(/^(\d{2})(\d{3})(\d{3})(\d{4}).*/, '$1-$2-$3-$4');
            }
            else if (address.length === 13) {
                formatted = address.replace(/^(\d{3})(\d{3})(\d{3})(\d{4}).*/, '$1-$2-$3-$4');
            }
            return formatted;
        };
    })
    ;
