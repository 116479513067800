angular.module('kno2.services')
    .service('NotificationService', ['$window', function ($window) {
        // This service acts as a communication broker between the
        // controller raising the events and the NotificationCtrl
        // that handles the events. 

        // Notify is a message that is less severe
        this.success = function (message) {
            if (this.notifySuccess) {
                this.notifySuccess(message);
            }
        };

        this.errorToaster = function(message) {
            if (this.notifyError) {
                this.notifyError(message);
            }
        }

        // Alerts are sticky and persist on the page
        this.error = function (options, noScroll) {
            if(!noScroll)
                $window.scrollTo(0, 0);
            if (this.showAlertOnPage) {
                this.showAlertOnPage(options);
            }
        };

        this.warning = function(message) {
            if (this.showAlertOnPage) {
                var options = {
                    caption: 'Warning!',
                    messages: [message],
                    level: 'warning'
                };
                this.showAlertOnPage(options);
            }
        };

        this.notice = function(message) {
            if (this.showAlertOnPage) {
                var options = {
                    caption: 'Notice!',
                    messages: [message],
                    level: 'warning'
                };
                this.showAlertOnPage(options);
            }
        }

        this.hideErrors = function () {
            if (this.hideAlertOnPage) {
                this.hideAlertOnPage();
            }
        };

        // This function is a standard call to report modelState errors.
        this.serverError = function (modelState, title) {
            let errors = new Array();
            if (modelState) {
                angular.forEach(modelState, function (value, key) {
                    value.forEach(e => errors.push(e));
                });
            }
            this.errors(errors, title);
        };

        this.errors = function (errors, title) {
            if (this.showAlertOnPage) {
                var options = {
                    messageTitle: title,
                    messages: errors
                };
                this.error(options);
            }
        };
    }])

    .service('NotificationModalService', [function () {
        // This service acts as a communication broker between the
        // controller raising the events and the NotificationCtrl
        // that handles the events. 

        // Notify is a message that is less severe
        this.success = function (message) {
            if (this.notifySuccess) {
                this.notifySuccess(message);
            }
        };

        // Alerts are sticky and persist on the page
        this.error = function (scope, options) {
            if (this.showAlertOnPage) {
                this.showAlertOnPage(scope, options);
            }
        };

        this.warning = function (message) {
            if (this.showAlertOnPage) {
                var options = {
                    caption: 'Warning!',
                    messages: [message],
                    level: 'warning'
                };
                this.showAlertOnPage(options);
            }
        };

        this.hideErrors = function () {
            if (this.hideAlertOnPage) {
                this.hideAlertOnPage();
            }
        };

        // This function is a standard call to report modelState errors.
        this.serverError = function (modelState, title) {
            let errors = new Array();
            if (modelState) {
                angular.forEach(modelState, function (value, key) {
                    value.forEach(e => errors.push(e));
                });
            }
            this.errors(errors, title);
        };

        this.errors = function (errors, title) {
            if (this.showAlertOnPage) {
                var options = {
                    messageTitle: title,
                    messages: errors
                };
                this.error(options);
            }
        };
    }]);
