ServiceAccountServiceFactory.$inject = ['$http', '$injector'];

export function ServiceAccountServiceFactory($http, $injector) {
    
    return {
        ofType: ofType
    }

    function ofType(type) {
        if (type === 'admin')
            return $injector.get('AdminServiceAccountService');
        else if (type !== 'network' && type !== 'organization')
            throw new Error('Invalid Service Account type: ' + type);

        return {
            getServiceAccount: getServiceAccount,
            addOrUpdateServiceAccount: addOrUpdateServiceAccount,
            updateIpAddresses: updateIpAddresses,
            toggleStatus: toggleStatus,
            deleteServiceAccount: deleteServiceAccount
        };

        function getServiceAccount(groupId) {
            return $http.get('/api/' + type + 's/' + groupId + '/serviceaccounts')
                .then(function (response) {
                    return response.data;
                });
        }

        function addOrUpdateServiceAccount(groupId) {
            return $http.put('/api/' + type + 's/' + groupId + '/serviceaccounts')
                .then(function (response) {
                    return response.data;
                });
        }

        function updateIpAddresses(groupId, addresses) {
            return $http.put('/api/' + type + 's/' + groupId + '/serviceaccounts/ipaddresses', addresses)
                .then(function (response) {
                    return response.data;
                });
        }

        function toggleStatus(groupId, state) {
            return $http.put('/api/' + type + 's/' + groupId + '/serviceaccounts/' + state)
                .then(function (response) {
                    return response.data;
                });
        }

        function deleteServiceAccount(groupId) {
            return $http.delete('/api/' + type + 's/' + groupId + '/serviceaccounts');
        }
    }
}