import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-example',
  template: `<h1>Example</h1>`,
  encapsulation: ViewEncapsulation.None
})
export class ExampleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
      console.log('example works')
  }

}
