export class ConfigurationService {

    constructor($http) {
        this.$http = $http;
    }

    getConfiguration() {
        return this.$http.get('/api/configuration', { cache: true }).then(x => x.data);
    }
}

ConfigurationService.$inject = ['$http'];
