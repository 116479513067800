var whitelist;

TriageHttpService.$inject = ['$http', '$q', 'Upload', '$location', '_'];

export function TriageHttpService($http, $q, Upload, $location, _) {

    var service = {
        show: show,
        upload: upload,
        triage: triage,
        triageAndShow: triageAndShow,
        get: get,
        submit: submit,
        getExtensions: getExtensions
    };

    return service;

    function show(id) {
        $location.path('/triage/' + id);
    }

    function upload(request) {

        request = _.clone(request);

        if (!request.ruleId)
            request.ruleId = null;

        return Upload.upload({
            url: '/api/triage/',
            data: {
                file: request.files,
                batch: Upload.json({
                    name: request.name,
                    ruleId: request.ruleId,
                    multiple: request.multiple
                })
            }
        }).then(function (response) {
            return response.data.id;
        });
    }

    function triage(messageId) {
        return $http.post('/api/messages/{id}/triage'.replace('{id}', messageId))
            .then(function (response) {
                return response.data.id;
            });
    }

    function triageAndShow(message) {
        return triage(message.id).then(function (id) {
            show(id);
            return id;
        });
    }

    function get(id) {
        return $http.get('/api/triage/{id}'.replace('{id}', id), {
            throbber: false
        })
            .then(function (response) {
                return response.data;
            });
    }

    function submit(batch) {
        var jsonData = angular.toJson(batch);
        return $http.put('/api/triage/{id}'.replace('{id}', batch.id), jsonData);
    }

    function getExtensions() {
        if (whitelist) return $q.resolve(whitelist);
        return $http.get('/api/triage/extensions').then(function (response) {
            return whitelist = response.data;
        });
    }
}
