
import verifyPhoneModalTemplate from './account.profile.verify-phone-modal.html';
import enableTokenAuthenticatorModalTemplate from './account.profile.enable-token-authenticator-modal.html';

EditProfileModalCtrl.$inject = ['$uibModalInstance', '$uibModal', '$timeout', '$location', 'usersFactory', 'NotificationService', 'ValidationService', 'userId', 'SessionService', 'showNotificationsTab', '$confirm', 'IntegrationsService', '_', 'AccountProfileService'];
export function EditProfileModalCtrl($uibModalInstance, $uibModal, $timeout, $location, usersFactory, NotificationService, ValidationService, userId, SessionService, showNotificationsTab, $confirm, IntegrationsService, _, AccountProfileService) {
    const ctrl = this;
    ctrl.userId = userId;
    ctrl.title = "Update Profile";
    ctrl.currentTab = "editProfile";
    ctrl.userProfile = SessionService.getProfile();
    ctrl.changingPassword = false;

    ctrl.loadUser = function () {
        usersFactory.user(ctrl.userId)
            .then(function (res) {
                ctrl.user = res.data;
                ctrl.user.originalTokenSetting = ctrl.user.tokenAuthEnabled;
                ctrl.user.originalPhone = ctrl.user.phoneNumber;
            },
                function (res) {
                    NotificationService.serverError(res.data.modelState,
                        "There was an error getting your information.");
                });

        if (ctrl.userProfile.organizations.length > 0)
            IntegrationsService.getCapabilities()
                .then(res => {
                    ctrl.cacheIntegrationData = !!_.find(res.capabilities, { display: 'cacheIntegrationData' });
                });
    };

    ctrl.loadUser();

    ctrl.validateUsername = function (username, callback) {
        ValidationService.validateUsername(username, callback);
    };

    ctrl.phoneChanged = function () {
        if (ctrl.user.phoneNumber !== ctrl.user.originalPhone) {
            ctrl.user.phoneNumberConfirmed = false;
            ctrl.user.twoFactorEnabled = !ctrl.user.canChangeTwoFactorSetting;
        } else {
            if (ctrl.form.$valid) {
                ctrl.user.phoneNumberConfirmed = true;
                ctrl.user.twoFactorEnabled = true;
            }
        }
    };

    ctrl.showTab = function (tab) {
        if (!ctrl.changingPassword) ctrl.currentTab = tab;
    };

    ctrl.selectedClass = function (tab) {
        if (ctrl.changingPassword) return 'disabled-tab';
        var cssclass = ctrl.currentTab === tab ? 'alerts-menu-active' : '';
        cssclass += (tab === 'editProfile' && ctrl.profileTabError)
            ? (cssclass ? ' ' : '') + 'error-tab'
            : '';
        cssclass += (tab === 'emailNotifications' && ctrl.emailNotificationsTabError)
            ? (cssclass ? ' ' : '') + 'error-tab'
            : '';
        cssclass += (tab === 'authorizedApplications' && ctrl.authorizedApplicationsTabError)
            ? (cssclass ? ' ' : '') + 'error-tab'
            : '';
        return cssclass;
    };

    ctrl.selectedTab = function (tab) {
        return ctrl.currentTab === tab ? '' : 'hide';
    };

    ctrl.saveChanges = function () {

        ctrl.profileTabError = false;
        ctrl.emailNotificationsTabError = false;
        ctrl.authorizedApplicationsTabError = false;

        if (ctrl.form && ctrl.form.$valid) {
            usersFactory.save(ctrl.user).then(function (res) {
                NotificationService.success("Profile has been updated.");
                $uibModalInstance.close(ctrl.user);
                if (!ctrl.user.phoneNumberConfirmed && ctrl.user.twoFactorEnabled) {
                    var verifyModalInstance = $uibModal.open({
                        templateUrl: verifyPhoneModalTemplate,
                        controller: 'VerifyPhoneModalCtrl',
                        controllerAs: '$ctrl',
                        resolve: { settings: { userId: function () { return ctrl.userId; } } }
                    });
                    verifyModalInstance.result.then(function () {
                        SessionService.refreshProfile();
                    }, () => { /* handle dismiss */ });
                }
                if (ctrl.user.tokenAuthEnabled && ctrl.user.tokenAuthEnabled !== ctrl.user.originalTokenSetting) {
                    var verifyGoogleAuthInstance = $uibModal.open({
                        templateUrl: enableTokenAuthenticatorModalTemplate,
                        controller: 'EnableTokenAuthenticatorModalCtrl',
                        controllerAs: '$ctrl',
                        resolve: { settings: { userId: function () { return ctrl.userId; } } }
                    });
                    verifyGoogleAuthInstance.result.then(function () {
                        SessionService.refreshProfile();
                    }, () => { /* handle dismiss */ });
                }
                SessionService.refreshProfile();

            },
                function (res) {
                    NotificationService.serverError(res.data.modelState,
                        "There was an error updating your profile.");
                });
        }
    };

    ctrl.removeApplication = function (appId) {

        return $confirm.open({
            bodyText: `Are you sure you want to remove this application?`,
            okText: 'Remove'
        })
            .result
            .then(() => {
                usersFactory.removeApplication(ctrl.user.id, appId)
                    .then(() => {
                        ctrl.loadUser();

                    });
            },
                () => { /* handle dismiss */ });
    };

    ctrl.revokeToken = function (login) {
        switch (login.name) {
            case 'PointClickCare':
                revokeToken();
                break;
            default:
                revokeTokenOauth();
                break;
        }
    };

    const confirmRevoke = () => {
        return $confirm.open({
            bodyText: 'Are you sure you want to revoke token access?',
            okText: 'Revoke'
        })
    }

    const revokeToken = () => {
        confirmRevoke().result.then(() => {
            IntegrationsService.revokeToken()
                .then(() => ctrl.loadUser(), () => { });
        });
    }

    const revokeTokenOauth = () => {
        confirmRevoke().result.then(() => {
            IntegrationsService.revokeTokenOauth()
                .then(() => ctrl.loadUser(), () => { });
        });
    }

    ctrl.clearCachedData = function () {
        return $confirm.open({
            bodyText: 'Are you sure you want to clear your Integration Cached Data?',
            okText: 'Clear'
        }).result.then(() => {
            IntegrationsService.clearCachedData()
                .then(() => {
                    NotificationService.success("Cleared cached data.");
                }, res => {
                    NotificationService.error('There was an error clearing the cached data.');
                });
        }, () => { /* handle dismiss */ });
    };

    ctrl.setForm = function (form) {
        ctrl.form = form;
    };

    ctrl.closeModal = function () {
        $uibModalInstance.dismiss('cancel');
    };

    if (showNotificationsTab) {
        ctrl.showTab('emailNotifications');
    }

    ctrl.toggleChangingPassword = function () {
        ctrl.password = {};
        ctrl.changingPassword = !ctrl.changingPassword;
    };

    ctrl.changePassword = function () {
        AccountProfileService.changePassword(ctrl.password)
            .then(() => {
                NotificationService.success('Password changed successfully');
                ctrl.changingPassword = false;
                ctrl.password = {};
            }, (err) => {
                const modelState = err.data.modelState[Object.keys(err.data.modelState)[0]];
                NotificationService.errors(modelState, err.data.message);
            });
    };
}
