import angular from 'angular';
import * as _ from 'lodash';
import * as daterangepicker from 'daterangepicker';
import templateUrl from './patient-attachments.component.html';
import messageOrderLookupModalTemplate from '../../../intake/message/intake.message.order-lookup-modal.html';
import messageReviewerLookupModalTemplate from '../../../intake/message/intake.message.reviewer-lookup-modal.html';
import patientLookupTemplate from '../../../intake/message/intake.message.patient-lookup-modal.html';
import hcsPatientLookupTemplate from '../../../intake/message/intake.message.hcs-patient-lookup-modal.html';

export const patientAttachmentsComponent = {
    templateUrl,
    controller: PatientAttachmentsCtrl,
    bindings: {
        isIntake: '<',
        pageState: '<',
        draftId: '=',
        message: '<',
        patient: '<',
        attachments: '<',
        attachmentsInProcess: '<',
        showAttachmentToggle: '<',
        invalidEmrDownload: '<',
        hideDeleteAttachments: '=',
        onAttachmentSelected: '&',
        onFilesDropped: '&',
        onInit: '&'
    }
};

PatientAttachmentsCtrl.$inject = [
    '$rootScope', '$timeout', '$interval', '$uibModal', '$confirm',
    'IntegrationsService', 'AttachmentService', 'NotificationService', 'SessionService',
    'CommonData', 'MessageFactory', 'AttachmentsFactory', 'DocumentTypesService',
    'FeatureService', 'ReleaseService', '_', 'ExternalLoginService', '$location'
];

export function PatientAttachmentsCtrl(
    $rootScope, $timeout, $interval, $uibModal, $confirm,
    IntegrationsService, AttachmentService, NotificationService, SessionService,
    CommonData, MessageFactory, AttachmentsFactory, DocumentTypesService,
    FeatureService, ReleaseService, _, ExternalLoginService, $location
) {
    const ctrl = this;
    let cancelOnAddAttachmentCompleted;

    ctrl.$onInit = $onInit;
    ctrl.$postLink = $postLink;
    ctrl.$onDestroy = $onDestroy;
    ctrl.onSave = onSave;
    ctrl.extractPatient = extractPatient;
    ctrl.openPatientLookupModal = openPatientLookupModal;
    ctrl.beginPatientSearch = beginPatientSearch;
    ctrl.openOrderLookupModal = openOrderLookupModal;
    ctrl.openReviewerLookupModal = openReviewerLookupModal;
    ctrl.documentTypeChanged = documentTypeChanged;
    ctrl.clearPatient = clearPatient;
    ctrl.clearVisit = clearVisit;
    ctrl.restoreAttachment = restoreAttachment;
    ctrl.isAttachmentReadOnlyMode = isAttachmentReadOnlyMode;
    ctrl.isStructuredDocument = isStructuredDocument;
    ctrl.documentDateTypeChanged = documentDateTypeChanged;
    ctrl.getConfidentialityFlagName = getConfidentialityFlagName;
    ctrl.selectAttachment = selectAttachment;
    ctrl.deleteAttachment = deleteAttachment;
    ctrl.selectedDocumentType = selectedDocumentType;
    ctrl.addAttachment = addAttachment;
    ctrl.externalLoginWindow = null;
    ctrl.openCalendar = openCalendar;

    function $onInit() {
        ctrl.allowAttachmentNameEdit = $location.path().contains('intake') || $location.path().contains('release/new') || ctrl.message.isDraft; 
        ctrl.patientLookupAvailable = false;
        ctrl.visitLookupAvailable = false;
        ctrl.reviewerLookupAvailable = false;
        ctrl.documentTypeLookupAvailable = false;
        ctrl.documentUploadAvailable = false;
        ctrl.states = CommonData.states.asArray();
        ctrl.confidentialityFlags = CommonData.confidentialityFlags.asArray();
        ctrl.dateOptions = { maxDate: new Date() };
        ctrl.patientLookupTemplate = patientLookupTemplate;
        ctrl.enablePatientExtract = false;
        ctrl.useIntegrationDocumentTypes = false;
        ctrl.documentTypeRequired = false;
        ctrl.externalLogin = false;
        ctrl.singleDatePickerConfig = {
            singleDatePicker: true,
            showCustomRangeLabel: false,
            autoUpdateInput: false,
            opens: 'left',
            maxDate: moment().utc().endOf('day'),
            ranges: {
                'Today': [moment(), moment().endOf('day')],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
            },
            locale: { "format": "MM/DD/YYYY" }
        };

        if (ctrl.attachments && ctrl.attachments.length) {
            // Load up the first document to view, as this is the first time the page is loading
            ctrl.selectAttachment(ctrl.attachments[0]);
        }

        cancelOnAddAttachmentCompleted = $rootScope.$on('onAddAttachmentCompleted', (event, attachment) => {
            ctrl.selectAttachment(attachment);

            if (attachment.isPreviewAvailable) {
                if (attachment.mimeType === 'application/pdf' && FeatureService.isEnabled('PatientExtractionAPI')) {
                    extractPatient();
                }
            }
        });

        IntegrationsService.getCapabilities()
            .then(integrations => {
                integrations.capabilities.forEach(x => ctrl[`${x.display}Available`] = true);

                const documentTypeLookup = _.find(integrations.capabilities, { display: IntegrationsService.capabilities.DocumentTypeLookup });
                ctrl.useIntegrationDocumentTypes = ctrl.documentTypeLookupAvailable && !!documentTypeLookup && documentTypeLookup.reference.indexOf('integrations') > -1;
                ctrl.documentTypeRequired = ctrl.isIntake && integrations.documentTypeRequiredOnIntake;
                ctrl.allowUrgent = ctrl.isIntake && integrations.allowUrgent;
                ctrl.supportsGenderOnSearch = _.some(integrations.capabilities, x => _.some(x.properties, y => y.name === 'gender'));
                ctrl.externalLogin = integrations.externalLogin || !!_.find(integrations.capabilities, { display: IntegrationsService.capabilities.HaveCodeGrantLogin });
                ctrl.integrationDisplayName = integrations.displayName;

                if (ctrl.documentUploadAvailable) {
                    var profile = SessionService.getProfile();
                    ctrl.attachments.forEach(x => {
                        x.integrationMeta = x.integrationMeta || {};
                        x.integrationMeta.userEmail = profile.userName;
                    });
                }

                if (ctrl.useIntegrationDocumentTypes && ctrl.externalLogin) checkExternalLogin(getIntegrationDocumentTypes);
                else if (ctrl.useIntegrationDocumentTypes && !ctrl.externalLogin) getIntegrationDocumentTypes();
                else getDocumentTypes();

                if (ctrl.patientLookupAvailable && integrations.customPatientLookupTemplate) {
                    const customTemplates = {
                        'hcs-patient-lookup-modal': hcsPatientLookupTemplate
                    };
                    ctrl.patientLookupTemplate = customTemplates[integrations.customPatientLookupTemplate];
                }
            });
    }

    $rootScope.$watch(
        () => AttachmentsFactory.documentTypeRequired,
        () => ctrl.documentTypeRequired = AttachmentsFactory.documentTypeRequired)

    function getDocumentTypes() {
        DocumentTypesService.getDocumentTypes()
            .then(x => ctrl.documentTypes = _.map(x.documentTypes, y => ({
                id: y.id,
                name: y.name
            })));
    }

    function getIntegrationDocumentTypes() {
        IntegrationsService.getDocumentTypes()
            .then(types => {
                ctrl.documentTypes = _.map(types, x => ({
                    id: x.id || x.name,
                    name: x.name,
                    source: x.source
                }));
            }, err => {
                if (err.data && err.data.message) {
                    NotificationService.error(`DocumentTypes - ${err.data.message}`);
                }
            });
    }

    function $postLink() {
        ctrl.onInit({ ctrl });
    }

    function $onDestroy() {
        cancelOnAddAttachmentCompleted();
    }

    function onSave() {
        ctrl.extracted = {};
    }

    function extractPatient() {
        ctrl.waitingForPatientExtraction = true;

        const messageId = ctrl.draftId || ctrl.message.id;
        const attachmentId = ctrl.selectedAttachment.id;

        function done() {
            ctrl.waitingForPatientExtraction = false;
            ctrl.enablePatientExtract = false;
        }

        AttachmentService.extractPatient(messageId, attachmentId)
            .then(() => {
                const interval = $interval(() => {
                    AttachmentService.getExtractedPatient(messageId, attachmentId)
                        .then(result => {
                            if (result.status === 'Processing') return;
                            if (result.status === 'Completed')
                                mapExtractedPatient(result.patient);

                            $interval.cancel(interval);
                        });
                }, 1000, 10);

                interval.then(done, done);
            })
            .catch(done);
    }

    function mapExtractedPatient(patient) {
        const properties = ['firstName', 'middleName', 'lastName', 'birthDate', 'gender'];

        const filtered = properties
            .filter(x => !ctrl.patient[x])
            .filter(x => patient[x]);

        ctrl.extracted = {};

        if (filtered.length) {

            ctrl.extracted = filtered.reduce((o, x) => (o[x] = true, o), {});

            filtered.forEach(x => {
                ctrl.patient[x] = patient[x];
            });

            ctrl.patientDetailsForm.$setUntouched();
        }
    }

    function externalLogin(integration, func) {
        return function() {
            ExternalLoginService.externalLogin(integration, func);
        };
    }

    function checkState(state, isPatient) {
        IntegrationsService.checkState(state).then((res) => {
            getIntegrationDocumentTypes();
            if (isPatient) {
                openPatientLookupModal();
            }
        }).catch(() => {
            NotificationService.error(
                'Error logging in to the External site. This can happen if the external login website was closed too quickly. Please wait for the window to close automatically or you are shown it is now safe to close. If the problem persists, please contact support.'
            );
        });
    }

    function checkExternalLoginPageClosed(state, isPatient) {
        const interval = $interval(() => {
            if (ctrl.externalLoginWindow && ctrl.externalLoginWindow.closed) {
                $interval.cancel(interval);
                ctrl.externalLoginWindow = null;
                checkState(state, isPatient);
            }
        }, 1000, 60);
    }

    function checkExternalLogin(func, isPatient) {
        IntegrationsService.externalLogin().then(data => {
            if (data.mustLogin) {
                openExternalLoginConfirm(data, isPatient);
            } else {
                func();
            }
        });
    }

    function beginPatientSearch() {
        if (!ctrl.externalLogin) {
            openPatientLookupModal();
        } else {
            ExternalLoginService.externalLogin({ name: ctrl.integrationDisplayName }, openPatientLookupModal);
//            checkExternalLogin(openPatientLookupModal);
        }
    }

    function openExternalLoginConfirm(data, isPatient) {
        $confirm.open({
            title: `${ctrl.integrationDisplayName} Login`,
            bodyText: `You must sign in to ${ctrl.integrationDisplayName} to proceed. This will open a new window and ask you to sign in using your ${ctrl.integrationDisplayName} credentials.\n\nDo you want to continue?`
        }).result.then(function (ok) {
            if (ok) {
                ctrl.externalLoginWindow = window.open(data.url);
                checkExternalLoginPageClosed(data.state, isPatient);
                ctrl.externalLoginWindow.focus();
            }
        });
    }

    function openPatientLookupModal() {
        const modal = $uibModal.open({
            templateUrl: ctrl.patientLookupTemplate,
            controller: 'PatientLookupModalCtrl as vm',
            windowClass: 'modal-draggable modal-800',
            resolve: {
                patient: function () {
                    return ctrl.patient;
                },
                patientLookupTemplate: function () {
                    return ctrl.patientLookupTemplate;
                },
                visitEnabled: function () {
                    return ctrl.visitLookupAvailable &&
                        ctrl.pageState &&
                        ctrl.pageState.patient &&
                        ctrl.pageState.patient.visitDate &&
                        ctrl.pageState.patient.visitDate.enabled;
                },
                supportsGenderOnSearch: function () {
                    return ctrl.supportsGenderOnSearch;
                }
            }
        });

        modal.result.then(function (result) {
            if (!result) return;

            if (result.patient) {
                if (ctrl.patientDetailsLookupAvailable) {
                    IntegrationsService.getPatientById(result.patient.patientResourceId).then(x => {
                        mapPatient(ctrl.patient, x);
                    });
                } else {
                    mapPatient(ctrl.patient, result.patient);
                }
            }

            if (result.visit) {
                ctrl.visit = result.visit;
                mapVisit(ctrl.patient, result.visit);
            }
        }, () => { /* handle dismiss */ });
    }

    function openOrderLookupModal(attachment) {
        const modal = $uibModal.open({
            templateUrl: messageOrderLookupModalTemplate,
            controller: 'OrderLookupModalCtrl as vm',
            resolve: {
                visit: function () {
                    return ctrl.visit;
                },
                state: function () {
                    return {
                        visitId: ctrl.patient.integrationMeta && ctrl.patient.integrationMeta.visitId,
                        patientId: ctrl.patient.integrationMeta && ctrl.patient.integrationMeta.patientId,
                        orderIds: (attachment.integrationMeta && attachment.integrationMeta.orderIds) || []
                    };
                },
                allowMultipleOrders: function () {
                    return ctrl.reviewerGroupLookupAvailable;
                }
            }
        });

        modal.result.then(function (result) {
            if (!result) return;
            ctrl.visit = result.visit;
            attachment.integrationMeta = attachment.integrationMeta || {};
            attachment.integrationMeta.orderIds = result.orderIds;
        }, () => { /* Handle rejection when closing modal */ });
    }

    function openReviewerLookupModal(attachment) {
        const modal = $uibModal.open({
            templateUrl: messageReviewerLookupModalTemplate,
            controller: 'ReviewerLookupModalCtrl as vm',
            windowClass: 'modal-draggable modal-800',
            resolve: {
                reviewerId: function () {
                    return attachment.integrationMeta
                        && attachment.integrationMeta.reviewerId;
                },
                reviewer: function () {
                    return attachment.$$reviewer;
                },
                reviewerGroupUuid: function () {
                    return attachment.integrationMeta && attachment.integrationMeta.groupReviewerId;
                },
                reviewerGroup: function () {
                    return attachment.$$reviewerGroup;
                },
                groupLookupAvailable: function () {
                    return ctrl.reviewerGroupLookupAvailable;
                },
                reviewerNote: function () {
                    return attachment.integrationMeta && attachment.integrationMeta.reviewerNote;
                }
            }
        });

        modal.result.then(function (reviewerResult) {
            if (!reviewerResult) {
                attachment.$$reviewer = undefined;
                attachment.$$reviewerGroup = undefined;
                attachment.integrationMeta = attachment.integrationMeta || {};
                attachment.integrationMeta.reviewerId = null;
                attachment.integrationMeta.groupReviewerId = null;
                attachment.integrationMeta.urgent = null;
                attachment.integrationMeta.reviewerNote = null;
            } else {
                attachment.$$reviewer = reviewerResult.reviewer || undefined;
                attachment.$$reviewerGroup = reviewerResult.group || undefined;
                attachment.integrationMeta = attachment.integrationMeta || {};
                attachment.integrationMeta.reviewerId = reviewerResult.reviewer ? reviewerResult.reviewer.id : null;
                attachment.integrationMeta.groupReviewerId = reviewerResult.group ? reviewerResult.group.groupUid : null;
                attachment.integrationMeta.reviewerNote = reviewerResult.reviewerNote;
                if (reviewerResult.group)
                    attachment.integrationMeta.urgent = null;
                if (!reviewerResult.reviewer && !reviewerResult.group) attachment.integrationMeta.urgent = null;
            }
        }, () => { /* Handle rejection when closing modal */ });
    }

    function documentTypeChanged(attachment) {
        const documentType = _.find(ctrl.documentTypes, { name: attachment.attachmentMeta.documentType });
        if (!documentType) return;

        attachment.documentType = documentType.name;
        attachment.attachmentMeta = attachment.attachmentMeta || {};
        attachment.attachmentMeta.documentType = documentType.name;

        if (!attachment || !attachment.integrationMeta) return;

        const id = attachment.integrationMeta.documentTypeId;
        if (!id) return;


    }

    function clearPatient() {
        mapPatient(ctrl.patient, {});
        clearVisit();
    }

    function clearVisit() {
        ctrl.visit = null;
        mapVisit(ctrl.patient, {});
        clearOrders();
    }

    function clearOrders() {
        _.each(ctrl.attachments,
            function (a) {
                if (a.integrationMeta)
                    a.integrationMeta.orderIds = [];
            });
    }

    function restoreAttachment(messageId, attachment) {
        $confirm.open({
            title: 'Restore Attachment',
            bodyText: `You are about to restore the attachment, ${attachment.fileName}, to its original state.\n\nAre you sure?`
        }).result.then(function (ok) {
            if (ok) {
                AttachmentService.restoreAttachment(messageId, attachment.id)
                    .then(function () {
                        NotificationService.success('Original attachment restored.');
                    }, function () {
                        NotificationService.error('Error restoring attachment.');
                    })
                    .then(function () {
                        return AttachmentService.getAttachment(messageId, attachment.id)
                            .then(function (response) {
                                const a = _.find(ctrl.attachments, { id: attachment.id });
                                const index = _.indexOf(ctrl.attachments, a);
                                const merged = _.merge(response.data, _.pick(a, [
                                    'attachmentMeta',
                                    'integrationMeta',
                                    'isSelected'
                                ]));

                                merged.$$restored = true;

                                ctrl.attachments.splice(index, 1, merged);
                                ctrl.selectAttachment(merged);
                            });
                    });
            }
        });
    }

    function mapPatient(p1, p2) {
        p1.integrationMeta = p1.integrationMeta || {};
        p1.integrationMeta.patientId = p2.patientResourceId;
        p1.patientId = p2.patientId;
        p1.firstName = p2.firstName;
        p1.middleName = p2.middleName;
        p1.lastName = p2.lastName;
        p1.birthDate = p2.birthdate;
        p1.gender = mapGender(p2.gender);
        if (p2.medicalRecordNumber) p1.integrationMeta.medicalRecordNumber = p2.medicalRecordNumber;
        else delete p1.integrationMeta.medicalRecordNumber;

        p1.streetAddress1 = p2.streetAddress1;
        p1.streetAddress2 = p2.streetAddress2;
        p1.city = p2.city;
        if (p2.facilityId)
            p1.integrationMeta.facilityId = p2.facilityId.toString();
        else delete p1.integrationMeta.facilityId;


        const state = mapState(p2.state);
        p1.state = state ? state.abbr : null;

        p1.postalCode = p2.postalCode;
        p1.telephone = p2.telephone;
    }

    function mapGender(gender) {
        if (!gender) return null;
        switch (gender.toLowerCase()) {
            case "m":
            case "male":
                return "M";
            case "f":
            case "female":
                return "F";
            case "un":
            case "undifferentiated":
                return "UN";
            default:
                return null;
        }
    }

    function mapState(state) {
        if (!state) return null;
        const lowerState = state.toLowerCase();
        const foundState = _.find(ctrl.states,
            function (s) {
                if (lowerState.length === 2)
                    return s.abbr.toLowerCase() === lowerState;
                return s.name.toLowerCase() === lowerState;
            });
        return foundState;
    }

    function mapVisit(v1, v2) {
        v1.integrationMeta = v1.integrationMeta || {};
        v1.integrationMeta.visitId = v2.id;
        v1.visitId = v2.id;
        v1.visitDate = v2.date;
    }

    function isAttachmentReadOnlyMode(attachment) {
        return MessageFactory.isAttachmentReadOnlyMode(attachment, ctrl.pageState);
    }

    function isStructuredDocument(attachment) {
        return MessageFactory.isStructuredDocument(attachment);
    }

    function documentDateTypeChanged(index) {
        MessageFactory.documentDateTypeChanged(index, ctrl.attachments, ctrl.message.systemReceivedDate);
    }

    function getConfidentialityFlagName(flagId) {
        return CommonData.confidentialityFlags.getById(flagId);
    }

    function selectAttachment(a) {
        ctrl.selectedAttachment = a;
        ctrl.onAttachmentSelected({ $attachment: a });

        if (!a) return;

        if (a.mimeType === 'application/pdf') {
            ctrl.enablePatientExtract = true;
        }

        // ML - XML documents that are not preview available may still be convertering.
        // Refresh from server
        if (!a.isPreviewAvailable && a.previewAvailable !== 'Failed') {

            const messageId = ctrl.draftId || ctrl.message.id;

            AttachmentService.requestPreview(messageId, a.id).then(() => {

                const interval = $interval(() => {
                    AttachmentService.getAttachment(messageId, a.id).then(response => {

                        const attachment = response.data;

                        if (attachment && attachment.isPreviewAvailable || attachment.previewAvailable === 'Failed') {
                            $interval.cancel(interval);

                            // replace attachment with updated attachment
                            const index = _.findIndex(ctrl.attachments, x => x.id === a.id);
                            if (index >= 0) {
                                ctrl.attachments[index] = attachment;
                                selectAttachment(attachment);
                            }
                        }
                    });
                }, 2000, 15);
            });
        };
    }


    function deleteAttachment(index) {

        const attachment = ctrl.attachments[index];

        const modal = $confirm.open({
            title: 'Delete Attachment',
            bodyText: `You are about to delete attachment "${attachment.fileName}".\n\nAre you sure?`
        });

        modal.result.then((ok) => {
            if (ok) {
                AttachmentService.deleteAttachment(ctrl.draftId, attachment.id).then(function () {
                    selectNextPreviousAttachment(index);
                    ctrl.attachments.splice(index, 1);

                    NotificationService.success('Attachment has been deleted.');
                });
            }
        });
    }

    // Select the next/previous attachment if deleting the opened one.
    function selectNextPreviousAttachment(index) {
        if (ctrl.selectedAttachment === ctrl.attachments[index]) {
            var newIndex = -1;
            if (index + 1 <= (ctrl.attachments.length - 1)) {
                newIndex = index + 1;
            } else if (index - 1 >= 0) {
                newIndex = index - 1;
            }
            if (newIndex >= 0) {
                ctrl.selectAttachment(ctrl.attachments[newIndex]);
            } else {
                ctrl.selectAttachment(null);
            }
        }
    }

    function selectedDocumentType(attachment) {
        return function (value) {
            let documentType = null;
            if (!angular.isDefined(value)) {
                if (!ctrl.documentTypes) return null;
                documentType = ctrl.documentTypes.find(x => x.name === attachment.attachmentMeta.documentType);
                return documentType ? documentType.id : null
            }
            else {
                documentType = ctrl.documentTypes.find(x => x.id === value);
                attachment.attachmentMeta = {
                    ...attachment.attachmentMeta,
                    documentType: documentType ? documentType.name : null
                }

                if (ctrl.isIntake) {
                    attachment.integrationMeta = {
                        ...attachment.integrationMeta,
                        documentTypeId: value
                    }
                }
            }

            return attachment.attachmentMeta && attachment.attachmentMeta.documentType || null
        }
    }

    function addAttachment() {
        NotificationService.hideErrors();
        if (!ctrl.draftId) {
            ReleaseService.saveDraft(ctrl.draftId, ctrl.message, ctrl.attachments, ctrl.patient)
                .then(function (message) {
                    ctrl.draftId = message.id;
                    openAddAttachmentModal(message.id, ctrl.documentTypes);
                });
        } else {
            openAddAttachmentModal(ctrl.draftId, ctrl.documentTypes);
        }
    }

    function openAddAttachmentModal(messageId, documentTypes) {
        $uibModal.open({
            component: 'k2AddAttachmentModal',
            resolve: {
                messageId: function () {
                    return messageId;
                },
                documentTypes: function () {
                    return documentTypes;
                }
            }
        }).result.then(() => { }, () => { });
    }

    function openCalendar() {
        if (ctrl.pageState.messageStrategy === 'intake') {
            ctrl.singleDatePickerConfig.ranges = Object.assign({ "Message Date": [moment(ctrl.message.systemReceivedDate), moment(ctrl.message.systemReceivedDate)] }, ctrl.singleDatePickerConfig.ranges);
        }
        
        $('input[name="docDate"]').daterangepicker(ctrl.singleDatePickerConfig, function(chosen_date) {
            $(this.element[0]).val(chosen_date.format(ctrl.singleDatePickerConfig.locale.format)).trigger('input');
        });

        $(".daterangepicker").children(".ranges").addClass("patient-attachment-datepicker");
    }
}
