(function () {
    'use strict';

    angular
        .module('kno2.directives')
        .directive('k2ErrorAddon', kno2ErrorAddon);

    kno2ErrorAddon.$inject = ['_'];

    var defaultMessage = 'Please enter a valid value for this field.';

    function kno2ErrorAddon(_) {
        return {
            restrict: 'A',
            require: ['ngModel', '?^form'],
            link: function (scope, element, attrs, controllers) {
                var ngModelController = controllers[0],
                    formController = controllers[1],
                    messages = scope.$eval(attrs.messages) || {},
                    options = scope.$eval(attrs.k2ErrorAddon) || {},
                    validateOnSubmit = attrs.hasOwnProperty('validateOnSubmit'),
                    hasChosenAttr = ('chosen' in attrs);

                // save to element so other directives can access
                element.data('k2ErrorAddonMessages', messages);

                var toggleAddon = function () {
                    if (ngModelController.$invalid && hasChosenAttr) {
                        element.next('.chosen-container').addClass('has-error');
                    }
                    else if (ngModelController.$invalid && !hasChosenAttr) {
                        element.addClass('has-error');

                        var error = _.find(Object.keys(ngModelController.$error), function (key) {
                            return ngModelController.$error[key];
                        });

                        messages = element.data('k2ErrorAddonMessages');
                        element.attr('title', messages[error] || defaultMessage);
                    } else {
                        element.attr('title', '');
                        element.removeClass('has-error');
                        if (hasChosenAttr)
                            element.next('.chosen-container').removeClass('has-error');
                    }
                };

                ngModelController.$viewChangeListeners.push(function () {
                    if (ngModelController.$dirty) toggleAddon();
                });

                scope.$watch(function() {
                    return ngModelController.$dirty;
                },
                function(value) {
                    if (value) toggleAddon();
                });

                ngModelController.$viewChangeListeners.push(function () {
                    if (ngModelController.$dirty) toggleAddon();
                });

                if ((options.onSubmit || validateOnSubmit) && formController) {
                    var unbindFormSubmitted = scope.$watch(function() {
                        return formController.$submitted;
                    }, function (value) {
                        if (value) {
                            toggleAddon();
                            unbindFormSubmitted();
                        }
                    });
                }

                ngModelController.$viewChangeListeners.push(function setErrorClass() {
                    var invalid = ngModelController.$dirty && ngModelController.$invalid;
                    if (invalid) element.addClass('error');
                    else element.removeClass('error');
                });
            }
        };
    }

}());
