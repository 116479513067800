(function (angular) {
    'use strict';

    angular
        .module('kno2.settings')
        .service('AppsService', AppsService);

    AppsService.$inject = ['$http'];

    function AppsService($http) {
        // a LOT of duplicate boilerplate code in these services
        this.getDefaultPageParameters = function () {
            return {
                pageNumber: 1,
                pageSize: 10
            };
        };
        this.getPageSizeOptions = function () {
            return [10, 25, 50, 100];
        };
        this.getPages = function (params) {
            var pageParams = _.extend({ type: 'apps' }, this.getDefaultPageParameters(), params);
            var promise = $http({
                url: '/api/connectors',
                method: 'GET',
                params: pageParams
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };
        this.getById = function (id) {
            var promise = $http.get('/api/connectors/' + id).then(function (response) {
                return response.data;
            });
            return promise;
        };
        this.attachLicense = function (resource, licenseKey) {
            var promise = $http.post('/api/connectors/' + resource.id + '/license', { id: resource.id, licenseKey: licenseKey })
                .then(function (response) {
                    return response.data;
                });
            return promise;
        };
        this.detachLicense = function (resource) {
            var promise = $http.delete('/api/connectors/' + resource.id + '/license', { id: resource.id })
                .then(function (response) {
                    return response.data;
                });
            return promise;
        };
        this.delete = function (appId) {
            var promise = $http.delete('/api/connectors/' + appId)
                .then(function (response) {
                    return response.data;
                });
            return promise;
        };
    }

})(window.angular);
