angular.module('kno2.settings')
    .directive('ngEnter', function() {
        return function(scope, element, attrs) {
            element.bind("keydown keypress", function(event) {
                var inputElement = angular.element(element.children()[1]);
                var defaultFocus = $('[tabindex=1]');

                //Handle Enter key
                if (event.which === 13) {
                    $(inputElement[0]).blur();
                    if (defaultFocus) defaultFocus.focus();

                    event.preventDefault();
                }
                
                //Handle Tab key
                if (event.which === 9) {
                    if (defaultFocus) defaultFocus.focus();

                    event.preventDefault();
                }
            });
        };
    })
    .directive('editInPlace', function () {
        return {
            restrict: 'E',
            scope: {
                value: '=',
                validate: '&'  // valation function to call
            },
            template:
            `<div class="edit-in-place input-group mb-3 col-xs-12 pad0">
                <span class="edit-in-place-underline"><span class="edit-in-place" ng-click="editInPlace()" ng-bind="value" ng-blur="editOnBlur()"></span></span>
                <input tabindex="0" ng-model="value" class="form-control k2-full-width" style="margin-right: -4px;"></input>
            </div>`,
            link: function ($scope, element, attrs) {
                // Let's get a reference to the input element, as we'll want to reference it.
                var divElement = angular.element(element.children()[0]);
                var inputElement = angular.element(divElement.children()[1]);

                // This directive should have a set class so we can style it.
                element.addClass('edit-in-place');

                // Initially, we're not editing.
                $scope.editing = false;

                // ng-click handler to activate edit-in-place
                $scope.editInPlace = function() {
                    $scope.editing = true;

                    // We control display through a class on the directive itself. See the CSS.
                    element.addClass('active');

                    // And we must focus the element. 
                    // `angular.element()` provides a chainable array, like jQuery so to access a native DOM function, 
                    // we have to reference the first element in the array.
                    $(inputElement[0]).focus();

                    //Select all text on edit
                    $(inputElement[0]).select();
                };
                
                // When we leave the input, we're done editing.
                inputElement.bind('blur', function (evt) {

                    //Check for blank value -- prevent the element from disappearing
                    if (!$('.edit-in-place > div > span').html().trim()) {
                        $(inputElement[0]).focus();
                    }
                    else {
                        // Call our validation function if it exists
                        var validationResult = $scope.validate();

                        if (!validationResult) {
                            // validationResult is undefined when the validate function is not provided.
                            // Return to non-editable state.
                            $scope.editing = false;
                            element.removeClass('active');
                        }
                        else {
                            if (validationResult.isValid) {
                                // Return to non-editable state.
                                $scope.editing = false;
                                element.removeClass('active');
                                
                                // cleanup textbox and span control
                                inputElement.removeClass('ng-dirty ng-invalid error form-error');
                                if (divElement.find("span.edit-in-place-error-span").length > 0) {
                                    divElement.removeClass("error");
                                    inputElement.removeClass('has-error');
                                    divElement.find("span.edit-in-place-error-span").remove();
                                }
                            }
                            else {
                                //$scope.editInPlace(); // comment this out to keep focus from other controls
                                inputElement.addClass('ng-dirty ng-invalid error form-error');
                                if (divElement.find("span.edit-in-place-error-span").length == 0) {
                                    divElement.addClass("error");
                                    inputElement.addClass('has-error');
                                }
                            }
                        }
                    }
                    
                });
            }
        };
    })
;
