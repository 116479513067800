angular.module('kno2.services')
    .service('Kno2DirectoryService',
    ['$http',
	function ($http) {
	    var getDirectoryEntries = function () {
	        var promise = $http.get('/api/kno2directory')
                .then(function (result) {
                    return result.data;
                });
	        return promise;
	    };

	    var createEntry = function (entry) {
	        var promise = $http.post('/api/kno2directory', entry)
                .then(function (result) {
                    return result.data;
                });
	        return promise;
	    };

	    var updateEntry = function (entry) {
	        var promise = $http.put('/api/kno2directory', entry)
                .then(function (result) {
                    return result.data;
                });
	        return promise;
	    }

	    var getEntry = function (tenantId, documentId) {
	        var promise = $http.get('/api/kno2directory/' + tenantId + '/' + documentId)
                .then(function (result) {
                    return result.data;
                });
	        return promise;
	    }

	    var getDirectoryEntryTypes = function () {
	        var promise = $http.get('/api/kno2directory/entrytypes')
                .then(function (result) {
                    return result.data;
                });
	        return promise;
	    };

	    var deleteEntry = function (entry) {
	        var promise = $http.delete('/api/kno2directory/' + entry.tenantId + '/' + entry.id)
                .then(function (result) {
                    return result.data;
                });
	        return promise;
	    }

	    var search = function (searchText) {
	        var promise = $http.get('/api/kno2directory/search/' + searchText)
                .then(function (result) {
                    return result.data;
                });
	        return promise;
	    }

	    return {
	        getDirectoryEntries: getDirectoryEntries,
	        getDirectoryEntryTypes: getDirectoryEntryTypes,
	        createEntry: createEntry,
	        getEntry: getEntry,
	        deleteEntry: deleteEntry,
	        updateEntry: updateEntry,
	        search: search
	    };
	}]);