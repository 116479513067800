import templateUrl from './ipaddresses.upsert.modal.component.html';

class IpAddressesUpsertModalController {

    constructor(NotificationModalService, ipAddressesService, ) {
        this.notificationModalService = NotificationModalService;
        this.ipAddressesService = ipAddressesService;
    }

    $onInit() {
        this.ipAddress = { ...this.resolve.ipAddress};
        this.oldAddress = { ...this.ipAddress };
        this.userIpAddress = this.resolve.userIpAddress;
        
        this.changeConfirmed = false;
        this.hasUserIpAddress = this.resolve.hasUserIpAddress;

        this.resultText = this.ipAddress.id ? 'updated' : 'added';
        this.submitButtonText = this.ipAddress.id ? 'Update' : 'Save'
        this.ipAddressRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    }

    saveAddress() {
        const ipChanged = this.oldAddress.address === this.userIpAddress && this.oldAddress.address !== this.ipAddress.address;
        if (!ipChanged || this.changeConfirmed)
            return this.completeSaveAddress();

        this.notificationModalService.warning("You are changing the IP Address you logged in with. Click 'Confirm' to proceed or 'Cancel' to revert changes.");
        this.changeConfirmed = true;
        this.submitButtonText = 'Confirm';
    }

    completeSaveAddress() {
        return this.ipAddressesService.upsertIpAddress(this.ipAddress)
            .then(res => this.close({ $value: this.ipAddress}))
            .catch(err => this.notificationModalService.error(err.data.message));
    }

    useMyAddress() {
        this.ipAddress.address = this.userIpAddress;
    }
}

IpAddressesUpsertModalController.$inject = ['NotificationModalService', 'ipAddressesService'];
export const ipAddressesUpsertModalComponent = {
    templateUrl: templateUrl,
    controller: IpAddressesUpsertModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
}
