IdentityVerificationModal.$inject = ['$scope', '$uibModalInstance', 'SessionService', 'Auth0Service'];
export function IdentityVerificationModal($scope, $uibModalInstance, sessionService, Auth0Service) {
    $scope.title = "Identity Verification";
    $scope.userProfile = sessionService.getProfile();
    $scope.subdomain = JSON.parse(atob(Auth0Service.getAccessToken().split('.')[1]))['http://kno2/subdomain'];
    $scope.org = _.find($scope.userProfile.organizations, function (org) {
        return org.id == $scope.userProfile.organizationId;
    });

    $scope.setForm = function (form) {
        $scope.form = form;
    };

    $scope.submitNext = function () {
        $uibModalInstance.close();
    };
}
