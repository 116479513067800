import uiRouter from '@uirouter/angularjs';
import angulartics from 'angulartics';
import angularticsGoogle from 'angulartics-google-tag-manager';

import { TemplatesModule } from '../common/templates.module';
import { datePickerConfig } from './datepicker.config';
import { httpConfig } from './http.config';
import { idleConfig, idleRun } from './idle.config';
import { intercomConfig } from './intercom.config';
import { routeAuthConfig } from './route.auth.config';
import { routeConfig, routeRun } from './route.config';
import { routeModalConfig } from './route.modal.config';
import { routeSubscriptionConfig } from './route.subscription.config';
import { timeConfig } from './z.time.config';
import { tooltips } from './tooltips.constants';
import { planUpgradePrompts } from './plan-upgrade-prompts.constants';
import { knowledgeBaseLinks } from './knowledge-base.constants';
import { organizationSettings } from './organization-settings.constants';
import { Auth0Module } from '../auth0/auth0.module';
import { IntercomService } from '../common/services/intercom.service';

export const CoreModule = angular
    .module('kno2.core', [
        Auth0Module,
        'ngIdle',
        'ngFileUpload',
        'jcs.angular-http-batch',
        'ui.bootstrap',
        uiRouter,
        angulartics,
        angularticsGoogle,
        TemplatesModule,
        'kno2.factories',
        'kno2.services'
    ])
    .service('IntercomService', IntercomService)
    .config(['$compileProvider', '$sceDelegateProvider', ($compileProvider, $sceDelegateProvider) => {
        $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|mailto|javascript|data):/);
        $sceDelegateProvider.resourceUrlWhitelist([
            'self',
            `${ENVIRONMENT.baseUrl}/**`
        ]);
    }])
    //default client route & route authorization
    .run([
        '$rootScope', '$location', '$uibModalStack', '$transitions', '$window', '$state','$injector', 'ExportService', 'CacheService', 'Auth0Service', 'AccountProfileService', 'LocalStorageFactory',
        ($rootScope, $location, $uibModalStack, $transitions, $window, $state, $injector, ExportService, CacheService, Auth0Service, AccountProfileService, LocalStorageFactory) => {
            // Added Window Language Data to the RootScope
            if ($injector.has('language'))
                $rootScope.language = $injector.get('language');

            $transitions.onStart({}, () => {
                // Force left menu refresh
                $window.setActiveMenuItem();

                $uibModalStack.dismissAll('$transitions.onStart');
            });

            $rootScope.$on('notificationAlertVisible', () => {
                $rootScope.notificationAlert = true;
            });

            $rootScope.$on('notificationAlertHidden', () => {
                $rootScope.notificationAlert = false;
            });

            $rootScope.environment = {brand: ENVIRONMENT.brand};

            // Register Interoperability Service (Web Worker)
            ExportService.register();

            // Cache Service
            CacheService.register(CacheService.cacheNameL1);
            CacheService.register(CacheService.cacheNameL2);
            CacheService.clearAll();

            // Centralized logoff
            $rootScope.logoff = () => {
                angular.element($window).off('beforeunload'); // Clear any beforeunload handlers
                CacheService.clearAll();  // Clear cache before logoff.
                LocalStorageFactory.remove('ProfileData');

                Auth0Service.logout();
            };

        }
    ])
    .config(routeConfig)
    .run(routeRun)
    .config(datePickerConfig)
    .config(httpConfig)
    .config(idleConfig)
    .run(idleRun)
    .run(intercomConfig)
    .run(routeAuthConfig)
    .run(routeModalConfig)
    .run(routeSubscriptionConfig)
    .run(timeConfig)
    .constant("tooltips", tooltips)
    .constant("planUpgradePrompts", planUpgradePrompts)
    .constant("knowledgeBaseLinks", knowledgeBaseLinks)
    .constant("organizationSettings", organizationSettings)
    .name;
