(function () {
    'use strict';

    angular
        .module('kno2.services')
        .factory('DocumentSourceFaxService', DocumentSourceFaxService)
        .factory('FaxPortService', FaxPortService);

    DocumentSourceFaxService.$inject = ['$http'];
    FaxPortService.$inject = ['$http'];

    function FaxPortService($http) {
        var uri = '/api/fax/port';

        var get = function() {
            return $http.get(uri).then(function (res) {
                return res.data;
            });
        };

        var post = function (data) {
            return $http.post(uri, data).then(function (res) {
                return res.data;
            });
        };

        var getStatus = function (id) {
            var url = '/api/fax/port/' + id + '/status/';
            return $http.get(url);
        };

        var finish = function (id) {
            var url = '/api/fax/port/' + id + '/finish/';
            return $http.post(url, id).then(function (res) {
                return res.data;
            });
        };

        var getStatuses = function() {
            return $http.get('/api/fax/port/statuses');
        };

        return {
            get: get,
            post: post,
            getStatus: getStatus,
            statuses: getStatuses,
            finish: finish
        }
    }

    function DocumentSourceFaxService($http) {

        var service = {
            getFaxNumbers: getFaxNumbers,
            //getFaxNumber: getFaxNumber,
            addFaxNumber: addFaxNumber,
            updateFaxNumber: updateFaxNumber,
            removeFaxNumber: removeFaxNumber,
            sendCode: sendCode,
            verify: verify,
            setPrimaryFaxNumber: setPrimaryFaxNumber
        };

        return service;

        function getFaxNumbers(documentSourceId) {

            var uri = '/api/documentsources/{documentSourceId}/faxnumbers'
                .replace('{documentSourceId}', documentSourceId);

            return $http.get(uri).then(function (res) {
                return res.data;
            });
        }

        //function getFaxNumber(documentSourceId, faxNumberId) {

        //    var uri = '/api/documentsources/{documentSourceId}/faxnumbers/{faxNumberId}'
        //        .replace('{documentSourceId}', documentSourceId)
        //        .replace('{faxNumberId}', faxNumberId);

        //    return $http.get(uri).then(function (res) {
        //        return res.data;
        //    });
        //}

        function addFaxNumber(documentSourceId, data) {

            var uri = '/api/documentsources/{documentSourceId}/faxnumbers'
                .replace('{documentSourceId}', documentSourceId);

            return $http.post(uri, data).then(function (res) {
                return res.data;
            });
        }

        function updateFaxNumber(documentSourceId, faxNumberId, data) {
            var uri = '/api/documentsources/{documentSourceId}/faxnumbers/{faxNumberId}'
                .replace('{documentSourceId}', documentSourceId)
                .replace('{faxNumberId}', faxNumberId);

            return $http.put(uri, data).then(function (res) {
                return res.data;
            });
        }

        function removeFaxNumber(documentSourceId, faxNumberId) {

            var uri = '/api/documentsources/{documentSourceId}/faxnumbers/{faxNumberId}'
                .replace('{documentSourceId}', documentSourceId)
                .replace('{faxNumberId}', faxNumberId);

            return $http.delete(uri).then(function (res) {
                return res.data;
            });
        }

        function sendCode(documentSourceId, faxNumberId) {

            var uri = '/api/documentsources/{documentSourceId}/faxnumbers/{faxNumberId}/sendcode'
                .replace('{documentSourceId}', documentSourceId)
                .replace('{faxNumberId}', faxNumberId);

            return $http.post(uri).then(function (res) {
                return res.data;
            });
        }

        function verify(documentSourceId, faxNumberId, code) {

            var uri = '/api/documentsources/{documentSourceId}/faxnumbers/{faxNumberId}/verify'
                .replace('{documentSourceId}', documentSourceId)
                .replace('{faxNumberId}', faxNumberId);

            return $http.post(uri, { code: code }).then(function (res) {
                return res.data;
            });
        }

        function setPrimaryFaxNumber(documentSourceId, faxNumberId, code) {

            var uri = '/api/documentsources/{documentSourceId}/faxnumbers/{faxNumberId}/primary'
                .replace('{documentSourceId}', documentSourceId)
                .replace('{faxNumberId}', faxNumberId);

            return $http.put(uri, { code: code }).then(function (res) {
                return res.data;
            });
        }
    }

})();