angular.module('kno2.settings')
    .controller('CancelSubscriptionModalCtrl', ['$scope', 'SessionService', '$uibModalInstance', 'SubscriptionsFactory', 'NotificationService',
        function ($scope, sessionService, $uibModalInstance, subscriptionsFactory, notificationService) {
            $scope.forms = {
                cancellation: {
                    reason: ""
                }
            };

            $scope.dismissModal = function () {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.cancelSubscription = function () {
                $scope.formSubmitted = true;
                if ($scope.forms.cancellation.$valid) {
                    subscriptionsFactory.cancel($scope.forms.cancellation.reason)
                        .then(function (response) {
                            $uibModalInstance.close(response.data);
                        })
                        .catch(function () {
                            notificationService.error("There was an error cancelling your subscription");
                        });
                }
            };

            (function () {
                $scope.userProfile = sessionService.getProfile();
            }());
        }
    ]);
