angular.module('kno2.services')
    .factory('SessionService', ['$timeout', '$http', '_', 'LocalStorageFactory', 'Idle',
        function ($timeout, $http, _, LocalStorageFactory, Idle) {

            var profileData = {};
            var key = 'ProfileData';

            var getProfile = function () {
                if (_.isEmpty(profileData)) {
                    var str = angular.element("#ProfileData").val()
                        ? JSON.parse(angular.element("#ProfileData").val())
                        : LocalStorageFactory.get(key);
                    var data = str ? str : {};
                    setProfile(data);
                }
                return profileData;
            };

            var getProfileAsync = function() {
                return $http.get('/api/users/profiledata');
            }

            var refreshProfile = function () {
                var promise = $http.get('/api/users/profiledata');
                return promise.then(function (response) {
                    setProfile(response.data);
                    return getProfile();
                }, function (response) {
                    return response;
                });
            };

            var getClientIp = function () {
                return $http.get('/api/users/ipaddress');
            }

            var updateProfile = function (data) {
                angular.extend(profileData, data);
                LocalStorageFactory.set(key, data)
            }

            var setProfile = function (data) {
                if (data && data.sessionTimeoutMinutes) {
                    const timeout = data.sessionTimeoutMinutes || 10;
                    let idleDuration = (timeout * 60) - 60;
                    if (idleDuration <= 1) idleDuration = 5;
                    Idle.setIdle(idleDuration);
                }
                
                updateProfile(data);
            };

            var userInRole = function (roles) {
                if (_.isString(roles))
                    roles = roles.split(',').reduce(function (result, str) {
                        var value = str.trim();
                        if (value) result.push(value);
                        return result;
                    }, []);
                roles = [].concat(roles);
                var matched = _.intersection(getProfile().roles, roles);
                return matched.length > 0;
            };

            var hasUIResourceAction = function (resource, action) {
                var profile = getProfile();
                if (_.has(profile.uiResources || {}, resource)) {
                    return _.includes(profile.uiResources[resource], action);
                }
                return false;
            };

            var getUIResourceActions = function (resource) {
                var profile = getProfile();
                return profile.uiResources[resource];
            };

            var removeProfile = function() {
                LocalStorageFactory.remove(key);
            };

            return {
                getProfile: getProfile,
                getProfileAsync: getProfileAsync,
                setProfile: setProfile,
                refreshProfile: refreshProfile,
                getClientIp: getClientIp,
                userInRole: userInRole,
                hasUIResourceAction: hasUIResourceAction,
                getUIResourceActions: getUIResourceActions,
                removeProfile: removeProfile
            };
        }
    ]);
