angular.module('kno2.directives')
    .directive('popoverMessage', ['$timeout',
        ($timeout) => {
            return {
                template: '<div ng-transclude></div>',
                transclude: true,
                restrict: 'A',
                scope: {
                    popoverTitle: '@',
                    popoverPlacement: '@',
                    popoverId: '@',
                    popoverClass: '@',
                    popoverDelay: '@'
                },
                link: (scope, element, attrs) => {
                    const messageContent = element.find('.message-content');
                    messageContent.hide();

                    const template = `<div class="popover ${scope.popoverClass}">
                                    <div class="arrow"></div>
                                    <h3 class="popover-header">${scope.popoverTitle}</h3>
                                <div class="popover-body"></div></div>`;

                    const delay = (isNaN(scope.popoverDelay)) ? 300 : parseInt(scope.popoverDelay, 10);
                    const timeouts = {};

                    const clearTriggerTimeouts = () => {
                        Object.keys(timeouts).forEach((trigger) => {
                            $timeout.cancel(timeouts[trigger]);
                        });
                    };

                    element.popover({
                        container: 'body',
                        html: true,
                        template: template,
                        title: scope.popoverTitle || '',
                        placement: scope.popoverPlacement || 'bottom',
                        trigger: 'manual',
                        content: function () {
                            return $(this).find('.message-content').html();
                        }
                    }).on('mouseenter', ($event) => {
                        $event.stopPropagation();

                        element.popover('show');
                        $('.popover').on('mouseleave', () => {
                            element.popover('hide');
                        });
                    }).on('mouseleave', ($event) => {
                        $event.stopPropagation();

                        timeouts['mouseleave'] = $timeout(() => {
                            if (!$('.popover:hover').length) {
                                element.popover('hide');
                            }
                        }, delay);
                    }).on('$destroy', () => {
                        const popover = element.data('bs.popover');
                        if (popover && popover.getTipElement()) {
                            popover.getTipElement().remove();
                        }
                        element.popover('dispose');
                        clearTriggerTimeouts();
                    });
                }
            };
        }
    ]);
