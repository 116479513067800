import angular from 'angular';

import { CommonModule } from './common';
import { AccountModule } from './account';
import { IntakeModule } from './intake';
import { InviteModule } from './invite';
import { ReleaseModule } from './release';
import { RecordsModule } from './records';
import { SupportModule } from './support';
import { TriageModule } from './triage';
import { SettingsModule } from './settings';
import { DirectorySearchModalModule } from './directory';
import { NgxModule } from './ng-app/app.module';

// Client Module -- Inject all sub-modules here when adding new modules
angular
    .module('kno2.client', [
        CommonModule,
        AccountModule,
        IntakeModule,
        TriageModule,
        ReleaseModule,
        RecordsModule,
        SettingsModule,
        SupportModule,
        InviteModule,
        DirectorySearchModalModule,
        NgxModule
    ])
    .run(['SupportedFileExtensionsFactory', 'UnsupportedBrowserService',
        async (SupportedFileExtensionsFactory, UnsupportedBrowserService) => {
            UnsupportedBrowserService.checkBrowser();
            await SupportedFileExtensionsFactory.load();
        }]);
