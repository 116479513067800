import templateUrl from './step.component.html';

angular
    .module('kno2.components')
    .component('k2Step', {
        transclude: true,
        controller: StepController,
        templateUrl,
        require: {
            wizard: '^^k2Wizard'
        },
        bindings: {
            active: '<'
        }
    });

StepController.$inject = [];

function StepController() {
    var ctrl = this;

    ctrl.passiveActive = false;
    ctrl.$onInit = $onInit;
    ctrl.$onChanges = $onChanges;

    function $onInit() {
        ctrl.wizard.addStep(ctrl);
    }

    function $onChanges(changes) {
        if (changes.active && !changes.active.isFirstChange()) {
            ctrl.wizard.activeUpdated();
        }
    }
}
