(function (undefined) {
    'use strict';

    angular
        .module('kno2.filters')
        .filter('extension', extensionFilter);

    extensionFilter.$inject = [];

    function extensionFilter() {
        return function (filename) {
            return filename.substr(filename.lastIndexOf('.'));
        };
    }

})();