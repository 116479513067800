import settingsDirectAddressesEditModalTemplate from './settings.direct-addresses.edit-modal.html';
import settingsDirectAddressesDeleteModalTemplate from './settings.direct-addresses.delete-modal.html';

angular.module('kno2.settings')
    .controller('SettingsDirectAddressesCtrl', ['$scope', '$timeout', '$interval', 'PagingFactory', 'ModalFactory', 'DocumentSourcesService', 'SessionService',
        function ($scope, $timeout, $interval, PagingFactory, ModalFactory, DocumentSourcesService, SessionService) {
            var userProfile = SessionService.getProfile();

            $scope.userProfile = userProfile;

            // Common implementation
            $scope.sort = function () { ModalFactory.sort($scope); };
            $scope.gotoPage = function (page) { ModalFactory.gotoPage($scope, page); };
            $scope.editRecord = function (id) {
                var data = { id: id, userProfile: userProfile };
                ModalFactory.editRecord($scope, data);
            };
            $scope.deleteRecord = function (id) {
                var data = { id: id, userProfile: userProfile };
                ModalFactory.deleteRecord($scope, data);
            };
            $scope.updatePagedItemCount = function (pageItemSelected) { ModalFactory.updatePagedItemCount($scope, pageItemSelected); };
            $scope.showPage = function (page, currentPage) {
                return PagingFactory.getPageRange(page, currentPage, $scope.paging.pagedItemRange.value, $scope.paging.totalCount).visible[page - 1];
            };
            $scope.isFirstPageRange = function (page, currentPage) {
                return PagingFactory.getPageRange(page, currentPage, $scope.paging.pagedItemRange.value, $scope.paging.totalCount).isFirst;
            };
            $scope.isLastPageRange = function (page, currentPage) {
                return PagingFactory.getPageRange(page, currentPage, $scope.paging.pagedItemRange.value, $scope.paging.totalCount).isLast;
            };

            function documentSourcesCallback() {
                _.each($scope.documentSources, function (item) {
                    if (!_.isEmpty(item.users)) {
                        item.fullNames = _.map(item.users, 'fullName');

                        var userNames = _.map(item.users, 'userName');
                        item.users = userNames.join(';');
                    }
                });
            }

            var documentSourcesInterval = $interval(function () {
                var search = $scope.search == undefined ? "" : $scope.search.address;
                var sort = ($scope.paging.isSortDesc == undefined || $scope.paging.isSortDesc == false) ? "asc" : "desc";
                DocumentSourcesService.getDocumentSources(sort, $scope.paging.currentPage, $scope.paging.pagedItemRange.value, search).then(function (data) {
                    $scope.documentSources = data.documentSources;
                    $scope.paging.totalCount = data.documentSourcesCount;
                    documentSourcesCallback();
                });
            }, 5000);

            $scope.$on('$destroy', function () {
                $interval.cancel(documentSourcesInterval);
            });

            // Init setup
            ModalFactory.initialize({
                scope: $scope,
                paging: PagingFactory.paging({ sortBy: 'address' }),
                edit: {
                    templateUrl: settingsDirectAddressesEditModalTemplate,
                    controller: 'DirectAddressesEditModalCtrl',
                    controllerAs: '$ctrl'
                },
                remove: {
                    templateUrl: settingsDirectAddressesDeleteModalTemplate,
                    controller: 'DirectAddressesDeleteModalCtrl'
                },
                service: {
                    instance: DocumentSourcesService,
                    method: 'getDocumentSources',
                    response: 'documentSources',
                    callback: documentSourcesCallback
                }
            });
        }]);
