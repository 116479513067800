angular.module('kno2.settings')
    .controller('SettingsFaxEditModalCtrl', ['$timeout', '$filter', '$q', '$uibModalInstance', '$uibModal', '$confirm', 'data', 'DocumentSourcesFaxService', 'NotificationService', 'UsersService', 'CommonData', 'DocumentSourceFaxService', '_', 'SessionService',
        function ($timeout, $filter, $q, $uibModalInstance, $uibModal, $confirm, data, DocumentSourcesFaxService, NotificationService, UsersService, CommonData, DocumentSourceFaxService, _, SessionService) {

            const ctrl = this;
            ctrl.userProfile = data.userProfile;
            ctrl.addAll = addAll;
            ctrl.$onInit = init;
            ctrl.setForm = function (form) {
                ctrl.form = form;
            };

            ctrl.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };

            ctrl.saveChanges = function () {
                ctrl.formSubmitted = true;

                var users = [];
                _.each(ctrl.documentSource.users,
                    function (user) {
                        users.push(user);
                    });
                ctrl.documentSource.users = users;

                // Save changes if form valid
                if (ctrl.form.$valid) {
                    const functionName = ctrl.documentSource.id ? 'putDocumentSource' : 'postDocumentSource';
                    DocumentSourcesFaxService[functionName](ctrl.documentSource)
                        .then(function () {
                            NotificationService.success("The fax was saved successfully.");
                            $uibModalInstance.close(ctrl.documentSource);
                        },
                            function (response) {
                                if(!!response.data.message){
                                    NotificationService.serverError({}, response.data.message);
                                } else {
                                    NotificationService.serverError(response.data.modelState, "There was an error saving the fax.");
                                }
                            });
                } else {
                    _(ctrl.form.$error.required).forEach(function (value) {
                        // Triggers the red exlamation point (Focus input, swich to another input, switch back).
                        $timeout(function () {
                            $("input[name*='" + value.$name + "']").focus().select();
                        }, 250, false);
                        $timeout(function () {
                            $("input[name*='address']").focus().select();
                        }, 250, false);
                        $timeout(function () {
                            $("input[name*='" + value.$name + "']").focus().select();
                        }, 250, false);
                    });
                }
            };

            function addAll() {
                if (!ctrl.documentSource.users)
                    ctrl.documentSource.users = [];
                _.each(ctrl.organizationUsers,
                    function (user) {
                        ctrl.documentSource.users.push(user);
                    });
            }

            function init() {
                ctrl.restrictSendPermissions = SessionService.hasUIResourceAction('directAddressesSendPermissionsInput', 'disable');

                UsersService.getOrgUsers(false)
                    .then(function (response) {
                        ctrl.organizationUsers = response;

                        // Get the Document Sources
                        if (data.id) {
                            DocumentSourcesFaxService.getDocumentSource(data.id)
                                .then(function (response) {
                                    ctrl.documentSource = response;
                                    const users = ctrl.documentSource.users;

                                    ctrl.documentSource.users = [];
                                    if (users) {
                                        _.each(users, function (x) {
                                            const u = _.find(ctrl.organizationUsers, { id: x.id });
                                            if (u) {
                                                ctrl.documentSource.users.push(u);
                                            }
                                        });
                                    }
                                    ;

                                    if (ctrl.restrictSendPermissions) {
                                        ctrl.organizationUsers = [];
                                    }

                                });
                        } else {
                            ctrl.documentSource = {
                                directAddressType: 'fax',
                                type: 'Fax',
                            };
                        }
                        $timeout(function () {
                            $("input[name*='address']").focus();
                        }, 500, false);
                    });
            };
        }
    ]);
