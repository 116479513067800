VerifyPhoneModalCtrl.$inject = ['$uibModalInstance', '$timeout', 'usersFactory', 'NotificationService', 'settings'];
export function VerifyPhoneModalCtrl($uibModalInstance, $timeout, usersFactory, NotificationService, settings) {
    const ctrl = this;
    ctrl.settings = settings;
    ctrl.title = "Verify Phone to Enable Two Factor Authentication";
    ctrl.cancelText = "Cancel";
    if (ctrl.settings && ctrl.settings.verifyOnly) {
        ctrl.title = "Verify Phone Number";
        ctrl.cancelText = "Back";
    }
    ctrl.modal = {};

    ctrl.verifyPhone = function () {
        if (ctrl.settings && ctrl.settings.verifyOnly) {
            var verifyResource = {
                code: ctrl.modal.phoneCode,
                phoneNumber: ctrl.settings.user.phoneNumber
            };
            usersFactory.verifyCode(ctrl.settings.user.id, verifyResource).then(function (response) {
                if (response.data) {
                    NotificationService.success("Phone Number verified.");
                    $uibModalInstance.close();
                } else {
                    NotificationService.error("Unable to verify phone number with that verification code.");
                }
            });
        } else {
            usersFactory.enableTFA(ctrl.modal.phoneCode).then(function (res) {
                if (res.status === 200) {
                    NotificationService.success("Two Factor Authentication has been enabled.");
                    $uibModalInstance.close();
                } else {
                    NotificationService.serverError(res.data.modelState,
                        "Unabled to verify phone with that verification code.");
                }
            });
        }
    };

    ctrl.sendCode = function (method) {
        usersFactory.sendCode(method).then(function (res) {
            if (res.status === 200) {
                NotificationService.success("Your code has been sent.");
            } else {
                NotificationService.serverError(res.data.modelState,
                    "There was an error sending your code.");
            }
        });
    };

    ctrl.closeModal = function () {
        $uibModalInstance.dismiss('cancel');
    };
}
