import releaseNewDraftTemplate from './message/release.new-draft.html';
import releaseMessageViewTemplate from './message-view/release.message-view.html';
import releaseForwardTemplate from './message/release.forward.html';
import releaseReplyTemplate from './message/release.reply.html';

angular.module('kno2.release')
    .config([
        '$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('release', {
                    url: '/release',
                    parent: 'home',
                    component: 'k2Release',
                    data: { resource: 'releaseRoute' },
                    params: {
                        isLeftNav: false
                    },
                    resolve: {
                        isFirstRequestForView: ['$transition$', function ($transition$) {
                            return $transition$.from().name.indexOf('release') !== 0;
                        }]
                    }
                })
                .state('releaseDelivered', {
                    url: '/release/delivered', 
                    parent: 'home',
                    redirectTo: '/'
                })
                .state('releaseNew', {
                    url: '/release/new',
                    parent: 'home',
                    templateUrl: releaseNewDraftTemplate,
                    controller: 'ReleaseNewDraftCtrl',
                    data: { roles: ['Administrator', 'User'] },
                    reloadOnSearch: false,
                    resolve: {
                        load: ['$q', '$location', 'SessionService',
                            function ($q, $location, sessionService) {
                                var defer = $q.defer();
                                var profile = sessionService.getProfile();

                                if (!profile.orgIsActivated) {
                                    $location.path('/release');
                                } else {
                                    defer.resolve();
                                }

                                return defer.promise;
                            }
                        ]
                    }
                })
                .state('releaseDraft', {
                    url: '/release/draft/:id',
                    parent: 'home',
                    templateUrl: releaseNewDraftTemplate,
                    controller: 'ReleaseNewDraftCtrl',
                    data: { roles: ['Administrator', 'User'] },
                    reloadOnSearch: false
                })
                .state('releaseView', {
                    url: '/release/:id',
                    parent: 'home',
                    templateUrl: releaseMessageViewTemplate,
                    controller: 'ReleaseMessageViewCtrl',
                    data: { roles: ['Administrator', 'User'] },
                    reloadOnSearch: false,
                    resolve: {
                        message: ['$transition$', 'MessageService', function ($transition$, MessageService) {
                            return MessageService.getMessage($transition$.params().id).then(function (data) { return data; });
                        }]
                    }
                })
                .state('releaseForward', {
                    url: '/release/forward/:id',
                    parent: 'home',
                    templateUrl: releaseForwardTemplate,
                    controller: 'ReleaseNewDraftCtrl',
                    data: { roles: ['Administrator', 'User'] },
                    reloadOnSearch: false
                })
                .state('releaseForwardFraft', {
                    url: '/release/forward/draft/:id',
                    parent: 'home',
                    templateUrl: releaseForwardTemplate,
                    controller: 'ReleaseNewDraftCtrl',
                    data: { roles: ['Administrator', 'User'] },
                    reloadOnSearch: false
                })
                .state('releaseReplyDraft', {
                    url: '/release/reply/draft/:id',
                    parent: 'home',
                    templateUrl: releaseReplyTemplate,
                    controller: 'ReleaseNewDraftCtrl',
                    data: { roles: ['Administrator', 'User'] },
                    reloadOnSearch: false
                })
                .state('releaseReply', {
                    url: '/release/reply/:id',
                    parent: 'home',
                    templateUrl: releaseReplyTemplate,
                    controller: 'ReleaseNewDraftCtrl',
                    data: { roles: ['Administrator', 'User'] },
                    reloadOnSearch: false
                });
        }
    ]);
