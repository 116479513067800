export function documentDateFormatFilter() {
    return function (text) {
        var dateMoment = moment.utc(text).local();
        if (dateMoment.isValid()) {
            if (dateMoment.isSame(moment(), 'day')) {
                return dateMoment.format('h:mm A');
            } else {
                return dateMoment.format('MMM DD');
            }
        }
        return undefined;
    };
}
