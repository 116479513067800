(function (angular) {
    'use strict';

    angular
        .module('kno2.filters')
        .filter('capitalize', capitalizeFilter);

    capitalizeFilter.$inject = [];

    function capitalizeFilter() {
        return function (value) {
            if (!value || !angular.isString(value)) return value;
            return value.split(' ').map(function (s) { return s.charAt(0).toUpperCase() + s.slice(1); }).join(' ');
        };
    }

})(window.angular);