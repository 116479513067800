UserOrganizationCtrl.$inject = ['$scope', '$rootScope', '$location', '$window', 'SessionService', 'StateRoutingService', '_', '$http', '$timeout'];

export function UserOrganizationCtrl($scope, $rootScope, $location, $window, sessionService, StateRoutingService, _, $http, $timeout) {

    var profile = sessionService.getProfile();
    $scope.profile = profile;
    $scope.orgName = profile.organizationName;

    $scope.changeOrganization = async function (orgId) {
        let response = await $http.post('/api/account/change', { orgId: orgId })        
        if (!response.data) {
            sessionService.removeProfile();
            $rootScope.logoff();
            return;
        }

        await sessionService.refreshProfile();

        let newProfile = await sessionService.getProfileAsync();
        sessionService.setProfile(newProfile.data);
        $scope.profile = newProfile.data;
        $scope.orgName = newProfile.data.organizationName;

        $scope.$apply();

        const targetState = StateRoutingService.getState();
        StateRoutingService.goTo(targetState, true);
    };

    $scope.orgToggle = function () {
        $timeout(() => angular.element('#orgToggleSearch').trigger('focus'), 0);
    };

    const init = function (organizations) {
        if (organizations) {
            $scope.organizations = _.sortBy(organizations, [function (o) { return o.name.toLowerCase()}]);
            return;
        }
        $scope.organizations = [];
    };

    init(profile.organizations);
}
