angular.module('kno2.directives')
    .directive('validStreetAddress', ['ValidateFactory', function (ValidateFactory) {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ctrl) {
                element.on('keyup', function (evt) {
                    scope.$apply(function () {
                        var val = element.val();
                        var message = stringFormat("Street address cannot be a P.O. Box");
                        var reg = /\b^P(ost|ostal)?([ \.]+(O|0)(ffice)?)?([ \.]*Box)?\b/i;
                        var poBoxCheck = reg.exec(val);
                        var valid = (poBoxCheck === null);
                        ValidateFactory.setValidity(element, attrs, ctrl, "addresscontainspobox", valid, message);
                    });
                });
            }
        };
    }])
;