DownloadStatusService.$inject = ['$interval', 'MessageService', 'NotificationService'];

export function DownloadStatusService($interval, MessageService, NotificationService) {

    var cb, pollerPromise, _isWaiting = false;

    var service = {
        startPoller: startPoller,
        stopPoller: stopPoller,
        displayErrorIfPresent: displayErrorIfPresent,
        downloadStatusPoller: downloadStatusPoller,
        isWaiting: isWaiting,
        setIsWaiting: setIsWaiting,
        getMessageDownloadState: getMessageDownloadState,
        messageDownloadIsPending: messageDownloadIsPending,
        messageDownloadIsDownloaded: messageDownloadIsDownloaded,
        messageDownloadIsAvailable: messageDownloadIsAvailable,
        messageDownloadIsNotAvailable: messageDownloadIsNotAvailable,
        messageDownloadIsSuspended: messageDownloadIsSuspended
    };

    return service;

    function startPoller(messageId, callback) {
        stopPoller();
        cb = callback;
        return pollerPromise = $interval(service.downloadStatusPoller, 5000, null, true, messageId);
    }

    function stopPoller() {
        if (pollerPromise) $interval.cancel(pollerPromise);
    }

    function displayErrorIfPresent(message) {
        if (message.integrationLogs.length > 0 && message.status === 'Suspended') {
            var error = message.integrationLogs[message.integrationLogs.length - 1];
            var errorMessage = error.message + ' Reference ID: ' + error.referenceId;
            NotificationService.error(errorMessage);
        }
    }

    function downloadStatusPoller(messageId) {
        return MessageService.getMessage(messageId)
            .then(function (message) {
                try {
                    if (cb && angular.isFunction(cb)) cb(message);

                    var isPending = messageDownloadIsPending(message);
                    if (isPending) return;

                    var isDownloaded = messageDownloadIsDownloaded(message);
                    if (isDownloaded) {
                        service.stopPoller();
                        NotificationService.success('Message has been downloaded.');
                        return;
                    }

                    var isSuspended = messageDownloadIsSuspended(message);
                    if (isSuspended) {
                        displayErrorIfPresent(message);
                        service.stopPoller();
                        return;
                    }
                }
                catch (err) {
                    NotificationService.errorToaster(`An error occurred: ${err}`);
                    service.stopPoller();
                }
            })
    }

    function isWaiting() {
        return _isWaiting;
    }

    function setIsWaiting(isWaiting) {
        _isWaiting = isWaiting;
    }

    // Takes in a passed message and returns the message
    // download state object or an individual property.
    // This is exists because we want to get state for the
    // scoped message as well as messages against the server
    function getMessageDownloadState(message, property) {
        if (message == null) return null;

        var stateObject;

        if (_isWaiting) {
            stateObject = { name: 'waiting', button: 'btn-info', message: 'Message processing', disabled: true };

            if (property) {
                return stateObject[property];
            }

            return stateObject;
        }

        var processedTypes = message.processedType.split(/\s*;\s*/);
        var downloadPending = (message.status.toLowerCase() === 'processed' || message.status.toLowerCase() === 'pending')
            && _.some(processedTypes, function (processType) { return processType.toLowerCase() === 'awaitingemrexport'; });
        var downloaded = (message.status.toLowerCase() === 'processed')
            && _.some(processedTypes, function (processType) { return processType.toLowerCase() === 'emrexported'; });


        if (downloadPending && !downloaded) {
            stateObject = { name: 'downloadpending', button: 'btn-alt-warning', message: 'Message awaiting download', disabled: true };

            if (property) {
                return stateObject[property];
            }

            return stateObject;
        }

        if (!downloadPending && downloaded) {
            stateObject = { name: 'downloaded', button: 'btn-alt-success', message: 'Message has been downloaded', disabled: true };

            if (property) {
                return stateObject[property];
            }

            return stateObject;
        }

        var availableStatuses = [
            'processed',
            'received',
            'forwarded',
            'replied',
            'uploaded',
            'triaged'
        ],
            isAvailableStatus = _.includes(availableStatuses, message.status.toLowerCase());

        if (isAvailableStatus) {
            stateObject = { name: 'available', button: 'btn-info', message: 'Download to EMR', disabled: false };

            if (property) {
                return stateObject[property];
            }

            return stateObject;
        }

        if (message.status.toLowerCase() === 'suspended') {
            stateObject = { name: 'suspended', button: 'btn-danger', message: 'Message has problems, correct and remark for download', disabled: false };

            if (property) {
                return stateObject[property];
            }

            return stateObject;
        }

        stateObject = { name: 'notavailable', button: 'btn-alt-default', message: 'Not available for messages with this status', disabled: true };

        if (property) {
            return stateObject[property];
        }

        return stateObject;
    }

    function messageDownloadIsPending(message) {
        return getMessageDownloadState(message, 'name') === 'downloadpending';
    }

    function messageDownloadIsDownloaded(message) {
        return getMessageDownloadState(message, 'name') === 'downloaded';
    }

    function messageDownloadIsAvailable(message) {
        return getMessageDownloadState(message, 'name') === 'available';
    }

    function messageDownloadIsNotAvailable(message) {
        return getMessageDownloadState(message, 'name') === 'notavailable';
    }

    function messageDownloadIsSuspended(message) {
        return getMessageDownloadState(message, 'name') === 'suspended';
    }
}
