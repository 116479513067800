(function () {
    'use strict';

    angular.module('kno2.services')
        .service('$confirm', $confirm)
        .controller('ConfirmModalCtrl', ConfirmModalCtrl)
        .run(run);

    $confirm.$inject = ['$uibModal'];
    function $confirm($uibModal) {

        return {
            open: open
        };

        function open(options) {
            return $uibModal.open({
                templateUrl: 'confirm-modal',
                controller: 'ConfirmModalCtrl',
                windowClass: 'confirm-modal',
                resolve: {
                    confirmOptions: function () {
                        return options;
                    }
                }
            });
        }
    }

    ConfirmModalCtrl.$inject = ['$scope', '$sanitize', '$uibModalInstance', 'confirmOptions'];
    function ConfirmModalCtrl($scope, $sanitize, $uibModalInstance, confirmOptions) {

        var options = {
            title: 'Confirm',
            bodyText: 'Are you sure?',
            okText: 'OK',
            cancelText: 'Cancel',
            ok: ok,
            cancel: cancel,
            showCancel: true
        };

        (function activate() {
            angular.extend($scope, options, confirmOptions);
        })();

        function ok() {
            $uibModalInstance.close(true);
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }

    run.$inject = ['$templateCache'];
    function run($templateCache) {
        $templateCache.put('confirm-modal',
            '<form name="form" class="confirm-modal form-horizontal" role="form" novalidate ng-submit="ok()">' +
            '    <div class="modal-header">' +
            '        <button type="button" class="close" aria-hidden="true" ng-click="cancel()">&times;</button>' +
            '        <h2 class="modal-title">{{title}} </h2>' +
            '    </div>' +
            '    <div class="modal-body">' +
            '        <div style="white-space: pre-line;" ng-bind-html="bodyText"></div>' +
            '    </div>' +
            '    <div class="modal-footer">' +
            '        <button type="button" class="btn btn-default" ng-if="showCancel" ng-click="cancel()">{{cancelText}}</button>' +
            '        <button type="submit" class="btn btn-primary" autofocus>{{okText}}</button>' +
            '    </div>' +
            '</form>' +
            '');
    }

})();