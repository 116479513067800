(function () {
    'use strict;';

    angular
        .module('kno2.services')
        .service('DocumentProcessingService', DocumentProcessingService);

    DocumentProcessingService.$inject = ['$q', 'hubProxyFactory'];

    function DocumentProcessingService($q, hubProxyFactory) {

        const hub = hubProxyFactory('documentProcessingHub');
        const promises = {};

        hub.on('completed', function (action, attachmentId) {
            var deferred = getDeferred(action, attachmentId);
            if (deferred) {
                removeDeferred(action, attachmentId);
                stopWaitingFor(action, attachmentId).then(function () {
                    deferred.resolve();
                });
            }
        });

        return {
            waitFor: waitFor,
            stopWaitingFor: stopWaitingFor
        };

        function waitFor(action, attachmentId) {
            var deferred = getDeferred(action, attachmentId);
            return hub.invoke('waitFor', action, attachmentId)
                .then(function () {
                    return deferred.promise;
                });
        }

        function stopWaitingFor(action, attachmentId) {
            return hub.invoke('stopWaitingFor', action, attachmentId);
        }

        function getDeferred(methodName, key) {
            var methodPromises = promises[methodName] = promises[methodName] || {};
            var deferred = methodPromises[key] = methodPromises[key] || $q.defer();
            return deferred;
        }

        function removeDeferred(methodName, key) {
            if (promises[methodName] && promises[methodName][key])
                delete promises[methodName][key];
        }
    }

})();