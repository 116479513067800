(function () {
    'use strict';

    angular
        .module('kno2.services')
        .service('DirectoryService', directoryService);

    directoryService.$inject = ['$http', '$uibModal', 'removeNonNumericFromFax'];

    function directoryService($http, $uibModal, removeNonNumericFromFax) {
        this.validateAddresses = validateAddresses;
        this.searchDirectory = searchDirectory;
        this.faxDirectLookup = faxDirectLookup;

        function faxDirectLookup(faxNumber) {
            var promise = $http.get('/api/directory/lookup?term=' + removeNonNumericFromFax(faxNumber))
                .then(response => {
                    response.data.faxNumber = faxNumber;
                    return response.data;
                });
            return promise;
        };

        function validateAddresses(addresses) {
            return $http.get('/api/directory/validate', {
                params: {
                    addresses: addresses
                }
            }).then(response => response.data);
        }

        function searchDirectory(allowSelection, currentToAddresses, selectedCallback) {

            var openPromise = $uibModal.open({
                component: 'k2DirectorySearchModal',
                windowClass: 'directory-search-modal',
                resolve: {
                    allowSelection: () => allowSelection
                }
            });

            if (selectedCallback) {
                openPromise.result.then(addresses => {
                    const oldAddresses = _.map(currentToAddresses, 'text');
                    const allAddresses = _.uniq(addresses.concat(oldAddresses));

                    selectedCallback(_.map(allAddresses,
                        a => {
                            return { text: a };
                        }));
                }, () => {
                    // do nothing, modal closed/cancelled
                });
            } else {
                openPromise.result.then(() => {}, () => {});
            }
        }
    }
})();
