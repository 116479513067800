import reactivateOrganizationModalTemplate from './organization.reactivate-organization-modal.html';
import cancelledOrganizationModalTemplate from './organization.cancelled-org-modal.html';
import gracePeriodModalTemplate from './organization.grace-period-org-modal.html';

CancelledOrgCtrl.$inject = ['$scope', '$rootScope', '$uibModal', '$timeout', 'SessionService', 'NotificationService', 'SubscriptionsFactory'];
export function CancelledOrgCtrl($scope, $rootScope, $uibModal, $timeout, sessionService, notificationService, subscriptionsFactory) {
    $scope.profile = sessionService.getProfile();
    $scope.currentPlan = null;

    $scope.openReactivationModal = function () {
        var reactivationModal = $uibModal.open({
            templateUrl: reactivateOrganizationModalTemplate,
            controller: 'ReactivateOrgModalCtrl',
            keyboard: false,
            backdrop: 'static',
            resolve: { currentPlan: function () { return $scope.currentPlan; } }
        });
        reactivationModal.result.then(function () {
            notificationService.success("Organization successfully reactivated.");
            sessionService.refreshProfile();
        });
    };

    $scope.openCancelledOrgModal = function () {
        var cancelledOrgModal = $uibModal.open({
            templateUrl: cancelledOrganizationModalTemplate,
            controller: 'CancelledOrgModalCtrl',
            keyboard: false,
            backdrop: 'static'
        });
        cancelledOrgModal.result.then(function () {
            $rootScope.logoff();
        });
    };

    $scope.openGracePeriodOrgModal = function () {
        var gracePeriodOrgModal = $uibModal.open({
            templateUrl: gracePeriodModalTemplate,
            controller: 'OrgGracePeriodModalCtrl',
            keyboard: false,
            backdrop: 'static'
        });
    };

    (function () {
        if ($scope.profile.orgIsCancelled) {
            if ($scope.profile.isAdmin) {
                if ($scope.profile.subscriptionReference != null) {
                    subscriptionsFactory.fastspring($scope.profile.organizationId)
                        .then(function (response) {
                            $scope.currentPlan = response.data;
                            $timeout($scope.openReactivationModal, 500, false);
                        });
                } else {
                    $timeout($scope.openReactivationModal, 1000, false);
                }
            } else {
                $timeout($scope.openCancelledOrgModal, 1000, false);
            }
        } else if (!$scope.profile.orgIsCancelled && $scope.profile.orgIsInGracePeriod) {
            $timeout($scope.openGracePeriodOrgModal, 1000, false);
        }
    })();
}
