angular.module('kno2.services')
    .service('SyncService', [
        '$interval', '$rootScope', '_', function($interval, $rootScope, _) {

            // Set of objects comprised of a funcion, lastrun date in seconds, and interval 
            // Each function is checked inside of an internal timeout loop.  If the 
            // current time is greater than the functions lastrun + interval, then
            // we execute the function { f: func, last: 10232232232, i: 30 }
            var funcs = [];
            var intervalTime = 1000;
            var broadCastPromise;
            var broadcastSyncEvent = "syncservice:broadcast:sync";
            var enableLog = false;

            setInterval(function() {
                var current = new Date().getTime();
                _.each(funcs, function(f, index) {

                    // If func is null, remove it from funcs array
                    // dev can reregister if needed
                    if (!f.f) funcs.splice(index, 1);

                    // Current Time is greater than the next time we 
                    // should run the function.  Run the func and update
                    // the last run time
                    if (current >= f.nextRun()) {
                        f.f();
                        f.last = current;
                    }
                });
            }, intervalTime);

            // Publish a message to all the child scopes of rootScope
            // that the chile scope can subscribe to and do work on a regular
            // interval. broadcast.on('syncservice:sync', ...). Make sure to unsubscribe from
            // the event in scope.on('destroy') to avoid memory leaks. 
            // $scope.$off('syncservice:sync', ...);
            var broadcast = function(time) {
                var t = time;
                if (!t) t = intervalTime * 30;
                if (broadCastPromise) broadCastPromise.cancel();

                broadCastPromise = $interval(function() {
                    $rootScope.$broadcast(broadcastSyncEvent);
                    log("SyncService Broadcast Interval " + t);
                }, t);
            };

            // Setup Default Broadcast to use intervalTime;
            broadcast();

            var log = function(m) {
                if (enableLog) console.info(m);
            };

            return {

                // Ex: SyncService.register(function () { console.info("Func1"); }, 1);
                // Ex: SyncService.register(function () { console.info("Func10"); }, 10);
                register: function(func, time) {

                    var milliSeconds = time * 1000;

                    if (func) {

                        var o = {
                            f: func,
                            last: new Date().getTime(),
                            i: milliSeconds,
                            nextRun: function () { return this.last + this.i; }
                        };

                        funcs.unshift(o);

                        // Execute function once before register for 
                        // interval
                        func();
                    }
                },

                // Helper Function to register a scopes function for a broadcast
                // and does a cleanup of the event binding on destroy of the scope
                registerForBroadcast: function (func, scope) {

                    // Scope On returns a function that can be called
                    // to clean up the event listener (avoid memory leaks)
                    var f = scope.$on(broadcastSyncEvent, func);

                    scope.$on('$destroy', function () {
                        if (f) {
                            log("sync services $scope.$destroy unregister broadcast events");
                            f();
                        } 
                    });
                },
                broadcast: broadcast,
                SYNC_EVENT: broadcastSyncEvent
            };
        }
    ]);
