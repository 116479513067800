import templateUrl from './query.document.destination.modal.component.html';

angular
    .module('kno2.records')
    .component('k2QueryDocumentDestinationModal', {
        controller: QueryDocumentDestinationModalController,
        templateUrl,
        bindings: {
            modalInstance: '<',
            resolve: '<'
        }
    });

QueryDocumentDestinationModalController.$inject = ['DocumentSourceService', 'QueryService', 'NotificationModalService'];

function QueryDocumentDestinationModalController(DocumentSourceService, QueryService, NotificationModalService) {
    var ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.send = send;
    ctrl.cancel = cancel;

    function $onInit() {
        DocumentSourceService.getMyDocumentSources().then(function (documentSources) {
            ctrl.addresses = documentSources.map(function (d) {
                return d.address;
            });
        });
    }

    function send() {
        ctrl.sending = true;

        ctrl.resolve.query.sendTo = ctrl.sendTo;

        QueryService.sendDocumentToIntake(ctrl.resolve.query)
            .then(function () {
                NotificationModalService.success('Document sent to intake.');
            })
            .catch(function () {
                NotificationModalService.error('There was an error sending this document to your intake. Please try again.');
            })
            .then(function () {
                ctrl.sending = false;
                ctrl.modalInstance.close();
            });
    }

    function cancel() {
        ctrl.modalInstance.dismiss();
    }
}
