fullHeightDirective.$inject = ['$window', '$timeout'];

export function fullHeightDirective($window, $timeout) {
    var directive = {
        link: link,
        restrict: 'EA'
    };
    return directive;

    function link(scope, element) {

        angular.element($window).on('resize', function () {
            updateHeight(element);
        });

        updateHeight(element);
    }

    function updateHeight(element) {
        $timeout(function () {
            element.parents().each(function (i, e) {
                var padding,
                    $e = $(e),
                    min = parseInt($e.css('min-height'));

                if (!isNaN(min) && min && min > element.height()) {
                    padding = $e.innerHeight() - $e.height();
                    element.height(min - padding - 10);
                    return false;
                }
            });
        });
    }
}
