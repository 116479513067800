import updatePhoneNumberModalTemplate from './profile/account.profile.update-phone-number-modal.html';
import verifyPhoneNumberModalTemplate from './profile/account.profile.verify-phone-modal.html';


PhoneNumberCtrl.$inject = ['$uibModal', '$timeout', 'SessionService'];
export function PhoneNumberCtrl($uibModal, $timeout, SessionService) {
    const ctrl = this;

    ctrl.$onInit = onInit;
    ctrl.userProfile = SessionService.getProfile();
    ctrl.phoneNumber = ctrl.userProfile.phoneNumber;

    var openPhoneUpdateModal = function () {
        var phoneModal = $uibModal.open({
            templateUrl: updatePhoneNumberModalTemplate,
            controller: 'PhoneNumberModalCtrl',
            controllerAs: '$ctrl',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                profile: function () {
                    return ctrl.userProfile;
                }
            }
        });
        phoneModal.result.then(function (response) {
            var verifyModalInstance = $uibModal.open({
                templateUrl: verifyPhoneNumberModalTemplate,
                controller: 'VerifyPhoneModalCtrl',
                controllerAs: '$ctrl',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    settings: function () {
                        return {
                            verifyOnly: true,
                            user: response
                        }
                    }
                }
            });
            verifyModalInstance.result.then(function () {
                SessionService.refreshProfile();
            }, function () {
                openPhoneUpdateModal();
            });
        }, function(error){
        });
    };

    function onInit() {
        if ((!ctrl.phoneNumber || !ctrl.userProfile.phoneNumberConfirmed) && !ctrl.userProfile.tokenAuthEnabled) {
            $timeout(openPhoneUpdateModal, 1000, false);
        }
    }
}