angular.module('kno2.services')
    .service('ExternalLoginService', ExternalLoginService);

ExternalLoginService.$inject = ['IntegrationsService', 'CodeGrantsService', 'NotificationService', '$interval', '$confirm'];
function ExternalLoginService(IntegrationsService, CodeGrantsService, NotificationService, $interval, $confirm) {
    let externalLoginWindow;

    return {
        externalLogin
    };

    function externalLogin(integration, func) {
        IntegrationsService.externalLogin().then(data => {
            if (data.mustLogin) {
                $confirm.open({
                    title: `${integration.name} Login`, 
                    bodyText: `You must sign in to ${integration.name} to proceed. This will open a new window and ask you to sign in using your ${integration.name} credentials.\n\nDo you want to continue?`
                }).result.then(ok => {
                    if (ok) {
                        externalLoginWindow = window.open(data.url);
                        checkExternalLoginPageClosed(data.state, integration, func);
                        externalLoginWindow.focus();
                    }
                });
            } else {
                func();
            }
        });
    }

    function checkExternalLoginPageClosed(state, integration, func) {
        const interval = $interval(() => {
            if (externalLoginWindow && externalLoginWindow.closed) {
                $interval.cancel(interval);
                externalLoginWindow = null;
                checkState(state, integration, func);
            }
        }, 1000, 120);
    }

    function checkState(state, integration, func) {
        IntegrationsService.checkState(state).then((res) => {
            if (res.metaData && res.metaData.orgUuid)
                integration.values.OrgUuid = res.metaData.orgUuid;
            func();
        }).catch((err) => {
            NotificationService.error(
                'Error logging in to the External site. This can happen if the external login website was closed too quickly. Please wait for the window to close automatically or you are shown it is now safe to close. If the problem persists, please contact support.'
            );
        });
    }
}
