import { fromEvent } from 'rxjs';
import { messageOfType } from '../lib/observables';

export const viewerComponent = {
    template: '<iframe class="doc-viewer" src="about:blank" tabindex="-1"></iframe>',
    controller: ViewerController,
    bindings: {
        attachment: '<',
        key: '<',
        options: '<',
        disableAudit: '<'
    }
}

ViewerController.$inject = [
    '$element', '$window', '$location', '$sce', '$scope', '_', 'EventService', 'MessageFactory', 'SpinnerService', 'PrintService'
];

function ViewerController($element, $window, $location, $sce, $scope, _, EventService, MessageFactory, SpinnerService, PrintService) {
    const ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.$onChanges = $onChanges;
    ctrl.$onDestroy = $onDestroy;
    ctrl.getSrcUrl = getSrcUrl;

    const setUrl = _.debounce(function (attachment) {
        const url = ctrl.getSrcUrl(attachment);
        const iframe = ctrl.iframe[0];
        if (iframe.contentWindow) iframe.contentWindow.location.replace(url);
    }, 0);

    function $onInit() {
        ctrl.key = ctrl.key || 'previewKey';
        ctrl.base = getBaseUrl();

        ctrl.iframe = $element.children('iframe').first();

        const contentWindow = ctrl.iframe[0].contentWindow;
        const messages$ = fromEvent($window, 'message');

        ctrl.subscriptions = [
            messages$.pipe(messageOfType('loaded.kno2GroupDocs')).subscribe(() => {
                SpinnerService.stop();

                const transforms = getTransforms();
                if (transforms != null && Object.keys(transforms).length)
                    contentWindow.postMessage({
                        type: 'replay.kno2GroupDocs',
                        value: transforms
                    }, contentWindow.location.origin);
            }),

            messages$.pipe(messageOfType('reorderPages.kno2GroupDocs')).subscribe(data => {
                $scope.$apply(function () {
                    const transforms = getTransforms();
                    const moves = transforms.moves = transforms.moves || {};
                    moves[data.from] = data.to;
                });
            }),

            messages$.pipe(messageOfType('rotatePage.kno2GroupDocs')).subscribe(data => {
                $scope.$apply(function () {
                    const transforms = getTransforms();
                    const rotations = transforms.rotations = transforms.rotations || {};
                    rotations[data.page] = ((rotations[data.page] || 0) + data.rotation) % 360;
                });
            }),

            messages$.pipe(messageOfType('removePage.kno2GroupDocs')).subscribe(data => {
                $scope.$apply(function () {
                    const transforms = getTransforms();
                    const deletions = transforms.deletions = transforms.deletions || [];
                    deletions.push(data.page);
                });
            }),

            messages$.pipe(messageOfType('reset.kno2GroupDocs')).subscribe(() => {
                const a = ctrl.attachment;
                if (a) delete a.$$transforms;
                contentWindow.location.reload();
            }),

            messages$.pipe(messageOfType('print.kno2GroupDocs')).subscribe(() => {
                $scope.$apply(function () {
                    PrintService.print(ctrl.attachment.messageId, ctrl.attachment.id);
                });
            })
        ];
    }

    function $onChanges(changes) {
        if (changes.attachment) {
            const a = changes.attachment.currentValue;

            // Audit reads on attachments
            if (!ctrl.disableAudit && a && a.messageId)
                EventService.raiseAttachmentReadEvent(a.messageId, a.id);

            SpinnerService.start();
            setUrl(a);
        }
    }

    function $onDestroy() {
        ctrl.subscriptions.forEach(x => x.unsubscribe && x.unsubscribe());
    }

    function getTransforms() {
        const a = ctrl.attachment;
        if (!a) return null;
        return a.$$transforms = a.$$transforms || (a.transforms && _.clone(a.transforms)) || {};
    }

    function getSrcUrl(attachment) {

        const params = {};

        if (attachment) {
            if (attachment.fileName && MessageFactory.isStructuredDocument(attachment)) {
                params.supportPageRotation = false;
                params.supportPageReordering = false;
                params.supportPageRemoval = false;
            }
            params.request = attachment[ctrl.key] ? attachment[ctrl.key] : attachment;
        }

        const url = buildUrl(ctrl.base, _.extend({}, ctrl.options, params));

        return url;
    }

    function getBaseUrl() {
        // build up the URL with protocol, domain, port if not 80
        var host = $location.$$host.replace(/([^\.]+)*(.+)$/, 'documents$2');
        if ($location.$$host.indexOf('kno2fy-test.com') !== -1)
            host = $location.$$host.replace(/([^\.\-]+)*(.+)$/, 'documents$2');

        var url = $location.$$protocol + '://' + host;
        if ($location.$$port !== 80)
            url += ':' + $location.$$port;

        return url + '/viewer';
    }

    function buildUrl(url, params) {
        if (!params || !Object.keys(params).length) return url;
        return url + ((url.indexOf('?') === -1) ? '?' : '&') + encodeObject(params);
    }

    function encodeObject(params) {
        const parts = _.reduce(params, function (result, value, key) {
            if (value === null || value === undefined) return result;
            if (!_.isArray(value)) value = [value];

            _.each(value, function (v) {
                if (_.isObject(v)) v = angular.toJson(v);
                result.push(encodeUriQuery(key) + '=' + encodeUriQuery(v));
            });

            return result;
        }, []);

        return parts.join('&');
    }

    function encodeUriQuery(val, encodeSpaces) {
        return encodeURIComponent(val)
            .replace(/%40/gi, '@')
            .replace(/%3A/gi, ':')
            .replace(/%24/g, '$')
            .replace(/%2C/gi, ',')
            .replace(/%20/g, (encodeSpaces ? '%20' : '+'));
    }
}
