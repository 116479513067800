angular.module('kno2.records')
    .service('RequestService', [
        '$location', '$http', 'Upload',
        function ($location, $http, Upload) {
            this.sendRequest = function (request, file) {
                var url = '/api/requests';
                if (file)
                    return Upload.upload({
                        url: url,
                        data: {
                            file: file,
                            request: Upload.json(request)
                        }
                    });
                else
                    return $http.post(url, request);
            };

            this.getRequests = function () {


            };
        }
    ]);