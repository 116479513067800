import settingsFaxPortWizardModalTemplate from './settings.fax.port-wizard-modal.html';
import settingsFaxDeleteModalTemplate from './settings.fax.delete-modal.html';
import settingsFaxEditModalTemplate from './settings.fax.edit-modal.html';

angular.module('kno2.settings')
    .controller('SettingsFaxCtrl', [
        '$scope', 'PagingFactory', 'ModalFactory', 'DocumentSourcesFaxService', 'SessionService', '$confirm', 'FeatureService', '$interval',
        function ($scope,  PagingFactory, ModalFactory, DocumentSourcesFaxService, SessionService, $confirm, FeatureService, $interval) {
            $scope.isSysAdmin = SessionService.userInRole('SysAdmin');
            $scope.sort = function () {
                ModalFactory.sort($scope);
            };
            $scope.gotoPage = function (page) {
                ModalFactory.gotoPage($scope, page);
            };
            $scope.editRecord = function (id) {
                if (id === 0) {
                    if (FeatureService.isEnabled('EnableExternalEFaxAccount')) {
                        const data = {
                            id: null
                        };
                        ModalFactory.editRecord($scope, data);
                    } else {
                        $confirm.open({
                                bodyText: `To request a new fax number, please contact ${ENVIRONMENT.brand} support.`,
                                okText: 'Close',
                                title: 'New Fax Number',
                                showCancel: false
                            })
                            .result
                            .then(function () {}, function () {});
                    }
                } else {
                    const data = {
                        id: id
                    };
                    ModalFactory.editRecord($scope, data);
                }
            };
            $scope.addRecordWizard = function () {
                $confirm.open({
                        bodyText: `To port your current fax number(s), please contact ${ENVIRONMENT.brand} support.`,
                        okText: 'Close',
                        title: 'Port Fax Number',
                        showCancel: false
                    })
                    .result
                    .then(function () {}, function () {});
            };
            $scope.deleteRecord = function (id) {
                var data = {
                    id: id
                };
                ModalFactory.deleteRecord($scope, data);
            };
            $scope.updatePagedItemCount = function (pageItemSelected) {
                ModalFactory.updatePagedItemCount($scope, pageItemSelected);
            };
            $scope.showPage = function (page, currentPage) {
                return PagingFactory.getPageRange(page, currentPage, $scope.paging.pagedItemRange.value, $scope.paging.totalCount).visible[page - 1];
            };
            $scope.isFirstPageRange = function (page, currentPage) {
                return PagingFactory.getPageRange(page, currentPage, $scope.paging.pagedItemRange.value, $scope.paging.totalCount).isFirst;
            };
            $scope.isLastPageRange = function (page, currentPage) {
                return PagingFactory.getPageRange(page, currentPage, $scope.paging.pagedItemRange.value, $scope.paging.totalCount).isLast;
            };

            function documentSourcesCallback() {
                _.each($scope.documentSources, function (item) {
                    if (!_.isEmpty(item.users)) {
                        item.fullNames = _.map(item.users, 'fullName');

                        var userNames = _.map(item.users, 'userName');
                        item.users = userNames.join(';');
                    }
                });
            }

            var faxDocumentSourcesInterval = $interval(function () {
                var search = $scope.search == undefined ? "" : $scope.search.address;
                var sort = ($scope.paging.isSortDesc == undefined || $scope.paging.isSortDesc == false) ? "asc" : "desc";
                DocumentSourcesFaxService.getDocumentSources(sort, $scope.paging.currentPage, $scope.paging.pagedItemRange.value, search).then(function (data) {
                    $scope.documentSources = data.documentSources;
                    $scope.paging.totalCount = data.documentSourcesCount;
                    documentSourcesCallback();
                });
            }, 10000);

            $scope.$on('$destroy', function () {
                $interval.cancel(faxDocumentSourcesInterval);
            });


            // Init setup
            ModalFactory.initialize({
                scope: $scope,
                paging: PagingFactory.paging({
                    sortBy: 'address'
                }),
                edit: {
                    templateUrl: settingsFaxEditModalTemplate,
                    controller: 'SettingsFaxEditModalCtrl',
                    controllerAs: '$ctrl'
                },
                add: {
                    templateUrl: settingsFaxPortWizardModalTemplate,
                    controller: 'SettingsFaxPortWizardModalCtrl'
                },
                remove: {
                    templateUrl: settingsFaxDeleteModalTemplate,
                    controller: 'SettingsFaxDeleteModalCtrl'
                },
                service: {
                    instance: DocumentSourcesFaxService,
                    method: 'getDocumentSources',
                    response: 'documentSources',
                    callback: documentSourcesCallback
                }
            });
        }
    ])