AccountProfileService.$inject = ['$http'];
export function AccountProfileService($http) {
    return {
        activate: activate,
        changePassword: changePassword
    };

    function changePassword(model) {
        return $http.put('/api/account/password', model).then(res => res);
    }

    function activate(model) {
        return $http.post('/api/account/activate', model).then(res => res);
    }
}
