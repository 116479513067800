import angular from 'angular';

import { CommonModule } from '../common';
import { triageRoutes } from './triage.routes';
import { autoExtensionDirective } from './auto.extension.directive';
import { dragulaDirective } from './dragula.directive';
import { fullHeightDirective } from './fullheight.directive';
import { triageBatchDirective, triageMoveMenuDirective } from './triage.batch.directive';
import { TriageBatchCtrl } from './triage.batch.ctrl';
import { TriageHttpService } from './triage.http.service';
import { TriageServiceFactory } from './triage.service.factory';
import { TriageCtrl } from './triage.ctrl';
import { TriageCreateEditMessageModalCtrl } from './triage.edit-message-modal.ctrl';

export const TriageModule = angular
    .module('kno2.triage', [CommonModule])
    .config(triageRoutes)
    .directive('autoExtension', autoExtensionDirective)
    .directive('dragula', dragulaDirective)
    .directive('fullHeight', fullHeightDirective)
    .directive('triageBatch', triageBatchDirective)
    .directive('triageMoveMenu', triageMoveMenuDirective)
    .factory('TriageHttpService', TriageHttpService)
    .factory('TriageServiceFactory', TriageServiceFactory)
    .controller('TriageCtrl', TriageCtrl)
    .controller('TriageCreateEditMessageModalCtrl', TriageCreateEditMessageModalCtrl)
    .controller('TriageBatchCtrl', TriageBatchCtrl)
    .name;
