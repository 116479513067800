(function () {
    'use strict';

    angular
        .module('kno2.directives')
        .directive('toggleSensitive', toggleSensitive);

    toggleSensitive.$inject = ['$compile'];

    function toggleSensitive($compile) {
        const directive = {
            restrict: 'A',
            scope: {
                toggleSensitiveTooltip: '@'
            },
            link: link
        };
        return directive;

        function link(scope, element, attrs) {
            if (attrs.type !== 'password' || scope.toggleSensitiveTooltip === 'false') return;
            const hoverTitle = scope.toggleSensitiveTooltip || 'Password';
            const group = $compile(
                `<div class="input-group">
                    <span class="input-group-btn">
                        <button type="button" class="btn btn-default" title="{{showSensitive ? 'Hide ${hoverTitle}' : 'Show ${hoverTitle}'}}" ng-click="toggle()">
                            <i class="fa" ng-class="{ 'fa-eye': !showSensitive, 'fa-eye-slash': showSensitive }"></i>
                        </button>
                    </span>
                </div>'`)(scope);

            element.before(group).prependTo(group);

            scope.toggle = function () {
                scope.showSensitive = !scope.showSensitive;
                if (scope.showSensitive) element.attr('type', 'text');
                else element.attr('type', 'password');
            };
        }
    }

})();
