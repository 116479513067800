import templateUrl from './ipaddresses.whitelist.component.html';

class IpAddressesWhitelistController {
    constructor($state, $uibModal, $confirm, NotificationService, ipAddressesService, SessionService, OrganizationService, organizationSettings) {
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.$confirm = $confirm;
        this.notificationService = NotificationService;
        this.ipAddressesService = ipAddressesService;
        this.sessionService = SessionService;
        this.organizationService = OrganizationService;
        this.organizationSettings = organizationSettings;
        this.includeDisabled = false;
    }

    $onInit() {
        this.pagedItemRanges = [
            { value: '10' },
            { value: '25' },
            { value: '50' },
            { value: '100' }
        ];
        this.pagedItemRange = this.pagedItemRanges[0];
        this.pageStart = 1;

        this.profile = this.sessionService.getProfile();
        this.userIpAddress = this.profile.ipAddress;

        this.getPagedIpAddresses();
    }

    toggleIncludeDisabled() {
        this.includeDisabled = !this.includeDisabled;
        this.getPagedIpAddresses();
    }

    getPagedIpAddresses() {
        if (!this.search) this.search = null;
        return this.ipAddressesService.getPagedIpAddresses(
            this.pageStart, this.pagedItemRange.value, this.search, this.isWhitelisted, this.isBlacklisted, this.includeDisabled
        )
            .then(res => {
                this.ipAddresses = res.data.items;
                this.totalCount = res.data.totalCount;

                const userIpAddressResult = this.ipAddresses.find(x => x.address === this.profile.ipAddress);
                this.hasUserIpAddress = !!userIpAddressResult;

                this.getOrganizationSettings();
            })
    }

    getOrganizationSettings() {
        this.organizationService.getOrganizationSettings()
            .then((settings) => {
                this.requireWhitelistedIp = _.includes(settings, this.organizationSettings.RequireWhitelistedIp);
                this.hasIps = this.ipAddresses.length > 0;

                this.checkRequireWhitelistedIp();
            })
            .catch((err) => {
                let message = "An error occurred when retrieving the organization settings.";
                if (err.data && err.data.message) message = err.data.message;

                this.notificationService.error(message);
            })
    }

    upsertIpAddress(id) {
        const addressType = this.$state.$current.name.split('.')[1];
        const ipAddress = id ? this.ipAddresses.find(x => x.id == id) : {};

        if (!id)
            ipAddress.isBlacklisted = addressType === 'whitelist' ? false : true;

        const modal = this.$uibModal.open({
            component: 'k2IpAddressesUpsertModalComponent',
            resolve: {
                ipAddress: () => ipAddress,
                userIpAddress: () => this.userIpAddress,
                hasUserIpAddress: () => this.hasUserIpAddress
            }
        });

        modal.result
            .then((result) => {
                this.getPagedIpAddresses();
                this.notificationService.success(`The IP Address was saved successfully.`);
            })
            .catch (angular.noop);
    }

    toggleEnabled(ipAddress) {
        if (ipAddress.address !== this.userIpAddress || ipAddress.isDisabled) {
            return this.completeToggleEnabled(ipAddress);
        }
        return this.$confirm.open({
            bodyText: 'This is the IP Address you logged in to Kno2 with. Are you sure you want to disable it?',
            okText: 'OK'
        })
            .result
            .then(() => this.completeToggleEnabled(ipAddress))
            .catch(angular.noop);
    }

    completeToggleEnabled(ipAddress) {
        const target = { ...ipAddress };
        target.isDisabled = !target.isDisabled;

        return this.ipAddressesService.updateIpAddress(target.id, target)
            .then(res => {
                ipAddress.isDisabled = target.isDisabled;
                this.getPagedIpAddresses();
            })
            .catch(err => {
                let message = "An error occurred when updating the IP address.";
                if (err.data && err.data.message) message = err.data.message;

                this.notificationService.error(message);
            })
    }

    toggleRequireWhitelistedIp() {
        const userIpAddressResult = this.ipAddresses.find(x => x.address === this.userIpAddress);
        const userIpIsEnabled = this.hasUserIpAddress && userIpAddressResult && !userIpAddressResult.isDisabled;
        if (this.requireWhitelistedIp || userIpIsEnabled) {
            return this.completeToggleRequireWhitelistedIp();
        }
        return this.$confirm.open({
            bodyText: 'The IP Address you logged in to Kno2 with is not in the whitelist. Are you sure you want to enable whitelisting?',
            okText: 'OK'
        })
            .result
            .then(() => this.completeToggleRequireWhitelistedIp())
            .catch(angular.noop);
    }

    completeToggleRequireWhitelistedIp() {
        const isRequired = !this.requireWhitelistedIp;
        return this.organizationService.updateRequireWhitelistedIp(isRequired)
            .then(res => {
                this.requireWhitelistedIp = res.data.requireWhitelistedIp;
                this.checkRequireWhitelistedIp();
            })
            .catch(err => this.notificationService.error(err.data.message));
    }

    checkRequireWhitelistedIp() {
        this.notificationService.hideErrors();
        if (!this.requireWhitelistedIp && this.hasIps)
            this.notificationService.notice('You currently have "Require Whitelisted IP Addresses" disabled. Enable it in Organization Settings in order to restrict IP addresses');
    }
}

IpAddressesWhitelistController.$inject = ['$state', '$uibModal', '$confirm', 'NotificationService', 'ipAddressesService', 'SessionService', 'OrganizationService', 'organizationSettings'];
export const ipaddressesWhitelistComponent = {
    templateUrl: templateUrl,
    controller: IpAddressesWhitelistController
}
