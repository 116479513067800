angular.module('kno2.services')
    .service('ValidationService', ['$http', '$location', 'usersFactory',
        function ($http, $location, usersFactory) {

            this.validateUsername = function (username, callback) {

                // check if the username is even valid
                var regex = /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*$/i;
                var result = regex.exec(username);
                var userNameUpdated = false;

                if (!result) {
                    return callback(false, 'Username is not valid');
                }
                else {
                    if (result.input !== result.toString()) {
                        username = result[0];
                        userNameUpdated = true;
                    }
                }

                usersFactory.find(username).then(function (res) {
                    // success == failure in this case
                    if (res.id) {
                        callback(false, 'Username already exists', (userNameUpdated ? username : undefined));
                    } else {
                        callback(true, "Username is available", (userNameUpdated ? username : undefined));
                    }
                }, function (res) {
                    callback(true, "Username is available", (userNameUpdated ? username : undefined));
                });
            };
        }])
;
