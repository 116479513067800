import templateUrl from './auth0-callback.component.html';

class Auth0CallbackController {
    constructor($window, $state, LocalStorageFactory, StateRoutingService, Auth0Service, SessionService, IntercomService) {
        this.$window = $window;
        this.$state = $state;
        this.localStorageFactory = LocalStorageFactory;
        this.stateRoutingService = StateRoutingService;
        this.auth0Service = Auth0Service;
        this.sessionService = SessionService;
        this.intercomService = IntercomService;
    }

    $onInit() {
        this.auth0Service.callbackRenewTokens()
            .then(() => this.complete())
            .catch((err) => this.$state.go('authError', { error: err }));
    }

    async complete() {
        const redirectUrl = this.localStorageFactory.get('redirectUrl');
        this.localStorageFactory.remove('redirectUrl');

        await this.sessionService.refreshProfile();

        this.intercomService.boot();

        if (redirectUrl) {
            this.$window.location = redirectUrl;
        }
        else {
            const targetState = await this.stateRoutingService.getState();
            this.stateRoutingService.goTo(targetState);
        }
    }
}

Auth0CallbackController.$inject = ['$window', '$state', 'LocalStorageFactory', 'StateRoutingService', 'Auth0Service', 'SessionService', 'IntercomService'];

export const auth0CallbackComponent = {
    templateUrl: templateUrl,
    controller: Auth0CallbackController
};
