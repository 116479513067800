TermsOfUseCtrl.$inject = ['$scope', 'EulasService', '$location'];

export function TermsOfUseCtrl($scope, eulasService, $location) {

    const queryString = $location.search();
    
    eulasService.getViewActive().then(res => {
        $scope.document = res.data;
    });

    $scope.$on('$viewContentLoaded', function() {
        if (queryString.print === 'true') {
            window.print();
        }
      });
}
