import angular from 'angular';
import { CommonModule } from '../common';
import { CoreModule } from '../config';

import { accountRoutes } from './account.routes';
import { ResellerService } from './reseller.service'
import { resellerTosModalComponent } from './reseller-tos.modal.component';
import { NotificationsCtrl } from './notifications/account.notifications.ctrl';

import { DashboardService } from './dashboard/account.dashboard.services';
import { DashboardCtrl } from './dashboard/account.dashboard.ctrl';
import { DisplayGetStartedModalCtrl } from './dashboard/getting-started/account.dashboard.getting-started-modal.ctrl';
import { NewFeaturesAndUpdatesModalCtrl } from './dashboard/new-features/account.dashboard.new-features-modal.ctrl';
import { ReceiveModalCtrl } from './dashboard/optimize-receiving/account.dashboard.optimize-receiving-modal.ctrl';
import { SendModalCtrl } from './dashboard/optimize-sending/account.dashboard.optimize-sending-modal.ctrl';
import { DashboardRecentActivityModalCtrl } from './dashboard/recent-activity/account.dashboard.recenty-activity-modal.ctrl';
import { ShowAlertModalCtrl } from './dashboard/alerts/account.dashboard.alert-modal.ctrl';

import { CancelledOrgCtrl } from './organization/organization.cancelled-org.ctrl';
import { CancelledOrgModalCtrl } from './organization/organization.cancelled-org-modal.ctrl';
import { OrgGracePeriodModalCtrl } from './organization/organization.grace-period-org-modal.ctrl';
import { ReactivateOrgModalCtrl } from './organization/organization.reactivate-org-modal.ctrl';

import { IdProofingService } from './id-proofing/account.id-proofing.service';
import { IdProofingCtrl } from './id-proofing/account.id-proofing.ctrl';
import { idProofingModalHeaderComponent } from './id-proofing/account.id-proofing-modal-header.component';
import { SubmitIdentityModalCtrl } from './id-proofing/account.id-proofing.submit-identity-modal.ctrl';
import { IdentityQuestionsModalCtrl } from './id-proofing/account.id-proofing.identity-questions-modal.ctrl';
import { IdentityDecisionModal } from './id-proofing/account.id-proofing.identity-decision-modal.ctrl';
import { IdentityTermsAndConditionsModal } from './id-proofing/account.id-proofing.identity-terms-and-conditions-modal.ctrl';
import { IdentityWelcomeKno2Modal } from './id-proofing/account.id-proofing.identity-welcome-modal.ctrl';
import { IdentityVerificationModal } from './id-proofing/account.id-proofing.identity-verification-modal.ctrl';

import { eventsReportDateHeaderFilter } from './notifications/account.notifications.filters';
import { PhoneNumberCtrl } from './account.phonenumber.ctrl';
import { PhoneNumberModalCtrl } from './account.phonenumber.modal.ctrl';

import { ProfileCtrl, SettingsMenuCtrl } from './profile/account.profile.ctrl';
import { ResetPasswordModalCtrl } from './profile/account.profile.reset-password-modal.ctrl';
import { AccountProfileService } from './profile/account.profile.service'
import { EditProfileModalCtrl } from './profile/account.profile.edit-profile-modal.ctrl';
import { EnableTokenAuthenticatorModalCtrl } from './profile/account.profile.enable-token-authenticator-modal.ctrl';
import { VerifyPhoneModalCtrl } from './profile/account.profile.verify-phone-modal.ctrl'

import { chartDirective } from './directives/chart.directive';
import { compileTemplateDirective } from './directives/comile-template.directive';
import { accountActivateComponent } from './activate/account.activate.component';

export const AccountModule = angular
    .module('kno2.account', [CommonModule, 'ngSanitize'])
    .component('k2AccountActivate', accountActivateComponent)
    .config(accountRoutes)
    .service('ResellerService', ResellerService)
    .component('k2ResellerTosModal', resellerTosModalComponent)
    .controller('DashboardCtrl', DashboardCtrl)
    .directive('chart', chartDirective)
    .directive('compileTemplate', compileTemplateDirective)
    .controller('ShowAlertModal', ShowAlertModalCtrl)
    .controller('DisplayGetStartedModal', DisplayGetStartedModalCtrl)
    .controller('ReceiveModalCtrl', ReceiveModalCtrl)
    .controller('SendModalCtrl', SendModalCtrl)
    .controller('NewFeaturesAndUpdatesModalCtrl', NewFeaturesAndUpdatesModalCtrl)
    .controller('DashboardRecentActivityModalCtrl', DashboardRecentActivityModalCtrl)
    .service('DashboardService', DashboardService)
    .controller('NotificationsCtrl', NotificationsCtrl)
    .filter('eventsReportDateHeader', eventsReportDateHeaderFilter)
    .name;

angular
    .module(CoreModule)
    .controller('CancelledOrgCtrl', CancelledOrgCtrl)
    .controller('ReactivateOrgModalCtrl', ReactivateOrgModalCtrl)
    .controller('OrgGracePeriodModalCtrl', OrgGracePeriodModalCtrl)
    .controller('CancelledOrgModalCtrl', CancelledOrgModalCtrl)
    .controller('IdProofingCtrl', IdProofingCtrl)
    .component('idProofingModalHeader', idProofingModalHeaderComponent)
    .controller('SubmitIdentityModalCtrl', SubmitIdentityModalCtrl)
    .controller('IdentityQuestionsModalCtrl', IdentityQuestionsModalCtrl)
    .controller('IdentityDecisionModal', IdentityDecisionModal)
    .controller('IdentityTermsAndConditionsModal', IdentityTermsAndConditionsModal)
    .controller('IdentityWelcomeKno2Modal', IdentityWelcomeKno2Modal)
    .controller('IdentityVerificationModal', IdentityVerificationModal)
    .service('IdProofingService', IdProofingService)
    .controller('PhoneNumberCtrl', PhoneNumberCtrl)
    .controller('PhoneNumberModalCtrl', PhoneNumberModalCtrl)
    .controller('ProfileCtrl', ProfileCtrl)
    .controller('ResetPasswordModalCtrl', ResetPasswordModalCtrl)
    .controller('SettingsMenuCtrl', SettingsMenuCtrl)
    .service('AccountProfileService', AccountProfileService)
    .controller('EditProfileModalCtrl', EditProfileModalCtrl)
    .controller('EnableTokenAuthenticatorModalCtrl', EnableTokenAuthenticatorModalCtrl)
    .controller('VerifyPhoneModalCtrl', VerifyPhoneModalCtrl);
