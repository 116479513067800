(function () {

    var service = function ($http) {

        this.getDefaultPageParameters = function () {
            return {
                pageNumber: 1,
                pageSize: 10
            };
        };

        this.getPageSizeOptions = function () {
            return [10, 25, 50, 100];
        };

        this.getPages = function (params) {
            var pageParams = _.extend({ type: 'devices' }, this.getDefaultPageParameters(), params);
            var promise = $http({
                url: '/api/connectors',
                method: 'GET',
                params: pageParams
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };

        this.getAll = function () {
            var promise = $http.get('/api/devices')
                .then(function (response) {
                    return response.data;
                });
            return promise;
        };

        this.getById = function (id) {
            var promise = $http.get('/api/devices/' + id).then(function (response) {
                return response.data;
            });
            return promise;
        };

        this.save = function (device) {
            var promise = $http.post('/api/devices', device).then(function (response) {
                return response.data;
            });
            return promise;
        };

        this.update = function (device) {
            var promise = $http.put('/api/devices/' + device.deviceId, device).then(function (response) {
                return response.data;
            });
            return promise;
        };

        this.saveOrUpdate = function (device) {
            var action = (device.deviceId) ? this.update : this.save;
            return action(device);
        };

        this.delete = function (id) {
            var promise = $http.delete('/api/devices/' + id).then(function (response) {
                return response.data;
            });
            return promise;
        };

        this.attachLicense = function (device) {
            var promise = $http.post('/api/devices/{id}/license'.replace('{id}', device.deviceId)).then(function (response) {
                return response.data;
            });
            return promise;
        };

        this.detachLicense = function (device) {
            var promise = $http.delete('/api/devices/{id}/license'.replace('{id}', device.deviceId)).then(function (response) {
                return response.data;
            });
            return promise;
        };

    };

    service.$inject = ['$http'];
    angular.module('kno2.settings').service('DevicesService', service);

}());
