import releaseSendNonPhiModalTemplate from './send-non-phi/release.send-non-phi-modal.html';
import releaseDeleteDraftModalTemplate from './release.delete-draft-modal.html';
import deleteMessageModalTemplate from '../common/delete-message/delete-message-modal.html';
import intakeAuditMessageModalTemplate from '../intake/intake.audit-message-modal.html';

import templateUrl from './release.component.html';

angular
    .module('kno2.release')
    .component('k2Release', {
        templateUrl,
        controller: ReleaseComponentController,
        bindings: {
            isFirstRequestForView: "<"
        }
    });

ReleaseComponentController.$inject = [
    '$rootScope', '$stateParams', 'EventService', 'NotificationService', 'CommonData', 'ReleaseTypesService',
    'MessageService', 'SessionService', '$uibModal', 'LocalStorageFactory', '$timeout', '$interval'
];

function ReleaseComponentController(
    $rootScope, $stateParams, EventService, NotificationService, CommonData, ReleaseTypesService,
    MessageService, SessionService, $uibModal, LocalStorageFactory, $timeout, $interval) {

    var ctrl = this;
    ctrl.isLeftNav = $stateParams.isLeftNav;
    var timer;
    var draftTimer;
    var defaultTab = 'events';
    var RELEASE_RECENT_EVENTS_PAGE_KEY = 'release.recentEventsPage';
    var RELEASE_DRAFTS_PAGE_KEY = 'release.draftsPage';
    var RELEASE_ACTIVE_TABS_KEY = 'release.activeTab';

    ctrl.onFilterChange = onFilterChange;
    ctrl.restrictDraftSubjectLink = restrictDraftSubjectLink;
    ctrl.loadRecentEvents = loadRecentEvents;
    ctrl.showNonPhiFaxModal = showNonPhiFaxModal;
    ctrl.refreshEvents = refreshEvents;
    ctrl.showEventsForCurrentPage = showEventsForCurrentPage;
    ctrl.loadDrafts = loadDrafts;
    ctrl.changeActiveTab = changeActiveTab;
    ctrl.attachWorkflowIdToEvent = attachWorkflowIdToEvent;
    ctrl.deleteDraft = deleteDraft;
    ctrl.deleteClick = deleteClick;
    ctrl.$onInit = $onInit;
    ctrl.$onDestroy = $onDestroy;
    ctrl.onDraftPageChange = onDraftPageChange;
    ctrl.showActivity = showActivity;

    function onFilterChange(filter) {
        ctrl.filter = filter;
        ctrl.loadRecentEvents();
    }

    function setIntervalForTab(tab) {
        if (!tab || tab === 'events') {
            if (draftTimer) $interval.cancel(draftTimer);
            if(!timer || timer.$$state.status === 2)
                timer = ctrl.refreshEvents();
        }
        if (tab === 'drafts') {
            if (timer) $interval.cancel(timer);
            if(!draftTimer || draftTimer.$$state.status === 2)
                draftTimer = refreshDrafts();
        }
    }

    function restrictDraftSubjectLink(draft) {

        var showDeviceDraftSubjectLink = $rootScope.hasUIResourceAction('releaseDraftDeviceSubjectLink', 'show');
        if (draft.origin !== 'Browser' && showDeviceDraftSubjectLink) {
            return false;
        }

        var hideDraftSubjectLink = $rootScope.hasUIResourceAction('releaseDraftSubjectLink', 'hide');
        return hideDraftSubjectLink || false;
    }

    function loadRecentEvents() {
        const currentPage = ctrl.events.currentPage || 1;
        const promise = EventService.getMyRecentEvents(10, currentPage, null, ctrl.filter);
        promise.then(function(response) {
            ctrl.events.items = response.data.items;
            ctrl.events.count = response.data.totalCount;
            _.each(ctrl.events.items, ctrl.attachWorkflowIdToEvent);
            return response;
        });
        return promise;
    }

    function showNonPhiFaxModal() {
        $uibModal.open({
            windowClass: 'send-non-phi-fax-modal',
            templateUrl: releaseSendNonPhiModalTemplate,
            controller: 'ReleaseSendNonPhiFaxModalCtrl'
        }).result.then(angular.noop, angular.noop);
    }

    function refreshEvents() {
        return $interval(function () {
            $timeout(ctrl.showEventsForCurrentPage, 100);
        }, 10000);
    }

    function refreshDrafts() {
        return $interval(function() {
            $timeout(ctrl.loadDrafts, 100);
        }, 10000);
    }

    function showEventsForCurrentPage() {
        LocalStorageFactory.set(RELEASE_RECENT_EVENTS_PAGE_KEY, ctrl.events.currentPage);

        ctrl.loadRecentEvents();
    }

    function loadDrafts() {
        const promise = MessageService.getDrafts({
            pageStart: ctrl.drafts.currentPage,
            pageSize: ctrl.drafts.pageSize
        });
        promise.then(function (response) {
            LocalStorageFactory.set(RELEASE_DRAFTS_PAGE_KEY, ctrl.drafts.currentPage);
            ctrl.drafts.items = response.data.items;
            ctrl.drafts.count = response.data.totalCount;
        });
        return promise;
    }

    function changeActiveTab(tab) {
        Object.keys(ctrl.tabs).forEach(t => ctrl.tabs[t].active = false);
        ctrl.tabs[tab].active = true;

        LocalStorageFactory.set(RELEASE_ACTIVE_TABS_KEY, tab);
        setIntervalForTab(tab);
    }

    function attachWorkflowIdToEvent(event) {
        if (!_.isEmpty(event.subject)) {
            let stripFw = event.subject;
            if (event.subject.lastIndexOf("FW:", 0) === 0) {
                //prevents double FW:
                stripFw = event.subject.substring(4);
            }
            const releaseType = _.find(ctrl.releaseTypes, {
                name: stripFw
            });
            event.releaseType = releaseType ? releaseType.id : "";
        }
    }

    function deleteDraft(draftId) {
        const modalInstance = $uibModal.open({
            templateUrl: releaseDeleteDraftModalTemplate,
            controller: 'DeleteDraftCtrl',
            controllerAs: '$ctrl',
            resolve: {
                draftId: function () {
                    return draftId;
                }
            }
        });

        modalInstance.result.then(ctrl.loadDrafts, angular.noop);
    }

    function deleteClick(eventMessage, event) {
        event.stopPropagation();
        const modal = $uibModal.open({
            templateUrl: deleteMessageModalTemplate,
            controller: 'DeleteReleaseModalCtrl',
            controllerAs: '$ctrl',
            resolve: {
                eventMessage: function () {
                    return eventMessage;
                }
            }
        });

        modal.result.then(function () {
            ctrl.loadRecentEvents();
            $timeout(ctrl.showEventsForCurrentPage, 100);
        }, angular.noop);
    }

    function onDraftPageChange() {
        ctrl.loadDrafts();
    }

    function showActivity(message, event) {
        event.stopPropagation();
        $uibModal.open({
            windowClass: 'audit-message-modal',
            templateUrl: intakeAuditMessageModalTemplate,
            controller: 'AuditMessageModalCtrl',
            controllerAs: '$ctrl',
            resolve: {
                message: () => message
            }
        }).result.then(() => { }, () => { });
    }

    function $onInit() {
        ctrl.statuses = [
            'Bounced',
            'Deleted',
            'Delivered',
            'Exported',
            'Failed',
            'Faxed',
            'Forwarded',
            'Forwarding',
            'Pending',
            'Processed',
            'Read',
            'Received',
            'Replied',
            'Replying',
            'Sending',
            'Sent',
            'Unknown'
        ];


        ctrl.events = { items: [], pageSize: 10 };
        ctrl.drafts = { items: [], pageSize: 10 };

        ctrl.userIsAdmin = SessionService.userInRole('Administrator');

        ctrl.release = {};
        ctrl.releaseTypes = null;
        ReleaseTypesService.getReleaseTypes().then(function (response) {
            ctrl.releaseTypes = response.releaseTypes;
        });

        ctrl.tabs = {
            drafts: { active: false },
            events: { active: false }
        };

        const activeTab = ctrl.isFirstRequestForView ? defaultTab : LocalStorageFactory.get(RELEASE_ACTIVE_TABS_KEY) || defaultTab;
        ctrl.changeActiveTab(activeTab);

        $timeout(function delayCurrentPageLoading() {
            let draftsCurrentPage = parseInt(LocalStorageFactory.get(RELEASE_DRAFTS_PAGE_KEY), 10);
            ctrl.drafts.currentPage = isNaN(draftsCurrentPage) ? 1 : draftsCurrentPage;
            let releaseCurrentPage = parseInt(LocalStorageFactory.get(RELEASE_RECENT_EVENTS_PAGE_KEY, 10));
            ctrl.events.currentPage = isNaN(releaseCurrentPage) || ctrl.isFirstRequestForView || ctrl.isLeftNav
                ? 1
                : releaseCurrentPage;
            ctrl.showEventsForCurrentPage();

            setIntervalForTab(activeTab);
            ctrl.loadDrafts();
            ctrl.loadRecentEvents();
        });

        ctrl.loadDrafts();
    }

    function $onDestroy() {
        if (timer) {
            $interval.cancel(timer);
        }
        if (draftTimer) {
            $interval.cancel(draftTimer);
        }
    }
}
