export function eventsReportDateHeaderFilter() {
    return function (date) {
        if (date) {
            var eventMoment = moment(date);
            var isToday = eventMoment.isSame(moment(), 'day');
            if (isToday) {
                return "Today";
            } else {
                var yesterdayMoment = moment().subtract(1, 'days');
                if (eventMoment.isSame(yesterdayMoment, 'day')) {
                    return "Yesterday";
                }
            }
            return eventMoment.format("MMM D, YYYY");
        }
        return undefined;
    };
}
