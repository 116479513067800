angular.module('kno2.release')
    .controller('ConfirmDraftSaveModalCtrl', ConfirmDraftSaveModalCtrl)

ConfirmDraftSaveModalCtrl.$inject = ['$scope', '$uibModalInstance'];
function ConfirmDraftSaveModalCtrl($scope, $uibModalInstance) {

    $scope.saveDraft = function () {
        $uibModalInstance.close('save');
    };

    $scope.discardDraft = function () {
        $uibModalInstance.close('discard');
    };
}
