import templateUrl from './message-row-repeater.html';
import attachmentPreviewModalTemplate from '../../intake/intake.attachment-preview-modal.html';

class MessageRowRepeaterCtrl {

    constructor($uibModal) {
        this.$uibModal = $uibModal;
    }

    preview($event, message, attachment) {
        $event.stopPropagation();

        this.$uibModal.open({
            windowClass: 'attachment-preview-modal',
            templateUrl: attachmentPreviewModalTemplate,
            controller: 'AttachmentPreviewModalCtrl as vm',
            resolve: {
                message: function () {
                    return message;
                },
                attachment: function () {
                    return attachment;
                }
            }
        }).result.then(() => { }, () => { });
    }
}
MessageRowRepeaterCtrl.$inject = ['$uibModal'];
export const messageRowRepeater = {
    controller: MessageRowRepeaterCtrl,
    templateUrl: templateUrl,
    bindings: {
        messages: '=',
        rowClick: '&',
        messageSelected: '&',
        deleteClick: '&',
        replyClick: '&',
        forwardClick: '&',
        triageClick: '&',
        showTriage: '&',
        showActivity: '&',
        isFaxMessage: '&'
    }
};
