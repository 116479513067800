(function () {
    angular
        .module('kno2.services')
        .service('NotificationBaseService', NotificationBaseService);

    function NotificationBaseService() {

        return {
            success: success,
            warning: warning,
            notice: notice,
            error: error,
            errorToaster: errorToaster,
            serverError: serverError,
            hideErrors: hideErrors,
            errors: errors
        };

        function success(message) {
            if (this.notifySuccess) {
                this.notifySuccess(message);
            }
        }

        function errorToaster(message) {
            if (this.notifyError) {
                this.notifyError(message);
            }
        }

        // Alerts are sticky and persist on the page
        function error(options) {
            if (this.showAlertOnPage) {
                this.showAlertOnPage(options);
            }
        }

        function warning(message) {
            if (this.showAlertOnPage) {
                var options = {
                    caption: 'Warning!',
                    messages: [message],
                    level: 'warning'
                };
                this.showAlertOnPage(options);
            }
        }

        function notice(message) {
            if (this.showAlertOnPage) {
                var options = {
                    caption: 'Notice!',
                    messages: [message],
                    level: 'warning'
                };
                this.showAlertOnPage(options);
            }
        }

        function hideErrors() {
            if (this.hideAlertOnPage) {
                this.hideAlertOnPage();
            }
        }

        // This function is a standard call to report modelState errors.
        function serverError(modelState, title) {
            let errors = new Array();
            if (modelState) {
                Object.keys(modelState).forEach(e => errors.push(modelState[e]));
            }
            this.errors(errors, title);
        };

        function errors(errors, title) {
            if (this.showAlertOnPage) {
                var options = {
                    messageTitle: title,
                    messages: errors
                };
                this.error(options);
            }
        }
    }

})();
