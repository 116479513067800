angular.module('kno2.settings')
    .controller('DirectAddressesDeleteModalCtrl', ['$scope', '$uibModalInstance', 'data', 'DocumentSourcesService', 'NotificationService',
        function ($scope, $uibModalInstance, data, DocumentSourcesService, NotificationService) {
            $scope.userProfile = data.userProfile;
            $scope.setForm = function (form) { $scope.form = form; };
            $scope.cancel = function () { $uibModalInstance.dismiss('cancel'); };
            $scope.ok = function () {
                DocumentSourcesService.deleteDocumentSource($scope.documentSource.id)
                    .then(function () {
                        NotificationService.success("The direct address has been deleted.");
                        $uibModalInstance.close($scope.documentSource);
                    },
                        function (response) {
                            NotificationService.serverError(response.data.modelState, "There was an error deleting this direct address.");
                            $('.btn-primary').css('display', 'none');
                            $('#documentSourceDeleteForm').css('display', 'none');
                            $('.close').css('display', 'none');
                        });
            };
            DocumentSourcesService.getDocumentSource(data.id).then(function (data) { $scope.documentSource = data; });
        }
    ])
