angular.module('kno2.settings')
    .controller('SettingsFaxDeleteModalCtrl', ['$scope', '$uibModalInstance', 'data', 'DocumentSourcesFaxService', 'NotificationService',
        function ($scope, $uibModalInstance, data, DocumentSourcesFaxService, NotificationService) {
            $scope.userProfile = data.userProfile;
            $scope.setForm = function (form) {
                $scope.form = form;
            };
            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };
            $scope.ok = function () {
                DocumentSourcesFaxService.deleteDocumentSource($scope.documentSource.id)
                    .then(function () {
                        NotificationService.success("The fax has been deleted.");
                        $uibModalInstance.close($scope.documentSource);
                    },
                        function (response) {
                            NotificationService.serverError(response.data.modelState, "There was an error deleting this fax.");
                            $('.btn-primary').css('display', 'none');
                            $('#documentSourceDeleteForm').css('display', 'none');
                            $('.close').css('display', 'none');
                        });
            };
            DocumentSourcesFaxService.getDocumentSource(data.id).then(function (data) {
                $scope.documentSource = data;
            });
        }
    ]);
