import * as angular from 'angular';
import { downgradedSharedModule } from './shared/shared.module';
import { downgradeComponent } from '@angular/upgrade/static';
import { enableProdMode } from '@angular/core';
import { ExampleComponent } from './shared/example/example.component';

enableProdMode();

export const NgxModule = angular
    .module('kno2.ngx', [
        downgradedSharedModule
    ])
    .directive('k2Example', downgradeComponent({
        component: ExampleComponent,
        downgradedModule: downgradedSharedModule
    }))
    .name;
