import footerComponentTemplate from './footer.component.html';

class FooterController {

    constructor($rootScope) {
        this.hostName = HOST_NAME;
        this.build = BUILD_NUMBER;
        this.$rootScope = $rootScope;
    }

    $onInit() {
        const date = new Date();
        this.year = date.getFullYear();
        this.brandName = this.$rootScope.brand;
        this.setAboutUsUrl();
    }

    setAboutUsUrl() {
        switch (this.$rootScope.environment.brand) {
            case "Consensus":
                this.aboutUsUrl = 'https://www.consensus.com/';
                break;
            default:
                this.aboutUsUrl = 'aboutus';
        }
    }
}

FooterController.$inject = ['$rootScope'];
export const footerComponent = {
    templateUrl: footerComponentTemplate,
    controller: FooterController
};
