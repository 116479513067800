TriageServiceFactory.$inject = ['$q', '_'];

export function TriageServiceFactory($q, _) {

    var service;

    return function (options) {

        if (service)
            return service;

        var selected = null,
            pageCollections = [],
            attachments = [],
            defaultName = 'attachment.pdf';

        service = {
            messages: [],
            unprocessed: [],
            trash: [],
            selectedPages: [],
            destroy: destroy,
            addUnprocessed: addUnprocessed,
            getFirstPage: getFirstPage,
            createMessage: createMessage,
            deleteMessage: deleteMessage,
            createAttachment: createAttachment,
            cloneAttachment: cloneAttachment,
            moveAttachment: moveAttachment,
            moveSelectedPagesToAttachment: moveSelectedPagesToAttachment,
            moveSelectedPagesToCollection: moveSelectedPagesToCollection,
            select: select,
            isSelected: isSelected,
            getSelected: getSelected,
            isPageSelected: isPageSelected,
            toggleSelectedPage: toggleSelectedPage,
            clearSelectedPages: clearSelectedPages,
            $movePage: movePage,
            allPageCollections: allPageCollections,
            allAttachments: allAttachments,
            cleanUp: cleanUp,
            callbacks: {
                beforeCreateMessage: beforeCreateMessageStub,
                getRuleName: getRuleNameStub
            }
        };

        activate();

        return service;

        function activate() {
            angular.extend(service.callbacks, options);
        }

        function destroy() {
            service = undefined;
        }

        function cleanUp() {
            _.each(allAttachments(),
                function(a) {
                    removeAttachmentIfEmpty(a);
                });
        }

        function addUnprocessed(attachments) {
            _.forEach(attachments, function (attachment) {
                attachment.$$collection = service.unprocessed;
                attachment.name = getAttachmentName(attachment.name);
                _.forEach(attachment.pages, function (page) {
                    page.$$attachment = attachment;
                });
                service.unprocessed.push(attachment);
            });

            select(getFirstPage());

            return service.unprocessed;
        }

        function beforeCreateMessageStub(message) {
            var deferred = $q.defer();
            deferred.resolve(message);
            return deferred.promise;
        }

        function getRuleNameStub(ruleId) {
            return undefined;
        }

        function createMessage(createEmptyAttachment) {
            return service.callbacks.beforeCreateMessage()
                .then(function (message) {
                    if (message) return createMessageInternal(message, createEmptyAttachment);
                });
        }

        function createMessageInternal(message, createEmptyAttachment) {
            var deferred = $q.defer();
            message = message || {};
            message.attachments = [];
            if (createEmptyAttachment) createAttachment(message.attachments);
            service.messages.push(message);

            deferred.resolve(message);

            return deferred.promise;
        }

        function deleteMessage(message) {
            _.forEach(message.attachments.slice(0), function (a) {
                if (a.pages.length !== 0)
                    moveAttachment(a, service.unprocessed);
            });
            service.messages.remove(message);
        }

        function getFirstPage() {
            return service.unprocessed && service.unprocessed[0] && service.unprocessed[0].pages && service.unprocessed[0].pages[0];
        }

        function createAttachment(collection) {

            const attachment = {
                $$collection: collection,
                name: getAttachmentName(defaultName, collection.length),
                pages: []
            };
            collection.push(attachment);
            return attachment;
        }

        function cloneAttachment(attachment, collection) {
            var a = _.omit(attachment, ['$$hashKey', '$$collection', 'pages', 'id']);
            a.pages = [];

            if (collection) {
                collection.push(a);
                a.$$collection = collection;
            }

            return a;
        }

        function moveAttachment(attachment, to) {
            var from = attachment.$$collection;
            if (to === from) return;

            var a = findAttachment(to, attachment);
            if (a) {
                moveAllPages(attachment, a);
                removeAttachmentIfEmpty(attachment);
            } else {
                to.push(from.remove(attachment));
                attachment.$$collection = to;
            }
        }

        function removeAttachmentIfEmpty(attachment) {
            if (attachment.pages.length === 0 && attachment.$$collection) {
                attachment.$$collection.remove(attachment);
                attachment.$$collection = undefined;
            }
        }

        function moveSelectedPagesToCollection(collection) {
            _.forEach(service.selectedPages, function (p) {
                var a = findAttachment(collection, p.$$attachment) || cloneAttachment(p.$$attachment, collection);
                movePage(p, a);
            });

            clearSelectedPages();
        }

        function moveSelectedPagesToAttachment(attachment) {
            _.forEach(service.selectedPages, function (p) {
                movePage(p, attachment);
            });

            clearSelectedPages();
        }

        function moveAllPages(from, to) {
            // copy array
            var pages = from.pages.slice();

            // move pages
            _.forEach(pages, function (p) {
                movePage(p, to);
            });
        }

        function movePage(page, to) {
            var from = page.$$attachment;
            to.pages.push(from.pages.remove(page));
            page.$$attachment = to;

            removeAttachmentIfEmpty(from);
        }

        function findAttachment(collection, attachment) {
            return _.find(collection, function (a) {
                return attachment.name === a.name;
            });
        }

        function select(page) {
            selected = page;
        }

        function isSelected(page) {
            return selected === page;
        }

        function getSelected() {
            return selected;
        }

        function isPageSelected(p) {
            return service.selectedPages.indexOf(p) !== -1;
        }

        function toggleSelectedPage(p) {
            var index = service.selectedPages.indexOf(p);

            if (index >= 0) {
                service.selectedPages.splice(index, 1);
            } else {
                service.selectedPages.push(p);
            }
        }

        function clearSelectedPages() {
            service.selectedPages.length = 0;
        }

        function allAttachments() {

            attachments.length = 0; //empty array;

            var messages = _.flattenDeep(_.map(service.messages, 'attachments')),
                all = [].concat(messages, service.unprocessed, service.trash);

            _.forEach(all, function (a) {
                attachments.push(a);
            });

            return attachments;
        }

        function allPageCollections() {

            pageCollections.length = 0; //empty array;

            var messages = _.map(_.flattenDeep(_.map(service.messages, 'attachments')), 'pages'),
                unprocessed = _.map(service.unprocessed, 'pages'),
                trash = _.map(service.trash, 'pages'),
                all = [].concat(messages, unprocessed, trash);

            _.forEach(all, function (c) {
                pageCollections.push(c);
            });

            return pageCollections;
        }

        function getAttachmentName(name, length) {
            if (length) {
                name = name.replace(/\.[0-9a-z]+$/i, ++length + '$&');
            }
            return name;
        }
    }
}
