(function() {
    'use strict';

    angular
        .module('kno2.directives')
        .directive('modalContent', modalContent);

    modalContent.$inject = ['$document', '$timeout'];

    function modalContent($document, $timeout) {

        var directive = {
            restrict: 'C',
            link: link,
        };
        return directive;

        function link(scope, element, attrs) {

            $timeout(function () {
                var startX = 0, startY = 0, x = 0, y = 0;

                if (!element.closest('.modal.modal-draggable').length)
                    return;

                element.css({
                    position: 'relative'
                });

                element.find('.modal-header, .modal-title').css({
                    cursor: 'move'
                });

                element.on('mousedown', function (event) {
                    var classes = event.target.classList;

                    if (classes.contains('modal-header') || classes.contains('modal-title')) {
                        // Prevent default dragging of selected content
                        event.preventDefault();
                        startX = event.screenX - x;
                        startY = event.screenY - y;
                        $document.on('mousemove', mousemove);
                        $document.on('mouseup', mouseup);
                    }
                });

                function mousemove(event) {
                    y = event.screenY - startY;
                    x = event.screenX - startX;
                    element.css({
                        top: y + 'px',
                        left: x + 'px'
                    });
                }

                function mouseup() {
                    $document.off('mousemove', mousemove);
                    $document.off('mouseup', mouseup);
                }
            });
        }
    }

})();