DashboardService.$inject = ['$http', 'CacheService'];
export function DashboardService($http, CacheService) {

    // Ajax request to Message Statistics
    this.getMessageStatistics = function (fromDate, toDate) {
        var _fromDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), 0, 0, 0);
        var _toDate = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59, 59);
        var promise = $http.get('/api/messages/statistics?' +
            'fromDate=' + _fromDate.toJSON() +
            '&toDate=' + _toDate.toJSON() +
            '&timeOffset=' + _toDate.getTimezoneOffset() * -1 / 60, { cache: CacheService.cacheL1() })
            .then(function (response) {
                return response.data;
            });
        return promise;
    };

    this.getRecentActivities = function (top) {
        var promise = $http.get('/api/messagestatistics/recentactivities?top=' + top)
            .then(function (response) {
                return response.data;
            });
        return promise;
    };

    this.getChecklistValues = function (orgId) {
        var promise = $http.get('/api/getting_started_checklists/' + orgId)
            .then(function (response) {
                return response.data[0];
            });
        return promise;
    };

    this.getFeatures = function () {
        var promise = $http.get('/api/new_features_and_updates')
            .then(function (response) {
                return response.data;
            });
        return promise;
    };

    this.putChecklistValue = function (orgId, params) {
        return $http.put('/api/getting_started_checklists/' + orgId, params);
    };

    this.allChecked = false;
    this.checklistValues;

}
