import templateUrl from './identityproviders.component.html';

class IdentityProvidersController {
    constructor(IdentityProvidersService, NotificationService, $uibModal, $confirm) {
        this.identityProvidersService = IdentityProvidersService;
        this.notificationService = NotificationService;
        this.$uibModal = $uibModal;
        this.$confirm = $confirm;
    }

    $onInit() {
        this.getIdentityProvider();
        this.getIdentityProviderTypes();
    }

    getIdentityProvider() {
        this.identityProvidersService.get().then(res => {
            this.identityProvider = res.data.name === null ? null : res.data;
        });
    }

    getIdentityProviderTypes() {
        this.identityProvidersService.getTypes().then(res => {
            this.types = res.data;
        });
    }

    upsertIdentityProvider() {
        const identityProvider = this.identityProvider || {};
        const modal = this.$uibModal.open({
            component: 'k2IdentityProvidersUpsertModal',
            resolve: {
                availableTypes: () => this.types,
                identityProvider: () => identityProvider
            }
        });

        modal.result
            .then((result) => {
                this.getIdentityProvider();
                this.notificationService.success(`The Identity Provider was saved successfully.`);
            })
            .catch (angular.noop);
    }

    delete() {
        const $this = this;
        const id = this.identityProvider.objectId;

        const confirm = this.$confirm.open({
            title: 'Delete Identity Provider',
            bodyText: 'Are you sure you want to delete the Identity Provider?'
        });

        confirm.result.then(function (ok) {
            if (ok) {                
                $this.identityProvidersService.delete(id)        
                    .then(res => {
                        $this.getIdentityProvider();
                        $this.notificationService.success(`The Identity Provider was deleted successfully.`);
                    })
                    .catch(err => $this.notificationService.error(err.data.message));
            }
        });
    }
}

IdentityProvidersController.$inject = ['IdentityProvidersService', 'NotificationService', '$uibModal', '$confirm'];

export const identityProvidersComponent = {
    templateUrl: templateUrl,
    controller: IdentityProvidersController
}
