import requestMessageTemplate from './request/request.message.html';

(function () {
    'use strict';

    angular
        .module('kno2.records')
        .config(recordsRouteConfig)
        .run(recordsRouteRun);

    recordsRouteConfig.$inject = ['$stateProvider', '$urlRouterProvider'];

    function recordsRouteConfig($stateProvider, $urlRouterProvider) {
        $stateProvider
            .state('records', {
                abstract: true,
                url: '/records',
                parent: 'home',
                component: 'k2Records',
                data: { roles: ['Administrator', 'User'] }
            })
            .state('records.request', {
                url: '/request',
                component: 'k2RequestGrid'
            })
            .state('records.query', {
                url: '/query',
                template: '<ui-view><k2-query-grid /></ui-view>',
                data: {
                    feature: ['CarequalityUI', 'CarequalityQuery']
                }
            })
            .state('records.query.wizard', {
                url: '/new?test/:id',
                component: 'k2QueryWizard',
                params: { submit: null },
                resolve: {
                    history: ['$transition$', 'QueryService', function ($transition$, QueryService) {
                        if (!$transition$.params().id) return null;
                        return QueryService.getHistoryEntry($transition$.params().id);
                    }]
                }
            })
            .state('records.query.wizard.organizations', {
                url: '/organizations',
                views: {
                    requester: {
                        component: 'k2QueryRequesterReadonly'
                    },
                    step: {
                        component: 'k2QueryOrganizations'
                    }
                }
            })
            .state('records.query.wizard.patient', {
                url: '/patient',
                params: { submit: null },
                views: {
                    requester: {
                        component: 'k2QueryRequesterReadonly'
                    },
                    step: {
                        component: 'k2QueryPatient'
                    }
                }
            })
            .state('records.query.wizard.documents', {
                url: '/documents',
                views: {
                    requester: {
                        component: 'k2QueryRequesterReadonly'
                    },
                    step: {
                        component: 'k2QueryDocuments'
                    }
                }
            })
            .state('requestNew', {
                url: '/records/request/new',
                parent: 'home',
                templateUrl: requestMessageTemplate,
                controller: 'RequestCtrl',
                data: { roles: ['Administrator', 'User'] },
                reloadOnSearch: false,
                resolve: {
                    load: ['$q', '$location', 'SessionService',
                        function ($q, $location, sessionService) {
                            var defer = $q.defer();
                            var profile = sessionService.getProfile();

                            if (!profile.orgIsActivated) {
                                $location.path('/request');
                            } else {
                                defer.resolve();
                            }

                            return defer.promise;
                        }
                    ]
                }
            });

        $urlRouterProvider
            .when('/request', ['$state', function ($state) {
                $state.transitionTo('records.request');
            }])
            .when('/records', ['$state', 'FeatureService', function ($state, FeatureService) {
                return FeatureService.load().then(function () {
                    var state = FeatureService.isEnabled('CarequalityUI') ? 'records.query' : 'records.request';
                    return $state.transitionTo(state);
                });
            }]);
    }

    recordsRouteRun.$inject = ['$transitions'];

    function recordsRouteRun($transitions) {

        // Redirect to the start of the wizard if refreshed or hardlinked
        var criteria = { to: 'records.query.wizard.**' };
        $transitions.onBefore(criteria, function (transition) {
            if (transition.params().test) return;
            if (!transition.from().name && transition.to().name !== 'records.query.wizard') {
                return transition.router.stateService.target('records.query.wizard');
            }
        });
    }

})();


