angular.module('kno2.factories')
    .factory('ModalFactory', ['$timeout', '$uibModal',
        ($timeout, $uibModal) => {
            return {
                initialize: initialize,
                updatePagedItemCount: updatePagedItemCount,
                sort: sort,
                gotoPage: gotoPage,
                editRecord: editRecord,
                deleteRecord: deleteRecord
            };

            function initialize(settings) {
                // Set scope context
                var scope = settings.scope;
                scope.paging = settings.paging;

                // Keep references in the current scope.
                scope._edit = settings.edit;
                scope._remove = settings.remove;
                scope._searchCriteria =
                    settings.searchCriteria || scope.paging.sortBy; // searchCriteria used sortBy property name. 
                scope._service = settings.service;
                scope._service.callback = settings.service.callback || angular.noop;
                scope._getDataByPage = function (scope, page) {
                    // If page undefined, use currentPage.
                    if (page == undefined) {
                        var page = scope.paging.currentPage;
                    }
                    var pageSize = scope.paging.pagedItemRange.value;
                    var searchTerm = (scope.search != undefined) ? scope.search[scope._searchCriteria] : undefined;

                    scope.paging.currentPage = page;

                    // Call the Service instance.
                    scope._service.instance[scope._service.method](scope.paging.isSortDesc ? 'desc' : 'asc',
                        page,
                        pageSize,
                        searchTerm)
                        .then(function (data) {
                            scope[scope._service.response] = data[scope._service.response];
                            scope.paging.maxPage = Math.ceil(data[scope._service.response + 'Count'] /
                                scope.paging.pagedItemRange.value);
                            scope.paging.totalCount = data[scope._service.response + 'Count'];
                            scope.paging.pages = _.range(1, scope.paging.maxPage + 1);
                            return data;
                        }).then(scope._service.callback);
                };

                // Watch for search criteria changes.
                if (scope._searchCriteria != undefined) {
                    var searchTimeout;
                    scope.$watch('search.' + scope._searchCriteria,
                        function (searchTerm) {
                            if (searchTerm == undefined
                            ) return; // we want to allow the empty string but not undefined
                            if (searchTimeout) $timeout.cancel(searchTimeout);

                            searchTimeout = $timeout(function () {
                                scope._getDataByPage(scope, 1);
                            },
                                300);
                        });
                }
                // Read data after initialization completed.
                scope._getDataByPage(scope, 1);
            }

            function updatePagedItemCount(scope, pageValue) {
                scope.paging.pagedItemCount = pageValue;
                scope._getDataByPage(scope, 1);
            }

            function sort(scope) {
                scope.paging.isSortDesc = !scope.paging.isSortDesc;
                scope.paging.sortIconClass = scope.paging.isSortDesc ? 'fa fa-chevron-down' : 'fa fa-chevron-up';
                scope._getDataByPage(scope);
            }

            function gotoPage(scope, page) {
                if (page < 1)
                    page = 1;
                else if (page > scope.paging.maxPage) page = scope.paging.maxPage;

                scope.paging.currentPage = page;
                scope._getDataByPage(scope);
            }

            function editRecord(scope, data) {
                var modalInstance = $uibModal.open({
                    templateUrl: scope._edit.templateUrl,
                    controller: scope._edit.controller,
                    controllerAs: scope._edit.controllerAs,
                    resolve: { data: function () { return data; } }
                });

                modalInstance.result.then(function () {
                    // After a successful save, update the $scope
                    scope._getDataByPage(scope);
                },
                    function () {
                        // do something with dissResult
                    });
            }

            function deleteRecord(scope, data) {
                var modalInstance = $uibModal.open({
                    templateUrl: scope._remove.templateUrl,
                    controller: scope._remove.controller,
                    controllerAs: scope._remove.controllerAs,
                    resolve: { data: function () { return data; } }
                });

                modalInstance.result.then(function () {
                    // After a successful save, update the $scope.user
                    scope._getDataByPage(scope, 1);
                },
                    function () {
                        // do something with dissResult
                    });
            }
        }
    ]);
