angular.module('kno2.services')
    .service('AdminFaxApprovalService', ['$http', 'CacheService', function ($http, CacheService) {

    this.getFaxApprovals = function (sortDir, pageNumber, pageSize, searchTerm) {

        var searchKeyValue = (searchTerm == undefined || searchTerm == "") ? '' : '&search=' + searchTerm;
        var promise = $http.get('/api/admin/faxapprovals?' +
            'sortDir=' + sortDir +
            '&pageNumber=' + pageNumber +
            '&pageSize=' + pageSize +
            '&type=fax',
            searchKeyValue, { cache: CacheService.cacheL2() })
            .then(function (response) {
                return response.data;
            });
        return promise;
    };

    this.getFaxApproval = function (id) {
        var promise = $http.get('/api/admin/faxapprovals/' + id)
            .then(function (response) { return response.data; });
        return promise;
    };
 
    this.putFaxApproval = function (documentSource) {
        documentSource.directAddressType = 'fax';

        return $http.put('/api/admin/faxapprovals/' + documentSource.faxPortId, documentSource);
    };

    this.putFaxApprovalStatus = function (faxPortId, status) {
        var faxApprovalStatus = {
            faxPortId: faxPortId,
            faxPortComplete: status
        };
        return $http.put('/api/admin/faxapprovals/status', faxApprovalStatus);
    };

    this.putFaxApprovalDate = function (faxPortId, faxPortDate) {
        var faxApprovalDate = {
            faxPortId: faxPortId,
            faxPortDate: faxPortDate
        };
        return $http.put('/api/admin/faxapprovals/date', faxApprovalDate);
    };

    this.getFaxApprovalPdf = function(faxPortId) {
        return $http.get('/api/admin/faxapprovals/' + faxPortId + '/pdf/');
    }
}])