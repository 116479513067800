(function () {

    var service = function ($http) {
        this.getDefaultPageParameters = function () {
            return {
                pageNumber: 1,
                pageSize: 10
            };
        };

        this.getPageSizeOptions = function () {
            return [10, 25, 50, 100];
        };

        this.getPages = function (params) {
            var pageParams = _.extend({}, this.getDefaultPageParameters(), params);
            var promise = $http({
                url: '/api/licenses',
                method: 'GET',
                params: pageParams
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };

        this.getAvailable = function () {
            var promise = $http.get('/api/licenses/available').then(function (response) {
                return response.data;
            });
            return promise;
        };

        this.attachToOrganization = function (keys) {
            var promise = $http.post('/api/licenses/attach', keys).then(function (response) {
                return response.data;
            });
            return promise;
        };

        this.linkLicenseToApplication = function (licenseKey, applicationName) {
            var promise = $http.post('/api/connectors/', { licenseKey: licenseKey, applicationName: applicationName }).then(function (response) {
                return response.data;
            });
            return promise;
        };

        this.attachLicense = function (device, licenseKey) {
            var promise = $http.post('/api/connectors/' + device.id + '/license', { id: device.id, licenseKey: licenseKey }).then(function (response) {
                return response.data;
            });
            return promise;
        };

        this.detachLicense = function (device) {
            var promise = $http.delete('/api/connectors/' + device.id + '/license').then(function (response) {
                return response.data;
            });
            return promise;
        };
    };

    service.$inject = ['$http'];
    angular.module('kno2.settings').service('LicensesService', service);

}());
