import submitIdentityModalTemplate from './account.id-proofing.submit-identity-modal.html';
import questionsModalTemplate from './account.id-proofing.identity-questions-modal.html';
import decisionModalTemplate from './account.id-proofing.identity-decision-modal.html';
import tOSModalTemplate from './account.id-proofing.identity-terms-and-conditions-modal.html';
import welcomeModalTemplate from './account.id-proofing.identity-welcome-modal.html';
import identityVerificationModalTemplate from './account.id-proofing.identity-verification-modal.html';

IdProofingCtrl.$inject = ['$scope', '$uibModal', '$timeout', 'usersFactory', 'SessionService', 'NotificationService', 'IdProofingService'];
export function IdProofingCtrl($scope, $uibModal, $timeout, usersFactory, sessionService, notificationService, idProofingService) {
    $scope.userProfile = sessionService.getProfile();

    $scope.checkDuplicateAddress = function () {
        idProofingService.checkDuplicateAddress($scope.identity.currentAddress.addressLine);
    };

    $scope.openIdentityModal = function () {
        var identityModal = $uibModal.open({
            templateUrl: submitIdentityModalTemplate,
            windowClass: 'id-proofing-modal',
            controller: 'SubmitIdentityModalCtrl',
            keyboard: false,
            backdrop: 'static',
            resolve: {
                identity: function () {
                    return $scope.identity;
                }
            }
        });
        identityModal.result.then(function (identityResponse) {
            if (identityResponse.quizId) {
                // Got a quiz
                $scope.openQuizModal(identityResponse);
            } else {
                // Got a decision
                $scope.openDecisionModal(identityResponse);
            }
        });
    };

    $scope.openQuizModal = function (quiz) {
        var questionsModal = $uibModal.open({
            templateUrl: questionsModalTemplate,
            controller: 'IdentityQuestionsModalCtrl',
            windowClass: 'identity-questions-modal',
            backdrop: 'static',
            keyboard: false,
            resolve: { quiz: function () { return quiz; } }
        });
        questionsModal.result.then(function (answersResponse) {
            if (answersResponse.quizId) {
                // Got a quiz
                $scope.openQuizModal(answersResponse);
            } else {
                // Got a decision
                $scope.openDecisionModal(answersResponse);
            }
        });
    };

    $scope.openDecisionModal = function (completion) {
        var decisionModal = $uibModal.open({
            templateUrl: decisionModalTemplate,
            controller: 'IdentityDecisionModal',
            backdrop: 'static',
            keyboard: false,
            resolve: { completion: function () { return completion; } }
        });
        decisionModal.result.then(function () {
            sessionService.refreshProfile();
        }, function(error){
            
        });
    };

    $scope.openTermsAndConditionsModal = function () {
        var termsAndConditionsModal = $uibModal.open({
            windowClass: 'identity-terms-and-conditions-modal',
            templateUrl: tOSModalTemplate,
            controller: 'IdentityTermsAndConditionsModal',
            backdrop: 'static',
            keyboard: false
        });
        termsAndConditionsModal.result
            .then(() => {
                if (!$scope.userProfile.orgIsIdProofed) {
                    $scope.openWelcomeKno2Modal();
                }
            });
    };

    $scope.openResellerTosModal = function () {
        $uibModal.open({
            component: 'k2ResellerTosModal',
            backdrop: 'static',
            keyboard: false
        })
            .result
            .then(() => {
                if (!$scope.userProfile.orgIsIdProofed) {
                    $scope.openWelcomeKno2Modal();
                }
            });
    };

    $scope.openWelcomeKno2Modal = function () {
        var welcomeKno2Modal = $uibModal.open({
            windowClass: 'identity-welcome-kno2-modal',
            templateUrl: welcomeModalTemplate,
            controller: 'IdentityWelcomeKno2Modal',
            backdrop: 'static',
            keyboard: false
        });
        welcomeKno2Modal.result
            .then(function (response) {
                if (!$scope.userProfile.orgIsIdProofed) {
                    $scope.openIdentityVerificationModal();
                }
            });
    };

    $scope.openIdentityVerificationModal = function () {
        var welcomeKno2Modal = $uibModal.open({
            windowClass: 'identity-verification-modal',
            templateUrl: identityVerificationModalTemplate,
            controller: 'IdentityVerificationModal',
            backdrop: 'static',
            keyboard: false
        });
        welcomeKno2Modal.result
            .then(function (response) {
                if (!$scope.userProfile.orgIsIdProofed) {
                    $scope.openIdentityModal();
                }
            });
    };

    $scope.submitIdentity = function () {
        $scope.openIdentityModal();
    };

    // init
    (function () {
        // User is an admin and the org has no admins that have been ID Proofed
        idProofingService.getUserIdentity().then(function (res) {
            $scope.identity = res.data;
        });

        var action;
        if ($scope.userProfile.isAdmin) {
            if (!$scope.userProfile.orgIsAcceptedEULA) {
                action = $scope.userProfile.resellerBypassTos ? $scope.openResellerTosModal : $scope.openTermsAndConditionsModal;
            } else if (!$scope.userProfile.orgIsIdProofed) {
                if ($scope.userProfile.idProofedAttemptDate) {
                    action = function () {
                        return $scope.openDecisionModal({
                            decision: "N",
                            transactionKey: "",
                            overallScore: 0,
                            userIdentity: {},
                            reasons: []
                        });
                    }
                } else {
                    action = $scope.openWelcomeKno2Modal;
                }
            }
        }

        if (action) $timeout(action, 1000, false);
    })();
}
