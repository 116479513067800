ReceiveModalCtrl.$inject = ['$scope', '$uibModalInstance', 'SubscriptionService', 'SessionService'];
export function ReceiveModalCtrl($scope, $uibModalInstance, SubscriptionService, SessionService) {

    $scope.dismissReceiveModal = function () {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.oneAtATime = true;

    $scope.status = {
        isFirstOpen: true,
        isFirstDisabled: false
    };

    $scope.triageRulesClick = function (event) {
        if (SubscriptionService.checkAndShowPrompt('triageRoute'))
            event.preventDefault();
    }

    var init = function () {
        $scope.userProfile = SessionService.getProfile();
    };

    init();
}
