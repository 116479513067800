AttachmentPreviewModalCtrl.$inject = ['$uibModalInstance', '$scope', 'message', 'attachment'];

export function AttachmentPreviewModalCtrl($uibModalInstance, $scope, message, attachment) {
    var vm = this;

    $scope.message = message; // hack for now for read audit in viewer directive
    vm.attachment = attachment;
    vm.viewerOptions = {
        rotate: 'hidden',
        remove: 'hidden',
        print: 'hidden',
        download: 'hidden',
        zoom: 'auto',
        // old options
        height: -1,
        centerDocument: true
    };
    vm.dismiss = function () {
        $uibModalInstance.dismiss('cancel');
    };
}
