angular.module('kno2.factories')
    .factory('removeNonNumericFromFax', () => {
        return function (text) {
            if (text && text.indexOf('@') === -1) {
                return text.replace(/[^\d]/gi, '');
            } else {
                return text;
            }
        }
    });
