import { CommonModule } from '../common';
import { NetworksService } from './api/service-accounts/networks.service';
import { ServiceAccountServiceFactory } from './api/service-accounts/service-account-service.factory';
import { apiComponent } from './api/api.component';
import { ipAddressesComponent } from './ipaddresses/settings.ipaddresses.component';
import { IpAddressesService } from './ipaddresses/settings.ipaddresses.service';
import { ipaddressesWhitelistComponent } from './ipaddresses/whitelist/ipaddresses.whitelist.component';
import { ipAddressesUpsertModalComponent } from './ipaddresses/whitelist/ipaddresses.upsert.modal.component';
import { IdentityProvidersService } from './identityproviders/settings.identityproviders.service';
import { identityProvidersSettingsComponent } from './identityproviders/settings.identityproviders.component';
import { identityProvidersComponent } from './identityproviders/setup/identityproviders.component';
import { identityProvidersUpsertModalComponent } from './identityproviders/setup/identityproviders.upsert.modal.component';
import { apiLegacyComponent } from './api/api-legacy.component';
import { webhooksComponent } from './api/webhooks/webhooks.component';
import { webhooksUpsertModalComponent } from './api/webhooks/webhooks-upsert.modal.component';
import { webhooksDeleteModalComponent } from './api/webhooks/webhooks-delete.modal.component';
import { webhooksPayloadPreviewModalComponent } from './api/webhooks/webhooks-payload-preview.modal.component';
import { integrationsComponent } from './api/integrations/integrations.component';
import { integrationsUpsertModalComponent } from './api/integrations/integrations-upsert.modal.component';
import { serviceAccountipAddressesComponent } from './api/service-accounts/ip-addresses.component';
import { serviceAccountWizardComponent } from './api/service-accounts/service-account-wizard.component';
import { serviceAccountComponent } from './api/service-accounts/service-account.component';
import { clientCredentialsDeleteModalComponent } from './api/service-accounts/client-credentials-delete.modal.component';
import { clientCredentialsGenerateModalComponent } from './api/service-accounts/client-credentials-generate.modal.component';
import { integrationCapabilityFilter } from './api/integrations/integration-capability.filter';
import { settingsFaxAccountComponent } from './api/fax-account/fax-accounts.component';
import { settingsEFaxAccountComponent } from './api/efax-account/efax-accounts.component';
import { FaxAccountService } from './api/fax-account/fax-accounts.service';
import { EFaxAccountService } from './api/efax-account/efax-accounts.service';
import { CdaDocumentTypesService } from './cdadocumenttypes/cda-document-types.service';
import { cdaDocumentTypesComponent } from './cdadocumenttypes/cda-document-types.component';
import { subscriptionUpgradeComponent } from './subscription/settings.subscription.upgrade.component';

export const SettingsModule = angular
    .module('kno2.settings', [CommonModule])
    .service('NetworksService', NetworksService)
    .component('k2Api', apiComponent)
    .component('k2IpAddresses', ipAddressesComponent)
    .service('ipAddressesService', IpAddressesService)
    .component('k2IpaddressesWhitelist', ipaddressesWhitelistComponent)
    .component('k2IpAddressesUpsertModalComponent', ipAddressesUpsertModalComponent)
    .service('IdentityProvidersService', IdentityProvidersService)
    .component('k2IdentityProvidersSettings', identityProvidersSettingsComponent)
    .component('k2IdentityProviders', identityProvidersComponent)
    .component('k2IdentityProvidersUpsertModal', identityProvidersUpsertModalComponent)
    .component('k2ApiLegacy', apiLegacyComponent)
    .component('k2Webhooks', webhooksComponent)
    .component('k2WebhooksUpsertModal', webhooksUpsertModalComponent)
    .component('k2WebhooksDeleteModal', webhooksDeleteModalComponent)
    .component('k2WebhooksPayloadPreviewModal', webhooksPayloadPreviewModalComponent)
    .component('k2Integrations', integrationsComponent)
    .component('k2IntegrationsUpsertModal', integrationsUpsertModalComponent)
    .component('subscriptionUpgrade', subscriptionUpgradeComponent)
    .filter('integrationCapability', integrationCapabilityFilter)
    .service('FaxAccountService', FaxAccountService)
    .service('EFaxAccountService', EFaxAccountService)
    .component('k2FaxAccount', settingsFaxAccountComponent)
    .component('k2EFaxAccount', settingsEFaxAccountComponent)
    .service('CdaDocumentTypesService', CdaDocumentTypesService)
    .component('k2CdaDocumentTypes', cdaDocumentTypesComponent)
    .name;

angular.module('kno2.components')
    .factory('ServiceAccountServiceFactory', ServiceAccountServiceFactory)
    .component('k2ServiceAccountIpAddresses', serviceAccountipAddressesComponent)
    .component('k2ServiceAccountWizard', serviceAccountWizardComponent)
    .component('k2ServiceAccount', serviceAccountComponent)
    .component('k2ClientCredentialsDeleteModal', clientCredentialsDeleteModalComponent)
    .component('k2ClientCredentialsGenerateModal', clientCredentialsGenerateModalComponent);
