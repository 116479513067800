angular.module('kno2.filters')
    .filter('boolToYesNo', function() {

    	var formatBoolean = function (boolean) {
            return boolean ? "Yes" : "No";
        };

        return function(value) {
            return formatBoolean(value);
        };
    });
