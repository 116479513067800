angular.module('kno2.settings')
    .controller('ResendInvitationModalCtrl', ['$scope', '$uibModalInstance', 'user', 'usersFactory', 'NotificationService',
        function ($scope, $uibModalInstance, user, usersFactory, NotificationService) {
            $scope.user = user;
            $scope.resendInvitation = function () {
                usersFactory.resendInvitation($scope.user).then(function (res) {
                    NotificationService.success("Invitation has been sent");
                    $uibModalInstance.close($scope.user);
                }, function (res) {
                    NotificationService.serverError(res.data.modelState, "There was an error resending the invitation.");
                });
            };

            $scope.closeModal = function () {
                $uibModalInstance.dismiss($scope.user);
            };
        }
    ]);
