import emailFromToTemplate from './email-from-to.html';

angular.module('kno2.directives')
    .directive('emailFromTo', ['$templateCache', function ($templateCache) {
        return {
            restrict: 'E',
            scope: {
                'from': '@',
                'to': '@'
            },
            templateUrl: emailFromToTemplate
        };
    }])
