angular.module('kno2.directives')
    .directive('validateAddressPattern', ['ValidateFactory', function (ValidateFactory) {
        return {
            priority: 1,
            require: 'ngModel',
            link: function (scope, element, attrs, ctrl) {
                element.on('keyup blur', function () {
                    scope.$apply(function () {
                        const val = element.val();
                        const message = stringFormat("Direct Address contains invalid character.");
                        /* Pattern: ^[0-9a-zA-Z\.!#\$_%&/`'\|\{\?}=\*\+\^~-]+$
                                        0-9
                                        a-z
                                        A-Z
                            Special characters: \. ! # \$ _ % & / ` ' \| \{ \? } = \* \+ \^ ~ -*/
                        const allowSpecialChar = (typeof (attrs.validateAddressPatternAllow) !== 'undefined') ? attrs.validateAddressPatternAllow : '';
                        const pattern = "^[0-9a-zA-Z" + allowSpecialChar + "\.!#\$_%&/`'\|\{\?}=\*\+\^~-]+$";
                        const regEx = new RegExp(pattern);
                        const valid = (val.length === 0) || regEx.test(val);
                        ValidateFactory.setValidity(element, attrs, ctrl, "addresspattern", valid, message);
                    });
                });
            }
        };
    }])

    .directive('validateAddressConsecutivePeriod', ['ValidateFactory', function (ValidateFactory) {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ctrl) {
                element.on('keyup', function (evt) {
                    scope.$apply(function () {
                        const val = element.val();
                        const message = stringFormat("Direct Address can't have consecutive period character.");
                        const valid = (val.length === 0) ||
                                   ((val.length > 0) && (val.indexOf('..') === -1));
                        ValidateFactory.setValidity(element, attrs, ctrl, "addressconsecutiveperiod", valid, message);
                    });
                });
            }
        };
    }])

    .directive('validateAddressStartEndPeriod', ['ValidateFactory', function (ValidateFactory) {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ctrl) {
                element.on('keyup', function (evt) {
                    scope.$apply(function () {
                        const val = element.val();
                        const message = stringFormat("Direct Address can't start or end with period character.");
                        const valid = (val.length === 0) ||
                                   ((val.length > 0) && (val.indexOf('.') !== 0) && (val.lastIndexOf('.') !== val.length - 1));
                        ValidateFactory.setValidity(element, attrs, ctrl, "addressstartendperiod", valid, message);
                    });
                });
            }
        };
    }])
    .directive('validateFaxTextArea', [
        'ValidateFactory', '_', function (ValidateFactory, _) {
            return {
                require: 'ngModel',
                link: function (scope, element, attrs, ctrl) {
                    element.on('blur', function (evt) {
                        scope.$apply(function () {
                            const val = element.val();
                            const numbers = val.split(/\n+/);

                            let cleanedNumbers = [];
                            numbers.forEach(function (n) {
   
                                let c = n.replace(/\D/g, '');
                                if (c !== "") cleanedNumbers.push(c);
                            });

                            element.val(cleanedNumbers.join("\n"));

                            const message = stringFormat("Direct Address can't start or end with period character.");
                            ValidateFactory.setValidity(element, attrs, ctrl, "validateFaxTextArea", true, message);
                        });
                    });

                }
            };
        }
    ]);
;