import templateUrl from './account.activate.component.html';

class AccountActivateController {
    constructor($location, $state, AccountProfileService) {
        this.$location = $location;
        this.$state = $state;
        this.AccountProfileService = AccountProfileService;
    }

    $onInit() {
    }

    submit() {
        let model = {
            token: this.$location.search().t,
            password: this.password,
            confirmPassword: this.confirmPassword
        };
        this.AccountProfileService.activate(model).then(res => {
            this.$state.go('login');
        }, (err, res) => {
            const e = err.data.modelState[Object.keys(err.data.modelState)[0]][0];
            this.error = e;
        });
    }
}

AccountActivateController.$inject = ['$location', '$state', 'AccountProfileService'];

export const accountActivateComponent = {
    templateUrl: templateUrl,
    controller: AccountActivateController
};
