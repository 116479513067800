import settingsDirectAddressesFaxEditModalTemplate from './settings.direct-addresses.fax-edit-modal.html';
import settingsDirectAddressesFaxVerifyModalTemplate from './settings.direct-addresses.fax-verify-modal.html';

angular.module('kno2.settings')
    .controller('DirectAddressesEditModalCtrl', ['$timeout', '$filter', '$q', '$uibModalInstance', '$uibModal', '$confirm', 'data', 'DocumentSourcesService', 'NotificationService', 'UsersService', 'CommonData', 'DocumentSourceFaxService', '_', 'SessionService',
        function ($timeout, $filter, $q, $uibModalInstance, $uibModal, $confirm, data, DocumentSourcesService, NotificationService, UsersService, CommonData, DocumentSourceFaxService, _, SessionService) {
            const ctrl = this;

            ctrl.userProfile = data.userProfile;
            ctrl.setForm = function (form) { ctrl.form = form; };
            ctrl.cancel = function () { $uibModalInstance.dismiss('cancel'); };
            ctrl.nameRegex = "^[A-Za-z]+['-]*[A-Za-z]+$";

            ctrl.addFax = addFax;
            ctrl.editFax = editFax;
            ctrl.verifyFax = verifyFax;
            ctrl.deleteFax = deleteFax;
            ctrl.setPrimary = setPrimary;
            ctrl.addAll = addAll;

            ctrl.$onInit = init;

            ctrl.directAddressTypeChanged = function () {
                // Clear provider specific fields if not provider type 
                if (ctrl.documentSource && ctrl.documentSource.directAddressType !== 'provider') {
                    ctrl.documentSource.npi = null;
                    ctrl.documentSource.providerFirstName = null;
                    ctrl.documentSource.providerLastName = null;
                } else {
                    $timeout(function () {
                        $("input[name*='NPI']").focus().select();
                    }, 500, false);
                }
            };

            ctrl.saveChanges = function () {
                ctrl.formSubmitted = true;

                var users = [];
                _.each(ctrl.documentSource.users,
                    function (user) {
                        users.push(user);
                    });
                ctrl.documentSource.users = users;

                // Save changes if form valid
                if (ctrl.form.$valid) {
                    var functionName = ctrl.documentSource.id ? 'putDocumentSource' : 'postDocumentSource';
                    DocumentSourcesService[functionName](ctrl.documentSource)
                        .then(function () {
                            NotificationService.success("The direct address was saved successfully.");
                            $uibModalInstance.close(ctrl.documentSource);
                        },
                            function (response) {
                                NotificationService.serverError(response.data.modelState, "There was an error saving the direct address.");
                            });
                } else {
                    _(ctrl.form.$error.required).forEach(function (value) {
                        // Triggers the red exlamation point (Focus input, swich to another input, switch back).
                        $timeout(function () { $("input[name*='" + value.$name + "']").focus().select(); }, 250, false);
                        $timeout(function () { $("input[name*='address']").focus().select(); }, 250, false);
                        $timeout(function () { $("input[name*='" + value.$name + "']").focus().select(); }, 250, false);
                    });

                }
            };

            // This provides the necessary call information for the k2-unique directive.
            ctrl.documentSourceExists = function () {
                return {
                    serviceFunction: DocumentSourcesService.isAddressExists,
                    errorMessage: "This direct address already exists, please enter a different one."
                };
            };

            function addAll() {
                if (!ctrl.documentSource.users)
                    ctrl.documentSource.users = [];
                _.each(ctrl.organizationUsers,
                    function (user) {
                        ctrl.documentSource.users.push(user);
                    });
            }

            function init() {
                ctrl.restrictSendPermissions = SessionService.hasUIResourceAction('directAddressesSendPermissionsInput', 'disable');

                DocumentSourcesService.getAddressTypes().then(function (response) {
                    ctrl.directAddressTypes = response;
                });
                UsersService.getOrgUsers(false)
                    .then(function (response) {
                        ctrl.organizationUsers = response;

                        // Get the Document Sources
                        if (data.id) {
                            DocumentSourcesService.getDocumentSource(data.id)
                                .then(function (response) {
                                    ctrl.documentSource = response;
                                    ctrl.documentSource.directAddressType = ctrl.documentSource.npi ? 'provider' : 'organization';

                                    // Set selected Users
                                    var users = ctrl.documentSource.users;
                                    ctrl.documentSource.users = [];
                                    if (users) {
                                        _.each(users, function (x) {
                                            var u = _.find(ctrl.organizationUsers, { id: x.id });
                                            if (u) {
                                                ctrl.documentSource.users.push(u);
                                            }
                                        });
                                    };

                                    if (ctrl.restrictSendPermissions) {
                                        ctrl.organizationUsers = [];
                                    }

                                    getFaxNumbers();
                                });
                        } else {
                            ctrl.documentSource = {
                                directAddressType: 'organization',
                                type: 'DirectMessage',
                            };
                        }
                        $timeout(function () {
                            $("input[name*='address']").focus().select();
                        }, 500, false);
                    });
            };

            function addFax() {

                $uibModal
                    .open({
                        templateUrl: settingsDirectAddressesFaxEditModalTemplate,
                        controller: 'DirectAddressesFaxModalCtrl',
                        controllerAs: '$ctrl',
                        resolve: {
                            documentSourceId: function () {
                                return data.id;
                            },
                            faxNumber: function () {
                                return null;
                            },
                            faxNumbers: function () {
                                return ctrl.faxNumbers;
                            }
                        }
                    })
                    .result.then(getFaxNumbers);

            }

            function editFax(id) {

                var faxNumber = _.find(ctrl.faxNumbers, { id: id });

                $uibModal
                    .open({
                        templateUrl: settingsDirectAddressesFaxEditModalTemplate,
                        controller: 'DirectAddressesFaxModalCtrl',
                        resolve: {
                            documentSourceId: function () {
                                return data.id;
                            },
                            faxNumber: function () {
                                return _.clone(faxNumber);
                            }
                        }
                    })
                    .result.then(getFaxNumbers);
            }

            function verifyFax(id) {

                var faxNumber = _.find(ctrl.faxNumbers, { id: id });

                $uibModal
                    .open({
                        templateUrl: settingsDirectAddressesFaxVerifyModalTemplate,
                        controller: 'DirectAddressesFaxVerifyModalCtrl',
                        controllerAs: '$ctrl',
                        resolve: {
                            documentSourceId: function () {
                                return data.id;
                            },
                            faxNumber: function () {
                                return _.clone(faxNumber);
                            }
                        }
                    })
                    .result.then(getFaxNumbers);
            }

            function deleteFax(id) {

                var faxNumber = _.find(ctrl.faxNumbers, { id: id });

                $confirm
                    .open({
                        title: 'Remove Fax Number',
                        bodyText: 'Are you sure you want to remove ' + $filter('telephone')(faxNumber.number) + '?'
                    })
                    .result.then(function () {
                        DocumentSourceFaxService.removeFaxNumber(data.id, id)
                            .then(function () {
                                NotificationService.success("The fax number was removed.");
                            }, function (response) {
                                if (response.status === 400)
                                    NotificationService.serverError(response.data.modelState, response.data);
                                else
                                    NotificationService.error("An error occurred while trying to remove the fax number, please try again.");

                                return $q.reject('error');
                            })
                            .then(getFaxNumbers);
                    });
            }

            function setPrimary(id) {
                DocumentSourceFaxService.setPrimaryFaxNumber(data.id, id);
            }

            function getFaxNumbers() {
                return DocumentSourceFaxService.getFaxNumbers(data.id)
                    .then(function (faxNumbers) {
                        ctrl.faxError = false;
                        ctrl.faxNumbers = faxNumbers;
                        ctrl.primaryFax = _.result(_.find(faxNumbers, { primary: true }), 'id');
                    }, function () {
                        ctrl.faxError = true;
                    });
            }
        }
    ]);
