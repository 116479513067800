TriageUploadModalCtrl.$inject = ['$scope', '$q', '$uibModalInstance', 'TriageHttpService', 'NotificationService', '_', 'moment', 'rules', 'whitelist'];

export function TriageUploadModalCtrl($scope, $q, $uibModalInstance, TriageHttpService, NotificationService, _, moment, rules, whitelist) {
    var vm = this;

    vm.select = select;
    vm.upload = upload;
    vm.triage = triage;
    vm.cancel = cancel;
    vm.uploading = false;
    vm.hasFiles = null;
    vm.removeFile = removeFile;
    vm.rules = rules;
    vm.whitelist = whitelist;
    vm.totalSize = function () { return _(vm.request.files).map('size').sum(); };
    vm.request = {
        name: 'Upload ' + moment().format('M/D/YYYY h:mma'),
        files: []
    };

    activate();

    function activate() {
        vm.rules.splice(0, 0, { id: 0, name: 'My Intake' });
        vm.request.ruleId = vm.rules[0].id;
    }

    function select(files) {
        angular.forEach(files, function (file) {
            var extension = file.name.split('.').pop();
            if (!extension || file.name === extension)
                NotificationService.errorToaster('Files without an extension are not allowed.');
            else if (!_.includes(whitelist, '.' + extension.toLowerCase()))
                NotificationService.errorToaster('Files with the extension, .' + extension + ', are not allowed.');
            else
                vm.request.files.push(file);
        });
        onFilesChange();
    }

    function removeFile(index) {
        vm.request.files.splice(index, 1);
        onFilesChange();
    }

    function onFilesChange() {
        vm.hasFiles = vm.request.files.length ? true : null;
        if (vm.request.files.length < 2) vm.request.multiple = false;
    }

    function upload() {
        vm.uploading = true;

        go().then(function (id) {
            $uibModalInstance.close(id);
        });
    }

    function triage() {
        vm.triaging = true;

        go().then(function (id) {
            TriageHttpService.show(id);
        });
    }

    function cancel() {
        $uibModalInstance.dismiss('cancel');
    }

    function go() {
        return validate().then(function () {
            return TriageHttpService.upload(vm.request)
                .then(function (id) {
                    NotificationService.success('Batch has been uploaded.');
                    return id;
                })
                .catch(function (response) {
                    if (response.status === 400)
                        NotificationService.serverError(response.data.modelState, 'The file(s) failed to upload.');
                    else
                        NotificationService.error("An error occurred when uploading the batch.");
                    return $q.reject();
                });
        })
            .catch(function (message) {
                if (message) NotificationService.error(message);
                return $q.reject();
            })
            .finally(function () {
                vm.triaging = false;
                vm.uploading = false;
            });
    }

    function validate() {
        return $q(function (resolve, reject) {
            var size = vm.totalSize();
            if (size > 20000000) return reject('The attached file(s) exceed the total upload size limit.');
            return resolve();
        });
    }
}
