import settingsBillingCancelSubscriptionModalTemplate from './settings.billing.cancel-subscription-modal.html';
import reactivateOrganizationModalTemplate from '../../account/organization/organization.reactivate-organization-modal.html';

angular.module('kno2.settings')
    .controller('SettingsBillingCtrl', ['$scope', '$uibModal', '$timeout', 'SessionService', 'SubscriptionsFactory', 'NotificationService', 'SpinnerService', '$rootScope', '$window',
        function ($scope, $uibModal, $timeout, SessionService, SubscriptionsFactory, NotificationService, SpinnerService, $rootScope, $window) {

            $scope.userProfile = SessionService.getProfile();

            $scope.newPlan = {};

            $scope.cancelSubscription = function () {
                var cancelModal = $uibModal.open({
                    templateUrl: settingsBillingCancelSubscriptionModalTemplate,
                    controller: 'CancelSubscriptionModalCtrl'
                });
                cancelModal.result.then(function () {
                    $rootScope.logoff();
                }, () => { });
            };

            var refreshSubscription = function () {
                SubscriptionsFactory.get()
                    .then(function (res) {
                        $scope.plan = res.data;
                        if ($scope.plan.planId == null) {
                            $scope.plan.planId = -1;
                        }

                        $scope.plan.upgradePlanId = $scope.plan.planId;
                        $scope.plan.originalPlanId = $scope.plan.planId;

                        $scope.planChanged();
                    });
            };

            var openFastspringTab = function (url, data) {
                var submitForm = $("<form target='_blank' method='POST' style='display:none;'></form>").attr({
                    action: url
                }).appendTo(document.body);

                for (var i in data) {
                    if (data.hasOwnProperty(i)) {
                        $('<input type="hidden" />')
                            .attr({
                                name: i,
                                value: data[i]
                            })
                            .appendTo(submitForm);
                    }
                }

                submitForm.submit();

                submitForm.remove();
            };

            $scope.resetPlanSelection = function () {
                openFastspringTab($scope.newPlan.fastSpringUrl,
                    {
                        product_1_path: $scope.newPlan.urlPathName,
                        referrer: $scope.newPlan.referrer,
                        contact_fname: $scope.userProfile.firstName,
                        contact_lname: $scope.userProfile.lastName,
                        contact_email: $scope.userProfile.userName,
                        contact_phone: $scope.userProfile.phoneNumber,
                        contact_company: $scope.userProfile.organizationName
                    });

                SpinnerService.start();
                $scope.fastspringSubmitted = true;
                $scope.verifyPayment(true);
            };

            $scope.verifyPayment = function (keepLooping) {
                SubscriptionsFactory.get()
                    .then(function (res) {
                        $scope.confirmedPlan = res.data;
                        if ($scope.confirmedPlan.planId == $scope.plan.upgradePlanId && !!$scope.confirmedPlan.subscriptionUrl) {
                            refreshSubscription();
                            $scope.fastspringSubmitted = false;
                            SpinnerService.stop();
                            SessionService.refreshProfile();
                        } else {
                            if (keepLooping) {
                                $timeout(function () {
                                    $scope.verifyPayment(true);
                                }, 1500, false);
                            } else {
                                NotificationService.error("Unable to verify payment was completed");
                            }
                        }
                    });
            };

            $scope.planChanged = function () {
                $scope.plan.upgradePlanId = $scope.plan.planId;

                var upgradePlan = parseInt($scope.plan.upgradePlanId, 10);
                var newPlan = _.find($scope.plan.plans, { id: upgradePlan });

                $scope.newPlan.fastSpringUrl = $scope.plan.fastSpringUrl + newPlan.urlPathName + $scope.plan.fastSpringParams;
                $scope.newPlan.urlPathName = "/" + newPlan.urlPathName;
                $scope.newPlan.referrer = $scope.plan.orgId + "#" + upgradePlan + "#" + $scope.userProfile.fastSpringDomain;

                $scope.newPlan.isFreePlan = newPlan.id === -1;
            };

            $scope.updateSubscription = function () {
                var payedSubscription = $scope.userProfile.subscriptionReference;
                var updateToFreePlan = $scope.newPlan.isFreePlan;

                if (!payedSubscription && !updateToFreePlan) {
                    $scope.resetPlanSelection();
                } else {
                    $scope.upgradeSubscription();
                }
            };

            $scope.upgradeSubscription = function () {
                var upgradePlan = parseInt($scope.plan.upgradePlanId, 10);
                var newPlan = _.find($scope.plan.plans, { id: upgradePlan });
                if (newPlan) {
                    // Validate number of users before update
                    if (newPlan.allowedUsers != -1 && newPlan.allowedUsers < $scope.plan.totalUsersCount) {
                        NotificationService.error(stringFormat('Selected subscription plan allows a maximum of {0} users and you currently have {1} users defined. Please select a valid subscription plan to match the actual utilization.',
                            newPlan.allowedUsers, $scope.plan.totalUsersCount));
                    } else {
                        // Update Subscription
                        SubscriptionsFactory.update(null, $scope.plan.upgradePlanId).then(function () {
                            // success
                            NotificationService.success("Subscription plan has been updated.");
                            SessionService.refreshProfile();
                            refreshSubscription();
                        },
                            function (res) {
                                //failure
                                NotificationService.serverError(res.data.modelState, "There was an error updating your subscription plan.");
                            });
                    }
                }
            };

            $scope.openReactivationModal = function () {
                var reactivationModal = $uibModal.open({
                    templateUrl: reactivateOrganizationModalTemplate,
                    controller: 'ReactivateOrgModalCtrl',
                    keyboard: false,
                    backdrop: 'static',
                    resolve: { currentPlan: function () { return $scope.currentPlan; } }
                });
                reactivationModal.result.then(function () {
                    NotificationService.success("Organization successfully reactivated.");
                    SessionService.refreshProfile();
                });
            };

            (function () {

                if (!$scope.userProfile.orgIsCancelled && $scope.userProfile.orgIsInGracePeriod) {
                    if ($scope.userProfile.subscriptionReference != null) {
                        SubscriptionsFactory.fastspring($scope.userProfile.organizationId)
                            .then(function (response) {
                                $scope.currentPlan = response.data;
                                $timeout($scope.openReactivationModal, 500, false);
                            });
                    } else {
                        $timeout($scope.openReactivationModal, 500, false);
                    }
                }

                $scope.allowAllSubscriptions = true;      // Enabled all subscriptions selection from template.
                refreshSubscription();
            }());
        }
    ]);
