import templateUrl from './query.requester.component.html'

angular
    .module('kno2.records')
    .component('k2QueryRequester', {
        controller: QueryRequesterController,
        templateUrl,
        require: {
            wizard: '^^k2QueryWizard'
        }
    });

QueryRequesterController.$inject = ['$timeout', 'SessionService', 'QueryService', 'NotificationService', '_'];

function QueryRequesterController($timeout, SessionService, QueryService, NotificationService, _) {
    var ctrl = this;

    ctrl.$onInit = $onInit;

    function $onInit() {
        ctrl.requester = ctrl.wizard.query.requester;

        const profile = SessionService.getProfile();

        if (profile.job.id)
            ctrl.requester.role = { code: profile.job.roleCode };
        else
            $timeout(() => NotificationService.error('"Primary Job Function" is not set. Please ask an administrator to set the "Primary Job Function" for your user.'));

        if (!ctrl.requester.fullName) {
            ctrl.requester.fullName = profile.firstName + ' ' + profile.lastName;
        }

        QueryService.getPurposesOfUse().then(function (purposes) {
            ctrl.purposes = purposes;

            if (!purposes.length)
                NotificationService.error('"Purpose of Use" has not been configured for this organization. Please contact Support.');

            if (ctrl.requester.purpose) {
                ctrl.requester.purpose = _.find(purposes, function (p) {
                    return p.code === ctrl.requester.purpose.code;
                });
            }

            if (!ctrl.requester.purpose && purposes.length === 1) {
                ctrl.requester.purpose = purposes[0];
            }
        });
    }
}
