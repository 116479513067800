import templateUrl from './wizard.component.html';

angular
    .module('kno2.components')
    .component('k2Wizard', {
        transclude: true,
        controller: WizardController,
        templateUrl
    });

WizardController.$inject = [];

function WizardController() {
    var ctrl = this;

    ctrl.steps = [];
    ctrl.progress = 0;
    ctrl.addStep = addStep;
    ctrl.activeUpdated = activeUpdated;

    function addStep(step) {
        ctrl.steps.push(step);
        activeUpdated();
    }

    function activeUpdated() {

        var index = _.findLastIndex(ctrl.steps, function (step) {
            return step.active;
        });

        if (index === -1) {
            ctrl.progress = 0;
            return;
        }

        _.each(ctrl.steps, function (step, i) {
            if (i < index)
                step.passiveActive = true;
            else
                step.passiveActive = false;
        });

        ctrl.progress = (index + 1) / ctrl.steps.length;
    }
}
