SupportContactController.$inject = ['$state', 'CommonData', 'SessionService', 'SupportService', 'NotificationService'];

export function SupportContactController($state, CommonData, SessionService, SupportService, NotificationService) {
    var ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.submitTicket = submitTicket;
    ctrl.toggle = toggle;
    ctrl.placeholder = "DISCLAIMER – This system is provided for support and general customer communication only. Do not send or transmit through this system any information, including protected health information, that is protected under the Health Insurance Portability and Accountability Act of 1996, as amended (HIPAA), or any other applicable law. If you have uploaded any such information, you must remove it immediately.";

    function $onInit() {
        ctrl.supportTicketAreas = CommonData.supportTicketAreas.asArray();
        ctrl.supportTicketSeverities = CommonData.supportTicketSeverities.asArray();
        ctrl.profile = SessionService.getProfile();
        resetTicket();
    }

    function toggle(state) {
        if ($state.is(state))
            $state.go('support.default');
        else
            $state.go(state);
    }

    function resetTicket() {
        ctrl.ticket = {
            area: null,
            fullName: ctrl.profile.fullName,
            emailAddress: ctrl.profile.userName,
            issueText: null,
            userAgent: null
        };
        if (ctrl.form) ctrl.form.$setPristine();
    };

    function submitTicket() {

        if (ctrl.form.$invalid)
            return;

        // Get Info from userAgent
        var ua = new UAParser().getResult();
        ctrl.ticket.userAgent = {
            browser: ua.browser,
            engine: ua.engine,
            os: ua.os
        };

        SupportService.submitTicket(ctrl.ticket)
            .then(function () {
                NotificationService.success("The support ticket was submitted successfully.");
                resetTicket();
            },
            function (response) {
                NotificationService.serverError(response.data.modelState, "There was an error saving this support ticket.");
            });
    };
}
