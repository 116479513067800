(function () {
    'use strict';

    angular
        .module('kno2.filters')
        .filter('counter', () => {
            return (total, limit) =>
                   (total > limit) ? `${limit}+` : total;
        })
})();
