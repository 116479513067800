SupportService.$inject = ['$http'];

export function SupportService($http) {
    this.submitTicket = function(ticket) {
        return $http.post('/api/support/ticket/', ticket);
    };

    this.sendPlanUpgradeRequest = function () {
        var promise = $http.post('/api/support/plan/upgrade/request');
        return promise;
    };
}

