import templateUrl from './settings.subscription.upgrade.html';

export const subscriptionUpgradeComponent = {
    templateUrl: templateUrl,
    bindings: {
        plan: '=',
        allowAllSubscriptions: '=',
        planChanged: '&',
    }
};
