
import gettingStartedModalTemplate from './getting-started/account.dashboard.getting-started-modal.html'
import newFeaturesModalTemplate from './new-features/account.dashboard.new-features-modal.html';
import optimizeReceivingModalTemplate from './optimize-receiving/account.dashboard.optimize-receiving-modal.html';
import optimizeSendingModalTemplate from './optimize-sending/account.dashboard.optimize-sending-modal.html';
import userInvitationModalTemplate from '../../common/user-invitation/account.dashboard.user-invitation-modal.html';
import recentActivityModalTemplate from './recent-activity/account.dashboard.recenty-activity-modal.html';
import dashboardAlertModalTemplate from './alerts/account.dashboard.alert-modal.html';

DashboardCtrl.$inject = ['$scope', '$http', '$filter', '$timeout', '$interval', 'IntakeService', 'DashboardService', 'AlertsService', 'EventService', 'SessionService', '$sce', '$uibModal', '_'];
export function DashboardCtrl($scope, $http, $filter, $timeout, $interval, IntakeService, DashboardService, AlertsService, EventService, sessionService, $sce, $uibModal, _) {
    // Chart Data 
    $scope.chartData = [];
    $scope.chartDataTicks = [];
    $scope.chartDataSummary = [];
    $scope.recentActivities = [];
    $scope.recentActivitiesTop = 7;
    $scope.statsInterval = null;
    $scope.activityInterval = null;

    // Date selector 
    $scope.dateTo = new moment().toDate();
    $scope.dateFrom = new moment($scope.dateTo).subtract(13, 'days').toDate();

    $scope.displayGetStartedModal = function () {
        var modal = $uibModal.open({
            windowClass: 'get-started-modal',
            templateUrl: gettingStartedModalTemplate,
            controller: 'DisplayGetStartedModal'
        });
        modal.result.then(function(result){
            //success
        }, function(error){
            //error
        });
    };

    $scope.displayNewFeaturesAndUpdates = function () {
        var modal = $uibModal.open({
            templateUrl: newFeaturesModalTemplate,
            controller: 'NewFeaturesAndUpdatesModalCtrl'
        });
        modal.result.then(function(success){

        }, function(error){

        });
    };

    $scope.displayReceiveModal = function () {
        var modal = $uibModal.open({
            templateUrl: optimizeReceivingModalTemplate,
            controller: 'ReceiveModalCtrl'
        });
        modal.result.then(function(success){

        }, function(error){

        });
    };

    $scope.displaySendModal = function () {
        var modal = $uibModal.open({
            templateUrl: optimizeSendingModalTemplate,
            controller: 'SendModalCtrl'
        });
        modal.result.then(function(success){

        }, function(error){

        });
    };

    $scope.displayInviteModal = function () {
        $uibModal.open({
            templateUrl: userInvitationModalTemplate,
            controller: 'UserInvitationModalCtrl'
        });
        modal.result.then(function(success){

        }, function(error){

        });
    };

    $scope.displayActivityReportModal = function () {
        $uibModal.open({
            templateUrl: recentActivityModalTemplate,
            controller: 'DashboardRecentActivityModalCtrl',
            windowClass: 'modal-500'
        }).result.then(function(success){

        }, function(error){

        });;
    };

    $scope.dashboardService = DashboardService;

    $scope.onFreePlan = $scope.hasUIResourceAction('releaseSendButton', 'planUpgradePrompt');

    $scope.finished = function () {
        $scope.allChecked = false;
        $scope.dashboardService.allChecked = false;
        _.each($scope.checklistValues, function (value, key) {
            if ($scope.onFreePlan == true && key == 'setupReleaseTypes') {
                // do nothing
            } else if (value == false) {
                $scope.allChecked = true;
                $scope.dashboardService.allChecked = true;
            }
        });
    }

    $scope.getChecklistValues = function (orgId) {
        DashboardService.getChecklistValues(orgId)
            .then(function (response) {
                $scope.checklistValues = response;
                $scope.finished();
            });
    };

    var alertIconClassInternal = function (priority) {
        if (priority == 1) return 'alert-danger';
        else if (priority == 2) return 'alert-warning';
        else if (priority == 3) return 'alert-info';
        else return '';
    };

    $scope.alertIconClass = function (priority) {
        return alertIconClassInternal(priority);
    };

    var smartTrim = function (str, length, delim, appendix) {
        if (str.length <= length) return str;

        var trimmedStr = str.substr(0, length + delim.length);

        var lastDelimIndex = trimmedStr.lastIndexOf(delim);
        if (lastDelimIndex >= 0) trimmedStr = trimmedStr.substr(0, lastDelimIndex);

        if (trimmedStr) trimmedStr += appendix;
        return trimmedStr;
    }

    $scope.showAlertModal = function (alertId) {
        AlertsService.getAlert(alertId).then(
            function (data) {
                var showAlertModal = $uibModal.open({
                    windowClass: 'show-alert-modal',
                    templateUrl: dashboardAlertModalTemplate,
                    controller: 'ShowAlertModal',
                    resolve: {
                        data: function () { return data; },
                        alertIconClass: function () { return alertIconClassInternal(data.priority); },
                    }
                });
                showAlertModal.result.then(function (response){

                }, function(error){

                });
            });
    };

    $scope.openAlertTextModal = function (alertId) {
        $scope.showAlertModal(alertId);
    };

    $scope.formatAlertText = function (alertId, alertText) {
        var value = smartTrim(alertText, 200, ' ', ' ...');
        return $sce.trustAsHtml(value);
    };

    // Prepare chart data from Json Response.
    $scope.prepareChartData = function () {
        // Initialization
        var chartData = [], dataSent = [], dataReceived = [], dataFailure = [], dataTics = [],
            chartDataSummary = {};

        // Get color from CSS
        var receiveLineColor = new Array($('.dashboard-chart-received-line').css('color'), $('.dashboard-chart-received-line-fill').css('color'));
        var sentLineColor = new Array($('.dashboard-chart-sent-line').css('color'), $('.dashboard-chart-sent-line-fill').css('color'));
        var failedLineColor = new Array($('.dashboard-chart-failed-line').css('color'), $('.dashboard-chart-failed-line-fill').css('color'));

        // Build Chart Data from Json object.
        chartDataSummary.Sent = 0;
        chartDataSummary.SentSize = 0;
        chartDataSummary.SentAttachments = 0;
        chartDataSummary.SentFailures = 0;

        chartDataSummary.Received = 0;
        chartDataSummary.ReceivedSize = 0;
        chartDataSummary.ReceivedAttachments = 0;
        chartDataSummary.ReceivedUnprocessed = 0;

        var obj = $scope.messageStatistics;
        for (var i = 0; i < obj.length; i++) {
            // Chart entries
            var momentDate = moment(obj[i].statisticsDate.replace('T', ' '));
            var tooltip = '<strong>{0}</strong> message(s) <strong>{1}</strong><br/>';
            var tooltipDate = 'on ' + momentDate.format("dddd, MMMM Do YYYY");
            var tooltipSent = stringFormat(tooltip, obj[i].sentCount, 'sent') + tooltipDate;
            var tooltipReceived = stringFormat(tooltip, obj[i].receivedCount, 'received') + tooltipDate;
            var tooltipFailure = stringFormat(tooltip, obj[i].sentFailureCount, 'sent failed') + tooltipDate;
            var tics = (i % 2 == 1) ? momentDate.format('MM/DD') : "";

            dataTics.push([i, tics]);
            dataSent.push([i, obj[i].sentCount, tooltipSent, 50]);
            dataReceived.push([i, obj[i].receivedCount, tooltipReceived, 50]);
            dataFailure.push([i, obj[i].sentFailureCount, tooltipFailure, 70]);

            // Chart Summary Counts
            chartDataSummary.Sent += obj[i].hasOwnProperty('sentCount') ? obj[i].sentCount : 0;
            chartDataSummary.SentSize += obj[i].hasOwnProperty('sentSize') ? obj[i].sentSize : 0;
            chartDataSummary.SentAttachments += obj[i].hasOwnProperty('sentAttachmentCount') ? obj[i].sentAttachmentCount : 0;
            chartDataSummary.SentFailures += obj[i].hasOwnProperty('sentFailureCount') ? obj[i].sentFailureCount : 0;
            chartDataSummary.Received += obj[i].hasOwnProperty('receivedCount') ? obj[i].receivedCount : 0;
            chartDataSummary.ReceivedSize += obj[i].hasOwnProperty('receivedSize') ? obj[i].receivedSize : 0;
            chartDataSummary.ReceivedAttachments += obj[i].hasOwnProperty('receivedAttachmentCount') ? obj[i].receivedAttachmentCount : 0;
            chartDataSummary.ReceivedUnprocessed += obj[i].hasOwnProperty('receivedMessagesUnprocessedCount') ? obj[i].receivedMessagesUnprocessedCount : 0;
        }
        chartDataSummary.AvgSentSize = (chartDataSummary.Sent > 0) && (chartDataSummary.SentSize > 0) ? chartDataSummary.SentSize / chartDataSummary.Sent : 0;
        chartDataSummary.AvgReceivedSize = (chartDataSummary.Received > 0) && (chartDataSummary.ReceivedSize > 0) ? chartDataSummary.ReceivedSize / chartDataSummary.Received : 0;
        var totalSentReceived = chartDataSummary.Sent + chartDataSummary.Received;
        var totalSentReceivedSize = chartDataSummary.SentSize + chartDataSummary.ReceivedSize;
        chartDataSummary.AvgSize = (totalSentReceived > 0) && (totalSentReceivedSize > 0) ? (totalSentReceivedSize / totalSentReceived) / 1048576 : 0;
        $scope.chartDataSummary = chartDataSummary;

        // Add Chart Data and options (color, points, etc...)
        chartData.push({
            data: dataSent,
            label: "Messages Sent",
            color: sentLineColor[0],
            lines: { fillColor: sentLineColor[1], fill: true, show: true },
            points: { show: true }
        });
        chartData.push({
            data: dataReceived,
            label: "Messages Received",
            color: receiveLineColor[0],
            lines: { fillColor: receiveLineColor[1], fill: true, show: true },
            points: { show: true }
        });
        chartData.push({
            data: dataFailure,
            label: "Message Failures",
            color: failedLineColor[0],
            lines: { fillColor: failedLineColor[1], fill: true, show: true },
            points: { show: true }
        });
        // Update scope
        $scope.chartData = chartData;
        $scope.chartDataTicks = dataTics;
    };

    // Get Message Statistics used for the Chart.
    $scope.getMessageStatistics = function () {
        DashboardService.getMessageStatistics($scope.dateFrom, $scope.dateTo)
            .then(function (response) {
                $scope.messageStatistics = response;
                $scope.prepareChartData();
            });
    };

    // Get the recent actitities collection
    $scope.getRecentActivities = function () {
        EventService.getRecentEvents($scope.recentActivitiesTop)
            .then(function (events) {
                $scope.recentActivities = events;
            });
    };

    $scope.redirectToIntake = function () {
        if ($scope.userProfile.orgIsActivated) {
            IntakeService.redirectToIntakeView();
        }
    };

    // Controller Initialization
    $scope.init = async function () {
        $scope.userProfile = sessionService.getProfile();
        if (_.isEmpty($scope.userProfile)) {
            $scope.userProfile = await sessionService.refreshProfile();
        }
        $scope.getMessageStatistics();
        $scope.statsInterval = $interval($scope.getMessageStatistics, 240000);
        $scope.getRecentActivities();
        $scope.activityInterval = $interval($scope.getRecentActivities, 240000);
        $scope.getChecklistValues($scope.userProfile.organizationId);
    };

    // Initial load. 
    $scope.init();

    $scope.$on("$destroy", function (event, next, current) {
        if ($scope.statsInterval)
            $interval.cancel($scope.statsInterval);
        if ($scope.activityInterval)
            $interval.cancel($scope.activityInterval);
    });

}



















