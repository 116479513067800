IdentityWelcomeKno2Modal.$inject = ['$scope', '$uibModalInstance', 'SessionService', 'Auth0Service'];
export function IdentityWelcomeKno2Modal($scope, $uibModalInstance, sessionService, Auth0Service) {
    $scope.title = `Welcome to ${ENVIRONMENT.brand}`;
    $scope.userProfile = sessionService.getProfile();
    $scope.subdomain = JSON.parse(atob(Auth0Service.getAccessToken().split('.')[1]))['http://kno2/subdomain'];

    $scope.setForm = function (form) {
        $scope.form = form;
    };

    $scope.submitNext = function () {
        $uibModalInstance.close();
    };
}
