routeAuthConfig.$inject = ['$transitions', '$location', '$q', '$rootScope', 'SessionService', 'StateRoutingService'];

export function routeAuthConfig($transitions, $location, $q, $rootScope, SessionService, StateRoutingService) {
    $transitions.onBefore({}, function (transition) {
        const hasBrandGuard = transition.to().data && transition.to().data.brands;
        if (hasBrandGuard) {
            const targetState = StateRoutingService.getState();
            const isAccessible = transition.to().data.brands.indexOf($rootScope.environment.brand) !== -1;
            const isSysAdmin = SessionService.userInRole('SysAdmin');

            if (!isAccessible && isSysAdmin)
                return StateRoutingService.goTo(targetState);
            if (!isAccessible && !isSysAdmin)
                return transition.router.stateService.target(targetState);
        }
    });

    $transitions.onStart({}, function (transition) {
        var routeRoles = transition.to().data && transition.to().data.roles;
        if (routeRoles) {
            if (!SessionService.userInRole(routeRoles)) {
                $location.path('/unauthorized').replace();
            }
        }
    });
}
