(function () {

    var service = function ($http, Upload, _) {

        this.getDefaultPageParameters = function () {
            return {
                sortDir: 'desc',
                pageNumber: 1,
                pageSize: 10
            };
        };

        this.getPageSizeOptions = function () {
            return [10, 25, 50, 100];
        };

        this.getPages = function (params) {
            var pageParams = _.extend({}, this.getDefaultPageParameters(), params);
            var promise = $http({
                url: '/api/downloads/getpaged',
                method: 'GET',
                params: pageParams
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };

        this.getAll = function () {
            var promise = $http.get('/api/downloads')
                .then(function (response) {
                    return response.data;
                });
            return promise;
        };

        this.getById = function (id) {
            var promise = $http.get('/api/downloads/' + id).then(function (response) {
                return response.data;
            });
            return promise;
        };

        this.save = function (downloadableFileResource, file) {
            return Upload.upload({
                url: '/api/downloads/upload',
                data: {
                    file: file,
                    downloadableFileResource: Upload.json(downloadableFileResource)
                }
            });
        };

        this.update = function (downloadableFileResource) {
            var promise = $http.put('/api/downloads/' + downloadableFileResource.id + '/update',  downloadableFileResource )
            .then(function (response) {
              return response.data;
          });
            return promise;
         
        };

        this.delete = function (download) {
            var promise = $http.delete('/api/downloads/' + download.id).then(function (response) {
                return response.data;
            });
            return promise;
        };

        this.activate = function (download) {
            var promise;
            if (!download.isDownloadable) {
                promise = $http.put('/api/downloads/' + download.id + '/activate').then(function(response) {
                    return response.data;
                });
            } else {
                promise = $http.put('/api/downloads/' + download.id + '/deactivate').then(function (response) {
                    return response.data;
                });
            }
            return promise;
        };
    };

    service.$inject = ['$http', 'Upload', '_'];
    angular.module('kno2.services').service('DownloadsService', service);

}());
