import { ReleaseModule } from '../../release';

angular.module(ReleaseModule)
    .controller('MessageModalController', ['$scope', '$uibModalInstance','message',
        function ($scope, $uibModalInstance, message) {

            $scope.message = message;

            $scope.close = function () {
                $uibModalInstance.dismiss('close');
            };
        }
    ]);