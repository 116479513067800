import templateUrl from './pagination.component.html';
import _ from 'lodash';

class PaginationController {
    constructor($timeout, PagingFactory) {
        this.$timeout = $timeout;
        this.pagingFactory = PagingFactory;
    }

    $onChanges() {
        this.maxPage = Math.ceil(this.paginationTotalItems / this.paginationItemsPerPage);
        this.pages = _.range(1, this.maxPage + 1);
        this.rangeStart = this._setPageRangeStart(this.paginationCurrentPage);
    }

    _setPageRangeStart(currentPage) {
        const pageIndex = Math.max(0, currentPage - 1);
        return pageIndex - (pageIndex % this.pagingFactory.maxNavPages);
    }

    gotoPage (page) {
        this.rangeStart = this._setPageRangeStart(page);
        page = (page > this.maxPage) ? this.maxPage : page;
        this.$timeout(() => this.paginationChange({value: page}), 0);
    };

    showPage (page, currentPage) {
        return this.pagingFactory.getPageRange(page, currentPage, this.paginationItemsPerPage, this.paginationTotalItems).visible[page - 1];
    };

    isFirstPageRange (page, currentPage) {
        return this.pagingFactory.getPageRange(page, currentPage, this.paginationItemsPerPage, this.paginationTotalItems).isFirst;
    };

    isLastPageRange (page, currentPage) {
        return this.pagingFactory.getPageRange(page, currentPage, this.paginationItemsPerPage, this.paginationTotalItems).isLast;
    };
}

PaginationController.$inject = ['$timeout', 'PagingFactory'];
export const paginationComponent = {
    templateUrl: templateUrl,
    controller: PaginationController,
    bindings: {
        paginationChange: '&',
        paginationCurrentPage: '<',
        paginationItemsPerPage: '<',
        paginationTotalItems: '<',
        paginationFirstText: '@',
        paginationPreviousText: '@',
        paginationNextText: '@',
        paginationLastText: '@',
        paginationDisabled: '='
    }
};
