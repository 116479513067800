NetworksService.$inject = ['$http'];

export function NetworksService($http) {
    var svc = this;

    svc.getNetwork = getNetwork;

    function getNetwork(networkId) {
        return $http.get('/api/networks/' + networkId).then(function (response) {
            return response.data;
        });
    }
}