import templateUrl from './notification-list.component.html';

export class NotificationListController { 

    close(idx) {
        $(`#notification-item-${idx}`).fadeOut(200);
    };
}

NotificationListController.$inject = [];

export const notificationListComponent = {
    templateUrl,
    controller: NotificationListController,
    bindings: {
        notifications: '<',
    }
}
