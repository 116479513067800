import settingsLicensesAttachTemplate from './licenses/settings.licenses.attach.html';
import settingsAddonsDeleteTemplate from './devices/settings.addons.delete.html';
import settingsAddonsEditTemplate from './devices/settings.addons.edit.html';
import settingsLicensesCreateTemplate from './licenses/settings.licenses.create.html';

(function () {
    angular.module('kno2.settings').controller('SettingsAddonsCtrl', SettingsAddonsCtrl);
    SettingsAddonsCtrl.$inject = ['$uibModal', 'NotificationService', 'DevicesService', 'AppsService', 'LicensesService', 'SessionService', 'devices', 'licenses', 'apps'];
    function SettingsAddonsCtrl($uibModal, NotificationService, DevicesService, AppsService, LicensesService, SessionService, devices, licenses, apps) {
        var defaultDevicePageParameters = DevicesService.getDefaultPageParameters();
        // This is a mess and it's not my fault -DB

        var vm = this;

        this.licensing = {
            available: _.map(devices.licenses, function (license) {
                var key = license.licenseKey;
                var typeName = license.licenseType.name;
                key = "..." + key.substring(key.length - 4);
                var displayName = key + " - " + typeName;
                return _.extend(license, { displayName: displayName });
            }),
            compatibleLicenses: function (connector) {
                return _.filter(this.available, function (lic) {
                    return _.includes(lic.licenseType.applicationNames, connector.applicationName);
                });
            },
            attachLicense: function (connector, bindTo) {
                var modal = $uibModal.open({
                    templateUrl: settingsLicensesAttachTemplate,
                    controller: 'SettingsLicensesAttachCtrl as main',
                    resolve: {
                        connector: function () {
                            return connector;
                        },
                        licenses: function () {
                            return LicensesService.getAvailable();
                        }
                    }
                });
                modal.result.then(function () {
                    NotificationService.success("The license was successfully applied");
                    refreshItems.call(this);
                }.bind(bindTo), () => { });
            },
            detachLicense: function (connector, bindTo) {
                var promise = LicensesService.detachLicense(connector);
                promise.then(function () {
                    SessionService.refreshProfile();
                    NotificationService.success("The license was successfully detached.");
                    refreshItems.call(this);
                }.bind(bindTo));
                promise.catch(function (response) {
                    NotificationService.error(response.data.message);
                });
            }
        };
        this.apps = {
            items: apps.items,
            total: apps.totalCount,
            licenses: devices.licenses,
            totalPages: licenses.totalCount / defaultDevicePageParameters.pageSize,
            selectedLicenses: [],
            service: function () {
                return AppsService;
            },
            delete: function (app) {

                var modal = $uibModal.open({
                    templateUrl: settingsAddonsDeleteTemplate,
                    controller: 'SettingsDevicesDeleteCtrl as main',
                    resolve: {
                        service: function () {
                            return AppsService;
                        },
                        addon: function () {
                            return AppsService.getById(app.id);
                        },
                        title: function () {
                            return "Delete Application";
                        },
                        id: function () {
                            return app.id;
                        }
                    }
                });
                modal.result.then(function () {
                    NotificationService.success("The application was successfully deleted.");
                    refreshItems.call(vm.licenses);
                    refreshItems.call(vm.apps);
                }.bind(this), () => { });
            }
        }
        this.devices = {
            items: devices.items,
            total: devices.totalCount,
            licenses: devices.licenses,
            totalPages: devices.totalCount / defaultDevicePageParameters.pageSize,
            currentPage: defaultDevicePageParameters.pageNumber,
            pageSize: defaultDevicePageParameters.pageSize,
            selectedLicenses: [],
            service: function () {
                return DevicesService;
            },
            delete: function (device) {
                var modal = $uibModal.open({
                    templateUrl: settingsAddonsDeleteTemplate,
                    controller: 'SettingsDevicesDeleteCtrl as main',
                    resolve: {
                        service: function () {
                            return DevicesService;
                        },
                        addon: function () {
                            return DevicesService.getById(device.id);
                        },
                        title: function () {
                            return 'Delete Device';
                        },
                        id: function () {
                            return device.id;
                        }
                    }
                });
                modal.result.then(function () {
                    NotificationService.success("The device was successfully deleted.");
                    refreshItems.call(vm.devices);
                }.bind(this), () => { });
            },

            createUpdate: function (device) {
                var modal = $uibModal.open({
                    templateUrl: settingsAddonsEditTemplate,
                    controller: 'SettingsDevicesEditCtrl as main',
                    resolve: {
                        device: function () {
                            return device ? DevicesService.getById(device.deviceId) : {};
                        }
                    }
                });
                modal.result.then(function () {
                    var message = device ? "The device was successfully updated" : "The device was successfully created.";
                    NotificationService.success(message);
                    refreshItems.call(vm.devices);
                }.bind(this), () => { });
            },
            refresh: function () {
                refreshItems.call(vm.devices);
            },
            onSelectPage: function () {
                this.refresh();
            }
        };

        var defaultLicensePageParameters = LicensesService.getDefaultPageParameters();

        this.licenses = {
            items: licenses.items,
            total: licenses.totalCount,
            selected: {},
            totalPages: licenses.totalCount / defaultLicensePageParameters.pageSize,
            currentPage: defaultLicensePageParameters.pageNumber,
            pageSize: defaultLicensePageParameters.pageSize,
            attach: function () {
                var modal = $uibModal.open({
                    windowClass: 'settings-licenses-create-modal',
                    templateUrl: settingsLicensesCreateTemplate,
                    controller: 'SettingsLicensesCreateCtrl as main'
                });
                modal.result.then(function () {
                    NotificationService.success("The license was added successfully.");
                    refreshItems.call(vm.licenses);
                    refreshItems.call(vm.apps);
                }.bind(this), () => { });
            },
            refresh: function () {
                refreshItems.call(vm.licenses);
            },
            onSelectPage: function () {
                this.refresh();
            },
            service: function () {
                return LicensesService;
            }
        };

        var refreshItems = function () {
            var promise = this.service().getPages({
                pageNumber: this.currentPage,
                pageSize: this.pageSize
            });
            promise.then(function (data) {
                this.items = data.items;
                this.total = data.totalCount;
            }.bind(this));
        };
    };

}());
