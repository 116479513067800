(function () {

    var service = function ($http, Upload, _) {

        this.getDefaultPageParameters = function () {
            return {
                sortDir: 'desc',
                pageNumber: 1,
                pageSize: 10
            };
        };

        this.getPageSizeOptions = function () {
            return [10, 25, 50, 100];
        };

        this.getPages = function (params) {
            var pageParams = _.extend({}, this.getDefaultPageParameters(), params);
            var promise = $http({
                url: '/api/admin/eulas',
                method: 'GET',
                params: pageParams
            }).then(function (response) {
                return response.data;
            });
            return promise;
        };

        this.getAll = function () {
            var promise = $http.get('/api/admin/eulas')
                .then(function (response) {
                    return response.data;
                });
            return promise;
        };

        this.getById = function (id) {
            var promise = $http.get('/api/admin/eulas/' + id).then(function (response) {
                return response.data;
            });
            return promise;
        };

        this.save = function (eula, file) {
            return Upload.upload({
                url: '/api/admin/eulas',
                data: {
                    file: file,
                    eula: Upload.json(eula)
                }
            });
        };

        this.delete = function (eula) {
            var promise = $http.delete('/api/admin/eulas/' + eula.id).then(function (response) {
                return response.data;
            });
            return promise;
        };

        this.activate = function (eula) {
            var promise = $http.post('/api/admin/eulas/' + eula.id + '/activate').then(function (response) {
                return response.data;
            });
            return promise;
        };

        this.isEulaVersionExists = function (id, version, brandName) {
            return $http.get(`/api/eulas/exists`, {
                params: {
                    id: id,
                    version: version,
                    brandName: brandName
                }
            });
        };

        this.accept = function (eula) {
            return $http.post('/api/eulas/' + eula.id + '/accept');
        };

        this.getActive = function (type) {
            return $http.get(`/api/eulas/active`,
                {
                    params: {
                        type: type
                    }
                });
        };

        this.getViewActive = function (type) {
            return $http.get(`/api/eulas/active/view`,
                {
                    params: {
                        Type: type,
                        brandName: ENVIRONMENT.brand
                    }
                });
        };

        this.view = function (id) {
            return $http.get(`/api/admin/eulas/${id}/view`)
        };
    };

    service.$inject = ['$http', 'Upload', '_'];
    angular.module('kno2.services').service('EulasService', service);

}());
