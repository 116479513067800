SupportCtrl.$inject = ['$state', 'knowledgeBaseLinks'];

export function SupportCtrl($state, knowledgeBaseLinks) {
    var ctrl = this;

    ctrl.toggle = toggle;
    ctrl.knowledgeBaseLinks = knowledgeBaseLinks;

    function toggle(state) {
        if ($state.is(state))
            $state.go('support.default');
        else
            $state.go(state);
    };
}
