import templateUrl from './query.patient.display.modal.component.html';

angular
    .module('kno2.records')
    .component('k2PatientDisplayModal',
    {
        controller: querypatientDisplayModalController,
        templateUrl,
        bindings: {
            modalInstance: '<',
            resolve: '<'
        }
    });

    querypatientDisplayModalController.$inject = ['QueryService', 'NotificationModalService'];

function querypatientDisplayModalController(QueryService, NotificationModalService) {
    var ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.close = close;

    function $onInit() {
        ctrl.patient = ctrl.resolve.patient;
        ctrl.organization = ctrl.resolve.organization;
    }

    function close() {
        ctrl.modalInstance.dismiss();
    }
}
