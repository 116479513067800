EFaxAccountService.$inject = ['$http'];

export function EFaxAccountService($http) {
    this.save = function(account){
        return $http.post('/api/efaxorganizationaccounts', account).then(function(response){
            return response.data;
        })
    }

    this.get = function() {
        return $http.get('/api/efaxorganizationaccounts').then(function(response) {
            return response.data;
        });
    }

    this.delete = function(account) {
        return $http.delete('/api/efaxorganizationaccounts').then(function(response) {
            return response.data;
        });
    }

    this.verifyAccount = function(req){
        return $http.post( `${ENVIRONMENT.baseUrl}/incoming/efax/verifyaccount`, req).then(function(response){
            return response.data;
        });
    }
}