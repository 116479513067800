ExportMessageModalCtrl.$inject = [
    '$scope', '$filter', '$window', '$q', '$timeout', 'data', 'NotificationService', 'NotificationModalService',
    'MessageService', 'AttachmentService', 'ExportService', 'Auth0Service'
];

export function ExportMessageModalCtrl(
    $scope, $filter, $window, $q, $timeout, data, NotificationService, NotificationModalService,
    MessageService, AttachmentService, ExportService, Auth0Service
) {
    $scope.validateDownloadOptions = function () {
        NotificationService.hideErrors();
        $scope.downloadValidationErrors = [];
        if (!$scope.exportOptions.includeMessageBody && !$scope.exportOptions.includeAttachments) {
            $scope.downloadValidationErrors.push("You must include either the message body or at least one attachment.");
        }
        return $scope.downloadValidationErrors;
    };

    var processed = false;
    var buildBundleOptions = function () {
        $scope.bundleOptions["includeMessageBody"] = $scope.exportOptions.includeMessageBody;
        $scope.bundleOptions["includeAttachments"] = $scope.exportOptions.includeAttachments;
        $scope.bundleOptions.attachments = [];
        if ($scope.exportOptions.includeAttachments) {
            _.each($scope.attachments, function (item) {
                if (item.isSelected) {
                    $scope.bundleOptions.attachments.push(item.id);
                }
            });
        }
    };

    $scope.saveMessage = function (sendToClient, nonPatient) {
        if (sendToClient) {
            $scope.bundleOptions.sendToClient = sendToClient;
        }

        $scope.validateDownloadOptions();
        if ($scope.downloadValidationErrors.length) {
            NotificationService.error({
                messageTitle: "Please fix the following errors before saving.",
                messages: $scope.downloadValidationErrors
            });
            return;
        }

        buildBundleOptions();
        if (nonPatient) {
            $scope.saveNonPatientInProgress = true;
        } else {
            $scope.saveInProgress = true;
        }

        var bundleOptions = $scope.bundleOptions;
        var queryString = $filter('queryString')(bundleOptions);
        var now = moment().toDate();
        var url = `${ENVIRONMENT.baseUrl}/api/export/bundle`;

        var download = function () {
            return ExportService.postMessage({
                id: now.getTime(), // Use time as notification uid
                date: now, // Notification date
                url: url, // Download url
                href: "/#intake/" + data.message.id, // Item href (click event)
                message: data.message, // Intake message
                token: Auth0Service.getAccessToken(),
                type: bundleOptions.exportMode, // Export type: file or print
                sendToClient: bundleOptions.sendToClient,
                nonPatient: bundleOptions.nonPatient,
                body: bundleOptions
            });
        };

        $q.resolve()
            .then(function () {
                var selected = _.filter(data.attachments, 'isSelected'),
                    shouldWait = $scope.exportOptions.includeAttachments && _.some(selected);
                if (shouldWait) return $q.resolve()
                    .then(function () {
                        if (data.interopSettings.exportOption === 'xds-sd_cda')
                            return AttachmentService.convertAttachments(data.message.id, data.attachments, 'Cda');
                    })
                    .then(function () {
                        return MessageService.waitForConversion(data.message.id);
                    })
                    .then(function () {
                        return MessageService.waitForTransforms(data.message.id);
                    });
            })
            .then(download)
            .then(function () {
                return MessageService.saveProcessedMessage(data.message.id, $scope.processed.checked, data.message.subject, $scope.bundleOptions.exportMode)
                    .then(function () {
                        processed = $scope.processed.checked;
                    });
            })
            .then(function () {
                $scope.closeModal();
            })
            .catch(function (error) {
                NotificationModalService.error(error);
            })
            .finally(function () {
                $scope.saveInProgress = false;
                $scope.saveNonPatientInProgress = false;
            });
    };

    $scope.dismiss = function () {
        $scope.$dismiss('cancel');
    };

    $scope.closeModal = function () {
        $scope.$close({ processed: processed });
    };

    (function () {
        $scope.exportOptions = {
            includeMessageBody: data.attachments.length == 0,
            includeAttachments: true
        };
        $scope.bundleOptions = data.bundleOptions;
        $scope.attachments = data.attachments;
        $scope.title = (data.bundleOptions.exportMode == 'print') ? 'Print' : (data.bundleOptions.exportMode == 'forward') ? 'Forward to EMR' : 'Download';
        $scope.buttonText = $scope.title;
        if ($scope.bundleOptions.nonPatient) {
            $scope.title = 'Non-patient export';
            $scope.buttonText = 'Download';
        }
        $scope.message = data.message;
        $scope.status = data.status;
        $scope.processed = {
            id: 'markAsProcessed',
            name: 'Mark as Processed',
            checked: false
        };
    })();
}
