(function() {
    'use strict';

    angular
        .module('kno2.directives')
        .directive('focusPlaceholder', focusPlaceholder);

    focusPlaceholder.$inject = [];

    function focusPlaceholder() {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {

                var placeholder = attrs.placeholder;
                var focusPlaceholder = attrs.focusPlaceholder;

                element.on('focus', function() {
                    element.attr('placeholder', focusPlaceholder);
                });

                element.on('blur', function () {
                    element.attr('placeholder', placeholder);
                });
            }
        };
    }
})();
