angular.module('kno2.settings')
    .controller('ResetPasswordModalCtrl', ['$scope', '$uibModalInstance', 'user', 'usersFactory', 'NotificationService', 'SessionService',
        function ($scope, $uibModalInstance, user, usersFactory, NotificationService, SessionService) {
            $scope.user = user;

            $scope.userIsSelf = ($scope.user.id === SessionService.getProfile().userId) ? true : false;
            $scope.resetPassword = function () {
                usersFactory.resetPassword($scope.user).then(function (res) {
                    // success
                    NotificationService.success("Password reset request has been sent");
                    $uibModalInstance.close($scope.user);
                }, function (res) {
                    // failure
                    NotificationService.serverError(res.data.modelState, "There was an error reseting the user password.");
                });
            };

            $scope.closeModal = function () {
                $uibModalInstance.dismiss($scope.user);
            };
        }
    ]);
