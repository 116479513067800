import settingsSubscriptionUpgradeModalTemplate from '../subscription/settings.subscription.upgrade-modal.html';

angular.module('kno2.settings')
    .controller('AdminOrgUsersModalCtrl', ['$scope', '$uibModalInstance', '$timeout', 'data', 'usersFactory', 'NotificationService', 'NetworkService', 'SessionService', 'SubscriptionsFactory', '$uibModal',
        function ($scope, $uibModalInstance, $timeout, data, usersFactory, NotificationService, NetworkService, SessionService, SubscriptionsFactory, $uibModal) {

            $scope.userId = data;

            $scope.setForm = function (form) {
                $scope.form = form;
            };

            $scope.addOrg = function () {
                if ($scope.form.$valid) {
                    checkSubscriptionUpgrade('addOrg');
                }
            };


            var addToOrg = function () {
                $scope.assignment.userId = $scope.userId;
                if (!$scope.assignment.organizationId) {
                    NotificationService.error('No organization selected.');
                    return;
                }
                usersFactory.postOrgAssignment($scope.assignment)
                    .then(function () {

                        $scope.selected = null;
                        $scope.assignment = {};
                        $scope.userOrgForm.$setPristine();
                        $scope.userOrgForm.$setUntouched();

                        getUserData();
                        NotificationService.success("The user organization was saved successfully.");
                    }, function (response) {
                        if (response.data.message)
                            NotificationService.error(response.data.message);
                        else
                            NotificationService.error("There was an error assigning this organization");
                    });

            };

            $scope.deleteOrg = function (orgId) {
                usersFactory.deleteOrgAssignment({
                    organizationId: orgId,
                    userId: $scope.userId
                })
                    .catch(function (response) {
                        NotificationService.serverError(response.data, response.data.message);
                    })
                    .then(function (data) {
                        getUserData();
                        NotificationService.success("User successfully removed from organization.");
                    });
            };

            $scope.closeModal = function () {
                $uibModalInstance.dismiss('cancel');
            };

            var executeAction = function (operation) {
                switch (operation) {
                    case 'addOrg':
                        addToOrg();
                        break;
                }
            };

            var getFilteredPlans = function (plan) {
                var filteredPlans = _.reject(plan.plans, function (p) {
                    if (((p.allowedUsers > plan.allowedUsers) && (p.monthlyCost > 0)) ||
                        (p.id == -1 && plan.isFreePlan) ||
                        (p.id > 0 && p.monthlyCost == -1) ||
                        (p.id == plan.planId)) {
                        return false;
                    } else {
                        return true;
                    }
                });
                plan.plans = filteredPlans;
                return plan;
            };

            var resfreshSubscription = function () {
                SubscriptionsFactory.get()
                    .then(function (res) {
                        $scope.plan = getFilteredPlans(res.data);
                    });
            };

            var checkSubscriptionUpgrade = function (operation) {
                SubscriptionsFactory.get($scope.assignment.organizationId)
                    .then(function (res) {
                        $scope.plan = getFilteredPlans(res.data);
                        $scope.currentUser = SessionService.getProfile();

                        // If Subscription limit is reached and handles billing, then confirm subscription upgrade.
                        if ($scope.plan.isUsersLimitReached && $scope.currentUser.organizationId == $scope.assignment.organizationId) {
                            var modalInstance = $uibModal.open({
                                templateUrl: settingsSubscriptionUpgradeModalTemplate,
                                controller: 'SubscriptionUpgradeModalCtrl',
                                windowClass: 'subscription-plans',
                                resolve: {
                                    plan: function () {
                                        return $scope.plan;
                                    }
                                }
                            });

                            modalInstance.result.then(function (planId) {
                                // Update Subscription
                                SubscriptionsFactory.update(null, planId).then(function () {
                                    // success
                                    NotificationService.success("Subscription plan has been updated.");
                                    resfreshSubscription();

                                    executeAction(operation);
                                },
                                    function (res) {
                                        // failure
                                        NotificationService.serverError(res.data.modelState, "There was an error upgrading your subscription plan.");
                                    });
                            }, () => { });
                        } else if ($scope.plan.isUsersLimitReached && $scope.currentUser.organizationId != $scope.assignment.organizationId) {
                            NotificationService.serverError(data.data, "Target organization's subscription plan has reached it's user limit. ");
                        } else {
                            executeAction(operation);
                        }
                    });
            };

            var getUserData = function () {
                usersFactory.user($scope.userId)
                    .then(function (response) {
                        $scope.user = response.data;
                        $timeout(function () {
                            $("#foo").focus().select();
                        }, 500);
                    });
            };

            $scope.onSelected = function ($selected) {
                $scope.assignment.organizationId = $selected.id;
            };

            $scope.assignment = {};
            $scope.selected = undefined;
            $scope.getOrgs = function (orgSearch) {
                var networkId = SessionService.getProfile().administerOfNetworkObjectId;
                return NetworkService.getOrganizations(networkId, orgSearch).then(function (response) {
                    return _.filter(response.data, function (org) {
                        return _.every($scope.user.organizations, function (userOrg) {
                            return userOrg.id !== org.id;
                        });
                    });
                });
            };

            //Init
            getUserData();
        }
    ]);
