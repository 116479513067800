angular.module('kno2.factories')
    // Factory helper to reduce line of codes in validation directive.
    .factory('ValidateFactory', () => {
    	return {
    		setValidity: setValidity
    	};

    	function setValidity(element, attrs, ctrl, errorName, isValid, errorMsg) {
    		ctrl.$setValidity(errorName, isValid);

    		var INPUT_GROUP = "input-group",
				message = errorMsg,
				parent = element.parent();

	        if (!isValid) {
	            if (!parent.find('i').hasClass('fa fa-exclamation-circle')) {
					parent.addClass(INPUT_GROUP);
					parent.addClass('has-error');
	            } else {
	                // Update the title text
	                parent.find('span').attr('title', message);
	            }
	            element.attr('title', message);
	        } else {
	            let span = parent.find("." + errorName);
                if (span) span.remove();
				element.attr('title', '');
				parent.removeClass('has-error');
	        }
	        if (element.val === "") {
    			element.attr('title', '');
    		}
    	}
    });
