angular.module('kno2.release')
    .controller('ReleaseSendNonPhiFaxModalCtrl', ReleaseSendNonPhiFaxModalCtrl);

ReleaseSendNonPhiFaxModalCtrl.$inject = ['$scope', 'DocumentSourceService', '$uibModalInstance', 'ReleaseService', '$q', '_', '$timeout', 'NotificationModalService', 'AttachmentService'];
function ReleaseSendNonPhiFaxModalCtrl($scope, DocumentSourceService, $uibModalInstance, ReleaseService, $q, _, $timeout, NotificationModalService, AttachmentService) {
    $scope.message = {
        id: null,
        subject: '',
        toAddress: '',
        toAddresses: [],
        fromAddress: '',
        attachments: [],
        origin: `${ENVIRONMENT.brand}_non-patient`,
        body: '',
        status: 0
    };
    $scope.onFileSelect = onFileSelect;
    $scope.draftId = null;
    $scope.validateFax = validateFax;
    $scope.cancel = cancel;
    $scope.ok = ok;
    $scope.processingCount = 0;
    $scope.processing = processing;

    function processing() {
        return $scope.processingCount >= 1;
    }

    activate();

    function activate() {
        DocumentSourceService.getMyDocumentSources()
            .then(function (data) {
                $scope.availableFromAddresses = _.map(_.filter(data, { type: 'Fax' }), 'fullAddress');
            });
    };

    function onFileSelect(files) {
        if(files)
            $scope.processingCount += files.length;
        if (!$scope.draftId) {
            $scope.message.toAddresses.push({ text: $scope.message.toAddress });
            ReleaseService.saveDraft($scope.draftId, $scope.message, $scope.message.attachments, null)
                .then(function (res) {
                    $scope.draftId = res.id;
                    addAttachments(files);
                });
        } else {
            addAttachments(files);
        }
    };

    function cancel() {
        if ($scope.draftId)
            ReleaseService.deleteDraft($scope.draftId);
        $scope.message.status = 'canceled';
        $uibModalInstance.dismiss($scope.message);
    };

    function ok() {
        $scope.validateFax()
            .then(function () {
                const servicePromise = ReleaseService.sendMessage($scope.draftId, $scope.message);
                servicePromise.then(function () {
                    NotificationModalService.success('The message was sent successfully.');
                    $uibModalInstance.close('success');
                })
                    .catch(function (response) {
                        var msg = 'There was an error sending your message. Please contact support.';
                        if (response.data.message)
                            msg = response.data.message;
                        NotificationModalService.error(msg);
                    });
            }, function (error) {
                //nothing to do, notifications are handled in the validation
            });
    };

    function addAttachments(files) {
        if(!files) return;
        _.each(files,
            function (file) {
                addAttachment(file);
            });
    }

    function addAttachment(f) {
        if (f.size === 0) {
            NotificationModalService.error('File is empty. Please upload something more substantial.');
            $scope.processingCount--;
            return;
        }
        if (f.size > 20971520) {
            NotificationModalService.error('Your attachment exceeds the maximum (20MB) allowed size.');
            $scope.processingCount--;
            return;
        }
        AttachmentService.uploadAttachment($scope.draftId, f, {})
            .then(function (response) { //success
                $scope.message.attachments.push(response.data);
                $scope.processingCount--;
            },
                function () { //error
                    NotificationModalService.error('Unable to attach document.');
                    $scope.processingCount--;
                    return;
                });
    };

    function validateFax() {
        var deferred = $q.defer();
        var validate = function () {

            var errors = [];
            if (_.isEmpty($scope.message.toAddress)) {
                errors.push('"To" fax number is required.');
            }
            if (_.isEmpty($scope.message.subject)) {
                errors.push('"Subject" is required.');
            }
            if (_.isEmpty($scope.message.fromAddress)) {
                errors.push('"From" fax number is required.');
            }
            if (_.isEmpty($scope.message.attachments)) {
                errors.push('Missing file attachment.');
            }

            if (!_.isEmpty(errors)) {
                NotificationModalService.errors(errors, 'Please fix the following errors before sending.');
                deferred.reject();
            } else {
                deferred.resolve();
            }
        };

        $timeout(validate);

        return deferred.promise;
    }
}
