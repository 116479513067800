export class ConversationServices {

    constructor($http) {
        this.$http = $http;
    }

    choose360xReferral(status, conversationId, messageId) {
        const url = `/api/conversations/${conversationId}/respond`;
        const parameters = {responseToMessageId: messageId, responseType: status};
        return this.$http.post(url, parameters);
    }
}

ConversationServices.$inject = ['$http'];

angular
    .module('kno2.services')
    .service('ConversationServices', ConversationServices);
