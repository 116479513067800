angular.module('kno2.factories')
    .factory('PagingFactory', () => {
        const maxNavPages = 5; // Maximum of pages to display.

        return {
            paging: paging,
            getPageRange: getPageRange,
            maxNavPages: maxNavPages
        };

        function paging(settings) {
            var promise = {};
            promise.sortBy = settings.sortBy;
            promise.isSortDesc = (typeof (settings) == 'undefined') ? false : settings.isSortDesc;
            promise.sortIconClass = (promise.isSortDesc) ? 'fa fa-chevron-down' : 'fa fa-chevron-up';
            promise.currentPage = 1;
            promise.pagedItemRanges = [
                { value: '10' },
                { value: '25' },
                { value: '50' },
                { value: '100' }
            ];
            promise.pagedItemRange = promise.pagedItemRanges[0];
            return promise;
        }

        function getPageRange(page, currentPage, pageCount, totalCount) {
            var total = Math.ceil(totalCount / pageCount);
            var visible = new Array();
            var i;

            for (i = 0; i < total; i++) {
                visible.push(0);
            }

            // Starting range
            var endRange = currentPage - 1;
            do {
                visible[endRange] = 1;
                endRange++;
            } while (endRange % maxNavPages != 0);

            // Ending range
            for (i = 1; i <= maxNavPages; i++) {
                visible[endRange - i] = 1;
            }

            return {
                visible: visible,
                isFirst: (endRange - maxNavPages == page),
                isLast: (endRange == page) && (page < total)
            };
        }
    });
