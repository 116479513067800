angular.module('kno2.services')
    .service('CacheService', ['DSCacheFactory', '_', 'SessionService', '$rootScope',
        function (DSCacheFactory, _, SessionService, $rootScope) {
    	/* Cache level 1: Set to 1 minute.
           Cache level 2: Set to 60 minutes.*/

            var _cacheDisabled = false;   // Set to true if want to disable cache.
            var _cacheNameL1 = "l1";
            var _cacheNameL2 = "l2";
            var profile = SessionService.getProfile();
            var storagePrefix = 'kno2.' + profile.userName + '.cache.';

            // Clear the cache key based on a deleteKey. Keys that contains deleteKey are deleted.
            var clearCache = function (cacheName, deleteKey) {
                var cacheInstance = DSCacheFactory.get(cacheName);
                if (cacheInstance) {
                    var keys = cacheInstance.keys();
                    for (var i = 0; i < keys.length; i++) {
                        if (keys[i].indexOf(deleteKey) >= 0) {
                            cacheInstance.remove(keys[i]);
                        }
                    }
                }
            };

            var clearCacheLevels = function (deleteKeys)
            {
                if (_cacheDisabled) return;

                // Clear cache for deleteKeys in both cached levels
                for (var i = 0; i < deleteKeys.length; i++) {
                    clearCache(_cacheNameL1, deleteKeys[i]);
                    clearCache(_cacheNameL2, deleteKeys[i]);
                }
            };

            $rootScope.$on('disableCache', function () {
                if (_cacheDisabled) return;

                DSCacheFactory.disableAll();
                _cacheDisabled = true;
            });

            return {
                register: function(cacheName) {
                    var maxAge = 0;
                    switch (cacheName) {
                        case _cacheNameL1:
                            maxAge = 60000;
                            break;
                        case _cacheNameL2:
                            maxAge = 6000000;
                            break;
                        default:
                            console.error(stringFormat('Unsupported cache {0}', cacheName));    
                            return;
                    }
                    // Set default Kno2 cache
                    DSCacheFactory(cacheName, {
                       disabled: _cacheDisabled,        
                        storagePrefix: storagePrefix,
                        maxAge: maxAge,                 // Items added to this cache expire after x seconds.
                        cacheFlushInterval: 6000000,    // This cache will clear itself every hour.
                        deleteOnExpire: 'aggressive',   // Items will be deleted from this cache right when they expire.
                        storageMode: 'sessionStorage'   // Stored to sessionStorage
                    });
                },
                cacheNameL1: _cacheNameL1,
                cacheNameL2: _cacheNameL2,
                cacheL1: function () { return DSCacheFactory.get(_cacheNameL1); },
                cacheL2: function () { return DSCacheFactory.get(_cacheNameL2); },
                clear: function () {
                    if (_cacheDisabled) return;

                    var args = Array.prototype.slice.call(arguments, 0);
                    clearCacheLevels(args);                                         // Clear cache for current user.
                },
                clearAll: function() { DSCacheFactory.clearAll(); }                 // Clear L1 and L2 cache
            };
        }
    ]);