import releaseTypesEditModalTemplate from './release-types.edit-modal.html';
import releaseTypesDeleteModalTemplate from './release-types.delete-modal.html';

angular.module('kno2.shared.settings')
    .controller('ReleaseTypesCtrl', ['$scope', '$timeout', 'PagingFactory', 'ModalFactory', 'ReleaseTypesService', 'SessionService', function ($scope, $timeout, PagingFactory, ModalFactory, ReleaseTypesService, SessionService) {
        // Common implementation
        $scope.isSysAdmin = SessionService.userInRole('SysAdmin');
        $scope.sort = function () { ModalFactory.sort($scope); };
        $scope.gotoPage = function (page) { ModalFactory.gotoPage($scope, page); };
        $scope.editRecord = function (id) {
            var data = { id: id };
            ModalFactory.editRecord($scope, data);
        };
        $scope.deleteRecord = function (id) {
            var data = { id: id };
            ModalFactory.deleteRecord($scope, data);
        };
        $scope.updatePagedItemCount = function (pageItemSelected) { ModalFactory.updatePagedItemCount($scope, pageItemSelected); };
        $scope.showPage = function (page, currentPage) {
            return PagingFactory.getPageRange(page, currentPage, $scope.paging.pagedItemRange.value, $scope.paging.totalCount).visible[page - 1];
        };
        $scope.isFirstPageRange = function (page, currentPage) {
            return PagingFactory.getPageRange(page, currentPage, $scope.paging.pagedItemRange.value, $scope.paging.totalCount).isFirst;
        };
        $scope.isLastPageRange = function (page, currentPage) {
            return PagingFactory.getPageRange(page, currentPage, $scope.paging.pagedItemRange.value, $scope.paging.totalCount).isLast;
        };

        // Init setup
        ModalFactory.initialize({
            scope: $scope,
            paging: PagingFactory.paging({ sortBy: 'name' }),
            edit: {
                templateUrl: releaseTypesEditModalTemplate,
                controller: 'ReleaseTypesEditModalCtrl'
            },
            remove: {
                templateUrl: releaseTypesDeleteModalTemplate,
                controller: 'ReleaseTypesDeleteModalCtrl'
            },
            service: {
                instance: ReleaseTypesService,
                method: 'getReleaseTypes',
                response: 'items'
            }
        });
    }]);
