angular.module('kno2.services')
    .service('FileService', ['$q', function ($q) {
        var readFileAsText = function (file) {
            var deferred = $q.defer();
            var reader = new FileReader();

            reader.onload = function (e) {
                deferred.resolve(e.target.result);
            }

            reader.onerror = function () {
                deferred.reject(this);
            };

            reader.readAsText(file);
            return deferred.promise;
        };

        return {
            readFileAsText: readFileAsText
        };
    }
]);