(function () {

    var controller = function ($timeout, $uibModalInstance, _, LicensesService, NotificationModalService) {
        var self = this;

        self.keys = [''];
        self.text = '';
        self.showText = false;
        self.licenseMask = '';

        self.placeMask = function () {
            $timeout(function () {
                self.licenseMask = '****-****-****-****-****-****-****-****';
            }, 100, false);
        };

        self.saveChanges = function () {
            if (self.form.$valid) {
                const keys = self.getKeys();
                if (!keys.length) return NotificationModalService.error('There are no valid keys.');

                LicensesService.attachToOrganization(keys)
                    .then(function (data) {
                        const response = data[0];
                        if (response.licenseType.category !== 'LicensedPlan')
                            LicensesService
                                .linkLicenseToApplication(response.licenseKey, response.licenseType.apps[0].name)
                                .then(function () { });
                        $uibModalInstance.close();
                    })
                    .catch(function (error) {
                        if (error.data.modelState)
                            NotificationModalService.serverError(error.data.modelState, error.data.message);
                        else
                            NotificationModalService.error(error.data.message);
                    });
            }
        };

        self.toggle = function () {
            self.showText = !self.showText;
            if (self.showText)
                self.text = self.keysToText(self.keys);
            else
                self.keys = self.textToKeys(self.text);
        };

        self.remove = function (index) {
            self.keys.splice(index, 1);
        }

        self.add = function () {
            self.keys.push('');
        };

        self.dismissModal = function () {
            $uibModalInstance.dismiss('cancel');
        };

        self.keysToText = function (keys) {
            if (!keys) return '';
            return keys.join('\n');
        };

        self.textToKeys = function (text) {
            if (!text) return [''];
            return text.match(/[^\r\n]+/g) || [''];
        };

        self.getKeys = function () {
            if (self.showText) self.keys = self.textToKeys(self.text);
            return _(self.keys)
                .compact()
                .map(function (k) {
                    return _.replace(k, /-/g, '');
                })
                .value();
        }
    };

    controller.$inject = ['$timeout', '$uibModalInstance', '_', 'LicensesService', 'NotificationModalService'];
    angular.module('kno2.settings').controller('SettingsLicensesCreateCtrl', controller);

}());
