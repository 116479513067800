import templateUrl from './query.requester.readonly.component.html'

angular
    .module('kno2.records')
    .component('k2QueryRequesterReadonly', {
        controller: QueryRequesterController,
        templateUrl,
        require: {
            wizard: '^^k2QueryWizard'
        }
    });

QueryRequesterController.$inject = ['SessionService', 'QueryService'];

function QueryRequesterController(SessionService, QueryService) {
    var ctrl = this;

    ctrl.$onInit = $onInit;

    function $onInit() {
        ctrl.requester = ctrl.wizard.query.requester;

        if (!ctrl.requester.fullName) {
            const profile = SessionService.getProfile();
            ctrl.requester.fullName = profile.firstName + ' ' + profile.lastName;
        }

        if (!ctrl.requester.purpose.description)
            QueryService.getPurposesOfUse().then(function (purposes) {
                ctrl.purposes = purposes;

                ctrl.requester.purpose = _.find(purposes, function (p) {
                    return p.code === ctrl.requester.purpose.code;
                });
            });
    }
}
