import templateUrl from './intake.delete-multiple-messages-modal.component.html';

export class DeleteMultipleMessagesModalController {
    constructor(MessageService, $q) {
        this.messageService = MessageService;
        this.$q = $q;
        this.numberToLimit = 5;
    }

    $onInit() {
        this.messages = this.resolve.messages;
    }

    cancel() {
        this.dismiss();
    }

    ok() {
        const ctrl = this;
        const requests = [];
        ctrl.messages.forEach(x => {
            requests.push(ctrl.messageService.delete(x.id));
        });
        ctrl.$q.all(requests)
            .then(x => {
                ctrl.close();
            })
            .catch(errs => {
                ctrl.close(errs);
            });
    }
}

DeleteMultipleMessagesModalController.$inject = ['MessageService', '$q'];

export const deleteMultipleMessagesModalComponent = {
    templateUrl,
    controller: DeleteMultipleMessagesModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
};
