import documentTypesEditModalTemplate from './document-types.edit-modal.html';
import documentTypesDeleteModalTemplate from './document-types.delete-modal.html';

angular.module('kno2.shared.settings')

    .controller('DocumentTypesCtrl', ['$scope', '$timeout', 'PagingFactory', 'ModalFactory', 'DocumentTypesService', 'SessionService',
        function ($scope, $timeout, PagingFactory, ModalFactory, DocumentTypesService, SessionService) {
            // Common implementation
            $scope.isSysAdmin = SessionService.userInRole('SysAdmin');
            $scope.sort = function () { ModalFactory.sort($scope); };
            $scope.gotoPage = function (page) { ModalFactory.gotoPage($scope, page); };
            $scope.editRecord = function (id) {
                var data = { id: id };
                ModalFactory.editRecord($scope, data);
            };
            $scope.deleteRecord = function (id) {
                var data = { id: id };
                ModalFactory.deleteRecord($scope, data);
            };
            $scope.updatePagedItemCount = function (pageItemSelected) { ModalFactory.updatePagedItemCount($scope, pageItemSelected); };
            $scope.showPage = function (page, currentPage) {
                return PagingFactory.getPageRange(page, currentPage, $scope.paging.pagedItemRange.value, $scope.paging.totalCount).visible[page - 1];
            };
            $scope.isFirstPageRange = function (page, currentPage) {
                return PagingFactory.getPageRange(page, currentPage, $scope.paging.pagedItemRange.value, $scope.paging.totalCount).isFirst;
            };
            $scope.isLastPageRange = function (page, currentPage) {
                return PagingFactory.getPageRange(page, currentPage, $scope.paging.pagedItemRange.value, $scope.paging.totalCount).isLast;
            };

            // Init setup
            ModalFactory.initialize({
                scope: $scope,
                paging: PagingFactory.paging({ sortBy: 'name' }),
                edit: {
                    templateUrl: documentTypesEditModalTemplate,
                    controller: 'DocumentTypesEditModalCtrl'
                },
                remove: {
                    templateUrl: documentTypesDeleteModalTemplate,
                    controller: 'DocumentTypesDeleteModalCtrl'
                },
                service: {
                    instance: DocumentTypesService,
                    method: 'getDocumentTypes',
                    response: 'documentTypes'
                }
            });
        }])
    .directive('validateDocumentTypeSpecialChar', ['ValidateFactory', function (ValidateFactory) {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ctrl) {
                element.on('keyup', function (evt) {
                    scope.$apply(function () {
                        // Check characters validity
                        var isValidInput = function (inputValue, _invalidChars) {
                            for (var i = 0; i < inputValue.length; i++) {
                                if (_.indexOf(_invalidChars, inputValue[i]) >= 0) {
                                    return false;
                                }
                            }
                            return true;
                        };
                        var val = element.val();
                        var invalidChars = new Array('/', '\\', '?', '%', '*', ':', '|', '<', '>', '"');
                        var message = stringFormat('Special characters are not authorized: {0}"', invalidChars.join(" ").replace('"', ''));
                        var valid = (val.length == 0) || ((val.length > 0) && (isValidInput(val, invalidChars)));
                        ValidateFactory.setValidity(element, attrs, ctrl, "documenttypespecialchar", valid, message);
                    });
                });
            }
        };
    }])
    ;