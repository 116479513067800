import templateUrl from './efax-accounts.component.html'

export const settingsEFaxAccountComponent = {
    templateUrl: templateUrl,
    controller: EFaxAccountController
};

EFaxAccountController.$inject = ['$uibModal', 'NotificationService', 'EFaxAccountService', 'SessionService'];

export function EFaxAccountController($uibModal, NotificationService, EFaxAccountService, SessionService) {
    const ctrl = this;
    ctrl.$onInit = onInit;
    ctrl.account = {
        accountType: 'EFaxAccount'
    };

    ctrl.setForm = function(form) {ctrl.form = form;};

    function onInit() {
        EFaxAccountService.get().then(function(data){
            ctrl.account = data;
        });

        ctrl.inboundWebhook = `${ENVIRONMENT.baseUrl}/incoming/efax/notifyreceive`;
        ctrl.outboundWebhook = `${ENVIRONMENT.baseUrl}/incoming/efax/notifysend`;
    }

    ctrl.save = function() {
        console.log('test');
        if(!ctrl.form.$valid) return;
        EFaxAccountService.save(ctrl.account).then(function(data){
            ctrl.account = data;
            NotificationService.success('Account save');
        }, function(error){
            NotificationService.errorToaster(error.data.message);
        });
    }

    ctrl.delete = function () {
        if (!ctrl.account) return;
        EFaxAccountService.delete(ctrl.account).then(function(data) {
            ctrl.account = null;
            NotificationService.success('Account deleted');
        },
        function(error) {
            NotificationService.errorToaster(error.data.message);
        });
    }

    ctrl.verifyAccount = function() {
        var profile = SessionService.getProfile();
        var req = { organizationId: profile.organizationId }
        EFaxAccountService.verifyAccount(req).then(function(data){
                ctrl.verified = data.success;
                NotificationService.success('Account Verified');
        }, function(error){
            ctrl.verified = false;
            NotificationService.errorToaster(error.data.Message);
        })
    }

    ctrl.getInboundeFaxWebhook = function() {
        return ctrl.inboundWebhook;
    };

    ctrl.getOutboundeFaxWebhook = function() {
        return ctrl.outboundWebhook;
    };

    ctrl.showClipboardCapture = function() {
        NotificationService.success("Url copied to your clipboard");
    };
}