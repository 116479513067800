import supportTemplate from './support.html';

supportRoutes.$inject = ['$stateProvider'];

export function supportRoutes($stateProvider) {
    $stateProvider
        .state('support', {
            abstract: true,
            url: '/supportcenter',
            parent: 'home',
            template: '<div ui-view></div>',
            data: {
                roles: ['Administrator', 'User']
            }
        })
        .state('support.default', {
            url: '',
            templateUrl: supportTemplate,
            controller: 'SupportCtrl',
            controllerAs: '$ctrl'
        })
        .state('support.contact', {
            url: '/contact',
            templateUrl: supportTemplate,
            controller: 'SupportContactController',
            controllerAs: '$ctrl'
        })
        .state('support.downloads', {
            url: '/downloads',
            templateUrl: supportTemplate,
            controller: 'SupportDownloadsController',
            controllerAs: '$ctrl'
        })
        .state('support.knowledge', {
            url: '/knowledge',
            templateUrl: supportTemplate,
            controller: 'SupportCtrl',
            controllerAs: '$ctrl'
        })
}
