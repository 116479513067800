
export class IpAddressesService {

    constructor($http) {
        this.$http = $http;
    }

    getIpAddress(id) {
        return this.$http.get(`/api/ipaddresses/${id}`);
    }

    getPagedIpAddresses(pageStart, pageSize, search, isWhitelisted, isBlacklisted, isDisabled) {
        return this.$http.get(`/api/ipaddresses`, {
            params: { pageStart, pageSize, search, isWhitelisted, isBlacklisted, isDisabled }
        });
    }

    upsertIpAddress(ipAddress) {
        if (ipAddress.id)
            return this.updateIpAddress(ipAddress.id, ipAddress);
        else
            return this.addIpAddress(ipAddress);
    }

    addIpAddress(ipAddress) {
        return this.$http.post(`/api/ipaddresses`, ipAddress);
    }

    updateIpAddress(id, ipAddress) {
        return this.$http.put(`/api/ipaddresses/${id}`, ipAddress)
    }
};

IpAddressesService.$inject = ['$http'];
