angular.module('kno2.settings')
    .controller('SettingsFaxPortWizardModalCtrl', ['$scope', '$timeout', '$filter', '$q', '$uibModalInstance', '$uibModal', '$confirm', '$sce', 'data', 'DocumentSourcesFaxService', 'NotificationService', 'UsersService', 'CommonData', 'DocumentSourceFaxService', 'LocalStorageFactory', '_', 'FaxPortService',
        function ($scope, $timeout, $filter, $q, $uibModalInstance, $uibModal, $confirm, $sce, data, DocumentSourcesFaxService, NotificationService, UsersService, CommonData, DocumentSourceFaxService, LocalStorageFactory, _, FaxPortService) {

            var FAX_WIZARD_STEP = 'faxWizardStep';
            var FAX_WIZARD_DATA = 'faxWizardData';
            var timeout;

            var steps = [
                { name: "customer", pos: 1 },
                { name: "fax", pos: 2 },
                { name: "authorizer", pos: 3 },
                { name: "signature", pos: 4 }
            ];

            var getLastStep = function () {
                var s = LocalStorageFactory.get(FAX_WIZARD_STEP);
                if (!s) {
                    s = steps[0];
                }
                return s;
            };

            $scope.saveInLocalStorage = function () {
                LocalStorageFactory.set(FAX_WIZARD_DATA, $scope.port);
            }

            $scope.types = [
                { id: 1, name: "Full" },
                { id: 2, name: "Partial" }
            ];

            $scope.faxesRaw = { text: "" };
            $scope.faxes = [];

            $scope.step = getLastStep();

            $scope.pollCount = 1;

            var poll = function () {
                timeout = $timeout(function () {
                    $scope.pollCount++;
                    if ($scope.portRequestId) {
                        FaxPortService.getStatus($scope.portRequestId).then(function (result) {
                            $scope.isSigned = result.data.signed;
                            if ($scope.isSigned) {
                                $timeout.cancel(timeout);
                            }
                        });
                    }
                    if (!$scope.isSigned) poll();

                }, 2000);
            };

            var showValidation = function (form) {
                _(form.$error.required).forEach(function (value) {
                    $timeout(function () {
                        $("input[name*='" + value.$name + "']").focus().select();
                    }, 250, false);
                    $timeout(function () {
                        $("input[name*='address']").focus().select();
                    }, 250, false);
                    $timeout(function () {
                        $("input[name*='" + value.$name + "']").focus().select();
                    }, 250, false);
                });
            };

            $scope.next = function () {
                var s = $scope.step;
                if (!s) return;

                var next = _.find(steps, function (n) {
                    return n.pos === s.pos + 1;
                });

                if (s.pos === 1) {
                    if (!$scope.formProvider.$valid) {
                        showValidation($scope.formProvider);
                        return;
                    }
                }
                if (s.pos === 2) {
                    if (!$scope.formCustomer.$valid) {
                        showValidation($scope.formCustomer);
                        return;
                    }
                }

                // submit changes if transitioning from step 2 to step 3
                if (s.pos === 2 && next.pos === 3) {

                    $scope.nextLoading = true;
                    $scope.showSignatureLoader = true;

                    var success = function (result) {
                        $scope.nextLoading = false;
                        NotificationService.hideErrors();

                        if (result.warnings.length > 0)
                            $scope.warnings = result.warnings;

                        $scope.portRequestId = result.id;
                        poll();

                        $scope.pdfSignatureUrl = $sce.trustAsResourceUrl(result.url);

                        LocalStorageFactory.set(FAX_WIZARD_STEP, next);
                        $scope.step = next;

                        // Timeout the signature viewer
                        $timeout(function () {
                            $scope.showSignatureLoader = false;
                        }, 1000);
                    };

                    var error = function (error) {
                        $scope.nextLoading = false;
                        var message = "An error occured.";
                        if (error.data && error.data.message)
                            message = error.data.message;
                        NotificationService.error(message);
                    };

                    FaxPortService.post($scope.port).then(success, error);

                } else {
                    LocalStorageFactory.set(FAX_WIZARD_STEP, next);
                    $scope.step = next;
                }

            }

            $scope.back = function () {
                var s = $scope.step;
                if (timeout)
                    $timeout.cancel(timeout);

                if ($scope.pdfSignatureUrl)
                    $scope.pdfSignatureUrl = "";

                if (!s) return;

                var previous = _.find(steps, function (n) {
                    return n.pos === s.pos + -1;
                });

                LocalStorageFactory.set(FAX_WIZARD_STEP, previous);
                $scope.step = previous;
            }

            $scope.showBack = function () {
                if (!$scope.step || $scope.step.pos > steps[0].pos) return true;
                return false;
            }

            $scope.showNext = function () {
                if ($scope.step.pos == 3) return false;
                if ($scope.step && $scope.step.pos < _.last(steps).pos) return true;
                return false;
            }

            $scope.showFinish = function () {
                return $scope.isSigned;
            }

            $scope.finish = function () {
                FaxPortService.finish($scope.portRequestId).then(function (result) {
                    $scope.close();
                });
            }

            $scope.close = function () {
                LocalStorageFactory.remove(FAX_WIZARD_STEP);
                LocalStorageFactory.remove(FAX_WIZARD_DATA);
                if (timeout) $timeout.cancel(timeout);
                $uibModalInstance.dismiss('cancel');
            };

            $scope.processRawFaxes = function () {
                // add to array
                var raw = $scope.faxesRaw;

                var arr = raw.text.split(/\n/);
                $scope.port.numbersToBePorted = arr;
                $scope.saveInLocalStorage();
            }

            $scope.setFormProvider = function (form) {
                $scope.formProvider = form;
            };
            $scope.setFormCustomer = function (form) {
                $scope.formCustomer = form;
            };

            var convertPhoneNumberArrToRawText = function () {
                var arr = $scope.port.numbersToBePorted;
                var raw = "";
                _(arr).forEach(function (value) {
                    raw = raw + value + "\n";
                });
                return raw;
            }

            // init data
            var initData = function () {
                var tempData = LocalStorageFactory.get(FAX_WIZARD_DATA);
                if (tempData) {
                    $scope.port = tempData;
                    $scope.faxesRaw.text = convertPhoneNumberArrToRawText();
                } else {
                    $scope.port = data;
                    $scope.port.portType = 1;
                    $scope.saveInLocalStorage();
                }
            }

            initData();

            $scope.setForm = function (form) {
                $scope.form = form;
            }
        }
    ]);
