SubmitIdentityModalCtrl.$inject = ['$scope', '$uibModalInstance', '$timeout', '_', 'IdProofingService', 'NotificationService', 'ValidationService', 'SessionService', 'CommonData', 'identity'];
export function SubmitIdentityModalCtrl($scope, $uibModalInstance, $timeout, _, idProofingService, notificationService, validationService, sessionService, commonData, identity) {
    $scope.forms = {};
    $scope.title = "Submit Personal Identity: Step 1 of 2";
    $scope.states = commonData.states.asArray();
    $scope.userProfile = sessionService.getProfile();
    $scope.showFormerAddress = false;
    $scope.identitySubmitInProgress = false;

    var bypassCheck = false;

    $scope.checkDuplicateAddress = function () {
        if ($scope.identity.currentAddress.addressLine != undefined && bypassCheck === false) {
            var promise = idProofingService.checkDuplicateAddress($scope.identity.currentAddress.addressLine);
            promise.then(function (data) {
                if (data === "true") {
                    bypassCheck = true;
                    notificationService.warning("It looks like you may have entered the organization’s address instead of your current home address. " +
                        "If this is the correct address, click “Submit Identity” again to move forward.");
                }
            });
        }
    };

    $scope.identity = {
        customerId: $scope.userProfile.userId,
        firstName: identity.firstName,
        lastName: identity.lastName
    };

    $scope.toggleFormerAddress = function () {
        $scope.showFormerAddress === true ? $scope.showFormerAddress = false : $scope.showFormerAddress = true;
    };

    $scope.submitIdentity = function () {
        $scope.identitySubmitInProgress = true;

        if ($scope.forms.identity.$valid) {
            idProofingService.submitIdentity($scope.identity)
                .then(function (response) {
                    $uibModalInstance.close(response.data);
                })
                .catch(function (response) {
                    if (response.data.modelState.errors && response.data.modelState.errors.length > 0) {
                        var validationMessages = commonData.idProofingValidation.asArray();
                        var readableErrors = [];
                        _.each(response.data.modelState.errors, function (x) {
                            var message = _.find(validationMessages, { key: x });
                            readableErrors.push(message.value);
                        });
                        notificationService.errors(readableErrors, "There were was problem with your request.");
                    } else {
                        notificationService.error("An unknown error occured");
                    }

                })
                .finally(function () {
                    $scope.identitySubmitInProgress = false;
                });
        } else {
            notificationService.error("Please make sure all required fields are present.");
            $scope.identitySubmitInProgress = false;
        }
    };
}
