(function() {
    'use strict';

    angular
        .module('kno2.directives')
        .directive('k2FeatureToggle', featureToggle);

    featureToggle.$inject = ['$animate', '$compile', 'FeatureService', 'SessionService'];

    /**
     * Shows/hides an element depending on whether a feature flag is enabled. Partially copied from
     * https://github.com/angular/angular.js/blob/3651e42e49ded7d410fd1cbd46f717056000afd4/src/ng/directive/ngIf.js#L81
     */ 
    function featureToggle($animate, $compile, FeatureService, SessionService) {
        return {
            multiElement: true,
            transclude: 'element',
            priority: 599,
            terminal: true,
            restrict: 'A',
            $$tlb: true,
            link: function ($scope, $element, $attr, ctrl, $transclude) {
                var hasHideAttribute = 'k2FeatureToggleHide' in $attr;
                var block, childScope, previousElements;

                FeatureService.load().then(function() {
                    $scope.$watch(k2FeatureToggleWatcher, k2FeatureToggleWatchAction);
                });

                function k2FeatureToggleWatcher() {
                    return (!$attr.k2FeatureToggle || FeatureService.isEnabled($attr.k2FeatureToggle))
                        && (!$attr.k2FeatureToggleRole || SessionService.userInRole($attr.k2FeatureToggleRole));
                }

                function k2FeatureToggleWatchAction(isEnabled) {
                    var showElement = hasHideAttribute ? !isEnabled : isEnabled;

                    if (showElement) {
                        if (!childScope) {
                            $transclude(function (clone, newScope) {
                                childScope = newScope;
                                clone[clone.length++] = $compile.$$createComment('end k2FeatureToggle', $attr.k2FeatureToggle);
                                // Note: We only need the first/last node of the cloned nodes.
                                // However, we need to keep the reference to the jqlite wrapper as it might be changed later
                                // by a directive with templateUrl when its template arrives.
                                block = {
                                    clone: clone
                                };
                                $animate.enter(clone, $element.parent(), $element);
                            });
                        }
                    } else {
                        if (previousElements) {
                            previousElements.remove();
                            previousElements = null;
                        }
                        if (childScope) {
                            childScope.$destroy();
                            childScope = null;
                        }
                        if (block) {
                            previousElements = getBlockNodes(block.clone);
                            $animate.leave(previousElements).done(function (response) {
                                if (response !== false) previousElements = null;
                            });
                            block = null;
                        }
                    }
                }
            }
        };
    }

    /**
     * Return the DOM siblings between the first and last node in the given array.
     * @param {Array} array like object
     * @returns {Array} the inputted object or a jqLite collection containing the nodes
     * https://github.com/angular/angular.js/blob/3651e42e49ded7d410fd1cbd46f717056000afd4/src/Angular.js#L2008
     */
    function getBlockNodes(nodes) {
        // TODO(perf): update `nodes` instead of creating a new object?
        var node = nodes[0];
        var endNode = nodes[nodes.length - 1];
        var blockNodes;

        for (var i = 1; node !== endNode && (node = node.nextSibling); i++) {
            if (blockNodes || nodes[i] !== node) {
                if (!blockNodes) {
                    blockNodes = jqLite(slice.call(nodes, 0, i));
                }
                blockNodes.push(node);
            }
        }

        return blockNodes || nodes;
    }

})();