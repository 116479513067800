import angular from 'angular'
import { CommonModule } from '../common';
import { supportRoutes } from './support.routes';
import { SupportService } from './support.services';
import { SupportContactController } from './support.contact.ctrl';
import { SupportCtrl } from './support.ctrl';
import { SupportDownloadsController } from './support.downloads.ctrl';

export const SupportModule = angular
    .module('kno2.support', [CommonModule])
    .config(supportRoutes)
    .service('SupportService', SupportService)
    .controller('SupportContactController', SupportContactController)
    .controller('SupportCtrl', SupportCtrl)
    .controller('SupportDownloadsController', SupportDownloadsController)
    .name;
