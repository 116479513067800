export class HomeLayoutCtrl {

    constructor($window) {
        this.$window = $window;
    }
    
    $onInit() {
        // this.$window.document.title = ENVIRONMENT.brand;

        const h = $(window).height();
        if (h > 500)
            $('#content').css('min-height', h);
    }
}

HomeLayoutCtrl.$inject = ['$window'];
