EnableTokenAuthenticatorModalCtrl.$inject = ['$uibModalInstance', '$timeout', 'usersFactory', 'NotificationService', 'settings'];
export function EnableTokenAuthenticatorModalCtrl($uibModalInstance, $timeout, usersFactory, NotificationService, settings) {
    const ctrl = this;
    ctrl.settings = settings;
    ctrl.modal = {};

    ctrl.$onInit = onInit;

    ctrl.closeModal = function () {
        $uibModalInstance.dismiss('cancel');
    };

    ctrl.enable = function () {
        if (!ctrl.code)
            return;
        usersFactory.enableTokenAuth(ctrl.code).then(function (results) {
            NotificationService.success('Token authentication enabled.');
            $uibModalInstance.close();
        },
            function (error) {
                NotificationService.error(error.data.message);
            });
    }

    ctrl.showClipboardCapture = function () {
        NotificationService.success("Manual entry code copied to your clipboard");
    }

    ctrl.getManualEntryCode = function () {
        return ctrl.manualEntryCode;
    }

    function onInit() {
        usersFactory.getQrCode().then(function (results) {
            ctrl.imageUrl = 'http://qrcode.kaywa.com/img.php?s=4&d=' + results.data.barcodeUrl;
            ctrl.manualEntryCode = results.data.manualEntryCode;
        });
    }
}
