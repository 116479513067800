import angular from 'angular';

import { CommonModule } from '../common';
import { addAttachmentModalComponent } from './message/release.add-attachment-modal.component';
import { newMessageBodySummaryComponent } from './message/release.new-message-body-summary';

export const ReleaseModule = angular
    .module('kno2.release', [CommonModule, 'ngFileUpload'])
    .component('k2AddAttachmentModal', addAttachmentModalComponent)
    .component('newMessageBodySummary', newMessageBodySummaryComponent)
    .name;
