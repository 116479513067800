import idProofingModalHeaderComponentTemplate from './account.id-proofing-modal-header.component.html';

class IdProofingModalHeaderCtrl {
    $onInit() {
        this.logo = `/content/images/${ENVIRONMENT.brand}/logo-black.png`
    }
}

export const idProofingModalHeaderComponent = {
    templateUrl: idProofingModalHeaderComponentTemplate,
    controller: IdProofingModalHeaderCtrl,
    bindings: {
        headerTitle: '<'
    }
}
