import intakeTemplate from './intake.html';
import intakeMessageTemplate from './message/intake.message.html';

intakeRoutes.$inject = ['$stateProvider'];

export function intakeRoutes($stateProvider) {

    $stateProvider
        .state('intake',
            {
                url: '/intake',
                parent: 'home',
                templateUrl: intakeTemplate,
                controller: 'IntakeCtrl',
                controllerAs: '$ctrl',
                data: { roles: ['Administrator', 'User'] },
                reloadOnSearch: false,
                params: {
                    isLeftNav: false
                },
                resolve: {
                    isFirstRequestForView: ['$transition$', function ($transition$) {
                        return $transition$.from().name.indexOf('intake') !== 0 && $transition$.from().name.indexOf('triage') !== 0;
                    }]
                }
            })
        .state('intakeMessage',
            {
                url: '/intake/:messageId',
                parent: 'home',
                templateUrl: intakeMessageTemplate,
                controller: 'IntakeMessageCtrl',
                data: { roles: ['Administrator', 'User'] },
                reloadOnSearch: false,
                resolve: {
                    message: ['$transition$', 'MessageService', 'NotificationService', '$state', '$q', function ($transition$, MessageService, NotificationService, $state, $q) {
                        return MessageService.getMessage($transition$.params().messageId).then(
                            function (data) { return data; },
                            function (error) {
                                $q.reject(error);
                                NotificationService.errorToaster('This message cannot be loaded');
                                return $state.go("intake");
                            });
                    }],
                    interoperabilitySettings: ['InteroperabilityService', function (InteroperabilityService) {
                        return InteroperabilityService.get().then(x => x.data);
                    }]
                }
            });
}
