import homeLayoutUrl from '../layouts/home.layout.html';
import errorLayoutUrl from '../layouts/error.layout.html';
import unauthorizedTemplateUrl from '../common/unauthorized/unauthorized.html';
import unsupportedBrowserTemplateUrl from '../common/unsupported-browser/unsupported-browser.html';
import aboutUsTemplate from '../common/about-us/about-us.html';
import legalInformationTemplate from '../common/legal-information/legal-information.html';
import privacyPolicyTemplate from '../common/privacy/privacy-policy.html';
import termsOfUseTemplate from '../common/terms-of-use/terms-of-use.html';

routeConfig.$inject = ['$locationProvider', '$stateProvider', '$urlRouterProvider', '$urlMatcherFactoryProvider'];

export function routeConfig($locationProvider, $stateProvider, $urlRouterProvider, $urlMatcherFactoryProvider) {

    $locationProvider.html5Mode(true);
    $locationProvider.hashPrefix('');
    $urlMatcherFactoryProvider.caseInsensitive(true);
    $urlMatcherFactoryProvider.strictMode(false);

    $stateProvider
        .state('main',
            {
                url: '',
                redirectTo: 'login'
            })
        .state('login', {
            url: '/account/login',
            onEnter: ['Auth0Service', 'LocalStorageFactory', (Auth0Service, LocalStorageFactory) => {
                const loginType = LocalStorageFactory.get('loginType');
                Auth0Service.login(loginType);
            }]
        })
        .state('loginType', {
            url: '/account/login/:type',
            onEnter: ['Auth0Service', '$stateParams', 'LocalStorageFactory', (Auth0Service, $stateParams, LocalStorageFactory) => {
                LocalStorageFactory.set('loginType', $stateParams.type);
                Auth0Service.login($stateParams.type);
            }]
        })
        .state('logout', {
            url: '/account/logout',
            component: 'auth0Logout'
        })
        .state('renewtokens', {
            abstract: true,
            resolve: {
                init: ['Auth0Service', 'FeatureService', 'SyncService', 'AlertsNotificationService',
                    (Auth0Service, FeatureService, SyncService, AlertsNotificationService) => {
                        if (!Auth0Service.getAccessToken()) {
                            return Auth0Service.renewTokens()
                                .then(() => makeInitialApiCalls(SyncService, AlertsNotificationService, FeatureService))
                                .catch((err) => { });
                        } else {
                            return makeInitialApiCalls(SyncService, AlertsNotificationService, FeatureService);
                        }
                    }
                ]
            }
        })
        .state('unauthorized', {
            url: '/unauthorized',
            parent: 'error',
            templateUrl: unauthorizedTemplateUrl
        })
        .state('unsupportedBrowser', {
            url: '/unsupported',
            templateUrl: unsupportedBrowserTemplateUrl
        })
        .state('home', {
            templateUrl: homeLayoutUrl,
            controller: 'HomeLayoutCtrl',
            parent: 'renewtokens'
        })
        .state('error', {
            templateUrl: errorLayoutUrl,
            controller: 'ErrorLayoutCtrl'
        })
        .state('legalInformation', {
            url: '/legalinformation',
            parent: 'home',
            templateUrl: legalInformationTemplate,
            controller: 'LegalInformationCtrl',
            data: {
                roles: ['SysAdmin', 'Administrator', 'User']
            }
        })
        .state('privacyPolicy', {
            url: '/policies/privacy',
            templateUrl: privacyPolicyTemplate,
            controller: 'PrivacyPolicyCtrl'
        })
        .state('termsOfUse', {
            url: '/policies/termsofuse',
            templateUrl: termsOfUseTemplate,
            controller: 'TermsOfUseCtrl'
        })
        .state('aboutUs', {
            url: '/aboutus',
            parent: 'home',
            templateUrl: aboutUsTemplate,
            data: {
                brands: ['Kno2']
            }
        })

    $urlRouterProvider
        .otherwise(function ($injector, $location) {
            var path = $injector.get('RedirectService').redirect();
            return $location.replace().path(path);
        });
}

routeRun.$inject = ['$transitions'];

export function routeRun($transitions) {

    var criteria = {
        to: function (state) {
            return state.redirectTo != null;
        }
    };

    $transitions.onBefore(criteria, function (transition) {
        var state = transition.to().redirectTo;
        return transition.router.stateService.target(state);
    });
}

function makeInitialApiCalls(SyncService, AlertsNotificationService, FeatureService) {
    SyncService.register(AlertsNotificationService.refresh, 300);
    return FeatureService.load();
}
