intercomConfig.$inject = ['$transitions', '$window', 'SessionService', 'IntercomService'];

const blackListedHosts = [
    // 'kno2fy-dev.us'
];

export function intercomConfig($transitions, $window, SessionService, IntercomService) {
    const profile = SessionService.getProfile();

    if (!$window.Intercom) return;
    if (blackListedHosts.indexOf(profile.host) > -1) return;

    $transitions.onStart({}, function () {
        // Send intercom update event
        $window.Intercom('update');
    });

    if (profile && profile.userId) {
        IntercomService.boot();
    }
}
