UserInvitationModalCtrl.$inject = [
    '$scope', '$uibModalInstance', 'usersFactory', 'NotificationService', 'DocumentSourceService'
];

export function UserInvitationModalCtrl($scope, $uibModalInstance, usersFactory, NotificationService, DocumentSourceService) {

    $scope.forms = {};
    $scope.model = {};

    DocumentSourceService.getMyDocumentSources().then(function (data) {
        $scope.availableFromAddresses = _.map(data, 'address');
    });

    $scope.formSubmitted = false;

    $scope.sendInvitation = function () {
        $scope.formSubmitted = true;
        if ($scope.forms.invitation.$valid && !$scope.processing) {
            $scope.processing = true;
            usersFactory.sendInvitation({
                emailAddress: $scope.model.emailAddress,
                fullName: $scope.model.fullName,
                directAddress: $scope.model.fromDirectAddress
            }).then(function () {
                NotificationService.success("Invitation has been sent");
                $uibModalInstance.close();
            }).finally(function (e) {
                $scope.processing = false;
            });
        }
    };

    $scope.closeModal = function () {
        $uibModalInstance.dismiss('cancel');
    };
}
