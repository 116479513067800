angular.module('kno2.directives').directive('repaintSelect',
    function() {
        return {
            restrict: 'A',
            require: '?ngModel',
            link: function(scope, element, attrs, ngModel) {
                if (!ngModel) {
                    return;
                }
                scope.$watch(attrs.ngModel, function(newValue, oldValue) {
                    if (newValue !== oldValue) {
                        element.css('width', 0);
                        element.css('width', '');
                    }
                });
            }
        };
    }
);
