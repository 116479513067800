export class IdentityProvidersService {

    constructor($http) {
        this.$http = $http;
    }

    get() {
      return this.$http.get('/api/identityproviders');
    }

    getTypes() {
      return this.$http.get('/api/identityproviders/types');
    }

    upsert(identityProvider) {
        if (identityProvider.id)
            return this.update(identityProvider.objectId, identityProvider);
        else
            return this.add(identityProvider);
    }

    add(identityProvider) {
        return this.$http.post(`/api/identityproviders`, identityProvider);
    }

    update(id, identityProvider) {
        return this.$http.put(`/api/identityproviders/${id}`, identityProvider)
    }

    delete(id) {
      return this.$http.delete(`/api/identityproviders/${id}`);
    }
}

IdentityProvidersService.$inject = ['$http'];
