(function () {

    angular
        .module('kno2.factories')
        .factory('SupportedFileExtensionsFactory', SupportedFileExtensionsFactory);

    var extensions = [];

    SupportedFileExtensionsFactory.$inject = ['AttachmentService', '_'];

    function SupportedFileExtensionsFactory(AttachmentService, _) {
        return {
            load: load,
            getExtensions: getExtensions
        };

        function load() {
            return AttachmentService.getExtensions()
                .then(function(exts) {
                    _.each(exts, function (e) { extensions.push(e.substring(1)); });
                    return extensions;
                });
        }

        function getExtensions() {
            return extensions;
        }
    }

})();
