(function() {
    'use strict';

    angular
        .module('kno2.settings')
        .controller('DirectAddressesFaxModalCtrl', DirectAddressesFaxModalCtrl);

    DirectAddressesFaxModalCtrl.$inject = ['_', '$timeout', '$uibModalInstance', 'DocumentSourceFaxService', 'NotificationService', 'documentSourceId', 'faxNumber', 'faxNumbers'];

    function DirectAddressesFaxModalCtrl(_, $timeout, $uibModalInstance, DocumentSourceFaxService, NotificationService, documentSourceId, faxNumber, faxNumbers) {

        const ctrl = this;

        ctrl.$onInit = onInit;

        function getNumber(faxEntry) {
            if (faxEntry && faxEntry.number)
                return faxEntry.number;
            return null;
        }

        ctrl.faxNumbers = _.map(faxNumbers, getNumber) || [];
        ctrl.faxNumber = faxNumber || {};
        ctrl.save = save;
        ctrl.cancel = cancel;

        function save() {

            var exists = _.find(ctrl.faxNumbers,
                function(f) {
                    return f === ctrl.faxNumber.number;
                });
            if (exists)
            {
                NotificationService.error("The fax number is already in use.");
                return;
            }

            var promise;

            if (ctrl.faxNumber.id)
                promise = update();
            else
                promise = add();

            promise.then(function() {
                    NotificationService.success("The fax number was saved successfully.");
                    $uibModalInstance.dismiss('cancel');
                },
                function (response) {
                    if (response.status === 400)
                        NotificationService.serverError(response.data.modelState, response.data);
                    else if (response.status === 409) {
                        if (response.data === "Duplicate direct address")
                            NotificationService.error("The direct address is already in use.");
                        if (response.data === "Duplicate fax")
                            NotificationService.error("The fax number is already in use.");
                    }
                    else
                        NotificationService.error("There was an error adding the fax number, please try again.");
                });
        }

        function onInit() {
            $timeout(function () { $("input[name*='fax']").focus(); }, 250, false);
        }

        function add() {
            return DocumentSourceFaxService.addFaxNumber(documentSourceId, ctrl.faxNumber);
        }

        function update() {
            return DocumentSourceFaxService.updateFaxNumber(documentSourceId, ctrl.faxNumber.id, faxNumber);
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();