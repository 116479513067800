(function() {
    'use strict';

    angular
        .module('kno2.settings')
        .filter('twoFactorAuthStatus', twoFactorAuthStatusFilter);

    function twoFactorAuthStatusFilter() {
        return function(user) {
            if (user.security.twoFactorEnabled) {
                if (user.phoneNumberConfirmed)
                    return 'Yes';
                return 'Pending';
            }
            return 'No';
        }
    }

})();