angular.module('kno2.shared.settings')
    .controller('DocumentTypesDeleteModalCtrl', ['$scope', '$uibModalInstance', 'data', 'DocumentTypesService', 'NotificationService',
        function ($scope, $uibModalInstance, data, DocumentTypesService, NotificationService) {
            $scope.setForm = function (form) { $scope.form = form; };
            $scope.cancel = function () { $uibModalInstance.dismiss('cancel'); };
            $scope.ok = function () {
                DocumentTypesService.deleteDocumentType($scope.documentType.id)
                    .then(function (response) {
                        NotificationService.success("The document type was deleted successfully.");
                        $uibModalInstance.close($scope.documentType);
                    },
                        function (response) {
                            NotificationService.serverError(response.data.modelState, "There was an error deleting this document type.");
                        });
            };
            DocumentTypesService.getDocumentType(data.id).then(function (data) { $scope.documentType = data; });
        }
    ])
