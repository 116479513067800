angular.module('kno2.services')
    .service('RulesService', ['$http', 'LocalStorageFactory', '_', '$location', 'CacheService',
        function ($http, storage, _, $location, CacheService) {

            var _removeLocalRule = function (id) {
                var rules = storage.get('rules');
                if (rules) {
                    rules = _.filter(rules, function (r) {
                        return r.id != id;
                    });
                    storage.set('rules', rules);
                }
            };

            var _resetLocalRules = function (rules) {
                if (rules) {
                    storage.set('rules', rules);
                }
            };

            /*Replaced by getAllRules - Duplicate function.
            this.getAllOrgRules = function () {
    
                var promise = $http.get('/api/rules')
                    .then(function (response) {
                        return response.data;
                    });
                return promise;
            };*/

            this.getRule = function (id) {
                var promise = $http.get('/api/rules/' + id)
                    .then(function (response) {
                        return response.data;
                    });
                return promise;
            };

            this.getRules = function (userId) {
                var promise = $http.get('/api/users/{id}/rules/'.replace('{id}', userId), { cache: CacheService.cacheL2() })
                    .then(function (response) {
                        return response.data;
                    });
                return promise;
            };

            this.getTriageRules = function (userId) {
                var promise = $http.get('/api/users/{id}/rules/'.replace('{id}', userId), {
                    params: { type: 'Triage' },
                    cache: CacheService.cacheL2()
                })
                    .then(function (response) {
                        return response.data;
                    });
                return promise;
            };

            this.getAllRules = function () {
                var promise = $http.get('/api/rules', { cache: CacheService.cacheL2() })
                    .then(function (response) {
                        return response.data;
                    });
                return promise;
            };

            this.getAllTriageRules = function () {
                var promise = $http.get('/api/rules', {
                    params: { type: 'Triage' },
                    cache: CacheService.cacheL2()
                })
                    .then(function (response) {
                        return response.data;
                    });
                return promise;
            };

            this.createRule = function (ruleDto) {
                CacheService.clear('/rules');
                return $http.post('/api/rules/', ruleDto);
            };

            this.editRule = function (ruleDto) {
                _removeLocalRule(ruleDto.id);
                CacheService.clear('/rules');
                return $http.put('/api/rules/' + ruleDto.id, ruleDto);
            };

            this.deleteRule = function (id) {
                CacheService.clear('/rules');
                var promise = $http.delete('/api/rules/' + id)
                .then(function (response) {
                    return response;
                });
                _removeLocalRule(id);
                return promise;
            };

            this.getLocalRules = function () {
                return storage.get('rules') || [];
            };

            this.removeLocalRule = _removeLocalRule;

            this.resetLocalRules = _resetLocalRules;

            this.setLocalRules = _resetLocalRules;
        }]);
