(function (undefined) {
    'use strict';

    angular
        .module('kno2.filters')
        .filter('fromNow', fromNowFilter);

    fromNowFilter.$inject = ['moment'];

    function fromNowFilter(moment) {
        return function (value) {
            if (!value) return value;
            return moment(value).fromNow();
        };
    }

})();