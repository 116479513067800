angular.module('kno2.services')
    .service('WebHooksService', ['$http', '$filter', '_', function ($http, $filter, _) {

        this.list = function () {
            var promise = $http.get('/api/webhooks/').then(function (res) {
                return res.data;
            });
            return promise;
        };

        this.listevents = function () {
            return $http.get('/api/webhooks/events').then(function (res) {
                return res.data;
            });
        };

        this.authenticationTypes = function() {
            return $http.get('/api/webhooks/authenticationtypes').then(function(res) {
                return res.data;
            });
        };

        this.getById = function(id) {
            return $http.get('/api/webhooks/' + id).then(function (res) {
                return res.data;
            });
        }

        this.delete = function(id) {
            return $http.delete('/api/webhooks/' + id).then(function (res) {
                return res.data;
            });
        }

        this.upsert = function(data) {
            return $http.put('/api/webhooks/', data).then(function(res) {
                return res.data;
            });
        };

        this.update = function(data) {

        };

    }]);