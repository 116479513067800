(function () {
    'use strict';

    angular
        .module('kno2.services')
        .factory('FeatureService', FeatureService);

    FeatureService.$inject = ['$q', '$http', '_', 'SessionService', 'LocalStorageFactory'];

    function FeatureService($q, $http, _, SessionService, LocalStorageFactory) {

        var _profile = SessionService.getProfile();
        var _features = LocalStorageFactory.get('features');
        var _requesting = false;
        var _deferred;

        return {
            load: load,
            isEnabled: isEnabled
        };

        function load() {
            if (_features) return $q.resolve(_features);

            // handle simultaneous requests so the features api isn't queried mulitple times
            if (_requesting) {
                if (!_deferred) _deferred = $q.defer();
                return _deferred.promise;
            }
            _requesting = true;

            return $http.get(`/api/organizations/${_profile.organizationId}/features`)
                .then(function (response) {
                    _features = response.data;
                    LocalStorageFactory.set('features', _features);
                    if (_deferred) {
                        _deferred.resolve(_features);
                        _deferred = null;
                    }
                    _requesting = false;
                    return _features;
                });
        }

        /**
         * Accepts a string with a single value, a csv string and an array of strings.
         * @param {string|string[]} features
         */
        function isEnabled(features) {
            if (!features) return false;

            var array;
            if (_.isString(features))
                array = features.split(',').reduce(function (result, str) {
                    var value = str.trim();
                    if (value) result.push(value);
                    return result;
                }, []);
            else if (_.isArray(features))
                array = features;
            else
                return false;

            if (!array.length) return false;

            var matches = _.intersection(_features, array);
            return matches.length === array.length;
        }
    }

})();
