export const planUpgradePrompts = {

    directAddressesRouteAdmin: `Release options are not available in the Stay Connected plan.  Would you like to upgrade your plan to start securely sending patient documents?`,
    forwardEmrPlanUpgradeAdmin: `Forwarding to an EMR is not available in the Stay Connected plan.  Would you like to upgrade your plan to allow for message forwarding?`,
    intakeDownloadXdsSdCdaButtonAdmin: `XDS-SD/CDA allows for advanced EMR compatible downloads from the system.  This option is not available in the Stay Connected plan.  Would you like to upgrade your plan to allow for download to XDS-SD/CDA?`,
    releaseRouteAdmin: `Release options are not available in the Stay Connected plan.  Would you like to upgrade your plan to start securely sending patient documents?`,
    releaseRouteUser: `Release options are not available in the Stay Connected plan.  Would you like to send a request to upgrade your current plan to your Administrator to start securely sending patient documents?`,
    releaseSendButtonAdmin: `Release options are not available in the Stay Connected plan. Would you like to upgrade your plan to be able to send to all Direct addresses?`,
    releaseSendButtonUser: `Release options are not available in the Stay Connected plan. Would you like to send a request to upgrade your plan to your Administrator to be able to send to all Direct addresses?`,
    releaseTypesGridLinkAdmin: `Release options cannot be configured in the Stay Connected plan.  Would you like to upgrade your plan to be able to send to all Direct addresses?`,
    releaseTypesRouteAdmin: `Release Types are used for automating the secure release of patient documents and are not available for configuration in your current plan.  Would you like to upgrade your plan to start securely sending patient documents?`,
    replyAndForwardButtonAdmin: `Forward and Reply options are not available in the Stay Connected plan. Would you like to upgrade your plan to include these options?`,
    replyAndForwardButtonUser: `Forward and Reply options are not available in the Stay Connected plan. Would you like to send a request to upgrade your current plan to your Administrator, to include these options?`,
    triageRouteAdmin: `Triage options are not available with the Stay Connected plan. Would you like to upgrade your plan to start performing batch processing through one centralized location?`,
    triageRouteUser: `Triage options are not available with the Stay Connected plan. Would you like to send a request to upgrade your current plan to your Administrator to start performing batch processing through one centralized location?`
};
