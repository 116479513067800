import headerComponentTemplate from './header.component.html';
import userInvitationModalTemplate from '../../../common/user-invitation/account.dashboard.user-invitation-modal.html';

class HeaderController {
    constructor($uibModal, $rootScope) {
        this.$uibModal = $uibModal;
        this.$rootScope = $rootScope;
    }

    isOtherBrand() {
        return this.$rootScope.environment.brand !== 'Kno2';
    }

    openInvitationModal() {
        this.$uibModal.open({
            templateUrl: userInvitationModalTemplate,
            controller: 'UserInvitationModalCtrl'
        }).result.then(() => { }, () => { });
    };

    logout() {
        this.$rootScope.logoff();
    }
}
HeaderController.$inject = ['$uibModal', '$rootScope'];
export const headerComponent = {
    templateUrl: headerComponentTemplate,
    controller: HeaderController
};
