export class BrandService {

    constructor($http) {
        this.$http = $http;
    }

    getBrands() {
        return this.$http.get('/api/admin/brands', { cache: true }).then(x => x.data);
    }
}

BrandService.$inject = ['$http'];
