import templateUrl from './webhooks-delete.modal.component.html';

export const webhooksDeleteModalComponent = {
    templateUrl: templateUrl,
    controller: WebHooksDeleteModalController,
    bindings: {
        resolve: '<',
        modalInstance: '<'
    }
};

WebHooksDeleteModalController.$inject = ['WebHooksService', 'NotificationModalService'];

export function WebHooksDeleteModalController(WebHooksService, NotificationModalService) {
    var ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.dismissModal = dismissModal;
    ctrl.delete = deleteWebhook;

    function $onInit() {
        ctrl.model = ctrl.resolve.model;
        ctrl.title = 'Delete Webhook';
    }

    function dismissModal() {
        ctrl.modalInstance.dismiss();
    }

    function deleteWebhook() {
        WebHooksService.delete(ctrl.model.id).then(function (d) {
            ctrl.modalInstance.close();
        }, function (error) {
            NotificationModalService.error(error);
        });
    }
}
