NewFeaturesAndUpdatesModalCtrl.$inject = ['$scope', '$uibModalInstance', 'DashboardService', '$uibModal'];
export function NewFeaturesAndUpdatesModalCtrl($scope, $uibModalInstance, DashboardService, $uibModal) {
    $scope.dismissInviteModal = function () {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.getFeatures = function () {
        DashboardService.getFeatures()
            .then(function (response) {
                $scope.features = response.features;
            });
    };

    // Controller Initialization
    $scope.init = function () {
        $scope.getFeatures();
    };

    // Initial load.
    $scope.init();
}
