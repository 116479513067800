httpConfig.$inject = ['$httpProvider', 'httpBatchConfigProvider'];

export function httpConfig($httpProvider, httpBatchConfigProvider) {
    $httpProvider.interceptors.push('AuthInterceptorFactory');
    $httpProvider.interceptors.push('LoadingInterceptorFactory');
    $httpProvider.interceptors.push('ApiInterceptorFactory');

    httpBatchConfigProvider.setAllowedBatchEndpoint('/', '/api/batch', {
        canBatchRequest: function (url) {
            // only batch transform requests for now
            return /^\/api\/messages\/(.)+\/attachments\/(.)+\/transform$/.test(url);
        },
        enabled: false
    });
}
